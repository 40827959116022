import { makePetition } from "../../../../helpers/petition";

const fetchPresentacion = async (requesUrl, requestMethod, token, requestData) => {
  try {
    const requestToken = token;
    const request = await makePetition(
      requesUrl,
      requestData,
      requestMethod,
      requestToken
    );
    return request;
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message:
        "Ocurrió un error inexperado, por favor vuelva a intentar. Si este error persiste contacte al administrador del sistema",
    };
  }
};
const presentacionesPeticiones = async (method, token, requestData = {}) => {
  switch (method) {
    case "obtenerListaPresentacions":
      return await fetchPresentacion(`presentaciones/lista`, "GET", token, requestData);
    case "obtenerPresentacionPorId":
      const { presentacionId = 0 } = requestData;
      return await fetchPresentacion(`presentaciones/presentacion/${presentacionId}`, "GET", {});
    case "guardarDatosPresentacionEdicion":
      return await fetchPresentacion(`presentaciones/editar`, "PUT", token, requestData);
    case "eliminarPresentacion":
      return await fetchPresentacion(`presentaciones/eliminar`, "DELETE", token, requestData);
    default:
      return {
        success: false,
        message:
          "Ocurrió un error inexperado, por favor vuelva a intentar. Si este error persiste contacte al administrador del sistema",
      };
  }
};

export { presentacionesPeticiones };
