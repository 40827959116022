import { makePetition } from "../../../../helpers/petition";

const fetchFactura = async (
  requesUrl,
  requestMethod,
  requestToken,
  requestData
) => {
  try {
    const request = await makePetition(
      requesUrl,
      requestData,
      requestMethod,
      requestToken
    );
    return request;
  } catch (error) {
    throw new Error("Errror en peticion.");
  }
};
export const guardarNuevaFactura = async (requestData, token) =>
  await fetchFactura(`facturas/save`, "POST", token, requestData);
export const getFacturaLista = async (token) =>
  await fetchFactura(`facturas/obtenerFacturas`, "GET", token, {});
export const getFacturaPorId = async (idFactura, token) =>
  await fetchFactura(
    `facturas/obtenerFacturaPorId/${idFactura}`,
    "GET",
    token,
    {}
  );
//obtenerClienteFacturaPorFacturaId
export const getClienteFacturaPorId = async (idFactura, token) =>
  await fetchFactura(
    `facturas/obtenerClienteFacturaPorFacturaId/${idFactura}`,
    "GET",
    token,
    {}
  );
//obtenerProductosFacturaPorFacturaId
export const getFacturaProductosPorId = async (idFactura, token) =>
  await fetchFactura(
    `facturas/obtenerProductosFacturaPorFacturaId/${idFactura}`,
    "GET",
    token,
    {}
  );
//eliminarFactura use PUT
export const eliminarFactura = async (facturaId, token) =>
  await fetchFactura(`facturas/eliminar`, "PUT", token, {
    facturaId: parseInt(facturaId),
  });
//anularFactura use PUT
export const anularFactura = async (facturaId, token, razonAnulacion = "") =>
  await fetchFactura(`facturas/anular`, "PUT", token, {
    facturaId: parseInt(facturaId),
    razonAnulacion: razonAnulacion,
  });
