import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
} from "@themesberg/react-bootstrap";
import { ErrorAlert } from "../../Utils/ErrorAlert";
import { SuccessAlert } from "../../Utils/SuccessAlert";
import onlyLettersAndNumbers from "../../Utils/onlyLettersAndNumbers";
import { marcasPeticiones } from "../helper/peticionesMarcas";
import { useAuthContext } from "../../../../contexts/authContext";

const EditarMarca = ({
  marcaId,
  mostrarEditarMarca,
  setMostrarEditarMarca,
  marcaActualizadaHandle,
}) => {
  const { logout, token: Token } = useAuthContext();
  const alertaTextoInicial = {
    title: "",
    message: "",
  };
  const [Marca, setMarca] = useState("");
  const [alertaVisible, setAlertaVisible] = useState(false);
  const [desactivarBotonGuardar, setDesactivarBotonGuardar] = useState(false);
  const [alertText, setAlertText] = useState(alertaTextoInicial);
  const [alertSuccessVisible, setAlertSuccessVisible] = useState(false);
  const [alertSuccessText, setAlertSuccessText] = useState(alertaTextoInicial);
  const [consultarDatosDeMarca, setconsultarDatosDeMarca] = useState(true);
  const handleMarca = ({ target }) => {
    const { value } = target;
    setMarca(value);
  };
  useEffect(() => {
    if (!mostrarEditarMarca) {
      setAlertSuccessVisible(false);
      setAlertaVisible(false);
      setAlertText({
        title: "",
        message: "",
      });
      setAlertSuccessText({
        title: "",
        message: "",
      });
      setMarca("");
    }
  }, [mostrarEditarMarca]);
  useEffect(() => {
    if (consultarDatosDeMarca) {
      const obtenerDatosMarca = async () => {
        try {
          if (typeof marcaId === "undefined" || isNaN(marcaId) || marcaId < 1) {
            setAlertaVisible(true);
            setAlertText({
              title: "Error",
              message:
                "La marca no puede ser identificada, por favor contacta con soporte",
            });
            return false;
          }
          const request = await marcasPeticiones(
            "obtenerMarcaPorId",
            Token,
            { marcaId }
          );
          const { status, ok } = request;
          if (status === 401) {
            logout();
            return false;
          }
          if (!ok) {
            throw new Error("Error de petición.");
          }
          const response = await request.json();
          const {
            success = false,
            data = [],
            message = "",
          } = response;
          if (!success || Object.keys(data).length < 1) {
            console.error(message);
            setAlertaVisible(true);
            setAlertText({
              title: "Error",
              message:
                "Los datos de la marca no fueron encontrados, por favor contacta con soporte",
            });
            return false;
          }
          const { marcaDescripcion = "" } = data;
          setMarca(marcaDescripcion);
        } catch (error) {
          console.error(error);
          setAlertText({
            title: "Error",
            message: "Ocurrió un error inexperado, por favor contacta con soporte",
          });
          setAlertaVisible(true);
          setDesactivarBotonGuardar(false);
        }
      };
      setconsultarDatosDeMarca(false);
      obtenerDatosMarca();
    }
  }, [consultarDatosDeMarca, setconsultarDatosDeMarca,Token,logout,marcaId]);

  const guardarMarca = async (e) => {
    try {
      e.preventDefault();
      setAlertSuccessVisible(false);
      setAlertaVisible(false);
      if (Marca.trim().length <= 1) {
        setAlertText({
          title: "Error",
          message: "La descripción de Marca debe ser mayor a 1 caracter.",
        });
        setAlertaVisible(true);
        return false;
      }
      setDesactivarBotonGuardar(true);

      const request = await marcasPeticiones(
        "guardarDatosMarcaEdicion",
        Token,
        { marcaId: marcaId, marcaDescripcion: Marca }
      );
      const { status, ok } = request;
      if (status === 401) {
        logout();
        return false;
      }
      if (!ok) {
        throw new Error("Error de petición.");
      }
      const response = await request.json();
      const { success, message = "" } = response;
      if (!success) {
        setAlertText({ title: "Error", message: message });
        setAlertaVisible(true);
        setDesactivarBotonGuardar(false);
        return false;
      }
      setMarca("");
      setAlertSuccessText({ title: "Exito", message: message });
      setAlertSuccessVisible(true);
      setDesactivarBotonGuardar(false);
      setTimeout(() => {
        setAlertSuccessVisible(false);
        marcaActualizadaHandle(true);
      }, 1800);
      return true;
    } catch (error) {
      console.error(error);
      setAlertText({
        title: "Error",
        message: "Ocurrió un error inexperado, por favor contacta con soporte",
      });
      setAlertaVisible(true);
      setDesactivarBotonGuardar(false);
    }
  };
 
  return (
    <>
      <Modal
        as={Modal.Dialog}
        centered
        show={mostrarEditarMarca}
        onHide={() => setMostrarEditarMarca(false)}
        backdrop="static"
        keyboard={false}
        size="md"
      >
        <Container>
          <Modal.Header className="text-center">
            <Button
              variant="close"
              aria-label="Close"
              onClick={() => setMostrarEditarMarca(false)}
            />
          </Modal.Header>
          <Form onSubmit={guardarMarca}>
            <Modal.Body>
              <Row>
                <Col>
                  <h2>Editar Marca</h2>
                </Col>
                <Col xs={12}>
                  <Form.Group className="mb-4">
                    <Form.Label></Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Ingrese el nombre de la Marca"
                      id="Marca"
                      name="Marca"
                      value={Marca}
                      onChange={handleMarca}
                      size="md"
                      onKeyPress={(event) =>
                        event.target.value.toString().length > 98
                          ? event.preventDefault()
                          : onlyLettersAndNumbers(event)
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Col xs={12}>
                {alertaVisible ? (
                  <ErrorAlert
                    title={alertText.title}
                    message={alertText.message}
                  />
                ) : null}
                {alertSuccessVisible ? (
                  <SuccessAlert
                    title={alertSuccessText.title}
                    message={alertSuccessText.message}
                  />
                ) : null}
              </Col>
              <Button
                size="md"
                variant="outline-primary"
                className="w-100 mt-2"
                onClick={guardarMarca}
                disabled={desactivarBotonGuardar}
              >
                Guardar
              </Button>
              <Button
                size="md"
                variant="danger"
                className="w-100 mt-1"
                onClick={() => setMostrarEditarMarca(false)}
                disabled={desactivarBotonGuardar}
              >
                Cancelar
              </Button>
            </Modal.Footer>
          </Form>
        </Container>
      </Modal>
    </>
  );
};

export default EditarMarca;
