import { React, useState, useEffect } from "react";
import { useAuthContext } from "../../../contexts/authContext";
import {
  Alert,
  Button,
  Col,
  Form,
  InputGroup,
  Modal,
  Row,
} from "@themesberg/react-bootstrap";
import { GoCheck } from "react-icons/go";
import { BiBlock } from "react-icons/bi";
import { AiOutlineAlert } from "react-icons/ai";
import { format } from "date-fns";
import {
  obtenerFacturaCompraPorCodigo,
  obtenerListaProveedores,
} from "./helper/productosPeticiones";
import onlyNumbers from "../Utils/onlyNumbers";
import customDecimalRound from "../Utils/customDecimalRound";
import DatePicker from "react-date-picker";
import ProductoPrecios from "./ProductoPrecios";
//import moment from "moment-timezone";
import onlyLettersAndNumbers from "../Utils/onlyLettersAndNumbers";
import onlyLetters from "../Utils/onlyLetters";
const FomularioCompraIndividual = ({
  mostarAdministradorExistencia,
  setMostarAdministradorExistencia,
  estadoDeExistencia,
  setEstadoDeExistencia,
  guardarNuevaExistenciaHandler,
}) => {
  const { logout, token: Token } = useAuthContext();
  let {
    productosId = 0,
    proveedoresId = 0,
    codigoProducto = "",
    precioCompraIndividualProducto = 0.0,
    descripcionProducto = "",
    precioVentaListaProducto = 0.0,
    nuevaExistenciaProducto = 0.0,
    existenciaActualProducto = 0.0,
    facturaCodigo = "",
    facturaFecha = "",
    facturaFechaVencimiento = "",
    facturaComentarios = "",
  } = estadoDeExistencia;
  const [mostrarAlerta, setMostrarAlerta] = useState(false);
  const [mensajeAlerta, setMensajeAlerta] = useState("");
  const [consultarProveedores, setConsultarProveedores] = useState(true);
  const [listaProveedores, setListaProveedores] = useState([]);
  const [consultaProveedoresErrorMessage, setConsultaProveedoresErrorMessage] =
    useState("");
  const [deshabilitarBotonForm, setDeshabilitarBotonForm] = useState(false);
  const [precioLista, setPrecioLista] = useState(precioVentaListaProducto);
  const [precioCompra, setPrecioCompra] = useState(0);
  const [guardarNuevaExistencia, setGuargarNuevaExistencia] = useState(false);
  const [unoPorcientoHabilitado, setUnoPorcientoHabilitado] = useState(false);
  const [valorTotalIva, setValorTotalIva] = useState(0.0);
  const [valorTotalIvaUnoPorCiento, setValorTotalIvaUnoPorCiento] =
    useState(0.0);
  const [valorTotalFinal, setValorTotalFinal] = useState(0.0);
  const [valorSubTotal, setValorSubTotal] = useState(0.0);
  useEffect(() => {
    let valorFinal = 0.0;
    let valorConIva = 0.0;
    let valorConIvaUnoPorciento = 0.0;
    if (unoPorcientoHabilitado) {
      valorConIvaUnoPorciento = customDecimalRound(valorSubTotal * 0.01);
    }
    valorConIva = customDecimalRound(valorSubTotal * 0.13);
    valorFinal = valorSubTotal + valorConIva + valorConIvaUnoPorciento;
    valorFinal = customDecimalRound(valorFinal);
    setValorTotalIva(valorConIva);
    setValorTotalIvaUnoPorCiento(valorConIvaUnoPorciento);
    setValorTotalFinal(valorFinal);
  }, [valorSubTotal, unoPorcientoHabilitado]);

  const camposNumericosConDosDecimales = [
    "facturaIva",
    "facturaIvaUnoPorCiento",
    "facturaSubTotal",
    "facturaTotal",
    "precioProducto",
    "nuevaExistenciaProducto",
    "precioProducto",
  ];
  const camposConFechas = ["facturaFecha", "facturaFechaVencimiento"];
  const estadoDeExistenciaHandler = (e, v = null) => {
    if (camposConFechas.includes(e)) {
      //En este caso se esta usando el componente de fecha, por lo que se debe pasar el valor de la fecha directamente en la variable v
      // y el nombre del campo en la variable e
      setEstadoDeExistencia({
        ...estadoDeExistencia,
        [e]: v,
      });
    } else {
      const inputName = e.target.name;
      let inputValue = e.target.value;
      if (camposNumericosConDosDecimales.includes(inputName)) {
        inputValue = customDecimalRound(inputValue);
      }
      setEstadoDeExistencia({
        ...estadoDeExistencia,
        [inputName]: inputValue,
      });
    }
  };
  useEffect(() => {
    if (guardarNuevaExistencia) {
      try {
        setGuargarNuevaExistencia(false);
        (async () => {
          try {
            const requestGuardarExistencia =
              await guardarNuevaExistenciaHandler();
            const { ok, status } = requestGuardarExistencia;
            if (status === 401) {
              logout();
              return false;
            }
            if (!ok) {
              throw new Error(
                "Ocurrió un error al guardar un nueva existencia. Por favor contacte el administrador."
              );
            }
            const response = await requestGuardarExistencia.json();
            const { success = false, message = "" } = response;
            if (!success) {
              setMensajeAlerta(message || "Ocurrió un error inesperado.");
              setMostrarAlerta(true);
              setDeshabilitarBotonForm(false);
            }
          } catch (error) {
            setDeshabilitarBotonForm(false);
            setMensajeAlerta(
              "Ocurrió un error al consultar los proveedores. Por favor contacte el administrador."
            );
            setMostrarAlerta(true);
          }
        })();
      } catch (error) {
        console.error(error);
      }
    }
  }, [guardarNuevaExistencia, guardarNuevaExistenciaHandler, logout]);
  const formularioExistenciaSubmitHandler = async (e) => {
    try {
      e.preventDefault();
      let error = false;
      let errorMessage = "Ocurrió un error inesperado.";
      setDeshabilitarBotonForm(true);
      if (productosId < 1) {
        error = true;
        errorMessage = "El producto es requerido";
      }
      if (nuevaExistenciaProducto < 0.01) {
        error = true;
        errorMessage = "La nueva existencia del producto es requerida";
      }
      if (precioCompraIndividualProducto < 0.01) {
        error = true;
        errorMessage = "El precio de compra del producto es requerido";
      }
      if (facturaCodigo.trim().length < 1) {
        error = true;
        errorMessage = "El codigo de la factura es requerido";
      }
      //valorConIvaUnoPorciento
      if (valorTotalIva < 0.01) {
        error = true;
        errorMessage = "El iva de la factura es requerido";
      }
      if (valorSubTotal < 0.01) {
        error = true;
        errorMessage = "El subtotal de la factura es requerido";
      }
      if (valorTotalFinal < 0.01) {
        error = true;
        errorMessage = "El total de la factura es requerido";
      }
      if (facturaFecha === null) {
        error = true;
        errorMessage = "La fecha de la factura es requerida";
      }
      let facturaFechaFormateada = format(facturaFecha, "yyyy/MM/dd");
      let facturaFechaVencimientoFormateada = "";
      if (facturaFechaVencimiento !== null) {
        facturaFechaVencimientoFormateada = format(
          facturaFechaVencimiento,
          "yyyy/MM/dd"
        );
        if (
          new Date(facturaFechaVencimientoFormateada) <
          new Date(facturaFechaFormateada)
        ) {
          error = true;
          errorMessage =
            "La fecha de vencimiento no puede ser menor a la fecha de factura.";
        }
      }
      if (error) {
        setMensajeAlerta(errorMessage);
        setMostrarAlerta(true);
        setDeshabilitarBotonForm(false);
        return false;
      }
      setEstadoDeExistencia({
        ...estadoDeExistencia,
        facturaFechaFormateada: facturaFechaFormateada,
        facturaFechaVencimientoFormateada: facturaFechaVencimientoFormateada,
        facturaIvaUnoPorciento: valorTotalIvaUnoPorCiento,
        facturaUnoPorcientoHabilitado: unoPorcientoHabilitado ? 1 : 0,
        facturaIva: customDecimalRound(0.13),
        facturaTotalIva: valorTotalIva,
        facturaSubTotal: valorSubTotal,
        facturaTotal: valorTotalFinal,
      });
      setGuargarNuevaExistencia(true);
      return true;
    } catch (error) {
      setDeshabilitarBotonForm(false);
      setMensajeAlerta(
        "Ocurrió un error al guardar la existencia del producto. Por favor intente de nuevo, si el problema persiste contacte al administrador del sistema"
      );
      setMostrarAlerta(true);
      return false;
    }
  };

  useEffect(() => {
    const asignarValorPrecioProducto = setTimeout(() => {
      setPrecioCompra(precioCompraIndividualProducto);
    }, 1000);
    return () => clearTimeout(asignarValorPrecioProducto);
  }, [precioCompraIndividualProducto]);

  useEffect(() => {
    if (consultarProveedores) {
      setConsultarProveedores(false);
      const obtenerListaProveedoresHandler = async () => {
        try {
          const request = await obtenerListaProveedores(Token);
          const { ok, status } = request;
          if (status === 401) {
            logout();
            return false;
          }
          if (!ok) {
            throw new Error(
              "Ocurrió un error al consultar los proveedores. Por favor contacte el administrador."
            );
          }
          const response = await request.json();
          const { success, message = "", data = [] } = response;
          if (!success) {
            const errorMessage = message.toString().trim();
            setConsultaProveedoresErrorMessage(
              errorMessage.trim().length > 0
                ? errorMessage
                : "Ocurrió un error al consultar los proveedores"
            );
          }
          setListaProveedores(data);
        } catch (error) {
          setDeshabilitarBotonForm(false);
          setMensajeAlerta(
            "Ocurrió un error al consultar los proveedores. Por favor contacte el administrador."
          );
          setMostrarAlerta(true);
        }
      };
      obtenerListaProveedoresHandler();
    }
  }, [consultarProveedores, Token, logout]);

  useEffect(() => {
    if (facturaCodigo && facturaCodigo.toString().trim().length > 2) {
      const consultarFacturaPorCodigo = setTimeout(async () => {
        try {
          const request = await obtenerFacturaCompraPorCodigo(
            facturaCodigo,
            Token
          );
          const { ok, status } = request;
          if (status === 401) {
            logout();
            return false;
          }
          if (!ok) {
            throw new Error(
              "Ocurrió un error interno al consultar los datos. Por favor contacte el administrador."
            );
          }
          const response = await request.json();
          const { success, message = "", data = {} } = response;
          if (!success) {
            return false;
          }
          const {
            facturaCompraCodigo,
            facturaCompraFecha,
            facturaCompraFechaVencimiento,
            facturaCompraComentarios,
            facturaCompraIva,
            facturaCompraSubTotal,
            facturaCompraTotal,
          } = data;
          const facturaCompraFechaFormateada = new Date(facturaCompraFecha);
          const facturaCompraFechaVencimientoFormateada = new Date(
            facturaCompraFechaVencimiento
          );
          setEstadoDeExistencia({
            ...estadoDeExistencia,
            facturaCodigo: facturaCompraCodigo,
            facturaFecha: !isNaN(facturaCompraFechaFormateada.getTime())
              ? facturaCompraFechaFormateada
              : null,
            facturaFechaVencimiento: !isNaN(
              facturaCompraFechaVencimientoFormateada.getTime()
            )
              ? facturaCompraFechaVencimientoFormateada
              : null,
            facturaComentarios: facturaCompraComentarios,
            facturaIva: facturaCompraIva,
            facturaSubTotal: facturaCompraSubTotal,
            facturaTotal: facturaCompraTotal,
          });
        } catch (error) {
          setDeshabilitarBotonForm(false);
          setMensajeAlerta(
            "Ocurrió un error al obtener los datos. Por favor contacte el administrador."
          );
          setMostrarAlerta(true);
        }
      }, 3000);

      return () => clearTimeout(consultarFacturaPorCodigo);
    }
  }, [facturaCodigo,Token, logout]);

  return (
    <>
      <Modal
        show={mostarAdministradorExistencia}
        onHide={() => setMostarAdministradorExistencia(false)}
        centered
        as={Modal.Dialog}
        backdrop="static"
        keyboard={false}
        size="xl"
        dialogClassName="modal-90w"
      >
        <Form onSubmit={formularioExistenciaSubmitHandler}>
          <Modal.Header className="mx-4" closeButton>
            <Modal.Title>Registro de compra</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container-fluid">
              <Row>
                <Col>
                  <Row className="mt-4">
                    <h5 className="text-center">Datos de Factura.</h5>
                  </Row>
                  <Row className="mt-2">
                    <Col>
                      <Form.Group>
                        <Form.Label>Código Factura de Compra</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Factura de Compra"
                          value={facturaCodigo}
                          name="facturaCodigo"
                          onChange={estadoDeExistenciaHandler}
                          autoComplete="off"
                          onKeyPress={(event) => onlyLettersAndNumbers(event)}
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label>Fecha de Compra</Form.Label>
                        <DatePicker
                          className="form-control"
                          value={facturaFecha}
                          format="dd-MM-yyyy"
                          locale="es-ES"
                          name="facturaFecha"
                          onChange={(value) =>
                            estadoDeExistenciaHandler("facturaFecha", value)
                          }
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col>
                      <Form.Group>
                        <Form.Label>Fecha de Vencimiento</Form.Label>
                        <DatePicker
                          className="form-control"
                          value={facturaFechaVencimiento}
                          format="dd-MM-yyyy"
                          locale="es-ES"
                          name="facturaFechaVencimiento"
                          onChange={(value) =>
                            estadoDeExistenciaHandler(
                              "facturaFechaVencimiento",
                              value
                            )
                          }
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label>Comentarios Factura</Form.Label>
                        <textarea
                          type="text"
                          className="form-control"
                          placeholder="Comentarios"
                          value={facturaComentarios}
                          name="facturaComentarios"
                          onChange={estadoDeExistenciaHandler}
                          onKeyPress={(event) => onlyLetters(event)}
                          cols="30"
                          rows="4"
                        ></textarea>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col>
                      <Form.Group>
                        <Form.Label>Impuesto al Valor Agregado 13%</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Iva en factura"
                          value={valorTotalIva}
                          name="valorTotalIva"
                          step={0.01}
                          onKeyPress={(event) => onlyNumbers(event)}
                          readOnly
                          required
                        />
                      </Form.Group>
                      <Form.Group className="mt-1">
                        <Form.Label>Iva Percibido</Form.Label>
                        <InputGroup className="mb-3">
                          <InputGroup.Checkbox
                            onChange={({ target }) =>
                              setUnoPorcientoHabilitado(target.checked)
                            }
                            checked={unoPorcientoHabilitado}
                          />
                          <Form.Control
                            type="number"
                            placeholder="Retencion del 1%"
                            value={valorTotalIvaUnoPorCiento}
                            name="valorTotalIvaUnoPorCiento"
                            step={0.01}
                            min={0.0}
                            readOnly
                          />
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label>Sub Total</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Sub Total Sin IVA"
                          value={valorSubTotal}
                          onChange={({ target }) => {
                            let value = target.value;
                            value = customDecimalRound(value);
                            setValorSubTotal(value);
                          }}
                          name="valorSubTotal"
                          step={0.01}
                          min={0.0}
                          required
                        />
                      </Form.Group>
                      <Form.Group className="mt-1">
                        <Form.Label>Total</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Factura Total"
                          value={valorTotalFinal}
                          name="valorTotalFinal"
                          step={0.01}
                          min={0.0}
                          readOnly
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row className="mt-4">
                    <h5 className="text-center">Datos del Producto.</h5>
                  </Row>
                  <Row className="mt-2">
                    <Col>
                      <Form.Group>
                        <Form.Label>Código del Producto</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Codigo del Producto"
                          value={codigoProducto}
                          name="productosCodigo"
                          onChange={estadoDeExistenciaHandler}
                          disabled
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label>Descripción del Producto</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Descripción del Producto"
                          value={descripcionProducto}
                          name="descripcionProducto"
                          onChange={estadoDeExistenciaHandler}
                          disabled
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col>
                      <Form.Group>
                        <Form.Label>Preveedor Compra</Form.Label>
                        <Form.Select
                          name="proveedoresId"
                          value={proveedoresId}
                          onChange={estadoDeExistenciaHandler}
                          required
                        >
                          <option value={0}>Seleccione un proveedor</option>
                          {listaProveedores.map((proveedor) => {
                            const {
                              index,
                              proveedoresId,
                              proveedoresDescripcion,
                            } = proveedor;
                            return (
                              <option key={index} value={proveedoresId}>
                                {proveedoresDescripcion}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group id="precioProducto">
                        <Form.Label>Precio del Producto Por Unidad</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Precio del Producto"
                          value={precioCompraIndividualProducto}
                          name="precioProducto"
                          onChange={estadoDeExistenciaHandler}
                          onKeyPress={(event) => onlyNumbers(event)}
                          step={0.01}
                          min={0.0}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col>
                      <Form.Group>
                        <Form.Label>Nueva Existencia</Form.Label>
                        <Form.Control
                          type="number"
                          size="lg"
                          style={{ size: "1.2em" }}
                          value={nuevaExistenciaProducto}
                          name="nuevaExistenciaProducto"
                          onChange={estadoDeExistenciaHandler}
                          onKeyPress={(event) => onlyNumbers(event)}
                          step={0.01}
                          min={0.0}
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-2">
                {consultaProveedoresErrorMessage.trim().length > 0 && (
                  <Col>
                    <Alert variant="danger" show={true} dismissible>
                      <Alert.Heading>
                        <AiOutlineAlert size={25} />
                        <span className="mx-2">¡Atención!</span>
                      </Alert.Heading>
                      <p>{consultaProveedoresErrorMessage.trim()}</p>
                    </Alert>
                  </Col>
                )}
                <Col>
                  <Alert
                    variant="danger"
                    show={mostrarAlerta}
                    onClose={() => setMostrarAlerta(false)}
                    dismissible
                  >
                    <Alert.Heading>
                      <AiOutlineAlert size={25} />
                      <span className="mx-2">¡Atención!</span>
                    </Alert.Heading>
                    <p>{mensajeAlerta}</p>
                  </Alert>
                </Col>
              </Row>
              <ProductoPrecios
                precioLista={precioLista}
                setPrecioLista={setPrecioLista}
                precioCompra={precioCompra}
                setPrecioCompra={setPrecioCompra}
              />
            </div>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center">
            <Button
              type="button"
              variant="outline-info buttonWitthIconsBlack"
              onClick={() => setMostarAdministradorExistencia(false)}
              disabled={deshabilitarBotonForm}
            >
              <BiBlock className="mx-2" /> Cancelar
            </Button>
            <Button
              type="submit"
              variant="outline-info buttonWithIconsBlack"
              disabled={deshabilitarBotonForm}
            >
              <GoCheck className="mx-2" /> Guardar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};
export default FomularioCompraIndividual;
