import { Button, Col, Container, Modal, Row } from '@themesberg/react-bootstrap';
import React from 'react'
import ProveedorForm from './ProveedorForm';
import proveedores01 from "../../../assets/img/tiendaBuenPrecio/util/proveedores01.svg";
const ProveedorModalForm = (
{
    mostrarModalProveedor,
    setMostarModalProveedor,
    setProveedorIdModalState,
    setConsultarProveedores
}
) => {
  return (
    <>
      <Container>
        <Modal 
            as={Modal.Dialog} 
            centered 
            show={mostrarModalProveedor} 
            onHide={()=>setMostarModalProveedor(false)}
            backdrop="static"
            keyboard={false}
            size="xl"
            className='w-100'
            animation={true}
            autoFocus={true}
        >
            <Modal.Header className="text-center">
                    {/* <Modal.Title className="h2 w-100">Agregar Proveedor</Modal.Title> */}
                    <Button variant="close" aria-label="Close" onClick={()=>setMostarModalProveedor(false)} />
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12} className="d-flex justify-content-start">
                            <img src={proveedores01} width="40" height="40" className="svg-icon mx-3" alt="Agregar"/>
                            <h3>Nuevo Proveedor</h3>
                        </Col>
                    </Row>
                    <Row>
                       
                            <Col xs={12}>
                                <ProveedorForm 
                                    type={"NUEVO"}
                                    proveedorId={0}
                                    callFromModal={true}
                                    modalParams= {
                                        {
                                            closeModalEvent:setMostarModalProveedor,
                                            setProveedorId:setProveedorIdModalState,
                                            consultarListaProveedores:setConsultarProveedores
                                        }
                                    }
                                />
                            </Col>    
                    </Row>
                </Modal.Body>
        </Modal>

                
               
        </Container>
    </>
  )
}

export default ProveedorModalForm;