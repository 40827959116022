import React, { useEffect, useState } from 'react'
import FileBase64 from 'react-file-base64';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const ClientesImagenes = ({listaClientesImagenes,setListaClientesImagenes,imagenPreviewDui,imagenPreviewAdicional}) => {
    const [claseAsignada, setClaseAsignada] = useState(false)
    const MySwal = withReactContent(Swal);
    const tipoImagenesPermitidas = 
    [
        "image/apng",
        "image/gif",
        "image/jpeg",
        "image/png",
    ];
    const mostrarMensajeFormatoInvalido  = ()=>{
        MySwal.fire({
            icon:"error",
            html:(
                <>
                <h2>Atención!</h2>
                <h4>
                    El formato de imagen es invalido, por favor suba una imagen valida.
                </h4>
                </>
            ),
            showCancelButton: true,
            showCloseButton: true
        });
    }
    const handleImageDUI = (files)=>{
        const {name,type,size,base64} = files;
        if(!tipoImagenesPermitidas.includes(type))
        {
            mostrarMensajeFormatoInvalido();
            return false;
        }
        setListaClientesImagenes({...listaClientesImagenes,"imagenDUI":{name,type,size,base64}})
        return;
    }
    const handleImageNIC = (files)=>{
        const {name,type,size,base64} = files;
        if(!tipoImagenesPermitidas.includes(type))
        {
            mostrarMensajeFormatoInvalido();
            return false;
        }
        setListaClientesImagenes({...listaClientesImagenes,"imagenAdicional":{name,type,size,base64}})
        return;
    }
    useEffect(() => {
        if(!claseAsignada){
            [...document.querySelectorAll("input[type='file']")].map(input=>{
                const id = input.parentElement.classList[1];
                input.id = id;
                input.name = id;
                input.classList.add("form-control");
                return true;
            });
            setClaseAsignada(true);
        }
    }, [claseAsignada,setClaseAsignada]);
    const imagenDUIValue = listaClientesImagenes.imagenDUI;
    const imagenAdicional = listaClientesImagenes.imagenAdicional;
    return (
        <div className="d-flex d-flex justify-content-around">
            <div className="mt-4">
                <label htmlFor="imagenDUI" className="d-flex flex-column text-center pointer" >
                    <span>DUI</span>
                    <img 
                        className={[(Object.keys(imagenDUIValue).length && "imageUploadedPreview"),"mt-2"].join(" ")}
                        src={ Object.keys(imagenDUIValue).length >  0 ? imagenDUIValue.base64 : imagenPreviewDui} 
                        width={ Object.keys(imagenDUIValue).length >  0 ? 180 : 110} 
                        style={{"pointersEvent":"none"}} 
                        alt="Imagen DUI"
                    />
                </label>
                <div className="imageDUIContainer imagenDUI" style={{"display":"none"}}>
                        <FileBase64
                            onDone={handleImageDUI}
                        />
                </div>
            </div>
            <div className="mt-4">
                <label htmlFor="imagenAdicional" className="d-flex flex-column text-center pointer">
                    <span>Adicional</span>
                    <img 
                        className={[(Object.keys(imagenAdicional).length && "imageUploadedPreview"),"mt-2"].join(" ")}
                        src={ Object.keys(imagenAdicional).length >  0 ? imagenAdicional.base64 : imagenPreviewAdicional} 
                        width={ Object.keys(imagenAdicional).length >  0 ? 180 : 110} 
                        style={{"pointersEvent":"none"}} 
                        alt="Imagen DUI"/>
                </label>
                <div className="imageDUINIC imagenAdicional" style={{"display":"none"}}>
                    <FileBase64
                        onDone={handleImageNIC}
                    />
                </div>
            </div>
        </div>
    )
}

export default ClientesImagenes;