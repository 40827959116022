import React, { Suspense, useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
} from "@themesberg/react-bootstrap";
import { makePetition } from "../../../../helpers/petition";
import { ErrorAlert } from "../../Utils/ErrorAlert";
import { SuccessAlert } from "../../Utils/SuccessAlert";
import onlyLetters from "../../Utils/onlyLetters";
import { categoriasPeticiones } from "../helper/peticionesCategorias";
import { useAuthContext } from "../../../../contexts/authContext";

const EditarCategoria = (props) => {
  const { logout, token: Token } = useAuthContext();
  const {
    categoriaId,
    mostrarEditarCategoria,
    setMostrarEditarCategoria,
    categoriaActualizadaHandle,
  } = props;
  const alertTextInitial = {
    title: "",
    message: "",
  };
  const [categoria, setCategoria] = useState("");
  const [alertVisible, setAlertVisible] = useState(false);
  const [desactivarBotonGuardar, setDesactivarBotonGuardar] = useState(false);
  const [alertText, setAlertText] = useState(alertTextInitial);
  const [alertSuccessVisible, setAlertSuccessVisible] = useState(false);
  const [alertSuccessText, setAlertSuccessText] = useState(alertTextInitial);
  const [consultarDatosCategoria, setConsultarDatosCategoria] = useState(true);
  const handleCategoria = ({ target }) => {
    const { value } = target;
    setCategoria(value);
  };
  useEffect(() => {
    if (!mostrarEditarCategoria) {
      setAlertSuccessVisible(false);
      setAlertVisible(false);
      setAlertText({
        title: "",
        message: "",
      });
      setAlertSuccessText({
        title: "",
        message: "",
      });
      setCategoria("");
    }
  }, [mostrarEditarCategoria]);
  useEffect(() => {
    if (consultarDatosCategoria) {
      const consultarCategoria = async () => {
        try {
          if (
            typeof categoriaId === "undefined" ||
            !categoriaId ||
            isNaN(categoriaId) ||
            categoriaId < 1
          ) {
            setAlertText({
              title: "Error",
              message:
                "Ocurrió un error inexperado, por favor contacta con soporte",
            });
            setAlertVisible(true);
            setDesactivarBotonGuardar(false);
            return false;
          }
          const obtenerDatosCategoria = await categoriasPeticiones(
            "obtenerCategoriaPorId",
            Token,
            { categoriaId }
          );
          const { status, ok } = obtenerDatosCategoria;
          if (status === 401) {
            logout();
            return false;
          }
          if (!ok) {
            throw new Error("Error en peticion.");
          }
          const response = await obtenerDatosCategoria.json();
          const { success = false, message = "", data = [] } = response;
          if (!success) {
            setAlertText({
              title: "Error",
              message: message || "Datos de categoria no encontrados",
            });
            setAlertVisible(true);
            setDesactivarBotonGuardar(false);
            return false;
          }
          if (Object.keys(data).length < 1) {
            setAlertText({
              title: "Error",
              message: "Datos de categoria no encontrados",
            });
            setAlertVisible(true);
            setDesactivarBotonGuardar(false);
            return false;
          }
          const { categoriaDescripcion = "" } = data;
          setCategoria(categoriaDescripcion);
        } catch (error) {
          console.error(error);
          setAlertText({
            title: "Error",
            message:
              "Ocurrió un error inexperado, por favor contacta con soporte",
          });
          setAlertVisible(true);
          setDesactivarBotonGuardar(false);
        }
      };
      setConsultarDatosCategoria(false);
      consultarCategoria();
    }
  }, [consultarDatosCategoria, setConsultarDatosCategoria,Token,logout,categoriaId]);

  const guardarCategoria = async (e) => {
    try {
      e.preventDefault();
      setAlertSuccessVisible(false);
      setAlertVisible(false);
      if (categoria.trim().length <= 2) {
        setAlertText({
          title: "Error",
          message: "La descripción de categoria debe ser mayor a 2 caracteres.",
        });
        setAlertVisible(true);
        return false;
      }
      if (
        typeof categoriaId === "undefined" ||
        !categoriaId ||
        isNaN(categoriaId) ||
        categoriaId < 1
      ) {
        setAlertText({
          title: "Error",
          message:
            "Ocurrió un error inexperado, por favor contacta con soporte",
        });
        setAlertVisible(true);
        setDesactivarBotonGuardar(false);
        return false;
      }
      setDesactivarBotonGuardar(true);
      const peticionDatos = {
        categoriaId: categoriaId,
        categoriaDescripcion: categoria.trim(),
      };
      const guardarCategoria = await categoriasPeticiones(
        "guardarDatosCategoriaEdicion",
        Token,
        peticionDatos
      );
      const { status, ok } = guardarCategoria;
      if (status === 401) {
        logout();
        return false;
      }
      if (!ok) {
        throw new Error("Error en peticion.");
      }
      const response = await guardarCategoria.json();
      const { success, message = "" } = response;
      if (!success) {
        setAlertText({ title: "Error", message: message });
        setAlertVisible(true);
        setDesactivarBotonGuardar(false);
        return false;
      }
      setCategoria("");
      setAlertSuccessText({ title: "Exito", message: message });
      setAlertSuccessVisible(true);
      setDesactivarBotonGuardar(false);
      setTimeout(() => {
        setAlertSuccessVisible(false);
      }, 3000);
      categoriaActualizadaHandle(true);
      return true;
    } catch (error) {
      console.error(error);
      setAlertText({
        title: "Error",
        message: "Ocurrió un error inexperado, por favor contacta con soporte",
      });
      setAlertVisible(true);
      setDesactivarBotonGuardar(false);
    }
  };
  return (
    <>
      <Modal
        as={Modal.Dialog}
        centered
        show={mostrarEditarCategoria}
        onHide={() => setMostrarEditarCategoria(false)}
        backdrop="static"
        keyboard={false}
        size="md"
      >
        <Container>
          <Modal.Header className="text-center">
            <Button
              variant="close"
              aria-label="Close"
              onClick={() => setMostrarEditarCategoria(false)}
            />
          </Modal.Header>
          <Form onSubmit={guardarCategoria}>
            <Modal.Body>
              <Row>
                <Col xs={12}>
                  <h3>Editar Categoria</h3>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Form.Group className="mb-4">
                    <Form.Label></Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Ingrese el nombre de la categoria"
                      id="categoria"
                      name="categoria"
                      value={categoria}
                      onChange={handleCategoria}
                      size="md"
                      onKeyPress={(event) =>
                        event.target.value.toString().length > 98
                          ? event.preventDefault()
                          : onlyLetters(event)
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Col xs={12}>
                {alertVisible ? (
                  <ErrorAlert
                    title={alertText.title}
                    message={alertText.message}
                  />
                ) : null}
                {alertSuccessVisible ? (
                  <SuccessAlert
                    title={alertSuccessText.title}
                    message={alertSuccessText.message}
                  />
                ) : null}
              </Col>
              <Button
                size="md"
                variant="outline-primary"
                className="w-100 mt-4"
                onClick={guardarCategoria}
                disabled={desactivarBotonGuardar}
              >
                Guardar
              </Button>
              <Button
                size="md"
                variant="danger"
                className="w-100 mt-1"
                onClick={() => setMostrarEditarCategoria(false)}
                disabled={desactivarBotonGuardar}
              >
                Cancelar
              </Button>
            </Modal.Footer>
          </Form>
        </Container>
      </Modal>
    </>
  );
};

export default EditarCategoria;
