//import TOKEN from "../../../../helpers/auth";
import {
  makePetition,
  petitionImageToBase64,
} from "../../../../helpers/petition";
const fetchProductos = async (
  requesUrl,
  requestMethod,
  requestToken,
  requestData
) => {
  try {
    const request = await makePetition(
      requesUrl,
      requestData,
      requestMethod,
      requestToken
    );
    return request;
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message:
        "Ocurrió un error inexperado, por favor vuelva a intentar. Si este error persiste contacte al administrador del sistema",
      error: error,
    };
  }
};
const obtenerProductos = async (productosId, token) =>
  await fetchProductos(`productos/producto/${productosId}`, "GET", token, {});

const guardarProducto = async (data, token) =>
  await fetchProductos("productos/nuevo", "POST", token, data);

const editarProducto = async (data, token) =>
  await fetchProductos("productos/editar", "PUT", token, data);

const activarDesactivarProducto = async (estadoProducto, productosId, token) =>
  await fetchProductos(
    `productos/${estadoProducto === 1 ? "desactivar" : "activar"}`,
    "PUT",
    token,
    { productosId: productosId }
  );

const obtenerProductoEditar = async (productosId, token) =>
  await fetchProductos(`productos/usuario/${productosId}`, "GET", token, {});

const eliminarProducto = async (productosId, token) =>
  await fetchProductos(`productos/eliminar`, "DELETE", token, {
    productosId: productosId,
  });

const guardarProductosImagenesRequest = async (
  productosId,
  ImagenSufijo,
  ImagenCodigoBase64,
  ImagenNombre,
  token
) => {
  let sufijoMetodo = "";
  if (ImagenSufijo === "productosImagenFrontal")
    sufijoMetodo = "nuevaImagenFrontal";
  else if (ImagenSufijo === "productosImagenTrasera")
    sufijoMetodo = "nuevaImagenTrasera";
  else if (ImagenSufijo === "productosImagenAdicional")
    sufijoMetodo = "nuevaImagenAdicional";
  else return false;
  const formData = {
    productosId,
    productosImagenFrontalNombreArchivo: ImagenNombre,
    sufijoImagen: ImagenSufijo,
    productosImageFrontalBase64: ImagenCodigoBase64,
  };

  return await fetchProductos(
    `productosImagenes/${sufijoMetodo}`,
    "PUT",
    token,
    formData
  );
};

const obtenerImagenesProductos = async (productosId, token) =>
  await fetchProductos(
    `productosImagenes/obtenerImagenesProducto/${productosId}`,
    "GET",
    token,
    {}
  );

const obtenerImagenes = async (endpoint, token, requestData = {}) =>
  await petitionImageToBase64(endpoint, requestData, "GET", token);

const consultarExistenciaProducto = async (productosId, token) =>
  await fetchProductos(
    `productos/consultarExistenciaProducto/${productosId}`,
    "GET",
    token,
    {}
  );

const agregarCompraIndividual = async (nuevaExistenciaData, token) =>
  await fetchProductos(
    `compras/compraIndividual`,
    "POST",
    token,
    nuevaExistenciaData
  );

const obtenerListaProveedores = async (token) =>
  await fetchProductos(`proveedores/lista`, "GET", token, {});

const obtenerFacturaCompraPorCodigo = async (facturaCompraCodigo, token) =>
  await fetchProductos(
    `compras/obtenerFacturaPorCodigo/${facturaCompraCodigo}`,
    "GET",
    token,
    {}
  );

const consultarListaPrecioVentas = async (productosId, token) =>
  await fetchProductos(
    `productosPreciosVenta/listaPorProductoId/${productosId}`,
    "GET",
    token,
    {}
  );
export {
  obtenerProductos,
  obtenerProductoEditar,
  guardarProducto,
  editarProducto,
  eliminarProducto,
  activarDesactivarProducto,
  guardarProductosImagenesRequest,
  obtenerImagenesProductos,
  obtenerImagenes,
  consultarExistenciaProducto,
  obtenerListaProveedores,
  obtenerFacturaCompraPorCodigo,
  agregarCompraIndividual,
  consultarListaPrecioVentas,
};
