import { React,useState, useEffect, useCallback, useRef } from "react";
import { Alert, Button, Card, Col, Container, Form, Modal, Row } from "@themesberg/react-bootstrap";
import { AiOutlineAlert } from "react-icons/ai";

const ModalProductosCrud = ({
    showModalProductosCrud,
    setShowModalProductosCrud,
    productoSeleccionadoDatos,
    setProductoSeleccionadoDatos,
    productoSeleccionadoHandleChange,
    asignarProductoSeleccionadoAListaProducto,
    accion = "NUEVO"
}) => {
    const {
        productosId = 0,
        codigoProducto = "",
        descripcionProducto = "",
        precioVentaListaProducto = [],
        precioCompraIndividualProducto = 0,
        nuevaExistenciaProducto = 0
    } = productoSeleccionadoDatos;

    return (
        <div>
            <Modal
                as={Modal.Dialog}
                centered
                show={showModalProductosCrud}
                onHide={() => setShowModalProductosCrud(false)}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <Modal.Header>
                    <Modal.Title className="h3 p-3">Editor de Productos</Modal.Title>
                    <Button variant="close" aria-label="Close" onClick={() => setShowModalProductosCrud(false)} />
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <div className="w-100 mb-4">
                            <Alert
                                variant="info"
                                show={true}
                                // onClose={() => setMostrarAlerta(false)}

                            >
                                <Alert.Heading>
                                </Alert.Heading>
                                <p>
                                    <AiOutlineAlert size={20} className="mb-1 mr-1"/> Por favor ingrese el precio de compra, y la cantidad de producto comprado.
                                </p>
                            </Alert>
                        </div>
                        <Form>
                            <Row>
                                <Col xs={12} md={6}>
                                    <Form.Group className="mb-4">
                                        <Form.Label>
                                            Código
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="codigoProducto"
                                            name="codigoProducto"
                                            value={codigoProducto}
                                            onChange={({ target }) => productoSeleccionadoHandleChange(target)}
                                            disabled
                                        ></Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Group className="mb-4">
                                        <Form.Label>
                                            Nombre
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="descripcionProducto"
                                            name="descripcionProducto"
                                            value={descripcionProducto}
                                            onChange={({ target }) => productoSeleccionadoHandleChange(target)}
                                            disabled
                                        >
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={6}>
                                    <Form.Group className="mb-4">
                                        <Form.Label>
                                            Precio Compra por unidad
                                        </Form.Label>
                                        <Form.Control
                                            type="number"
                                            id="precioCompraIndividualProducto"
                                            name="precioCompraIndividualProducto"
                                            value={precioCompraIndividualProducto}
                                            onChange={({ target }) => productoSeleccionadoHandleChange(target)}
                                            min={0}
                                            step={0.01}
                                        >
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Group className="mb-4">
                                        <Form.Label>
                                            Nueva Existencia por unidad
                                        </Form.Label>
                                        <Form.Control
                                            type="number"
                                            id="nuevaExistenciaProducto"
                                            name="nuevaExistenciaProducto"
                                            value={nuevaExistenciaProducto}
                                            onChange={({ target }) => productoSeleccionadoHandleChange(target)}
                                            min={0}
                                            step={0.01}
                                        >
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex justify-content-center w-100">
                        <Button
                            size="md"
                            variant="success"
                            className="w-60 mt-4"
                            onClick={asignarProductoSeleccionadoAListaProducto}
                        >
                            Guardar
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
export default ModalProductosCrud;