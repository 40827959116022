import React, {useEffect, useState } from 'react'
import Scrollbars from 'react-custom-scrollbars/lib/Scrollbars';
import '../../../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import '../../../../../node_modules/react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import { makePetition } from '../../../../helpers/petition';
import { categoriasPeticiones } from '../helper/peticionesCategorias';
import { useAuthContext } from '../../../../contexts/authContext';
import SweetAlert from '../../Utils/SweetAlert';
export const ListadoCategoriasProductos = ({
  listadoCategorias,
  setListadoCategorias,
  consultarListaCategoria,
  setConsultarListaCategoria,
  listaCategoriasSelected = [],
  setListaCategoriasSelected = () => { }
}) => {
  const { logout, token: Token } = useAuthContext();
  const [listadoCategoriasAlmacenadas, setListadoCategoriasAlmacenadas] = useState(listaCategoriasSelected);
  useEffect(() => {
    if (consultarListaCategoria) {
      const requestListaCategorias = async () => {
        const request = await categoriasPeticiones("obtenerListaCategorias",Token);
        const {status, ok  }= request;
        if(status === 401 || !ok){
          logout();
          return false;
        }
        const response = await request.json()
        const { success, message = "", data = [] } = response;
        if (!success) {
          SweetAlert(
            "Error",
            message ||
              "Ocurrió un error al obtener el listado de categorias.",
            "error",
            true
          );
          return false;
        }
        setListadoCategoriasAlmacenadas(data);
        setConsultarListaCategoria(false);
      }
      requestListaCategorias();
    }
  }, [consultarListaCategoria, setConsultarListaCategoria]);


  const filasCategorias = () => {
    const nuevaListadoCategoriasAlmacenadas = listadoCategoriasAlmacenadas.map((cat) => (
      {
        "categoriaId": cat.categoriaId,
        "descripcion": cat.categoriaDescripcion
      }
    ));
    return nuevaListadoCategoriasAlmacenadas;
  };
  useEffect(() => {
    // console.log(listadoCategorias);
  }, [listadoCategorias])

  const selectRow = {
    mode: 'checkbox',
    onSelect: (row, isSelect, rowIndex, e) => {
      if (Object.keys(row).length > 0) {
        if (isSelect) {
          const nuevoListado = listadoCategorias.filter((categoria) => parseInt(categoria.categoriaId) !== parseInt(row.categoriaId));
          setListadoCategorias([...nuevoListado, row]);
        } else {
          const nuevoListado = listadoCategorias.filter((categoria) => parseInt(categoria.categoriaId) !== parseInt(row.categoriaId));
          setListadoCategorias(nuevoListado);
        }
      }
    },
    selected: listadoCategorias.map((categoria) => (categoria.categoriaId)),
    onSelectAll: (isSelect, rows, e) => {
      if (Object.keys(rows).length > 0) {
        if (isSelect) setListadoCategorias(rows);
        else setListadoCategorias([]);
      }
    }
  };

  const columns = [
    { dataField: 'categoriaId', text: 'Id' },
    { dataField: 'descripcion', text: 'Descripcion' }
  ];
  return (
    <div>
      <Scrollbars style={{ width: "100%", height: 250 }} className="mt-4">
        {
          listadoCategoriasAlmacenadas.length > 0 ?
            (
              <BootstrapTable
                tableHeaderClass='table-centered table-nowrap rounded mb-0 table'
                data={filasCategorias()}
                columns={columns}
                keyField="categoriaId"
                selectRow={selectRow}
              // selectRow={ selectRow }
              // hover 
              //search
              //searchPlaceholder='Buscar Categoria' 
              >
              </BootstrapTable>
            ) :
            <p>No existe registros aún</p>

        }

      </Scrollbars>
    </div>
  );
}

export const ListadoCategoriasFormProductos = React.memo(ListadoCategoriasProductos);