import {
  makePetition,
  petitionImageToBase64,
} from "../../../../helpers/petition";
const obtenerUsuarios = async (token) => {
  try {
    const formData = {};
    const urlTarget = "usuarios/usuarios";
    const petitionMethod = "GET";
    const petition = await makePetition(
      urlTarget,
      formData,
      petitionMethod,
      token
    );
    return petition;
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message:
        "Ocurrió un error inesperado. Por favor contacte al administrador del sistema",
    };
  }
};
const guardarPerfilUsuario = async (datosPeticion,token)=>{
  try {
    const urlTarget = `usuarios/editarUsuarioPerfil`;
    const metodo = "PUT";
    const petition = await makePetition(
      urlTarget,
      datosPeticion,
      metodo,
      token
    );
    return petition;
  } catch (error) {
   throw error;
  }
}
const guardarUsuario = async (
  token,
  usuariosEmail,
  usuariosNombreUsuario,
  usuariosActivo,
  usuariosRole,
  usuariosPassword,
  usuariosId = 0
) => {
  try {
    let urlTarget = "";
    let petitionMethod = "";
    let formData = {};
    if (parseInt(usuariosId) > 0) {
      urlTarget = `usuarios/editar`;
      petitionMethod = "PUT";
      formData = {
        usuariosId,
        usuariosEmail,
        usuariosNombreUsuario,
        usuariosActivo,
        usuariosRole,
      };
    } else {
      urlTarget = `usuarios/nuevo`;
      petitionMethod = "POST";
      formData = {
        usuariosEmail,
        usuariosNombreUsuario,
        usuariosActivo,
        usuariosRole,
        usuariosPassword,
      };
    }
    const petition = await makePetition(
      urlTarget,
      formData,
      petitionMethod,
      token
    );
    return petition;
  } catch (error) {
    return {
      success: false,
      message: "Ocurrió un error inesperado.",
    };
  }
};
const obtenerUsuarioEditar = async (usuariosId, token) => {
  try {
    const urlTarget = `usuarios/usuario/${usuariosId}`;
    const petitionMethod = "GET";
    const formData = {};
    const petition = await makePetition(
      urlTarget,
      formData,
      petitionMethod,
      token
    );
    return petition;
  } catch (error) {
    return {
      success: false,
      message: "Ocurrió un error inesperado.",
    };
  }
};
const obtenerUsuarioPerfil = async (usuariosId, token) => {
  try {
    const urlTarget = `usuarios/usuarioPerfil/${usuariosId}`;
    const petitionMethod = "GET";
    const formData = {};
    const petition = await makePetition(
      urlTarget,
      formData,
      petitionMethod,
      token
    );
    return petition;
  } catch (error) {
    return {
      success: false,
      message: "Ocurrió un error inesperado.",
    };
  }
};
const eliminarUsuario = async (usuariosId, token) => {
  try {
    const urlTarget = `usuarios/eliminar`;
    const petitionMethod = "DELETE";
    const formData = { usuariosId };
    const petition = await makePetition(
      urlTarget,
      formData,
      petitionMethod,
      token
    );
    return petition;
  } catch (error) {
    return {
      success: false,
      message: "Ocurrió un error inesperado.",
    };
  }
};
const activarDesactivarUsuario = async (estadoUsuario, usuariosId, token) => {
  try {
    const urlTarget = `usuarios/${
      estadoUsuario === 1 ? "desactivar" : "activar"
    }`;
    const petitionMethod = "PUT";
    const formData = { usuariosId: usuariosId };
    const petition = await makePetition(
      urlTarget,
      formData,
      petitionMethod,
      token
    );
    return petition;
  } catch (error) {
    return {
      success: false,
      message: "Ocurrió un error inesperado.",
    };
  }
};
const guardarUsuariosImagenesRequest = async (
  usuariosId,
  ImagenSufijo,
  ImagenCodigoBase64,
  ImagenNombre,
  token
) => {
  try {
    let sufijoMetodo = "";
    if (ImagenSufijo === "usuariosImagenFrontal")
      sufijoMetodo = "nuevaImagenFrontal";
    else if (ImagenSufijo === "usuariosImagenAdicional")
      sufijoMetodo = "nuevaImagenAdicional";
    else return false;

    const urlTarget = `usuariosImagenes/${sufijoMetodo}`;
    const petitionMethod = "PUT";
    const formData = {
      usuariosId,
      usuariosImagenesArchivo: ImagenNombre,
      sufijoImagen: ImagenSufijo,
      usuariosImagenesBase64: ImagenCodigoBase64,
    };
    const petition = await makePetition(
      urlTarget,
      formData,
      petitionMethod,
      token
    );
    return petition;
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: "Ocurrió un error inesperado",
    };
  }
};
const obtenerImagenesUsuarios = async (usuariosId, token) => {
  const formData = {};
  const urlTarget = `usuariosImagenes/obtenerImagenesUsuario/${usuariosId}`;
  const petitionMethod = "GET";
  const petition = await makePetition(
    urlTarget,
    formData,
    petitionMethod,
    token
  );
  return petition;
};
const obtenerImagenes = async (url, token) => {
  const formData = {};
  const urlTarget = url;
  const petitionMethod = "GET";
  const petition = await petitionImageToBase64(
    urlTarget,
    formData,
    petitionMethod,
    token
  );
  return petition;
};
export {
  obtenerUsuarios,
  obtenerUsuarioEditar,
  guardarUsuario,
  eliminarUsuario,
  activarDesactivarUsuario,
  guardarUsuariosImagenesRequest,
  obtenerImagenesUsuarios,
  obtenerImagenes,
  obtenerUsuarioPerfil,
  guardarPerfilUsuario
};
