import React, { useEffect, useState } from "react";
import consultarListaPrecioVentasHandle from "./helper/preciosHelpers";
import { Block } from "notiflix";
import { Card, Table } from "@themesberg/react-bootstrap";
import customDecimalRound from "../Utils/customDecimalRound";
import { useAuthContext } from "../../../contexts/authContext";
import SweetAlert from "../Utils/SweetAlert";

const ConsultarListaPrecioVentasTable = ({
  productosId = 0,
  usarLoading = true,
  container = "body",
}) => {
  const { logout, token: Token } = useAuthContext();
  const [realizarPeticion, setRealizarPeticion] = useState(true);
  const [productosPrecioLista, setProductosPrecioLista] = useState([]);
  useEffect(() => {
    if (realizarPeticion) {
      setRealizarPeticion(false);
      (async () => {
        try {
          if (usarLoading) {
            Block.standard(container, {
              cssAnimationDuration: 1881,
              svgSize: "19px",
            });
          }
          const consultarLista = async () => {
            try {
              const datosLista = await consultarListaPrecioVentasHandle(
                productosId,
                Token
              );
              const { status, ok } = datosLista;
              if (status === 401) {
                logout();
                return false;
              }
              if (!ok) {
                throw new Error("Error de petición.");
              }
              const response = await datosLista.json();
              const { data = [] } = response;
              setProductosPrecioLista(data);
            } catch (error) {
              throw error;
            }
          };
          await consultarLista();
          if (usarLoading) Block.remove(container);
        } catch (error) {
          if (usarLoading) Block.remove(container);
          SweetAlert(
            "Error",
            `Ocurrió un error en la consulta de precios. Por favor contacte al administrador del sistema`,
            "error",
            true
          );
        }
      })();
    }
  }, [
    productosId,
    realizarPeticion,
    setRealizarPeticion,
    usarLoading,
    container,
    Token,
    logout,
  ]);
  const TableRow = (props) => {
    const {
      rangoPrecioId,
      precioCompra,
      precioVenta,
      porcentajeVenta,
      productoCantidadRangoMinimo,
      productoCantidadRangoMaximo,
      productosId,
      productosDescripcion,
    } = props;

    return (
      <tr>
        <td className="fw-bold">
          <span>{productosDescripcion}</span>
        </td>
        <td className="fw-bold">
          <span className="badge bg-danger text-wrap p-2 mx-2">
            ${customDecimalRound(Number(precioCompra)).toFixed(2)}
          </span>
        </td>
        <td className="fw-bold">
          <span>{customDecimalRound(Number(porcentajeVenta)).toFixed(2)}%</span>
        </td>
        <td>
          <span>
            <span className="badge bg-success text-wrap p-2 mx-2">
              ${customDecimalRound(Number(precioVenta))}
            </span>
          </span>
        </td>
        <td>
          <span className="badge bg-info text-wrap p-2 mx-2">
            {customDecimalRound(Number(productoCantidadRangoMinimo))} uds.
          </span>
        </td>
        <td>
          <span className="badge bg-info text-wrap p-2 mx-2">
            {customDecimalRound(Number(productoCantidadRangoMaximo))} uds.
          </span>
        </td>
      </tr>
    );
  };
  return (
    <Card border="light" className="shadow-sm">
      <Card.Body className="pb-0">
        <Table responsive className="table-centered table-nowrap rounded mb-0">
          <thead className="thead-light">
            <tr>
              <th className="border-0">Producto</th>
              <th className="border-0">Precio Compra</th>
              <th className="border-0">Ganancia %</th>
              <th className="border-0">Precio Venta</th>
              <th className="border-0">Rango Minimo uds.</th>
              <th className="border-0">Rango Maximon uds.</th>
            </tr>
          </thead>
          <tbody>
            {productosPrecioLista.map((r) => (
              <TableRow key={`ranking-${r.rangoPrecioId}`} {...r} />
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default ConsultarListaPrecioVentasTable;
