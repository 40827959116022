//import { Form } from "@themesberg/react-bootstrap";
import React, { useEffect, useState,useRef } from "react";
import { presentacionesPeticiones } from "../helper/peticionesPresentaciones";
import { useAuthContext } from "../../../../contexts/authContext";
//import { Button } from "@themesberg/react-bootstrap";
//import DatalistInput from 'react-datalist-input';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from  "@fortawesome/free-solid-svg-icons";
const ProductosListaPresentacion = ({
  presentacionesId,
  presentacionIdModalState,
  setPresentacionIdModalState,
  consultarPresentaciones,
  setConsultarPresentaciones,
  setFieldValue,
  setAlertText,
  setAlertVisible,
  establecerValorDePresentacion = false
}) => {
  const [presentacionesData, setPresentacionesData] = useState([]);
  const [inputPresentacionesDisabled, setInputPresentacionesDisabled] = useState(false);
  const [estableValorDePresentacionState, setEstableValorDePresentacionState] = useState(establecerValorDePresentacion);
  const presentacionInputDataList = useRef(null);
  const { logout, token: Token } = useAuthContext();
  const presentacionesHandleChange = (e) => {
    if(e){
      const {target = null} = e;
      if(target){
        const presentacionDescripcionSeleccionada = target.value;
        if(parseInt(presentacionDescripcionSeleccionada)===0){
          setFieldValue(
            "presentacionesId",
            parseInt(presentacionDescripcionSeleccionada),
            true
          );
          setInputPresentacionesDisabled(true);
        }
        presentacionesData.map((presentacion)=>{
          const {presentacionesId,presentacionesDescripcion} = presentacion;
          if(presentacionesDescripcion.toString().trim()===presentacionDescripcionSeleccionada){
            setFieldValue(
              "presentacionesId",
              parseInt(presentacionesId),
              true
            );
            setInputPresentacionesDisabled(true);
          }
        });
      }
    }
  };
  const removerPresentacion = ()=>{
    setInputPresentacionesDisabled(false);
    setFieldValue(
      "presentacionesId",
      0,
      true
    );
  }
  useEffect(() => {
    if(estableValorDePresentacionState && presentacionesData.length > 0){
      setEstableValorDePresentacionState(false);
      let presentacionesIdSeleccionada = parseInt(presentacionesId);
      if(parseInt(presentacionesIdSeleccionada)===0){
        presentacionInputDataList.current.value =  0;
        setInputPresentacionesDisabled(true);
      }
      presentacionesData.map((presentacion)=>{
        const {presentacionesId,presentacionesDescripcion} = presentacion;
        if(parseInt(presentacionesId)===presentacionesIdSeleccionada){
          presentacionInputDataList.current.value =  presentacionesDescripcion;
          setInputPresentacionesDisabled(true);
        }
      });
      
    }
  }, [estableValorDePresentacionState,presentacionesId,presentacionesData]);
  
  useEffect(() => {
    const obtenerPresentaciones = async () => {
      const request = await presentacionesPeticiones(
        "obtenerListaPresentacions",
        Token
      );
      const { status, ok } = request;
      if (status === 401) {
        logout();
        return false;
      }
      if (!ok) {
        setConsultarPresentaciones(true);
        setAlertText({
          title: "Error",
          message:
            "Ocurrió un error inesperado al consultar las presentaciones de producto.",
        });
        setAlertVisible(true);
        setPresentacionesData([]);
        return false;
      }
      const response = await request.json();
      const { success, message = "", data:presentacionesData = [] } = response;
      if (!success) {
        setConsultarPresentaciones(true);
        setAlertText({ title: "Error", message: message });
        setAlertVisible(true);
        setPresentacionesData(presentacionesData);
        return false;
      }
      setConsultarPresentaciones(false);
      setPresentacionesData(presentacionesData);
      if (presentacionIdModalState > 0 || presentacionesId>0) {
        if(presentacionIdModalState>0){
          setFieldValue("presentacionesId", presentacionIdModalState, true);
          setPresentacionIdModalState(0);
        }
        let presentacionesIdSeleccionada = parseInt(presentacionIdModalState > 0 ? presentacionIdModalState : presentacionesId);
        if(presentacionesIdSeleccionada>0){
          presentacionesData.map((presentacion)=>{
            const {presentacionesId,presentacionesDescripcion} = presentacion;
            if(parseInt(presentacionesId)===presentacionesIdSeleccionada){
              presentacionInputDataList.current.value =  presentacionesDescripcion;
              setInputPresentacionesDisabled(true);
            }
          });
        }
      }
    };
    if (consultarPresentaciones) {
      obtenerPresentaciones();
    }
  }, [
    consultarPresentaciones,
    presentacionIdModalState,
    setAlertText,
    setAlertVisible,
    setConsultarPresentaciones,
    setFieldValue,
    setPresentacionIdModalState,
    Token,
    logout,
  ]);
  return (
    <>
      <div style={{
        display:'flex'
      }}>
        <input
          id="seleccionarPresentacion"
          list="presentacionesId"
          aria-label="Seleccion la unidad de medida"
          className="form-control w-90"
          onChange={presentacionesHandleChange}
          disabled={inputPresentacionesDisabled}
          ref={presentacionInputDataList}
        />
        <div className="w-1 0" style={{
          display:inputPresentacionesDisabled ? "flex" : "none",
          alignContent:"center",
          justifyContent:"center",
        }}>
          <button
            className="btn btn-xs"
            type="button"
            onClick={() => removerPresentacion()}
          >
            <FontAwesomeIcon
              icon={faWindowClose}
              className="text-danger me-2"
            />
          </button>
        </div>
        <datalist
          name="presentacionesId"
          id="presentacionesId"
        >
          <option value={0}>No aplica</option>
          {presentacionesData.map((presentacion) => (
            <option
              id={presentacion.presentacionesId}
              key={presentacion.presentacionesId}
              value={presentacion.presentacionesDescripcion}
            />
          ))}
        </datalist>
      </div>
    </>
  );
};

export const ProductosPresentacion = React.memo(ProductosListaPresentacion);
