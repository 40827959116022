import React from 'react';
import { BsPeople } from 'react-icons/bs';
//import clientes from "../../assets/img/tiendaBuenPrecio/util/customers.svg";
import ClienteForm from '../../components/tiendaBuenPrecioComponents/Clientes/ClientesForm';
const AgregarClientes = () => {
  return (
    <div className= "mt-4">
      <div className="px-0 mb-3" >
        <div className="d-flex justify-content-start">
          <div className="mx-3">
            <BsPeople size="2em" />
          </div>
          <h4>
            Agregar Cliente
          </h4>
        </div>
      </div>
      <ClienteForm formType="NUEVO"/>
    </div>
  )
}

export default AgregarClientes;