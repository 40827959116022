import React from 'react';
import { useParams } from 'react-router-dom';
import proveedores01 from "../../assets/img/tiendaBuenPrecio/util/proveedores01.svg";
import ProveedorForm from '../../components/tiendaBuenPrecioComponents/Proveedores/ProveedorForm';
const AgregarProveedores = () => {
    const { id:proveedorId } = useParams();
    return (
        <div className="mt-4 pagesCustomParent">
            <div className="px-0 mb-3" >
                <div className="d-flex justify-content-start">
                    <div className="mx-3">
                        <img src={proveedores01} width="40" height="40" className="svg-icon" alt="Agregar" />
                    </div>
                    <h4>
                        Editar Proveedor
                    </h4>
                </div>
            </div>
            <ProveedorForm 
                type="EDITAR" 
                proveedorId={proveedorId} 
            />
        </div>
    )
}

export default AgregarProveedores;