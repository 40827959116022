
import React, { useState } from "react";
import SimpleBar from 'simplebar-react';
import { useLocation } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Nav, Badge, Image, Button, Accordion, Navbar } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import { BsTruck, BsBasket3, BsPeople, BsPersonBadge, BsCart3, BsTree, BsCartPlus, BsCalculator, BsPen, BsUiChecks } from "react-icons/bs";
import Store from "../assets/img/tiendaBuenPrecio/store/market-svgrepo-com.svg";
import ProfilePicture from "../assets/img/team/profile-picture-3.jpg";
import { FiUsers } from "react-icons/fi";
import { Paths } from "../config/router/paths";

//import ReactHero from "../assets/img/technologies/react-hero-logo.svg";
//import logoTbp from "../assets/img/tiendaBuenPrecio/util/logoTbp.png";
const Sidebar = (props = {}) => {
  const location = useLocation();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const showClass = show ? "show" : "";

  const onCollapse = () => setShow(!show);

  const CollapsableNavItem = (props) => {
    const { eventKey, title, icon, children = null, useReactIcon = false, reactIcon = null } = props;
    const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "";
    return (
      <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
        <Accordion.Item eventKey={eventKey}>
          <Accordion.Button as={Nav.Link} className="d-flex justify-content-between align-items-center">
            <span>
              <span className="sidebar-icon">{useReactIcon ? reactIcon : <FontAwesomeIcon icon={icon} />} </span>
              <span className="sidebar-text">{title}</span>
            </span>
          </Accordion.Button>
          <Accordion.Body className="multi-level">
            <Nav className="flex-column">
              {children}
            </Nav>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const NavItem = (props) => {
    const { 
      title, 
      link, 
      external, 
      target, 
      icon, 
      image, 
      badgeText, 
      badgeBg = "secondary", 
      badgeColor = "primary", 
      useReactIcon = false, 
      reactIcon = null, 
      headNav = false, 
      centerText = false
    } = props;
    //const classNames = badgeText ? "d-flex justify-content-start align-items-center justify-content-between" : "";
    const classNames = `d-flex justify-content-start align-items-center ${!centerText ? "justify-content-between" : "justify-content-center"}`;
    const navItemClassName = link === pathname ? "active" : "";
    const linkProps = external ? { href: link } : { as: Link, to: link };

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link {...linkProps} target={target} className={classNames}>
          <span>
            {useReactIcon && <span className="sidebar-icon">{reactIcon}</span>}
            {icon ? <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /></span> : null}
            {image ? <Image src={image} width={20} height={20} className="sidebar-icon svg-icon" /> : null}
            {!headNav ? <span className="sidebar-text">{title}</span> : <span className="sidebar-text" style={{ fontSize: '1.2rem', letterSpacing: 1.5, fontWeight: 900 }}>{title}</span>}

          </span>
          {badgeText ? (
            <Badge pill bg={badgeBg} text={badgeColor} className="badge-md notification-count ms-2">{badgeText}</Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };
  return (
    <>
      <Navbar expand={false} collapseOnSelect variant="dark" className="navbar-theme-primary px-4 d-md-none">
        <Navbar.Brand className="me-lg-5" as={Link} to={Paths.DashboardHome.path}>
          <Image src={Store} className="navbar-brand-light" />
        </Navbar.Brand>
        <Navbar.Toggle as={Button} aria-controls="main-navbar" onClick={onCollapse}>
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={350} in={show} classNames="sidebar-transition">
        <SimpleBar className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}>
          {/* <div className="sidebar-inner px-4 pt-3"> */}
          <div className="sidebar-inner px-2 pt-3">
            {
              /**
               * 
               * Navbar
               * 
               */
            }
            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
              <div className="d-flex align-items-center">
                <div className="user-avatar lg-avatar me-4">
                  <Image src={ProfilePicture} className="card-img-top rounded-circle border-white" />
                </div>
                <div className="d-block">
                  <h6>Hola, Darwin</h6>
                  <Button as={Link} variant="secondary" size="xs" to={Paths.Signin.path} className="text-dark">
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-2" /> Sign Out
                  </Button>
                </div>
              </div>
              <Nav.Link className="collapse-close d-md-none" onClick={onCollapse}>
                <FontAwesomeIcon icon={faTimes} />
              </Nav.Link>
            </div>
            {
              /**
               * 
               *Sidebar 
               * 
               */

            }
            <Nav className="flex-column pt-md-0">
              <NavItem
                title="Buen Precio"
                badgeText=""
                link={Paths.DashboardHome.path}
                headNav={true}
                centerText={true}
              />
              {/* <NavItem title="Buen Precio" link={Paths.DashboardHome.path} image={ReactHero} /> */}

              <div className="mt-4">
                { }
              </div>
              <NavItem
                title="Productos"
                link={Paths.ListaProductos.path}
                useReactIcon={true}
                reactIcon={<BsBasket3 size="1.4em" />}
              />
              <NavItem
                title="Marcas"
                link={Paths.ListaMarcas.path}
                useReactIcon={true}
                reactIcon={<BsTree size="1.4em" />}
              />
              <NavItem
                title="Categorias"
                link={Paths.ListaCategorias.path}
                useReactIcon={true}
                reactIcon={<BsUiChecks size="1.4em" />}
              />
              <NavItem
                title="Ventas"
                link={Paths.ListaFacturas.path}
                useReactIcon={true}
                reactIcon={<BsCart3 size="1.4em" />}
              /> 
              <NavItem
                title="Compras"
                link={Paths.ListaFacturaCompra.path}
                useReactIcon={true}
                reactIcon={<BsCartPlus size="1.4em" />}
              />
              <NavItem
                title="Clientes"
                link={Paths.ListaClientes.path}
                useReactIcon={true}
                reactIcon={<BsPeople size="1.4em" />}
              />
              <NavItem
                title="Kardex"
                link={Paths.Kardex.path}
                useReactIcon={true}
                reactIcon={<BsPen size="1.4em" />}
              />
              <NavItem
                title="Inventario"
                link={Paths.Inventario.path}
                useReactIcon={true}
                reactIcon={<BsCalculator size="1.4em" />}
              />
              {/* <CollapsableNavItem eventKey="clientes/" title="Clientes" useReactIcon={true} reactIcon={<BsPeople size="1.2em"/>}>
                <NavItem title="Lista Clientes" link={Paths.ListaClientes.path} />
                <NavItem title="Agregar Clientes" link={Paths.AgregarClientes.path} />
              </CollapsableNavItem> */}
              <NavItem
                title="Proveedores"
                link={Paths.ListaProveedores.path}
                useReactIcon={true}
                reactIcon={<BsTruck size="1.4em"/>}
              />
              {/* <CollapsableNavItem eventKey="proveedores/" title="Proveedores" useReactIcon={true} reactIcon={<BsTruck size="1.2em" />} >
                <NavItem title="Lista Proveedores" link={Paths.ListaProveedores.path} /> 
                <NavItem title="Agregar Proveedor" link={Paths.AgregarProveedores.path} />
              </CollapsableNavItem> */}
              <NavItem
                title="Usuarios"
                link={Paths.ListaUsuarios.path}
                useReactIcon={true}
                reactIcon={<FiUsers size="1.4em"/>}
              />
              <NavItem
                title="Empleados"
                link={Paths.ListaEmpleados.path}
                useReactIcon={true}
                reactIcon={<BsPersonBadge size="1.2em" />}
              />
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};
export default Sidebar;