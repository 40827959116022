import { React,useState } from "react";
import Footer from "../Footer";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";

const RouteWithSidebar = (props) => {
  const { Component } = props;
  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem("settingsVisible") === "false" ? false : true;
  };
  const [showSettings, setShowSettings] = useState(
    localStorageIsSettingsVisible
  );

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem("settingsVisible", !showSettings);
  };
  return (
    <>
      {/* <Preloader show={loaded ? false : true} /> */}
      <Sidebar />

      <main className="content">
        <Navbar />
        <Component {...props} />
        <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
      </main>
    </>
  );
};

export default RouteWithSidebar;
