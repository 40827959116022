import {
  makePetition,
  petitionImageToBase64,
} from "../../../../helpers/petition";

const obtenerClientes = async (token) => {
  const formData = {};
  const urlTarget = "clientes/clientes";
  const petitionMethod = "GET";
  const petition = await makePetition(
    urlTarget,
    formData,
    petitionMethod,
    token
  );
  return petition;
};
const obtenerClienteEditar = async (clienteId, token) => {
  const formData = {};
  const urlTarget = `clientes/cliente/${clienteId}`;
  const petitionMethod = "GET";
  const petition = await makePetition(
    urlTarget,
    formData,
    petitionMethod,
    token
  );
  return petition;
};
const obtenerImagenes = async (payload, token) => {
  const formData = {};
  const urlTarget = payload;
  const petitionMethod = "GET";
  const petition = await petitionImageToBase64(
    urlTarget,
    formData,
    petitionMethod,
    token
  );
  return petition;
};
const obtenerImagenesClientes = async (clientesId, token) => {
  const formData = {};
  const urlTarget = `clientesImagenes/obtenerImagenesCliente/${clientesId}`;
  const petitionMethod = "GET";
  const petition = await makePetition(
    urlTarget,
    formData,
    petitionMethod,
    token
  );
  return petition;
};
const guardarCliente = async (
  clientesData,
  token
) => {
  try {
   
    const urlTarget = `clientes/${clientesData.clientesId > 0 ? "editar" : "nuevo"}`;
    const petitionMethod = clientesData.clientesId > 0 ? "PUT" : "POST";
    const petition = await makePetition(
      urlTarget,
      clientesData,
      petitionMethod,
      token
    );
    return petition;
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message:
        "Ocurrió un error inexperado, por favor vuelva a intentar. Si este error persiste contacte al administrador del sistema",
    };
  }
};
const guardarClientesImagenesRequest = async (
  clientesId,
  ImagenSufijo,
  ImagenCodigoBase64,
  ImagenNombre,
  token
) => {
  try {
    let sufijoMetodo = "";
    if (ImagenSufijo === "clientesImagenDui") sufijoMetodo = "nuevaImagenDui";
    else if (ImagenSufijo === "clientesImagenAdicional")
      sufijoMetodo = "nuevaImagenAdicional";
    else return false;

    const urlTarget = `clientesImagenes/${sufijoMetodo}`;
    const petitionMethod = "PUT";
    const formData = {
      clientesId,
      clientesImagenesArchivo: ImagenNombre,
      sufijoImagen: ImagenSufijo,
      clientesImagenesBase64: ImagenCodigoBase64,
    };
    const petition = await makePetition(
      urlTarget,
      formData,
      petitionMethod,
      token
    );
    return petition;
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: "Ocurrió un error inesperado",
    };
  }
};
const eliminarCliente = async (clientesId, token) => {
  try {
    const formData = { clientesId: clientesId };
    const urlTarget = "clientes/eliminar";
    const petitionMethod = "DELETE";
    const petition = await makePetition(
      urlTarget,
      formData,
      petitionMethod,
      token
    );
    return petition;
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message:
        "Ocurrió un error inexperado, por favor vuelva a intentar. Si este error persiste contacte al administrador del sistema",
    };
  }
};

const obtenerDepartamentos = async (paisId, token) => {
  const formData = {};
  const urlTarget = `departamentoPaises/obtenerDepartamentosPorPais/${paisId}`;
  const petitionMethod = "GET";
  const petition = await makePetition(
    urlTarget,
    formData,
    petitionMethod,
    token
  );
  return petition;
};

const obtenerPaises = async (token) => {
  const formData = {};
  const urlTarget = `departamentoPaises/obtenerPaises`;
  const petitionMethod = "GET";
  const petition = await makePetition(
    urlTarget,
    formData,
    petitionMethod,
    token
  );
  return petition;
};

export {
  obtenerClientes,
  obtenerClienteEditar,
  guardarCliente,
  eliminarCliente,
  guardarClientesImagenesRequest,
  obtenerImagenesClientes,
  obtenerImagenes,
  obtenerDepartamentos,
  obtenerPaises
};
