import {
  React,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import PropTypes from "prop-types";
import jwt_decode from "jwt-decode";
const AUTENTICACION = "TBP_AUTH";
const TOKEN = "TBP_TOKEN";
export const AuthContext = createContext();
export function AuthContextProvider({ children }) {
  const [estaAutenticado, setEstaAutenticado] = useState(
    window.localStorage.getItem(AUTENTICACION) ?? false
  );
  const [token, setToken] = useState(window.localStorage.getItem(TOKEN) ?? "");
  const login = useCallback(function (tokenLogin, datosUsuariosLogin) {
    window.localStorage.setItem(AUTENTICACION, true);
    window.localStorage.setItem(TOKEN, tokenLogin);
    setEstaAutenticado(true);
    setToken(tokenLogin);
  }, []);
  const logout = useCallback(function () {
    setToken("");
    setEstaAutenticado(false);
    localStorage.clear();
  }, []);
  const obtenerDatosUsuarios = useCallback(() => {
    const datosToken = jwt_decode(token);
    const datosUsuarios = datosToken["userData"] ?? {};
    return datosUsuarios;
  }, [token]);
  const valores = useMemo(() => {
    return {
      estaAutenticado,
      login,
      logout,
      token,
      obtenerDatosUsuarios,
    };
  }, [estaAutenticado, login, logout, token, obtenerDatosUsuarios]);

  return (
    <AuthContext.Provider value={valores}>{children}</AuthContext.Provider>
  );
}
AuthContextProvider.propTypes = {
  children: PropTypes.object,
};
export function useAuthContext() {
  return useContext(AuthContext);
}
