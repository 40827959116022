import React from 'react';
import add from "../../assets/img/tiendaBuenPrecio/util/plus02.svg";
import ProductoForm from '../../components/tiendaBuenPrecioComponents/Productos/ProductoForm';
import { useNavigate } from 'react-router-dom';
const AgregarProductos = () => {
  const navigate = useNavigate();
  const cancelarFormProductos = () => {
    navigate("/ListaProductos");
  }
  return (
    <div className= "mt-4">
      <div className="px-0 mb-3" >
        <div className="d-flex justify-content-start">
          <div className="mx-3">
            <img src={add} width="30" height="30" className="svg-icon" alt="Agregar"/>
          </div>
          <h4>
            Agregar Producto
          </h4>
        </div>
      </div>
      <ProductoForm 
        formType="NUEVO"
        cancelarFormProductos={cancelarFormProductos}
      />
    </div>
  )
}

export default AgregarProductos;