import React from 'react'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const SweetAlertQuestion = async (params) => {
    const {
        titulo = "",
        mensaje = "",
        cuerpo = "",
        tipo = "",
        textoBotonConfirmar = "Confirmar",
        textoBotonDenegar = "Cancelar",
        mostrarBotonConfirmar = true,
        mostrarBotonDenegar = true,
        htmlPersonalizado = null,
        functionCallback = () => { console.log("ON_OPEN")}
    } = params;
    const MySwal = withReactContent(Swal);
    let icono = "";
    switch (tipo) {
        case "informacion":
            icono = "info";
            break;
        case "error":
            icono = "error";
            break;
        case "advertencia":
            icono = "warning";
            break;
        case "pregunta":
            icono = "question";
            break;
        default:
            icono = "success";
            break;
    }
    const HtmlALert = () => {
        return htmlPersonalizado ? (htmlPersonalizado) :
            (<>
                <h2>{titulo}</h2>
                <h4>{mensaje}</h4>
                {Object.keys(cuerpo).length > 1 &&
                    <div>
                        {cuerpo}
                    </div>}

            </>);
    }
    const resultadoSwal = await MySwal.fire({
        icon: icono,
        html: <HtmlALert />,
        showDenyButton: mostrarBotonDenegar,
        showCancelButton: false,
        showConfirmButton: mostrarBotonConfirmar,
        denyButtonText: textoBotonDenegar,
        confirmButtonText: textoBotonConfirmar,
        showCloseButton: true,
        allowOutsideClick:false,
        didOpen: () => {
            functionCallback();
        }
    });
    return resultadoSwal;

}

export default SweetAlertQuestion;