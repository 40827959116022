import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, Modal, Row } from '@themesberg/react-bootstrap';
import { makePetition } from '../../../../helpers/petition';
import { ErrorAlert } from '../../Utils/ErrorAlert';
import { SuccessAlert } from '../../Utils/SuccessAlert';
//import onlyLetters from '../../Utils/onlyLetters';
import onlyLettersAndNumbers from '../../Utils/onlyLettersAndNumbers';
import { useAuthContext } from '../../../../contexts/authContext';
import { marcasPeticiones } from '../helper/peticionesMarcas';

const NuevaMarca = ({
    mostrarAgregarMarca,
    setMostrarAgregarMarca,
    setConsultarMarcas,
    setMarcaIdModalState = () => { }
}) => {
    const { logout, token: Token } = useAuthContext();
    const alertaTextoInicial = {
        title: "",
        message: ""
    };
    const [Marca, setMarca] = useState("");
    const [alertaVisible, setAlertaVisible] = useState(false);
    const [desactivarBotonGuardar, setDesactivarBotonGuardar] = useState(false)
    const [alertText, setAlertText] = useState(alertaTextoInicial);
    const [alertSuccessVisible, setAlertSuccessVisible] = useState(false);
    const [alertSuccessText, setAlertSuccessText] = useState(alertaTextoInicial);
    const handleMarca = ({ target }) => {
        const { value } = target;
        setMarca(value);
    }
    useEffect(() => {
        if (!mostrarAgregarMarca) {
            setAlertSuccessVisible(false);
            setAlertaVisible(false);
            setAlertText({
                title: "",
                message: ""
            });
            setAlertSuccessText({
                title: "",
                message: ""
            });
            setMarca("");
        }
    }, [mostrarAgregarMarca]);
    const guardarMarca = async (e) => {
        try {
            e.preventDefault();
            setAlertSuccessVisible(false);
            setAlertaVisible(false);
            if (Marca.trim().length <= 2) {
                setAlertText({ title: "Error", message: "La descripción de Marca debe ser mayor a 2 caracteres." });
                setAlertaVisible(true);
                return false;
            }
            setDesactivarBotonGuardar(true);
            const requestData =
            {
                "marcaDescripcion": Marca
            };
            const request = await marcasPeticiones("nuevaMarca",Token,requestData);
            const { status, ok } = request;
            if (status === 401) {
              logout();
              return false;
            }
            if (!ok) {
                setAlertText({ title: "Error", message: "Ocurrió un error inesperado al agregar una nueva marca, por favor contacte con el administrador." })
                setAlertaVisible(true);
                setDesactivarBotonGuardar(false);;
                return false;
            }
            const response = await request.json();
            const { success, message = "", data = {} } = response;
            if (!success) {
                setAlertText({ title: "Error", message: message })
                setAlertaVisible(true);
                setDesactivarBotonGuardar(false);
                return false;
            }
            setMarca("");
            if (Object.keys(data).length > 0) {
                setMarcaIdModalState(data.marcasId);
            }
            setAlertSuccessText({ title: "Exito", message: message });
            setAlertSuccessVisible(true);
            setDesactivarBotonGuardar(false);
            setTimeout(() => {
                setAlertSuccessVisible(false);
                setMostrarAgregarMarca(false)
            }, 2000);
            setConsultarMarcas(true);
            return true;
        } catch (error) {
            console.error(error);
            setAlertText({ title: "Error", message: "Ocurrió un error inexperado, por favor contacta con soporte" });
            setAlertaVisible(true);
            setDesactivarBotonGuardar(false);
        }
    }
    return (
        <>
            <Modal
                as={Modal.Dialog}
                centered
                show={mostrarAgregarMarca}
                onHide={() => setMostrarAgregarMarca(false)}
                backdrop="static"
                keyboard={false}
                size="md"
            >
                <Container>
                    <Modal.Header className="text-center">
                        <Button variant="close" aria-label="Close" onClick={() => setMostrarAgregarMarca(false)} />
                    </Modal.Header>
                    <Form onSubmit={guardarMarca}>
                        <Modal.Body>

                            <Row>
                                <Col>
                                    <h2>Nueva Marca</h2>
                                </Col>
                                <Col xs={12}>
                                    <Form.Group className="mb-4">
                                        <Form.Label></Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Ingrese el nombre de la Marca"
                                            id="Marca"
                                            name="Marca"
                                            value={Marca}
                                            onChange={handleMarca}
                                            size="md"
                                            onKeyPress={(event) => event.target.value.toString().length > 98 ? event.preventDefault() : onlyLettersAndNumbers(event)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Col xs={12}>
                                {(alertaVisible ? (
                                    <ErrorAlert title={alertText.title} message={alertText.message} />
                                ) : null)}
                                {(alertSuccessVisible ? (
                                    <SuccessAlert title={alertSuccessText.title} message={alertSuccessText.message} />
                                ) : null)}
                            </Col>
                            <Button
                                size="md"
                                variant="outline-primary"
                                className="w-100 mt-2"
                                onClick={guardarMarca}
                                disabled={desactivarBotonGuardar}
                            >
                                Guardar
                            </Button>
                            <Button
                                size="md"
                                variant="danger"
                                className="w-100 mt-1"
                                onClick={() => setMostrarAgregarMarca(false)}
                                disabled={desactivarBotonGuardar}
                            >
                                Cancelar
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Container>
            </Modal>
        </>
    )
}

export default NuevaMarca;