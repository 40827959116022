import { Button } from '@themesberg/react-bootstrap';
import React from 'react';
import { BsPeople, BsPlusLg } from 'react-icons/bs';
import { Link} from 'react-router-dom';
import ClientesLista from '../../components/tiendaBuenPrecioComponents/Clientes/ClientesLista';
import { Routes } from '../../routes';
import { Paths } from '../../config/router/paths';
const ListaClientes = (props) => {
    
    return (
        <div className='mt-4'>
            <div className="mb-4 mx-1 d-flex justify-content-between" >
                <div className="d-flex justify-content-start">
                    <BsPeople size="2em" className='mx-2' />
                    <h4>
                        Lista Clientes
                    </h4>
                </div>
                <div className=''>
                    <Link
                        className='mx-2 btn btn-lg btn-outline-primary'
                        to={Paths.AgregarClientes.path}
                    >
                        <BsPlusLg className='mx-2'/>  Nuevo Cliente
                    </Link>
                </div>
            </div>
            <ClientesLista />
        </div>
    )
}

export default ListaClientes;