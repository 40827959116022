import React, { useEffect, useState } from "react";
import { Form, Button, Col, Row, Card } from "@themesberg/react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import UsuariosImagenes from "./UsuariosImagenes";
import { useNavigate } from "react-router-dom";
import SweetAlert from "../Utils/SweetAlert";
import UsuariosModalPassword from "./UsuariosModalPassword";
import Cargando from "../Utils/Loading";
import {
  guardarUsuario,
  guardarUsuariosImagenesRequest,
  obtenerImagenes,
  obtenerImagenesUsuarios,
  obtenerUsuarioEditar,
} from "./helper/usuariosPeticiones";
import imgPreview from "../../../assets/img/tiendaBuenPrecio/util/image-svgrepo-com.svg";
import { useAuthContext } from "../../../contexts/authContext";
import onlyLettersAndNumbers from "../Utils/onlyLettersAndNumbers";
import emailChecker from "../Utils/emailChecker";
const UsuarioForm = ({
  formType,
  usuariosId = 0,
  llamadaDesde = { desde: "PAGINA", fnCierre: () => {}, fnCallBack: () => {} },
  guardarIdState = false,
  guardarId = () => {},
}) => {
  if (formType === "EDITAR") {
    usuariosId = parseInt(usuariosId);
    if (
      !usuariosId ||
      isNaN(parseInt(usuariosId)) ||
      parseInt(usuariosId) <= 0
    ) {
      SweetAlert(
        "Error",
        `El identificador del usuario es invalido, por favor contacte al administrador del sistema`,
        "error",
        true
      );
    }
  } else {
    usuariosId = 0;
  }
  let usuariosDefaultEsquema = {
    usuarioEmail: "",
    usuarioNombreUsuario: "",
    usuarioPassword: "",
    usuarioPasswordRepetir: "",
    usuarioActivo: 1,
    usuariosRole: 2,
  };
  if (formType === "EDITAR") {
    usuariosDefaultEsquema = {
      usuarioEmail: "",
      usuarioNombreUsuario: "",
      usuarioActivo: 1,
      usuariosRole: 2,
    };
  }
  const { logout, token: Token } = useAuthContext();
  const [mostrarCambiarContrasenia, setMostrarCambiarContrasenia] =
    useState(false);
  const [consultarUsuario, setConsultarUsuario] = useState(
    formType === "EDITAR" ? true : false
  );
  const [showLoading, setShowLoading] = useState(false);
  const [usuariosDefaultValues, setUsuariosDefaultValues] = useState(
    usuariosDefaultEsquema
  );
  const [cambioContraseniaExitoso, setCambioContraseniaExitoso] =
    useState(false);
  const [imagenPreviewFrontal, setImagenPreviewFrontal] = useState(imgPreview);
  const [imagenPreviewAdicional, setImagenPreviewAdicional] =
    useState(imgPreview);
  const hanleCloseModalContrasenia = () => {
    setMostrarCambiarContrasenia(false);
  };
  const handleShowModalContrasenia = () => {
    setMostrarCambiarContrasenia(true);
  };
  const navigate = useNavigate();
  useEffect(() => {
    if (cambioContraseniaExitoso) {
      setCambioContraseniaExitoso(false);
      SweetAlert("Exito", `Contraseña cambiada con éxito`, "success", false);
    }
  }, [cambioContraseniaExitoso]);

  useEffect(() => {
    if (
      consultarUsuario &&
      !(!usuariosId || isNaN(parseInt(usuariosId)) || parseInt(usuariosId) <= 0)
    ) {
      setConsultarUsuario(false);
      setShowLoading(true);
      (async () => {
        try {
          const obtenerUsuariosDatos = await obtenerUsuarioEditar(
            usuariosId,
            Token
          );
          const {
            status: obtenerUsuariosDatosStatus,
            ok: obtenerUsuariosDatosOk,
          } = obtenerUsuariosDatos;
          if (obtenerUsuariosDatosStatus === 401) {
            setShowLoading(false);
            logout();
            return false;
          }
          if (!obtenerUsuariosDatosOk) {
            throw new Error("Error de petición.");
          }
          const obtenerUsuariosDatosResponse =
            await obtenerUsuariosDatos.json();
          const { success, message, data } = obtenerUsuariosDatosResponse;
          if (!success || Object.keys(data).length < 1) {
            setShowLoading(false);
            SweetAlert("Error", `${message}`, "error", true);
            return false;
          }

          const {
            usuariosEmail,
            usuariosNombreUsuario,
            usuariosActivo,
            usuariosRole,
          } = data[0];
          setUsuariosDefaultValues({
            usuarioEmail: usuariosEmail,
            usuarioNombreUsuario: usuariosNombreUsuario,
            usuarioPassword: "",
            usuarioPasswordRepetir: "",
            usuarioActivo: usuariosActivo,
            usuariosRole: usuariosRole,
          });
          const obtenerImagenesUsuariosPeticion = await obtenerImagenesUsuarios(
            usuariosId,
            Token
          );
          const {
            status: obtenerImagenesUsuariosPeticionStatus,
            ok: obtenerImagenesUsuariosPeticionStatusOk,
          } = obtenerImagenesUsuariosPeticion;
          if (obtenerImagenesUsuariosPeticionStatus === 401) {
            setShowLoading(false);
            logout();
            return false;
          }
          if (!obtenerImagenesUsuariosPeticionStatusOk) {
            throw new Error("Error de petición.");
          }
          const obtenerImagenesUsuariosPeticionResponse =
            await obtenerImagenesUsuariosPeticion.json();
          const {
            success: successObtenerImagenes,
            data: dataObtenerImagenes,
            message: messageObtenerImagenes,
          } = obtenerImagenesUsuariosPeticionResponse;
          if (!successObtenerImagenes) {
            SweetAlert(
              "Error",
              messageObtenerImagenes ||
                "Ocurrió un error al obtener las imagenes del usuario.",
              "error",
              true
            );
            setShowLoading(false);
            return false;
          }
          if (dataObtenerImagenes.length < 1) {
            setShowLoading(false);
            return true;
          }
          dataObtenerImagenes.map(async (imagen) => {
            const { usuariosImagenesArchivo, link, sufijoImagen } = imagen;
            if (sufijoImagen === "usuariosImagenFrontal") {
              const imagenBase64 = await obtenerImagenes(link,Token);
              setImagenPreviewFrontal(imagenBase64);
            } else if (sufijoImagen === "usuariosImagenAdicional") {
              const imagenBase64 = await obtenerImagenes(link,Token);
              setImagenPreviewAdicional(imagenBase64);
            }
          });
          setShowLoading(false);
        } catch (error) {
          setShowLoading(false);
          SweetAlert(
            "Error",
            `Ocurrió un error inesperado en la peticion de datos de usuario`,
            "error",
            true
          );
          return false;
        }
      })();
    }
  }, [consultarUsuario, setConsultarUsuario, usuariosId, Token, logout]);

  let esquemaValidacionUsuario = {
    usuarioEmail: Yup.string()
      .email("Por favor ingrese un correo valido ej: usuario@email.com")
      .min(5, "El correo electrónico debe ser mayor o igual 5 caracteres")
      .max(98, "El correo electrónico debe ser menor o igual a 98 caracteres")
      .required("El correo electrónico es obligatorio"),
    usuarioNombreUsuario: Yup.string()
      .min(7, "El nombre de usuario debe ser mayor o igual a 4 caracteres")
      .max(26, "El nombre de usuario debe ser menor o igual a 26 caracteres")
      .required("El nombre de usuario es obligatorio"),
    usuarioActivo: Yup.number()
      .integer()
      .min(0)
      .max(1)
      .required("Debe establecer si el usuario esta activo o desactivado."),
    usuariosRole: Yup.string()
      .min(4, "Debe establecer el rol del usuario.")
      .required("Debe establecer el rol del usuario."),
  };
  if (formType === "NUEVO") {
    esquemaValidacionUsuario = {
      ...esquemaValidacionUsuario,
      usuarioPassword: Yup.string()
        .min(8, "La contraseña debe ser mayor o igual a 8 caracteres")
        .required("La contraseña es obligatoria, por favor escriba una"),
      usuarioPasswordRepetir: Yup.string()
        .min(8, "La contraseña debe ser mayor o igual a 8 caracteres")
        .oneOf(
          [Yup.ref("usuarioPassword"), null],
          "La confirmación de contraseña fallo. Por favor ingrese la misma contraseña del campo anterior"
        )
        .required("La confirmación de contraseña es obligatoria"),
    };
  }
  const UsuariosSchema = Yup.object().shape(esquemaValidacionUsuario);

  const formSubmitHandler = (valores, resetForm) => {
    (async () => {
      setShowLoading(true);
      let errorResultanteEventos = false;
      let mensajeResultanteEventos = "";
      try {
        const {
          usuarioEmail,
          usuarioNombreUsuario,
          usuarioActivo,
          usuariosRole,
          usuarioPassword,
        } = valores;
        const guardarUsuarioRequest = await guardarUsuario(
          Token,
          usuarioEmail,
          usuarioNombreUsuario,
          usuarioActivo,
          usuariosRole,
          usuarioPassword,
          formType === "EDITAR" ? usuariosId : 0
        );
        setShowLoading(false);
        const { ok, status } = guardarUsuarioRequest;
        if (status === 401) {
          logout();
          return false;
        }
        if (!ok) {
          throw new Error("Ocurrió un  error en la peticion.");
        }
        const guardarUsuarioResponse = await guardarUsuarioRequest.json();
        const {
          success = false,
          message = "",
          data = [],
        } = guardarUsuarioResponse;
        if (!success) {
          SweetAlert("Error", `${message}`, "error", true);
          return false;
        }
        let usuarioAlmacenadoId = 0;
        if (parseInt(usuariosId) === 0) {
          const { userId = 0 } = data;
          usuarioAlmacenadoId = userId;
          if (usuarioAlmacenadoId < 1) {
            SweetAlert("Error", `Ocurrió un error inesperado.`, "error", true);
            return false;
          }
          guardarId(usuarioAlmacenadoId);
        } else {
          usuarioAlmacenadoId = usuariosId;
          guardarId(usuarioAlmacenadoId);
        }
        const { imagenFrontal, imagenAdicional } = listaUsuariosImagenes;
        if (Object.keys(imagenFrontal).length > 1) {
          const { name: imagenNameFrontal, base64: imagenBase64Frontal } =
            imagenFrontal;
          const guardarImageneFrontalUsuarios =
            await guardarUsuariosImagenesRequest(
              usuarioAlmacenadoId,
              "usuariosImagenFrontal",
              imagenBase64Frontal,
              imagenNameFrontal,
              Token
            );
          const { ok, status } = guardarImageneFrontalUsuarios;
          if (status === 401) {
            logout();
            return false;
          }
          if (!ok) {
            throw new Error("Ocurrió un  error en la peticion.");
          }
          const guardarImageneFrontalUsuariosResponse =
            await guardarImageneFrontalUsuarios.json();
          const { success } = guardarImageneFrontalUsuariosResponse;
          if (!success) {
            errorResultanteEventos = true;
            mensajeResultanteEventos += `\n Ocurrió un error al subir la foto de perfil del usuario. `;
          } else {
            mensajeResultanteEventos += `\n Foto de perfil subida con éxito.`;
          }
        }
        if (Object.keys(imagenAdicional).length > 1) {
          const {
            name: imagenNameImagenAdicional,
            base64: imagenBase64ImagenAdicional,
          } = imagenAdicional;
          const guardarImageneAdicionalUsuarios =
            await guardarUsuariosImagenesRequest(
              usuarioAlmacenadoId,
              "usuariosImagenAdicional",
              imagenBase64ImagenAdicional,
              imagenNameImagenAdicional,
              Token
            );
          const { ok, status } = guardarImageneAdicionalUsuarios;
          if (status === 401) {
            logout();
            return false;
          }
          if (!ok) {
            throw new Error("Ocurrió un  error en la peticion.");
          }
          const guardarImageneAdicionalUsuariosResponse =
            await guardarImageneAdicionalUsuarios.json();
          const { success } = guardarImageneAdicionalUsuariosResponse;
          if (!success) {
            errorResultanteEventos = true;
            mensajeResultanteEventos += `\n Ocurrió un error al subir la imagen adicional del usuario.`;
          } else {
            mensajeResultanteEventos += `\n Imagen adicional subida con éxito.`;
          }
        }
        if (errorResultanteEventos) {
          mensajeResultanteEventos +=
            "\n Si ocurrieron errores en la subida de imágenes, puede volver a intentarlo en la edición del usuario.";
          SweetAlert(
            "Advertencia",
            message + mensajeResultanteEventos,
            "warning",
            true
          );
        } else {
          SweetAlert(
            "Exito",
            message + mensajeResultanteEventos,
            "success",
            true
          );
        }
        cancelarForm();
        return true;
      } catch (error) {
        console.errpr(error);
        setShowLoading(false);
        SweetAlert("Error", `Ocurrió un error inesperado`, "error", true);
        return false;
      }
    })();
  };
  const cancelarForm = () => {
    const { desde, fnCierre, fnCallBack } = llamadaDesde;
    if (desde === "MODAL") {
      fnCierre(true);
      if (typeof fnCallBack === "function") {
        fnCallBack();
      }
    } else {
      navigate("/ListaUsuarios");
    }
  };
  const [listaUsuariosImagenes, setListaUsuariosImagenes] = useState({
    imagenFrontal: {},
    imagenAdicional: {},
  });

  return (
    <>
      <Cargando loading={showLoading} />
      <Formik
        initialValues={usuariosDefaultValues}
        validationSchema={UsuariosSchema}
        enableReinitialize={formType === "EDITAR" ? true : false}
        onSubmit={(values, { resetForm }) =>
          formSubmitHandler(values, resetForm)
        }
      >
        {({
          handleChange,
          handleSubmit,
          handleBlur,
          values: inputs,
          errors,
          touched,
        }) => {
          const {
            usuarioEmail,
            usuarioNombreUsuario,
            usuarioActivo,
            usuariosRole,
            usuarioPassword = "",
            usuarioPasswordRepetir = "",
          } = inputs;
          return (
            <Card className="p-4">
              <Form onSubmit={handleSubmit} className="mb-4">
                <Row>
                  <Col md={4} sm={12} xs={12} className="mt-4">
                    <Form.Group>
                      <Form.Label>Correo Electronico</Form.Label>
                      <Form.Control
                        autoFocus
                        type="text"
                        id="usuarioEmail"
                        name="usuarioEmail"
                        value={usuarioEmail}
                        onChange={handleChange}
                        placeholder="Ingrese el correo electronico del usuario"
                        isInvalid={touched.usuarioEmail && errors.usuarioEmail}
                        onKeyPress={emailChecker}
                      ></Form.Control>
                    </Form.Group>
                    {touched.usuarioEmail && errors.usuarioEmail && (
                      <div className="text-danger">{errors.usuarioEmail} </div>
                    )}
                  </Col>
                  <Col md={4} sm={12} xs={12} className="mt-4">
                    <Form.Group>
                      <Form.Label>Nombre de Usuario</Form.Label>
                      <Form.Control
                        type="text"
                        id="usuarioNombreUsuario"
                        name="usuarioNombreUsuario"
                        value={usuarioNombreUsuario}
                        onChange={handleChange}
                        onKeyPress={onlyLettersAndNumbers}
                        placeholder="Ingrese el nombre del usuario"
                        isInvalid={touched.usuarioNombreUsuario && errors.usuarioNombreUsuario}
                        maxLength={20}
                      ></Form.Control>
                      {touched.usuarioNombreUsuario &&
                        errors.usuarioNombreUsuario && (
                          <div className="text-danger">
                            {errors.usuarioNombreUsuario}
                          </div>
                        )}
                    </Form.Group>
                  </Col>
                  <Col md={4} sm={12} xs={12} className="mt-4">
                    <Form.Group>
                      <Form.Label>Usuario Activo</Form.Label>
                      <Form.Select
                        type="text"
                        id="usuarioActivo"
                        name="usuarioActivo"
                        value={usuarioActivo}
                        onChange={handleChange}
                        isInvalid={touched.usuarioActivo && errors.usuarioActivo}
                      >
                        <option value={0}>Desactivado</option>
                        <option value={1}>Activado</option>
                      </Form.Select>
                      {touched.usuarioActivo && errors.usuarioActivo && (
                        <div className="text-danger">
                          {errors.usuarioActivo}
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={4} sm={12} xs={12} className="mt-4">
                    <Form.Group>
                      <Form.Label>Usuario Role</Form.Label>
                      <Form.Select
                        id="usuariosRole"
                        name="usuariosRole"
                        value={usuariosRole}
                        onChange={handleChange}
                        isValid={touched.usuariosRole && !errors.usuariosRole}
                        isInvalid={touched.usuariosRole && errors.usuariosRole}
                      >
                        <option value="">Seleccionar</option>
                        <option value="VENDEDOR">Vendedor</option>
                        <option value="BODEGUERO">Bodeguero</option>
                        <option value="VIGILANTE">Vigilante</option>
                      </Form.Select>
                      {touched.usuariosRole && errors.usuariosRole && (
                        <div className="text-danger">{errors.usuariosRole}</div>
                      )}
                    </Form.Group>
                  </Col>
                  {formType !== "EDITAR" ? (
                    <>
                      <Col md={4} sm={12} xs={12} className="mt-4">
                        <Form.Group>
                          <Form.Label>Password</Form.Label>
                          <Form.Control
                            type="password"
                            id="usuarioPassword"
                            name="usuarioPassword"
                            value={usuarioPassword}
                            onChange={handleChange}
                            isInvalid={
                              usuarioPassword.trim().length > 0 &&
                              usuarioPasswordRepetir !== usuarioPassword
                                ? true
                                : false
                            }
                            isValid={
                              usuarioPassword.trim().length > 0 &&
                              usuarioPasswordRepetir === usuarioPassword
                                ? true
                                : false
                            }
                          ></Form.Control>
                          {touched.usuarioPassword &&
                            errors.usuarioPassword && (
                              <div className="text-danger">
                                {errors.usuarioPassword}
                              </div>
                            )}
                          {!errors.usuarioPasswordRepetir &&
                            usuarioPasswordRepetir !== usuarioPassword && (
                              <div className="text-danger">
                                La contraseña no coincide
                              </div>
                            )}
                        </Form.Group>
                      </Col>
                      <Col md={4} sm={12} xs={12} className="mt-4">
                        <Form.Group>
                          <Form.Label>Confirmar Password</Form.Label>
                          <Form.Control
                            type="password"
                            id="usuarioPasswordRepetir"
                            name="usuarioPasswordRepetir"
                            value={usuarioPasswordRepetir}
                            onChange={handleChange}
                            isInvalid={
                              usuarioPasswordRepetir.trim().length > 0 &&
                              usuarioPasswordRepetir !== usuarioPassword
                                ? true
                                : false
                            }
                            isValid={
                              usuarioPasswordRepetir.trim().length > 0 &&
                              usuarioPasswordRepetir === usuarioPassword
                                ? true
                                : false
                            }
                          ></Form.Control>
                          {touched.usuarioPasswordRepetir &&
                            errors.usuarioPasswordRepetir && (
                              <div className="text-danger">
                                {errors.usuarioPasswordRepetir}
                              </div>
                            )}
                          {!errors.usuarioPasswordRepetir &&
                            usuarioPasswordRepetir !== usuarioPassword && (
                              <div className="text-danger">
                                La contraseña no coincide
                              </div>
                            )}
                        </Form.Group>
                      </Col>
                    </>
                  ) : (
                    <Col md={4} sm={12} xs={12} className="mt-4">
                      <div className="mt-4 mx-2">
                        <p>
                          Cambiar contraseña{"  "}
                          <button
                            className="btn btn-primary btn-sm"
                            type="button"
                            onClick={handleShowModalContrasenia}
                          >
                            Aqui
                          </button>
                        </p>
                      </div>
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col md={9} xs={12} className="mt-5">
                    <h5>Imágenes de Usuario</h5>
                    <UsuariosImagenes
                      listaUsuariosImagenes={listaUsuariosImagenes}
                      setListaUsuariosImagenes={setListaUsuariosImagenes}
                      imagenPreviewFrontal={imagenPreviewFrontal}
                      imagenPreviewAdicional={imagenPreviewAdicional}
                    />
                  </Col>
                  <Col md={3} xs={12} className="mt-7">
                    <Button
                      variant="outline-primary"
                      type="submit"
                      className="w-100"
                    >
                      Guardar
                    </Button>
                    <Button
                      variant="danger"
                      type="button"
                      className="w-100 mt-2"
                      onClick={cancelarForm}
                    >
                      Cancelar
                    </Button>
                  </Col>
                </Row>
              </Form>
              {mostrarCambiarContrasenia && (
                <UsuariosModalPassword
                  mostrarCambiarContrasenia={mostrarCambiarContrasenia}
                  hanleCloseModalContrasenia={hanleCloseModalContrasenia}
                  setCambioContraseniaExitoso={setCambioContraseniaExitoso}
                  setShowLoading={setShowLoading}
                  usuariosId={usuariosId}
                />
              )}
            </Card>
          );
        }}
      </Formik>
    </>
  );
};

export default UsuarioForm;
