import {
  makePetition,
  petitionImageToBase64,
} from "../../../../helpers/petition";

const obtenerEmpleados = async (Token) => {
  const formData = {};
  const urlTarget = "empleados/empleados";
  const petitionMethod = "GET";
  const petition = await makePetition(
    urlTarget,
    formData,
    petitionMethod,
    Token
  );
  return petition;
};
const obtenerEmpleadoEditar = async (empleadosId, Token) => {
  const formData = {};
  const urlTarget = `empleados/empleado/${empleadosId}`;
  const petitionMethod = "GET";
  const petition = await makePetition(
    urlTarget,
    formData,
    petitionMethod,
    Token
  );
  return petition;
};
const obtenerImagenes = async (payload, Token) => {
  const formData = {};
  const urlTarget = payload;
  const petitionMethod = "GET";
  const petition = await petitionImageToBase64(
    urlTarget,
    formData,
    petitionMethod,
    Token
  );
  return petition;
};
const obtenerImagenesEmpleados = async (empleadosId, Token) => {
  const formData = {};
  const urlTarget = `empleadosImagenes/obtenerImagenesEmpleado/${empleadosId}`;
  const petitionMethod = "GET";
  const petition = await makePetition(
    urlTarget,
    formData,
    petitionMethod,
    Token
  );
  return petition;
};
const obtenerUsuariosPorEmpresa = async (type, Token) => {
  const formData = {};
  const urlTarget =
    type === "NUEVO" ? `usuarios/usuariosDisponibles` : `usuarios/usuarios`;
  const petitionMethod = "GET";
  const petition = await makePetition(
    urlTarget,
    formData,
    petitionMethod,
    Token
  );
  return petition;
};
const guardarEmpleado = async (
  Token,
  empleadosId,
  empleadosNombres,
  empleadosApellidos,
  empleadosFechaNacimiento,
  empleadosDireccion,
  empleadosTelefono,
  empleadosEmail,
  empleadosNumeroDeCuenta,
  empleadosSalario,
  empleadosDui,
  empleadosNit,
  bancosId,
  usuariosId
) => {
  try {
    const formData = {
      empleadosNombres,
      empleadosApellidos,
      empleadosFechaNacimiento,
      empleadosDireccion,
      empleadosTelefono,
      empleadosEmail,
      empleadosNumeroDeCuenta,
      empleadosSalario,
      empleadosDui,
      empleadosNit,
      bancosId,
      usuariosId
    };
    if (empleadosId > 0) {
      formData.empleadosId = empleadosId;
    }
    const urlTarget = `empleados/${empleadosId > 0 ? "editar" : "nuevo"}`;
    const petitionMethod = empleadosId > 0 ? "PUT" : "POST";
    const petition = await makePetition(
      urlTarget,
      formData,
      petitionMethod,
      Token
    );
    return petition;
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message:
        "Ocurrió un error inexperado, por favor vuelva a intentar. Si este error persiste contacte al administrador del sistema",
      errorMessage: error,
    };
  }
};
const guardarEmpleadosImagenesRequest = async (
  Token,
  empleadosId,
  ImagenSufijo,
  ImagenCodigoBase64,
  ImagenNombre
) => {
  try {
    let sufijoMetodo = "";
    if (ImagenSufijo === "empleadosImagenDui") sufijoMetodo = "nuevaImagenDui";
    else if (ImagenSufijo === "empleadosImagenAdicional")
      sufijoMetodo = "nuevaImagenAdicional";
    else return false;
    const urlTarget = `empleadosImagenes/${sufijoMetodo}`;
    const petitionMethod = "PUT";
    const formData = {
      empleadosId,
      empleadosImagenesArchivo: ImagenNombre,
      sufijoImagen: ImagenSufijo,
      empleadosImagenesBase64: ImagenCodigoBase64,
    };
    const petition = await makePetition(
      urlTarget,
      formData,
      petitionMethod,
      Token
    );
    return petition;
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: "Ocurrió un error inesperado",
    };
  }
};
const eliminarEmpleado = async (empleadosId, Token) => {
  try {
    const formData = { empleadosId: empleadosId };
    const urlTarget = "empleados/eliminar";
    const petitionMethod = "DELETE";
    const petition = await makePetition(
      urlTarget,
      formData,
      petitionMethod,
      Token
    );
    return petition;
  } catch (error) {
    throw error;
  }
};

export {
  obtenerEmpleados,
  obtenerEmpleadoEditar,
  guardarEmpleado,
  eliminarEmpleado,
  guardarEmpleadosImagenesRequest,
  obtenerImagenesEmpleados,
  obtenerImagenes,
  obtenerUsuariosPorEmpresa,
};
