import {
  faFileCsv,
  faFilePdf,
  faPen,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Col, Row } from "@themesberg/react-bootstrap";
import jsPDF from "jspdf";
import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  CSVExport,
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { Link } from "react-router-dom";
import Cargando from "../Utils/Loading";
import SweetAlert from "../Utils/SweetAlert";
import SweetAlertQuestion from "../Utils/SweetAlertQuestion";
import { eliminarCliente, obtenerClientes } from "./helper/clientesPeticiones";
import { useAuthContext } from "../../../contexts/authContext";

const ClientesLista = () => {
  const { logout, token: Token } = useAuthContext();
  const [consulta, setConsulta] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  const [listaClientes, setListaClientes] = useState([]);
  useEffect(() => {
    if (consulta) {
      setConsulta(false);
      (async () => {
        try {
          setShowLoading(true);
          const request = await obtenerClientes(Token);
          setShowLoading(false);
          const { status, ok } = request;
          if (status === 401) {
            logout();
            return false;
          }
          if (!ok) {
            throw new Error("Error de petición.");
          }
          const response = await request.json();
          const { success, message = "", data = [] } = response;
          if (!success || data.length <= 0) {
            setListaClientes([]);
            return false;
          }
          setListaClientes(data);
          return true;
        } catch (error) {
          setShowLoading(false);
          SweetAlert(
            "Error",
            `Por favor contacte al administrador del sistema`,
            "error",
            true
          );
          return false;
        }
      })();
    }
  }, [consulta, Token, logout]);

  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const generarPdfTabla = () => {
    const doc = new jsPDF({ orientation: "landscape" });
    doc.text("Lista de Clientes", 18, 18);
    doc.autoTable({ html: "#clientesTabla" });
    doc.autoTable({
      head: [
        ["#", "Descripcion", "Direccion", "Telefono", "Email", "NIC", "NIC"],
      ],
      body: listaClientes.map((cliente, index) => [
        index + 1,
        cliente.clientesNombres,
        cliente.clientesDui,
        cliente.clientesNRC,
        cliente.clientesDireccion,
        cliente.clientesTelefono,
        cliente.clientesEmail,
        cliente.clientesFechaNacimiento,
      ]),
    });

    doc.save("table.pdf");
  };

  const botonesOpciones = (cell, row, rowIndex) => {
    const handlerEliminarCliente = async (clientesDatos) => {
      try {
        let { clientesId } = clientesDatos;
        clientesId = parseInt(clientesId);
        if (clientesId < 1) {
          SweetAlert(
            "Error",
            `No se puede identificar el cliente que desea eliminar`,
            "error",
            true
          );
        }
        const preguntaEliminacionParametros = {
          tipo: "pregunta",
          titulo: "Advertencia",
          mensaje: "¿Esta seguro que desea eliminar este cliente?",
        };

        const preguntaEliminacion = await SweetAlertQuestion(
          preguntaEliminacionParametros
        );
        const { isConfirmed = false } = preguntaEliminacion;
        if (!isConfirmed) {
          return false;
        }
        setShowLoading(true);
        const request = await eliminarCliente(clientesId, Token);
        setShowLoading(false);
        const { status, ok } = request;
        if (status === 401) {
          logout();
          return false;
        }
        if (!ok) {
          throw new Error("Error de petición.");
        }
        const response = await request.json();
        const { success = false, message = "" } = response;
        if (!success) {
          SweetAlert("Error", `${message}`, "error", true);
          return false;
        }
        SweetAlert("Exito", `${message}`, "success", true);
        setConsulta(true);
        return true;
      } catch (error) {
        SweetAlert(
          "Exito",
          `Ocurrió un error en la peticion, por favor contacte con el administrador.`,
          "success",
          true
        );
      }
    };
    const urlEditar = `/EditarClientes/${row.clientesId}`;
    return (
      <div className="d-flex">
        <Link
          to={urlEditar}
          className="mx-1 bnt btn-icon-only  btn-info btn-sm"
        >
          <AiOutlineEdit size="1.2em" />
        </Link>
        <Button
          size="sm"
          variant="danger"
          onClick={() => handlerEliminarCliente(row)}
          className="btn-icon-only"
        >
          <AiOutlineDelete size="1.2em" />
        </Button>
      </div>
    );
  };
  const index = (cell, row, rowIndex) => rowIndex + 1;
  const columns = [
    { dataField: "", text: "#", sort: true, formatter: index },
    {
      dataField: "clientesId",
      text: "Opciones",
      sort: false,
      formatter: botonesOpciones,
    },
    { dataField: "clientesNombres", text: "Nombre", sort: true },
    { dataField: "clientesDui", text: "DUI", sort: true },
    { dataField: "clientesNRC", text: "NIC", sort: true },
    { dataField: "clientesDireccion", text: "Dirección", sort: true },
    { dataField: "clientesTelefono", text: "Teléfono", sort: true },
    { dataField: "clientesEmail", text: "Email", sort: true },
    { dataField: "clientesFechaNacimiento", text: "Nacimiento", sort: true },
  ];
  return (
    <div className="px-1">
      <Card>
        <div className="pagesCustomParent p-4">
          <Cargando loading={showLoading} />
          <div>
            <ToolkitProvider
              keyField="clientesId"
              data={listaClientes}
              columns={columns}
              search
              exportCSV
            >
              {(props) => (
                <>
                  <Row>
                    <Col md={6} sm={12}>
                      <label>Buscar Cliente:</label>
                      <SearchBar {...props.searchProps} className="my-2 w-40" />
                    </Col>
                    <Col
                      md={6}
                      sm={12}
                      className="d-flex align-items-end justify-content-end"
                    >
                      <ExportCSVButton
                        {...props.csvProps}
                        className="btn btn-tertiary btn-sm my-2"
                      >
                        <FontAwesomeIcon icon={faFileCsv} /> CSV
                      </ExportCSVButton>
                      <Button
                        className="mx-2 my-2"
                        size="sm"
                        variant="danger"
                        onClick={generarPdfTabla}
                      >
                        <FontAwesomeIcon icon={faFilePdf} /> PDF
                      </Button>
                    </Col>
                  </Row>

                  <BootstrapTable
                    tableHeaderClass="table"
                    wrapperClasses="table-responsive"
                    headerClasses=""
                    hover
                    bordered={false}
                    search={{}}
                    align="center"
                    tabIndexCell
                    pagination={paginationFactory()}
                    noDataIndication="No existen clientes registrados"
                    {...props.baseProps}
                  ></BootstrapTable>
                </>
              )}
            </ToolkitProvider>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default ClientesLista;
