import React from 'react';
import { BsReverseLayoutTextSidebarReverse } from 'react-icons/bs';
import InventarioLista from '../components/InventarioLista';

const Inventario = () => {
  return (
    <div className="mt-4">
      <div className="mb-4 mx-1 d-flex justify-content-between">
        <div className="d-flex justify-content-start">
          <BsReverseLayoutTextSidebarReverse size="1.8em" className="mx-2" />
          <h4>Inventarios</h4>
        </div>
      </div>
      <InventarioLista />
    </div>
  )
}

export default Inventario;
