//import { useState } from "react";
import { Button, Container, Modal } from "@themesberg/react-bootstrap";
import { useZxing } from "react-zxing";
export const BarcodeScanner = ({
  mostrarCodigoEscaner,
  setMostrarCodigoEscaner,
  setResultadoCodigoEscaner,
  resultadoCodigoEscaner,
}) => {
  const { ref } = useZxing({
    onDecodeResult(result) {
      setResultadoCodigoEscaner(result.getText());
    },
    onError:(error)=>{
      alert("Ocurrió un error al acceder a la camara");
      console.error(error);
    },
    constraints:{
      video: {
        facingMode: 'environment', // Cámara trasera
        width: { ideal: 1920 },   // Ancho ideal
        height: { ideal: 1080 },  // Altura ideal
        aspectRatio: { ideal: 16/9 },
      },
      audio: false,
    }
  });
  return (
    <>
      <Modal
        as={Modal.Dialog}
        centered
        show={mostrarCodigoEscaner}
        onHide={() => setMostrarCodigoEscaner(false)}
        backdrop="static"
        keyboard={false}
        size="xl"
        dialogClassName="modal-100w"
      >
        <div className="container-fluid" style={{ height: "100%" }}>
          <Modal.Header className="text-center">
            <Button
              variant="close"
              aria-label="Close"
              onClick={() => setMostrarCodigoEscaner(false)}
            />
          </Modal.Header>
          <Modal.Body>
            <h4>Escanear código de barra.</h4>
            <div className="video-container">
              <video ref={ref} />
              {/* Líneas guía para centrar el código de barras */}
              <div className="guide-lines">
                <div className="vertical-line"></div>
                <div className="horizontal-line"></div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};
