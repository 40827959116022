export const Paths = {
  // pages
  DashboardHome: { path: "/" },
  //Authentication: { path: "/" },
  //Facturas
  AgregarFactura: { path: "/AgregarFactura" },
  EditarFactura: { path: "/EditarFactura/:id" },
  ListaFacturas: { path: "/ListaFacturas" },
  //Factura Compra
  AgregarFacturaCompra: { path: "/AgregarFacturaCompra" },
  EditarFacturaCompra: { path: "/EditarFacturaCompra/:id" },
  ListaFacturaCompra: { path: "/ListaFacturaCompra" },
  //Productos
  ListaProductos: { path: "/ListaProductos" },
  EditarProductos: { path: "/EditarProductos/:id" },
  AgregarProductos: { path: "/AgregarProductos" },
  //categorias
  ListaCategorias: { path: "/Categorias" },
  //marcas
  ListaMarcas: { path: "/Marcas" },
  //Usuarios
  EditarUsuarios: { path: "/EditarUsuarios/:id" },
  AgregarUsuarios: { path: "/AgregarUsuarios" },
  ListaUsuarios: { path: "/ListaUsuarios" },
  UsuarioPerfil: { path: "/Perfil" },
  //Clientes
  AgregarClientes: { path: "/AgregarClientes" },
  EditarClientes: { path: "/EditarClientes/:id" },
  ListaClientes: { path: "/ListaClientes" },
  //Empleados
  ListaEmpleados: { path: "/ListaEmpleados" },
  AgregarEmpleados: { path: "/AgregarEmpleados" },
  EditarEmpleados: { path: "/EditarEmpleados/:id" },
  //Proveedores
  AgregarProveedores: { path: "/Proveedores/AgregarProveedores" },
  EditarProveedores: { path: "/Proveedores/EditarProveedores/:id" },
  ListaProveedores: { path: "/Proveedores" },
  //kardex
  Kardex: { path: "/kardex" },
  //Inventario
  Inventario: { path: "/Iventario" },
  //No Found
  NotFound: { path: "*" },
  //Error
  ServerError: { path: "/examples/500" },
  //Login
  Signin: { path: "/login" },
};
