
import { React } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
//buenPrecio
import SignupAuth from "./tiendaBuenPrecioPages/Signin.js";

import AgregarProductos from "./tiendaBuenPrecioPages/AgregarProductos";
import EditarProductos from "./tiendaBuenPrecioPages/EditarProductos";
import ListaClientes from "./tiendaBuenPrecioPages/ListaClientes";
import AgregarClientes from "./tiendaBuenPrecioPages/AgregarClientes";

import AgregarEmpleados from "./tiendaBuenPrecioPages/AgregarEmpleados";

import AgregarProveedores from "./tiendaBuenPrecioPages/AgregarProveedores";

import ListaProveedores from "./tiendaBuenPrecioPages/ListaProveedores";
import EditarProveedores from "./tiendaBuenPrecioPages/EditarProveedores";

import ListaUsuarios from "./tiendaBuenPrecioPages/ ListaUsuarios";
import EditarUsuarios from "./tiendaBuenPrecioPages/EditarUsuarios";

// pages
import DashboardOverview from "./dashboard/DashboardOverview";
import AgregarUsuarios from "./tiendaBuenPrecioPages/AgregarUsuarios";
import EditarClientes from "./tiendaBuenPrecioPages/EditarClientes";
import ListaEmpleados from "./tiendaBuenPrecioPages/ListaEmpleados";
import EditarEmpleados from "./tiendaBuenPrecioPages/EditarEmpleados";
import ListaFactura from "./tiendaBuenPrecioPages/ListaFactura";
import AgregarFactura from "./tiendaBuenPrecioPages/AgregarFactura";
import EditarFactura from "./tiendaBuenPrecioPages/EditarFactura";
import NotFound from "./examples/NotFound";
import AgregarFacturaCompra from "./tiendaBuenPrecioPages/AgregarFacturaCompra";
import EditarFacturaCompra from "./tiendaBuenPrecioPages/EditarFacturaCompra";
import ListaKardex from "../components/tiendaBuenPrecioComponents/Kardex/page/Kardex";
import Inventario from "../components/tiendaBuenPrecioComponents/Inventario/page/Inventario";
import Marcas from "../components/tiendaBuenPrecioComponents/Marcas/page/Marcas";
import Categorias from "../components/tiendaBuenPrecioComponents/Categorias/pages/Categorias";
import { AuthContextProvider } from "../contexts/authContext";
import PublicRouter from "../components/router/PublicRouter";
import PrivateRouter from "../components/router/PrivateRouter";
import { Paths } from "../config/router/paths";
import RouteWithSidebar from "../components/router/RouteWithSidebar.jsx";
import Productos from "../components/tiendaBuenPrecioComponents/Productos/pages/Productos.jsx";
import FacturasCompra from "../components/tiendaBuenPrecioComponents/FacturacionCompras/page/FacturasCompra";
import { UsuarioPerfil } from "../components/tiendaBuenPrecioComponents/Usuarios/pages/UsuarioPerfil.jsx";

const SwitchRoutes = () => (
  <AuthContextProvider>
    <BrowserRouter>
      <Routes>
        <Route path={Paths.Signin.path} element={<PublicRouter />}>
          <Route index path={Paths.Signin.path} element={<SignupAuth/>} />
        </Route>
        <Route path={Paths.DashboardHome.path} element={<PrivateRouter />}>
          <Route
            index
            path={Paths.DashboardHome.path}
            element={<RouteWithSidebar Component={DashboardOverview} />}
          />
          <Route exact path={Paths.ListaFacturas.path} element={<RouteWithSidebar Component={ListaFactura} />} />
          <Route
            exact
            path={Paths.AgregarFactura.path}
            element={<RouteWithSidebar Component={AgregarFactura} />}
          />
          <Route
            exact
            path={Paths.EditarFactura.path}
            element={<RouteWithSidebar Component={EditarFactura} />}
          />

          {/*
      Factura Compras
      */}
          <Route
            exact
            path={Paths.ListaFacturaCompra.path}
            element={<RouteWithSidebar Component={FacturasCompra} />}
          />
          <Route
            exact
            path={Paths.AgregarFacturaCompra.path}
            element={<RouteWithSidebar Component={AgregarFacturaCompra} />}
          />
          <Route
            exact
            path={Paths.EditarFacturaCompra.path}
            element={<RouteWithSidebar Component={EditarFacturaCompra} />}
          />

          {/*
      Productos
      */}
          <Route
            exact
            path={Paths.AgregarProductos.path}
            element={<RouteWithSidebar Component={AgregarProductos} />}
          />
          <Route
            exact
            path={Paths.EditarProductos.path}
            element={<RouteWithSidebar Component={EditarProductos} />}
          />
          <Route
            exact
            path={Paths.ListaProductos.path}
            element={<RouteWithSidebar Component={Productos} />}
          />
          {/* Marcas */}
          <Route exact path={Paths.ListaMarcas.path} element={<RouteWithSidebar Component={Marcas} />} />
          {/* Categorias */}
          <Route exact path={Paths.ListaCategorias.path} element={<RouteWithSidebar Component={Categorias} />} />
          {/*
      Usuarios
      */}
          <Route
            exact
            path={Paths.ListaUsuarios.path}
            element={<RouteWithSidebar Component={ListaUsuarios} />}
          />
          <Route
            exact
            path={Paths.AgregarUsuarios.path}
            element={<RouteWithSidebar Component={AgregarUsuarios} />}
          />
          <Route
            exact
            path={Paths.EditarUsuarios.path}
            element={<RouteWithSidebar Component={EditarUsuarios} />}
          />
          <Route
            exact
            path={Paths.UsuarioPerfil.path}
            element={<RouteWithSidebar Component={UsuarioPerfil} />}
          />
          {/*
      Clientes
      */}
          <Route
            exact
            path={Paths.AgregarClientes.path}
            element={<RouteWithSidebar Component={AgregarClientes} />}
          />
          <Route
            exact
            path={Paths.ListaClientes.path}
            element={<RouteWithSidebar Component={ListaClientes} />}
          />
          <Route
            exact
            path={Paths.EditarClientes.path}
            element={<RouteWithSidebar Component={EditarClientes} />}
          />
          {/*
      Empleados
      */}
          <Route
            exact
            path={Paths.ListaEmpleados.path}
            element={<RouteWithSidebar Component={ListaEmpleados} />}
          />
          <Route
            exact
            path={Paths.AgregarEmpleados.path}
            element={<RouteWithSidebar Component={AgregarEmpleados} />}
          />
          <Route
            exact
            path={Paths.EditarEmpleados.path}
            element={<RouteWithSidebar Component={EditarEmpleados} />}
          />

          {/*
      Proveedores
      */}
          <Route
            exact
            path={Paths.AgregarProveedores.path}
            element={<RouteWithSidebar Component={AgregarProveedores} />}
          />
          <Route
            exact
            path={Paths.EditarProveedores.path}
            element={<RouteWithSidebar Component={EditarProveedores} />}
          />
          <Route
            exact
            path={Paths.ListaProveedores.path}
            element={<RouteWithSidebar Component={ListaProveedores} />}
          />
          {/* Kardex */}
          <Route exact path={Paths.Kardex.path} element={<RouteWithSidebar Component={ListaKardex} />} />
          {/* Iventario */}
          <Route exact path={Paths.Inventario.path} element={<RouteWithSidebar Component={Inventario} />} />
          {/*
      Not Found
      */}
          <Route exact path={Paths.NotFound.path} element={<NotFound/>} />
          {/* <redirect to={Paths.NotFound.path} /> */}
        </Route>
      </Routes>
    </BrowserRouter>
  </AuthContextProvider>
);
export default SwitchRoutes;
