import React from "react";
import Profile1 from "../../../../assets/img/team/profile-picture-1.jpg";
import ProfileCover from "../../../../assets/img/profile-cover.jpg";
import { Button, Card } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";

const TarjetaPresentacionUsuarioPerfil = ({ usuariosId = 0,nombres = "" , apellidos = "", rol = "", usuarioImagen = [] }) => {
  
  // consultar la imagen de perfil 
 
  return (
    <Card border="light" className="text-center p-0 mb-4">
      <div
        style={{ backgroundImage: `url(${ProfileCover})` }}
        className="profile-cover rounded-top"
      />
      <Card.Body className="pb-5">
        <Card.Img
          src={usuarioImagen}
          alt={`${nombres} Perfil Foto`}
          className="user-avatar large-avatar rounded-circle mx-auto mt-n7 mb-4"
        />
        <Card.Title>{nombres} {apellidos}</Card.Title>
        <Card.Subtitle className="fw-normal">
          {rol}
        </Card.Subtitle>
        {/* <Card.Text className="text-gray mb-4">New York, USA</Card.Text> */}

        {/* <Button variant="primary" size="sm" className="me-2">
          <FontAwesomeIcon icon={faUserPlus} className="me-1" /> Connect
        </Button>
        <Button variant="secondary" size="sm">
          Send Message
        </Button> */}
      </Card.Body>
    </Card>
  );
};

export default TarjetaPresentacionUsuarioPerfil;
