import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "@themesberg/react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import jsPDF from "jspdf";
import "jspdf-autotable";
import "../../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "../../../../node_modules/react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "../../../../node_modules/react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import SweetAlert from "../Utils/SweetAlert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faFileCsv } from "@fortawesome/free-solid-svg-icons";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { Link } from "react-router-dom";
import Cargando from "../Utils/Loading";
import {
  activarDesactivarUsuario,
  eliminarUsuario,
  obtenerUsuarios,
} from "./helper/usuariosPeticiones";
import SweetAlertQuestion from "../Utils/SweetAlertQuestion";
import { GrCheckboxSelected, GrCheckbox } from "react-icons/gr";
import { useAuthContext } from "../../../contexts/authContext";
import moment from "moment-timezone";

const UsuariosLista = () => {
  const { logout, token: Token } = useAuthContext();
  const [consulta, setConsulta] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  const [listaUsuarios, setListaUsuarios] = useState([]);
  useEffect(() => {
    if (consulta) {
      setConsulta(false);
      (async () => {
        setShowLoading(true);
        const obtenerUsuariosData = await obtenerUsuarios(Token);
        setShowLoading(false);
        const { status, ok } = obtenerUsuariosData;
        if (status === 401) {
          logout();
          return false;
        }
        if (!ok) {
          throw new Error("Error de petición.");
        }
        const response = await obtenerUsuariosData.json();
        const { success, message, data } = response;
        if (!success || data.length <= 0) {
          SweetAlert("Error", `${message}`, "error", true);
          return false;
        }
        setListaUsuarios(data);
      })();
    }
  }, [consulta, setConsulta, setShowLoading,Token,logout]);

  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  /**
   *
   * Genera pdf de exportacion
   *
   */
  const generarPdfTabla = () => {
    const doc = new jsPDF({ orientation: "landscape" });
    const fechaActual = ()=>{
      return moment().utcOffset('-06:00').format('DD-MM-YYYY');
    }
    const fechaActualCompleta = ()=>{
      return moment().utcOffset('-06:00').format('DD-MM-YYYY-HH-mm-ss');
    }
    doc.text(`Lista de Usuarios ${fechaActual()}`, 16, 10);
    doc.autoTable({
      head: [["#", "Usuario", "Email", "Role"]],
      body: listaUsuarios.map((usuario, index) => [
        index + 1,
        usuario.usuariosNombreUsuario,
        usuario.usuariosEmail,
        usuario.usuariosRole,
      ]),
    });

    doc.save(`Lista-Usuarios-${fechaActualCompleta()}.pdf`);
  };

  const index = (cell, row, rowIndex) => rowIndex + 1;

  /**
   *
   *  Imprimie los botones de edicion y eliminacion en la lista proveedores
   * @param {*} cell
   * @param {object} row
   * @param {int} rowIndex
   * @returns
   */
  const handleHerramientas = (cell, row, rowIndex) => {
    const handlerEliminarUsuario = async (usuariosDatos) => {
      const { usuariosId = 0 } = usuariosDatos;
      if (usuariosId < 1) {
        SweetAlert(
          "Error",
          `No se puede identificar el usuario que desea eliminar`,
          "error",
          true
        );
      }
      const preguntaEliminacionParametros = {
        tipo: "pregunta",
        titulo: "Advertencia",
        mensaje: "¿Esta seguro que desea eliminar este usuario?",
      };

      const preguntaEliminacion = await SweetAlertQuestion(
        preguntaEliminacionParametros
      );
      const { isConfirmed = false } = preguntaEliminacion;
      if (!isConfirmed) {
        return false;
      }
      setShowLoading(true);
      const eliminarUsuarioPeticion = await eliminarUsuario(usuariosId,Token);
      setShowLoading(false);
      const { status, ok } = eliminarUsuarioPeticion;
        if (status === 401) {
          logout();
          return false;
        }
        if (!ok) {
          throw new Error("Error de petición.");
        }
      const response = await eliminarUsuarioPeticion.json();
      const { success, message } = response;
      if (!success) {
        SweetAlert("Error", `${message}`, "error", true);
        return false;
      }
      SweetAlert("Exito", `${message}`, "success", true);
      setConsulta(true);
      return true;
    };
    const urlEditar = `/EditarUsuarios/${row.usuariosId}`;
    return (
      <div className="d-flex">
        <Link
          to={urlEditar}
          className="mx-1 bnt btn-icon-only  btn-info btn-sm"
        >
          <AiOutlineEdit size="1.2em" />
        </Link>
        <Button
          size="sm"
          variant="danger"
          onClick={() => handlerEliminarUsuario(row)}
          className="btn-icon-only "
        >
          <AiOutlineDelete size="1.2em" />
        </Button>
      </div>
    );
  };

  const handleActivarDesactivar = (cell, row, rowIndex) => {
    const { usuariosActivo, usuariosId } = row;
    const activarDesactivar = async (usuariosActivo, usuariosId) => {
      usuariosActivo = parseInt(usuariosActivo);
      usuariosId = parseInt(usuariosId);
      if (usuariosId < 1) {
        SweetAlert(
          "Error",
          `No se puede identificar el usuario que desea eliminar`,
          "error",
          true
        );
      }
      const preguntaActivacionParametros = {
        tipo: "pregunta",
        titulo: "Advertencia",
        mensaje: `¿Está seguro que desear ${
          usuariosActivo === 1 ? "Desactivar" : "Activar"
        } este usuario?.`,
      };
      const preguntaActivacion = await SweetAlertQuestion(
        preguntaActivacionParametros
      );
      const { isConfirmed = false } = preguntaActivacion;
      if (!isConfirmed) {
        return false;
      }
      setShowLoading(true);
      const solicitudActivarDesactivar = await activarDesactivarUsuario(
        usuariosActivo,
        usuariosId,
        Token
      );
      setShowLoading(false);
      const { status, ok } = solicitudActivarDesactivar;
      if (status === 401) {
        logout();
        return false;
      }
      if (!ok) {
        SweetAlert("Error", `Error en Petición.`, "error", true);
        return false;
      }
      const solicitudActivarDesactivarResponse = await solicitudActivarDesactivar.json();
      const { success, message } = solicitudActivarDesactivarResponse;
      if (!success) {
        SweetAlert("Error", `${message}`, "error", true);
        return false;
      }
      SweetAlert("Exito", `${message}`, "success", true);
      setConsulta(true);
      return true;
    };
    return (
      <>
        <button
          size="sm"
          className="mx-4 btn-icon-only   btn-sm"
          onClick={() => activarDesactivar(usuariosActivo, usuariosId)}
        >
          {parseInt(usuariosActivo) ? (
            <GrCheckboxSelected size="1.2em" />
          ) : (
            <GrCheckbox size="1.2em" />
          )}
        </button>
      </>
    );
  };
  //   usuariosId
  // usuariosNombreUsuario
  // usuariosEmail
  // usuariosRole
  const columns = [
    { dataField: "", text: "#", sort: true, formatter: index },
    {
      dataField: "usuariosId",
      text: "Opciones",
      sort: true,
      formatter: handleHerramientas,
    },
    { dataField: "usuariosNombreUsuario", text: "Usuario", sort: true },
    { dataField: "usuariosEmail", text: "Email", sort: true },
    { dataField: "usuariosRole", text: "Rol", sort: true },
    {
      dataField: "usuariosActivo",
      text: "Activo",
      sort: true,
      formatter: handleActivarDesactivar,
    },
  ];

  return (
    <div className="px-1">
      <Card>
        <div className="pagesCustomParent px-4">
          <Cargando loading={showLoading} />
          <div className="mt-5">
            <ToolkitProvider
              keyField="usuariosId"
              data={listaUsuarios}
              columns={columns}
              search
              exportCSV
            >
              {(props) => (
                <>
                  <Row>
                    <Col md={6} sm={12}>
                      <label>Buscar Usuario:</label>
                      <SearchBar {...props.searchProps} className="my-2 w-40" />
                    </Col>
                    <Col
                      md={6}
                      sm={12}
                      className="d-flex align-items-end justify-content-end"
                    >
                      <ExportCSVButton
                        {...props.csvProps}
                        className="btn btn-tertiary btn-sm my-2"
                      >
                        <FontAwesomeIcon icon={faFileCsv} /> CSV
                      </ExportCSVButton>
                      <Button
                        className="mx-2 my-2"
                        size="sm"
                        variant="danger"
                        onClick={generarPdfTabla}
                      >
                        <FontAwesomeIcon icon={faFilePdf} /> PDF
                      </Button>
                    </Col>
                  </Row>

                  <BootstrapTable
                    tableHeaderClass="table"
                    headerClasses=""
                    hover
                    bordered={false}
                    search={{}}
                    align="center"
                    tabIndexCell
                    pagination={paginationFactory()}
                    noDataIndication="No existen usuarios registrados"
                    {...props.baseProps}
                  ></BootstrapTable>
                </>
              )}
            </ToolkitProvider>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default UsuariosLista;
