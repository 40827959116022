import React from "react";
import { BsCart3 } from "react-icons/bs";
import FacturaCompra from "../../components/tiendaBuenPrecioComponents/FacturacionCompras/FacturaCompra";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../routes";
import { Paths } from "../../config/router/paths";

const AgregarFacturaCompra = () => {
  const generarCodigoAleatorioNumerico = () => {
    const randomNum = Math.floor(Math.random() * 900000) + 100000; // Generar un número aleatorio de 6 dígitos
    const uniqueId = `${randomNum}${Date.now()}`; // Generar un identificador único
    return uniqueId;
  };
  const navigate = useNavigate();
  const accionCancelar = () => {
    const pathListaFactura =Paths.ListaFacturaCompra.path;
    navigate(pathListaFactura);
  };
  const datosFacturaDefault = {
    proveedoresId: 0,
    facturaCodigo: generarCodigoAleatorioNumerico(),
    facturaCodigoCompra: generarCodigoAleatorioNumerico(),
    facturaCodigoPedido: generarCodigoAleatorioNumerico(),
    facturaFecha: new Date(),
    facturaFechaVencimiento: null,
    facturaUnoPorcientoHabilitado: false,
    facturaTotalIvaUno: 0.0,
    facturaComentarios: "",
    facturaTotalIva: 0.0,
    facturaTotal: 0.0,
    facturaSubTotal: 0.0,
    facturaIva: 0.13,
  };
  const listaProductosDefaultState = [];
  return (
    <div className="mt-4">
      <div className="d-flex">
        <BsCart3 className="mx-3" size="2em" style={{ fontWeight: "800" }} />
        <h3>Facturación Compras</h3>
      </div>
      <div>
        <FacturaCompra
          type="NUEVO"
          datosFacturaDefault={datosFacturaDefault}
          listaProductosDefaultState={listaProductosDefaultState}
          accionCancelar={accionCancelar}
        />
      </div>
    </div>
  );
};
export default AgregarFacturaCompra;
