import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button, Col, Row, Card } from "@themesberg/react-bootstrap";
import { Formik } from "formik";
import InputMask from "react-input-mask";
import * as Yup from "yup";
import SweetAlert from "../Utils/SweetAlert";
import Cargando from "../Utils/Loading";
import {
  guardarProveedor,
  obtenerProveedorEditar,
} from "./helper/proveedoresPeticiones";
import onlyNumbers from "../Utils/onlyNumbers";
import onlyAddress from "../Utils/onlyAddress";
import onlyLettersAndNumbers from "../Utils/onlyLettersAndNumbers";

import { Paths } from "../../../config/router/paths";
import { useAuthContext } from "../../../contexts/authContext";
const ProveedorForm = ({
  type,
  proveedorId = 0,
  callFromModal = false,
  modalParams = {},
}) => {
  const { logout, token: Token } = useAuthContext();
  const [consultarProveedor, setConsultarProveedor] = useState(
    type === "EDITAR" ? true : false
  );
  const [showLoading, setShowLoading] = useState(false);
  const navigate = useNavigate();
  const redireccionLista = () => {
    if (
      callFromModal &&
      Object.keys(modalParams).length > 0 &&
      modalParams?.closeModalEvent
    ) {
      modalParams.closeModalEvent(false);
    } else {
      navigate(Paths.ListaProveedores.path);
    }
  };
  if (type === "EDITAR") {
    if (
      !proveedorId ||
      isNaN(parseInt(proveedorId)) ||
      parseInt(proveedorId) <= 0
    ) {
      SweetAlert(
        "Error",
        `El identificador del proveedor es invalido, por favor contacte al administrador del sistema`,
        "error",
        true
      );
    }
  }
  const [defaultFormValue, setdefaultFormValue] = useState({
    proveedoresDescripcion: "",
    proveedoresDireccion: "",
    proveedoresNIT: "",
    proveedoresNRC: "",
    proveedoresTelefono: "",
    proveedoresEmail: "",
    proveedoresGiro: "",
  });
  const ProoveedoresSchema = Yup.object().shape({
    proveedoresDescripcion: Yup.string()
      .min(
        4,
        "La descripcion del Proveedor debe ser mayor o igual a 5 caracteres"
      )
      .max(
        99,
        "La descripcion del Proveedor debe ser menor o igual a 99 caracteres"
      )
      .required("La descripción del Proveedor es obligatoria"),
    proveedoresDireccion: Yup.string()
      .min(
        4,
        "La dirección del Proveedor debe ser mayor o igual a 5 caracteres"
      )
      .max(
        199,
        "La dirección del Proveedor debe ser menor o igual a 99 caracteres"
      )
      .required("La dirección del Proveedor es obligatoria"),
    proveedoresNIT: Yup.string()
      .min(
        17,
        'El NIT del Proveedor debe tener 17 caracteres incluyendo los "-"'
      )
      .max(18, "El NIT del Proveedor no puede ser mayor a 50 caracteres"),
    proveedoresNRC: Yup.string()
      .min(
        8,
        'El NRC del Proveedor debe tener 17 caracteres incluyendo los "-"'
      )
      .max(9, "El NRC del Proveedor no puede ser mayor a 8 caracteres"),
    proveedoresTelefono: Yup.string()
      .min(
        9,
        "EL número de teléfono del Proveedor debe tener al menos 9 caracteres"
      )
      .max(10, "EL número de teléfono no puede ser mayor a 16 caracteres")
      .required("El número de teléfono del Proveedor es obligatorio"),
    proveedoresEmail: Yup.string()
      .email("Por favor ingrese un correo electrónico válido")
      .min(6, "El correo del proveedor debe tener al menos 8 caracteres")
      .max(
        99,
        "El correo del Proveedor debe ser menor o igual a 99 caracteres"
      ),
    proveedoresGiro: Yup.string()
      .min(0, "El correo del Proveedor debe tener al menos 8 caracteres")
      .max(
        200,
        "El correo del Proveedor debe ser menor o igual a 99 caracteres"
      ),
  });
  useEffect(() => {
    if (consultarProveedor) {
      const obtenerDataProveedor = async (proveedorId) => {
        proveedorId = parseInt(proveedorId);
        if (proveedorId < 1) {
          SweetAlert(
            "Error",
            `No se puede identificar el proveedor que desea actualizar`,
            "error",
            true
          );
        }
        setShowLoading(true);
        const request = await obtenerProveedorEditar(Token, proveedorId);
        const { status, ok } = request;
        if (status === 401) {
          logout();
          return false;
        }
        if (!ok) {
          throw new Error("Error en peticion.");
        }
        const response = await request.json();
        const { success, message = "", data = [] } = response;
        if (!success) {
          SweetAlert("Error", `${message}`, "error", true);
          return false;
        }
        if (data.lenght <= 0) {
          SweetAlert(
            "Error",
            `No existen datos de este proveedor`,
            "error",
            true
          );
          return false;
        }
        let {
          proveedoresDescripcion = "",
          proveedoresDireccion = "",
          proveedoresTelefono = "",
          proveedoresEmail = "",
          proveedoresNIT = "",
          proveedoresNRC = "",
          proveedoresGiro = "",
        } = data;
        proveedoresDescripcion = proveedoresDescripcion?.toString().trim();
        proveedoresDireccion = proveedoresDireccion?.toString().trim();
        proveedoresTelefono = proveedoresTelefono?.toString().trim();
        proveedoresEmail = proveedoresEmail?.toString().trim();
        proveedoresNIT = proveedoresNIT?.toString().trim();
        proveedoresNRC = proveedoresNRC?.toString().trim();
        proveedoresGiro = proveedoresGiro?.toString().trim();
        setdefaultFormValue({
          proveedoresDescripcion: proveedoresDescripcion,
          proveedoresDireccion: proveedoresDireccion,
          proveedoresTelefono: proveedoresTelefono,
          proveedoresEmail: proveedoresEmail,
          proveedoresNIT: proveedoresNIT,
          proveedoresNRC: proveedoresNRC,
          proveedoresGiro: proveedoresGiro,
        });
        setShowLoading(false);
        return true;
      };
      obtenerDataProveedor(proveedorId);
      setConsultarProveedor(false);
    }
  }, [consultarProveedor, proveedorId, Token, logout]);

  const formSubmitHandler = async (values, resetForm) => {
    try {
      if (type === "EDITAR") {
        proveedorId = parseInt(proveedorId);
        if (proveedorId < 1) {
          SweetAlert(
            "Error",
            `No se puede identificar el proveedor que desea actualizar`,
            "error",
            true
          );
        }
      } else {
        proveedorId = 0;
      }
      if (Object.keys(values).length < 5) {
        SweetAlert(
          "Error",
          `No se puede procesar los datos necesarios para guardar un proveedor`,
          "error",
          true
        );
      }
      const proveedoresDescripcion = values?.proveedoresDescripcion || "";
      if (proveedoresDescripcion.trim().lenght < 1) {
        SweetAlert(
          "Error",
          `Descripcion de proveedor invalida.`,
          "error",
          true
        );
      }
      const proveedoresDireccion = values?.proveedoresDireccion || "";
      const proveedoresNIT = values?.proveedoresNIT || "";
      const proveedoresNRC = values?.proveedoresNRC || "";
      const proveedoresTelefono = values?.proveedoresTelefono || "";
      const proveedoresEmail = values?.proveedoresEmail || "";
      const proveedoresGiro = values?.proveedoresGiro || "";
      setShowLoading(true);
      const request = await guardarProveedor(
        Token,
        proveedorId,
        proveedoresDescripcion,
        proveedoresDireccion,
        proveedoresNIT,
        proveedoresNRC,
        proveedoresTelefono,
        proveedoresEmail,
        proveedoresGiro
      );
      setShowLoading(false);
      const { status, ok } = request;
      if (status === 401) {
        logout();
        return false;
      }
      if (!ok) {
        throw new Error("Error en peticion.");
      }
      const response = await request.json();
      const { success, message = "", data = {} } = response;
      if (!success) {
        setShowLoading(false);
        SweetAlert("Error", `${message}`, "error", true);
        return false;
      }
      setShowLoading(false);
      if (callFromModal) {
        if (Object.keys(modalParams).length > 0) {
          const { proveedoresId } = data;
          modalParams.setProveedorId(parseInt(proveedoresId));
          modalParams.consultarListaProveedores(true);
        }
      }
      await SweetAlert("Exito", `${message}`, "success", true);
      resetForm();
      redireccionLista();
      return true;
    } catch (error) {
      console.error(error);
      setShowLoading(false);
      SweetAlert(
        "Error",
        `Ocurrió un error inesperado al ${
          type !== "EDITAR"
            ? "guardar un nuevo proveedor."
            : "editar el proveedor"
        }`,
        "error",
        true
      );
      return false;
    }
  };

  return (
    <>
      <Formik
        initialValues={defaultFormValue}
        validationSchema={ProoveedoresSchema}
        enableReinitialize={type === "EDITAR" ? true : false}
        onSubmit={(values, { resetForm }) =>
          formSubmitHandler(values, resetForm)
        }
      >
        {({
          handleChange,
          handleSubmit,
          //handleBlur,
          values: inputs,
          errors,
          touched,
        }) => {
          const {
            proveedoresDescripcion,
            proveedoresDireccion,
            proveedoresNIT,
            proveedoresNRC,
            proveedoresTelefono,
            proveedoresEmail,
            proveedoresGiro,
          } = inputs;
          console.log("proveedoresTelefono:",proveedoresTelefono);
          return (
            <Card className="p-4">
              <Cargando loading={showLoading} />
              <Form onSubmit={handleSubmit} className="mb-4">
                <Row>
                  <Col md={4} sm={12} xs={12} className="mt-4">
                    <Form.Group>
                      <Form.Label>Nombre Proveedor</Form.Label>
                      <Form.Control
                        autoFocus
                        type="text"
                        id="proveedoresDescripcion"
                        name="proveedoresDescripcion"
                        value={proveedoresDescripcion}
                        onChange={handleChange}
                        placeholder="Ingrese el nombre o descripcion del Proveedor"
                        isValid={
                          touched.proveedoresDescripcion &&
                          !errors.proveedoresDescripcion
                        }
                        onKeyPress={onlyLettersAndNumbers}
                        maxLength={100}
                      ></Form.Control>
                    </Form.Group>
                    {touched.proveedoresDescripcion &&
                      errors.proveedoresDescripcion && (
                        <div className="text-danger">
                          {errors.proveedoresDescripcion}{" "}
                        </div>
                      )}
                  </Col>
                  <Col md={4} sm={12} xs={12} className="mt-4">
                    <Form.Group>
                      <Form.Label>NIC</Form.Label>
                       <InputMask
                        mask="999999-9"
                        type="text"
                        id="proveedoresNRC"
                        name="proveedoresNRC"
                        value={proveedoresNRC}
                        onChange={handleChange}
                        placeholder="Ingrese el NIC del Proveedor"
                        isValid={
                          touched.proveedoresNRC && !errors.proveedoresNRC
                        }
                        onKeyPress={(event) => onlyNumbers(event, true)}
                        className="form-control"
                      />
                      {touched.proveedoresNRC && errors.proveedoresNRC && (
                        <div className="text-danger">
                          {errors.proveedoresNRC}
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={4} sm={12} xs={12} className="mt-4">
                    <Form.Group>
                      <Form.Label>NIT</Form.Label>
                      <InputMask
                        mask="9999-999999-999-9"
                        type="text"
                        id="proveedoresNIT"
                        name="proveedoresNIT"
                        value={proveedoresNIT}
                        onChange={handleChange}
                        placeholder="Ingrese el NIT del Proveedor"
                        isValid={
                          touched.proveedoresNIT && !errors.proveedoresNIT
                        }
                        onKeyPress={(event) =>
                          event.target.value.length > 26
                            ? event.preventDefault()
                            : onlyNumbers(event, true)
                        }
                        className="form-control"
                      />
                      {touched.proveedoresNIT && errors.proveedoresNIT && (
                        <div className="text-danger">
                          {errors.proveedoresNIT}
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={4} sm={12} xs={12} className="mt-4">
                    <Form.Group>
                      <Form.Label>Correo Electrónico</Form.Label>
                      <Form.Control
                        type="email"
                        id="proveedoresEmail"
                        name="proveedoresEmail"
                        value={proveedoresEmail}
                        onChange={handleChange}
                        placeholder="Ingrese el correo electrónico del Proveedor"
                        isValid={
                          touched.proveedoresEmail && !errors.proveedoresEmail
                        }
                        onKeyPress={(event) =>
                          event.target.value.length > 99
                            ? event.preventDefault()
                            : null
                        }
                        maxLength={98}
                      ></Form.Control>
                      {touched.proveedoresEmail && errors.proveedoresEmail && (
                        <div className="text-danger">
                          {errors.proveedoresEmail}
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={4} sm={12} xs={12} className="mt-4">
                    <Form.Group>
                      <Form.Label>Teléfono</Form.Label>
                       <InputMask
                        id="proveedoresTelefono"
                        name="proveedoresTelefono"
                        mask="9999-9999"
                        value={proveedoresTelefono}
                        onChange={handleChange}
                        placeholder="Ingrese el número telefonico del Proveedor"
                        isValid={
                          touched.proveedoresTelefono &&
                          !errors.proveedoresTelefono
                        }
                        onKeyPress={(e) => {
                          const { target = null } = e;
                          console.log(target.value.toString().length);
                          if (target.value.toString().length > 9) {
                            e.preventDefault();
                          }
                          return onlyNumbers(e,true);
                        }}
                        className="form-control"
                      />
                      {touched.proveedoresTelefono &&
                        errors.proveedoresTelefono && (
                          <div className="text-danger">
                            {errors.proveedoresTelefono}
                          </div>
                        )}
                    </Form.Group>
                  </Col>
                  <Col md={4} sm={12} xs={12} className="mt-4">
                    <Form.Group>
                      <Form.Label>Giro</Form.Label>
                      <Form.Control
                        as="textarea"
                        id="proveedoresGiro"
                        name="proveedoresGiro"
                        value={proveedoresGiro}
                        onChange={handleChange}
                        placeholder="Ingrese el giro del Proveedor"
                        rows={4}
                        isValid={
                          touched.proveedoresGiro && !errors.proveedoresGiro
                        }
                        onKeyPress={onlyAddress}
                        maxLength={180}
                      ></Form.Control>
                      {touched.proveedoresGiro && errors.proveedoresGiro && (
                        <div className="text-danger">
                          {errors.proveedoresGiro}
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={12} xs={12} className="mt-4">
                    <Form.Group>
                      <Form.Label>Dirección</Form.Label>
                      <Form.Control
                        as="textarea"
                        id="proveedoresDireccion"
                        name="proveedoresDireccion"
                        value={proveedoresDireccion}
                        onChange={handleChange}
                        placeholder="Ingrese la dirección del proveedor"
                        isValid={
                          touched.proveedoresDireccion &&
                          !errors.proveedoresDireccion
                        }
                        onKeyPress={onlyAddress}
                        rows={3}
                        maxLength={190}
                      ></Form.Control>
                      {touched.proveedoresDireccion &&
                        errors.proveedoresDireccion && (
                          <div className="text-danger">
                            {errors.proveedoresDireccion}
                          </div>
                        )}
                    </Form.Group>
                  </Col>
                  <Col
                    md={6}
                    xs={12}
                    className="d-flex flex-column align-items-end mt-5"
                  >
                    <Button
                      variant="outline-primary"
                      type="submit"
                      className="w-60 mt-2"
                      size="lg"
                    >
                      Guardar
                    </Button>
                    <Button
                      variant="danger"
                      type="button"
                      className="w-60 mt-2"
                      onClick={redireccionLista}
                      size="lg"
                    >
                      Cancelar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card>
          );
        }}
      </Formik>
    </>
  );
};

export default ProveedorForm;
