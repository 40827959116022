import React from "react";
import { BsPlusLg } from "react-icons/bs";
import { FiUsers } from "react-icons/fi";
import { Link } from "react-router-dom";
import UsuariosLista from "../../components/tiendaBuenPrecioComponents/Usuarios/UsuariosLista";
import { Paths } from "../../config/router/paths";
const ListaUsuarios = () => {
  return (
    <div className="mt-4">
      <div className="px-0 mb-3 d-flex justify-content-between">
        <div className="d-flex justify-content-start">
          <FiUsers size="2em" className="mx-4" />
          <h4>Lista Usuarios</h4>
        </div>
        <div className="">
          <Link
            className="mx-2 btn btn-lg btn-outline-primary"
            to={Paths.AgregarUsuarios.path}
          >
            <BsPlusLg className="mx-2" /> Nuevo Usuario
          </Link>
        </div>
      </div>
      <UsuariosLista />
    </div>
  );
};

export default ListaUsuarios;
