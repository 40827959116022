import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuthContext } from "../../contexts/authContext";
import { Paths } from "../../config/router/paths";

const PublicRouter = () => {
  const { estaAutenticado, token } = useAuthContext();
  if (estaAutenticado) {
    return <Navigate to={Paths.DashboardHome.path} />;
  }
  return (
    <div>
      <Outlet />
    </div>
  );
};

export default PublicRouter;
