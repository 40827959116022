import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Table,
} from "@themesberg/react-bootstrap";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { BsPlusLg, BsReply } from "react-icons/bs";
import { GoX, GoPerson } from "react-icons/go";
import { Notify } from "notiflix/build/notiflix-notify-aio";
import DatePicker from "react-date-picker";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { Report } from "notiflix/build/notiflix-report-aio";
import ProductosSearch from "./ProductosSearch";
import ProductosBuscarPorCodigo from "./ProductosBuscarPorCodigo";
import customDecimalRound from "../Utils/customDecimalRound";
import BusquedaCliente from "./BusquedaCliente";
import SweetAlert from "../Utils/SweetAlert";
import {
  guardarNuevaFactura,
  getFacturaProductosPorId,
  getClienteFacturaPorId,
  getFacturaPorId,
} from "./helper/facturaPeticiones";
import dateValidation from "../Utils/dateValidation";
import { Paths } from "../../../config/router/paths";
import { useAuthContext } from "../../../contexts/authContext";
const Factura = ({ type, facturaId = 0 }) => {
  const { logout, token: Token,obtenerDatosUsuarios } = useAuthContext();
  const { iva} = obtenerDatosUsuarios();
  const history = useNavigate();

  const productosPreviewDefault = {
    facturaProductosId: 0,
    facturaProductosCodigo: "",
    facturaProductosNombre: "",
    facturaProductosCantidad: 0,
    facturaProductosPrecioVenta: 0,
    facturaProductosMontoNoIva: 0.0,
    facturaProductosMonto: 0.0,
    facturaProductoRangoPrecios: [],
  };
  const clientesDatosDefault = {
    clientesId: 0,
    clientesDireccion: "",
    clientesDui: "",
    clientesEmail: "",
    clientesFechaNacimiento: "",
    clientesNRC: "",
    clientesNombres: "",
    clientesTelefono: "",
  };
  const facturaDatosDefault = {
    facturaFecha: facturaId > 0 ? new Date() : "",
    facturaFechaVencimiento: "",
    facturaNumero:
      facturaId > 0 ? "" : Math.floor(Math.random() * 100000000000),
    facturaTipo: 1,
    facturaGiro: "",
    facturaCuentaDe: "",
    facturaComentarios: "",
    facturaSubTotal: 0.0,
    facturaIvaIndividual: iva,
    facturaDescuentoTipo: 0,
    facturaDescuentoValor: 0,
    facturaTotal: 0.0,
    facturaNumeroNotaRemisionAnterior: "",
    facturaFechaNotaRemisionAnterior: "",
    facturaRegistroNumero: "",
  };
  /**
   *
   * Datos de factura. Estado general.
   *
   *
   */
  const [facturaDatos, setFacturaDatos] = useState(facturaDatosDefault);
  const [facturaClienteId, setFacturaClienteId] = useState(0);
  /**
   *
   *
   * Datos de cliente seleccionado
   *
   *
   */
  const [clienteDatos, setClienteDatos] = useState(clientesDatosDefault);
  /**
   *
   *
   * Lista de productos ya agregados a la factura
   *
   *
   *
   */
  const [listadoFacturaProductos, setListadoFacturaProductos] = useState([]);
  /**
   *
   *
   * Datos de factura seleccionado para agregar
   *
   * Este producto no ha sido agregado a los productos de factura
   *
   *
   */
  const [facturaProdutoDatosPreview, setFacturaProdutoDatosPreview] = useState(
    productosPreviewDefault
  );

  const [consultarDatosDeFacturaHandle, setConsultarDatosDeFacturaHandle] =
    useState(type === "EDITAR" ? true : false);

  useEffect(() => {
    if (consultarDatosDeFacturaHandle) {
      setConsultarDatosDeFacturaHandle(false);
      let datosDeFacturaEdicion = {};
      (async () => {
        const obtenerDatosDeFactura = async () => {
          try {
            const request = await getFacturaPorId(facturaId, Token);
            const { status, ok } = request;
            if (status === 401) {
              logout();
              return false;
            }
            if (!ok) {
              throw new Error("Error de petición.");
            }
            const getFacturaDatos = await request.json();
            const {
              success = false,
              error = false,
              message = "",
              data = {},
            } = getFacturaDatos;
            if (error || !success) {
              //SweetAlert("Error", message || `Ocurrió un error inesperado, por favor trate nuevamente si el proble persiste contacte con el administrador`, "error", true);
              return {
                success: false,
                error: true,
                data: {},
                message:
                  message ||
                  `Ocurrió un error inesperado, por favor trate nuevamente si el proble persiste contacte con el administrador`,
              };
            }
            if (Object.keys(data).length < 1) {
              //SweetAlert("Error", `No se encontraron datos para esta factura`, "error", true);
              return {
                success: false,
                error: true,
                data: {},
                message: `No se encontraron datos para esta factura`,
              };
            }
            const {
              //facturaProductosId,
              facturaProductosCodigo,
              facturaProductosFecha,
              facturaProductosFechaVencimiento,
              facturaProductosComentarios,
              facturaTipo,
              facturaGiro,
              facturaVentaCuentaDe,
              facturaProductosIva,
              facturaProductosDescuentoTipo,
              facturaProductosDescuentoValor,
              facturaProductosSubTotal,
              facturaProductosTotal,
              // facturaProductosEliminada,
              // facturaProductosFechaCreacion,
              // facturaProductosFechaEdicion,
              // facturaProductosFechaEliminacion,
              // facturaProductosIdUsuariosCreo,
              // facturaProductosIdUsuariosActualizo,
              // facturaProductosIdUsuariosElimino,
              // clientesId,
              // empresaId,
            } = data;

            let facturaFecha = "";
            if (
              facturaProductosFecha.trim().length > 0 &&
              dateValidation(facturaProductosFecha)
            ) {
              facturaFecha = new Date(facturaProductosFecha);
            }
            let facturaFechaVencimiento = "";
            if (
              facturaProductosFechaVencimiento.trim().length > 0 &&
              dateValidation(facturaProductosFechaVencimiento)
            ) {
              facturaFechaVencimiento = new Date(
                facturaProductosFechaVencimiento
              );
            }
            const facturaDatos = {
              facturaFecha: facturaFecha,
              facturaFechaVencimiento: facturaFechaVencimiento,
              facturaNumero: facturaProductosCodigo,
              facturaTipo,
              facturaGiro,
              facturaCuentaDe: facturaVentaCuentaDe,
              facturaComentarios: facturaProductosComentarios,
              facturaSubTotal: facturaProductosSubTotal,
              facturaIvaIndividual: facturaProductosIva,
              facturaDescuentoTipo: facturaProductosDescuentoTipo,
              facturaDescuentoValor: facturaProductosDescuentoValor,
              facturaTotal: facturaProductosTotal,
            };
            return {
              success: true,
              error: false,
              data: facturaDatos,
              message: "Datos de factura obtenidos correctamente",
            };
          } catch (error) {
            console.error(error.message);
            return {
              success: false,
              error: true,
              data: {},
              message: `Ocurrió un error inesperado, por favor trate nuevamente si el proble persiste contacte con el administrador`,
            };
          }
        };
        const obtenerDatosDeFacturaProductos = async () => {
          try {
            const request = await getFacturaProductosPorId(facturaId, Token);
            const { status, ok } = request;
            if (status === 401) {
              logout();
              return false;
            }
            if (!ok) {
              throw new Error("Error de petición.");
            }
            const getFacturaProductos = await request.json();
            let datosProductos = [];
            const {
              success = false,
              error = false,
              message = "",
              data = [],
            } = getFacturaProductos;
            if (error || !success) {
              SweetAlert(
                "Error",
                message ||
                  `Ocurrió un error inesperado, por favor trate nuevamente si el problema persiste contacte con el administrador`,
                "error",
                true
              );
              return false;
            }
            if (data.length < 1) {
              SweetAlert(
                "Error",
                `No se encontraron productos para esta factura, por favor contacte con el admnistrador`,
                "error",
                true
              );
              return false;
            }
            data.forEach((productoDato) => {
              let {
                productosId,
                productosCantidad: facturaProductosCantidad,
                productosCodigo: facturaProductosCodigo,
                productosDescripcion: facturaProductosNombre,
                productosMonto: facturaProductosMonto,
                productosMontoSinIva: facturaProductosMontoNoIva,
                productosPrecioVenta: facturaProductosPrecioVenta,
              } = productoDato;
              productosId = parseInt(productosId);
              facturaProductosCantidad = Number(facturaProductosCantidad);
              facturaProductosCodigo = facturaProductosCodigo.toString().trim();
              facturaProductosNombre = facturaProductosNombre.toString().trim();
              facturaProductosMonto = Number(facturaProductosMonto);
              facturaProductosMontoNoIva = Number(facturaProductosMontoNoIva);
              facturaProductosPrecioVenta = Number(facturaProductosPrecioVenta);
              const totalFinal =
                Number(facturaDatos.facturaTotal) +
                Number(facturaProductosMonto);
              datosProductos = [
                ...datosProductos,
                {
                  facturaProductosId: productosId,
                  facturaProductosCodigo,
                  facturaProductosNombre,
                  facturaProductosCantidad,
                  facturaProductosPrecioVenta,
                  facturaProductosMontoNoIva,
                  facturaProductosMonto,
                  facturaSubTotal: totalFinal,
                },
              ];
            });
            return {
              success: true,
              error: false,
              data: datosProductos,
              message: "Datos de productos obtenidos correctamente",
            };
          } catch (error) {
            console.error(error.message);
            return {
              success: false,
              error: true,
              data: {},
              message: `Ocurrió un error inesperado, por favor trate nuevamente si el proble persiste contacte con el administrador`,
            };
          }
        };
        const obtenerDatosDeCliente = async () => {
          try {
            const request = await getClienteFacturaPorId(facturaId, Token);
            const { status, ok } = request;
            if (status === 401) {
              logout();
              return false;
            }
            if (!ok) {
              throw new Error("Error de petición.");
            }
            const getFacturaCliente = await request.json();
            const {
              success = false,
              message = "",
              data = [],
            } = getFacturaCliente;
            if (!success || data.length < 1) {
              return {
                success: false,
                error: true,
                data: {},
                message:
                  message || "No se encontró un cliente para esta factura",
              };
            }
            const {
              clientesId,
              clientesDireccion,
              clientesDui,
              clientesEmail,
              clientesFechaNacimiento,
              clientesNRC,
              clientesNombres,
              clientesTelefono,
            } = data[0];
            const clientesDatos = {
              clientesId,
              clientesDireccion,
              clientesDui,
              clientesEmail,
              clientesFechaNacimiento,
              clientesNRC,
              clientesNombres,
              clientesTelefono,
            };
            return {
              success: true,
              error: false,
              data: clientesDatos,
              message: "Datos de cliente obtenidos correctamente",
            };
          } catch (error) {
            console.error(error.message);
            return {
              success: false,
              error: true,
              data: {},
              message: `Ocurrió un error inesperado, por favor trate nuevamente si el proble persiste contacte con el administrador`,
            };
          }
        };
        const datosDeFactura = await obtenerDatosDeFactura();
        if (!datosDeFactura.success) {
          SweetAlert("Error", datosDeFactura.message, "error", true);
          return false;
        }
        datosDeFacturaEdicion = {
          ...datosDeFacturaEdicion,
          ...datosDeFactura.data,
        };
        const datosDeFacturaProductos = await obtenerDatosDeFacturaProductos();
        if (!datosDeFacturaProductos.success) {
          SweetAlert("Error", datosDeFacturaProductos.message, "error", true);
          return false;
        }
        setListadoFacturaProductos(datosDeFacturaProductos.data);
        setFacturaDatos(datosDeFacturaEdicion);
        const datosDeCliente = await obtenerDatosDeCliente();
        if (!datosDeCliente.success) {
          SweetAlert("Error", datosDeCliente.message, "error", true);
          return false;
        }
        setClienteDatos(datosDeCliente.data);
        //facturaDescuentoValor
        //facturaTotal
        //todo
        // 1. asignar el sub total y total de factura de la factura a editar
        // 2. Enviar los datos editados al backend
        // 3. Programar el backend
      })();
    }
  }, [
    consultarDatosDeFacturaHandle,
    facturaId,
    facturaDatos.facturaTotal,
    Token,
    logout,
  ]);

  const facturaProductosCodigoPreview = useRef(null);
  const facturaProductosCantidadPreview = useRef(null);

  /**
   *
   *
   * Estado para el buscador de codigo de producto
   *
   *
   */
  const [buscarPorCodigo, setBuscarPorCodigo] = useState("");
  //const [buscarPorNombre, setBuscarPorNombre] = useState("");
  /**
   *
   *
   * Estado que indica el tipo de factura
   * En caso sea estado 1 se mostrara el precio sin iva, y al total de cada producto se le sumara el
   * En caso sea estado 2 se mostrar el precio sin iva como un sub total al final de la factura y se le sumara el porcetaje de iva al final de la factura
   *
   *
   */
  const [mostrarPrecioSinIva, setmostrarPrecioSinIva] = useState(true);
  /**
   *
   *
   * Estado para indicar la visibilidad del popup para agregar un cliente
   *
   *
   */
  const [mostrarBusquedaCliente, setMostrarBusquedaCliente] = useState(false);
  /**
   *
   *
   * Funcion que cambia el estado y asigna los valores a el estado
   * que contien losd atos de factura
   *
   *
   *
   */
  const handleChangeFacturaDatos = ({ target }) => {
    const { name, value } = target;
    const nuevoFacturaDatos = { ...facturaDatos, [name]: value };
    setFacturaDatos(nuevoFacturaDatos);
  };
  /**
   * Asigna nuevo valor campo fecha
   *
   */
  const handleChangeFacturaDatosFechas = (value, name) => {
    const nuevoFacturaDatos = { ...facturaDatos, [name]: value };
    setFacturaDatos(nuevoFacturaDatos);
  };
  /**
   *
   *
   * Funcion que cambia el estado y asigna los valores a el estado
   * que contiene los datos del producto preview
   *
   *
   *
   */

  const handleChangeProductoPreview = ({ target }) => {
    const { name, value } = target;
    const nuevoFacturaProdutoDatosPreview = {
      ...facturaProdutoDatosPreview,
      [name]: value,
    };
    setFacturaProdutoDatosPreview(nuevoFacturaProdutoDatosPreview);
  };

  const setFacturaDatosProductoPreview = useCallback(
    (data) => {
      setBuscarPorCodigo("");
      const {
        productosCodigo,
        productosId,
        productosDescripcion,
        productosPrecioVentaLista,
      } = data;
      facturaProductosCantidadPreview.current.focus();
      setFacturaProdutoDatosPreview({
        ...facturaProdutoDatosPreview,
        facturaProductosCodigo: productosCodigo,
        facturaProductosId: productosId,
        facturaProductosNombre: productosDescripcion,
        facturaProductosCantidad: 1,
        facturaProductoRangoPrecios: productosPrecioVentaLista,
      });
    },
    [facturaProdutoDatosPreview, setFacturaProdutoDatosPreview]
  );

  /**
   *
   *
   * Recalculo de total de producto y precio sin iva
   *
   * @param {*} producto
   * @param {*} facturaTipo
   * @returns
   */
  const reestructurarProductosAgregadosPrecioIva = (producto, facturaTipo) => {
    let {
      facturaProductosId,
      facturaProductosCodigo,
      facturaProductosNombre,
      facturaProductosCantidad,
      facturaProductosPrecioVenta,
      facturaProductosMontoNoIva,
      facturaProductosMonto,
      facturaProductoRangoPrecios,
    } = producto;

    if (facturaTipo === 1) {
      facturaProductosMontoNoIva = customDecimalRound(
        Number(facturaProductosPrecioVenta) * Number(facturaProductosCantidad)
      );
      const totalMontoPreview =
        facturaProductosMontoNoIva * iva + facturaProductosMontoNoIva;
      facturaProductosMonto = customDecimalRound(totalMontoPreview);
    } else {
      facturaProductosMontoNoIva = 0;
      facturaProductosMonto = customDecimalRound(
        Number(facturaProductosPrecioVenta) * Number(facturaProductosCantidad)
      );
    }
    return {
      facturaProductosId,
      facturaProductosCodigo,
      facturaProductosNombre,
      facturaProductosCantidad,
      facturaProductosPrecioVenta,
      facturaProductosMontoNoIva,
      facturaProductosMonto,
      facturaProductoRangoPrecios,
    };
  };
  useEffect(() => {
    const recalculoProductosPrecioIva = (facturaTipo) => {
      const nuevoListadoFacturaProductos = listadoFacturaProductos.map(
        (producto) =>
          reestructurarProductosAgregadosPrecioIva(producto, facturaTipo)
      );
      let totalFinal = 0;
      nuevoListadoFacturaProductos.map(
        (producto) => (totalFinal += producto.facturaProductosMonto)
      );
      setFacturaDatos({ ...facturaDatos, facturaSubTotal: totalFinal });
      setListadoFacturaProductos(nuevoListadoFacturaProductos);
    };
    if (parseInt(facturaDatos.facturaTipo) === 1 && !mostrarPrecioSinIva) {
      setmostrarPrecioSinIva(true);
      recalculoProductosPrecioIva(1);
    } else if (
      parseInt(facturaDatos.facturaTipo) === 2 &&
      mostrarPrecioSinIva
    ) {
      setmostrarPrecioSinIva(false);
      recalculoProductosPrecioIva(2);
    }
  }, [
    facturaDatos.facturaTipo,
    listadoFacturaProductos,
    mostrarPrecioSinIva,
    facturaDatos,
  ]);

  useEffect(() => {
    const handleChangeFacturaTotal = () => {
      let totalFinal = Number(facturaDatos.facturaSubTotal);
      let nuevoFacturaDatos = { ...facturaDatos };
      // Set sub total
      nuevoFacturaDatos = {
        ...nuevoFacturaDatos,
        facturaSubTotal: Number(totalFinal),
      };
      //aplicar descuento en caso exista
      let {
        // facturaIvaIndividual,
        facturaDescuentoTipo,
        facturaDescuentoValor,
      } = facturaDatos;
      // facturaIvaIndividual = Number(facturaIvaIndividual);
      facturaDescuentoTipo = Number(facturaDescuentoTipo);
      facturaDescuentoValor = Number(facturaDescuentoValor);
      if (facturaDescuentoTipo > 0) {
        if (facturaDescuentoTipo === 1 && facturaDescuentoValor > 0.0) {
          const descuento = (facturaDescuentoValor * totalFinal) / 100;
          totalFinal -= descuento;
        } else if (facturaDescuentoTipo === 2 && facturaDescuentoValor > 0.0) {
          totalFinal -= facturaDescuentoValor;
        }
      }
      if (totalFinal < 0) {
        totalFinal = 0;
        Notify.failure("El descuento aplicado es inválido");
      }
      if (parseInt(facturaDatos.facturaTipo) === 2) {
        const totalMontoPreview = totalFinal * iva + totalFinal;
        totalFinal = customDecimalRound(totalMontoPreview);
      }
      nuevoFacturaDatos = {
        ...nuevoFacturaDatos,
        facturaTotal: customDecimalRound(totalFinal),
      };
      setFacturaDatos(nuevoFacturaDatos);
    };
    if (
      Number(facturaDatos.facturaSubTotal) > 0 &&
      facturaDatos.facturaDescuentoTipo > 0 &&
      facturaDatos.facturaDescuentoValor > 0
    ) {
      handleChangeFacturaTotal();
    } else {
      if (facturaDatos.facturaSubTotal !== facturaDatos.facturaTotal) {
        let facturaTotal;
        if (parseInt(facturaDatos.facturaTipo) === 1) {
          facturaTotal = customDecimalRound(
            Number(facturaDatos.facturaSubTotal)
          );
        } else {
          facturaTotal = customDecimalRound(
            Number(facturaDatos.facturaSubTotal) * iva +
              Number(facturaDatos.facturaSubTotal)
          );
        }
        const nuevoFacturaDatos = {
          ...facturaDatos,
          facturaTotal: facturaTotal,
        };
        setFacturaDatos(nuevoFacturaDatos);
      }
    }
  }, [
    facturaDatos.facturaSubTotal,
    facturaDatos.facturaDescuentoTipo,
    facturaDatos.facturaDescuentoValor,
  ]);

  useEffect(() => {
    let { facturaProductosCantidad = 0, facturaProductoRangoPrecios = [] } =
      facturaProdutoDatosPreview;
    const calcularPrecioVenta = (rangoPrecio, cantidadProducto) => {
      cantidadProducto = Number(cantidadProducto);
      if (rangoPrecio.length > 0) {
        const rangoAplicar = rangoPrecio.filter((rango) => {
          let { productoCantidadRangoMinimo, productoCantidadRangoMaximo } =
            rango;
          productoCantidadRangoMinimo = Number(productoCantidadRangoMinimo);
          productoCantidadRangoMaximo = Number(productoCantidadRangoMaximo);
          return (
            cantidadProducto >= productoCantidadRangoMinimo &&
            cantidadProducto <= productoCantidadRangoMaximo
          );
        });
        let { precioVenta } = rangoAplicar[0];
        precioVenta = customDecimalRound(Number(precioVenta));
        if (precioVenta > 0) {
          /**
           *
           * Asignar totales producto preview
           *
           */
          let facturaProductosMontoNoIva = customDecimalRound(
            precioVenta * cantidadProducto
          );
          let facturaProductosMonto = 0;
          if (mostrarPrecioSinIva) {
            const totalPreview =
              facturaProductosMontoNoIva * iva + facturaProductosMontoNoIva;
            facturaProductosMonto = customDecimalRound(totalPreview);
          } else {
            facturaProductosMonto = customDecimalRound(
              facturaProductosMontoNoIva
            );
          }
          setFacturaProdutoDatosPreview({
            ...facturaProdutoDatosPreview,
            facturaProductosMontoNoIva: facturaProductosMontoNoIva,
            facturaProductosPrecioVenta: Number(precioVenta),
            facturaProductosMonto: customDecimalRound(facturaProductosMonto),
          });
        }
      } else {
        console.error("No hay rango de precios");
      }
    };

    const delayDebounceFn = setTimeout(() => {
      if (facturaProductosCantidad > 0) {
        if (facturaProductoRangoPrecios.length < 0) {
          Notify.failure("No existe un precio de venta para este producto");
          setFacturaProdutoDatosPreview({
            ...facturaProdutoDatosPreview,
            facturaProductosCantidad: 0,
          });
        } else {
          calcularPrecioVenta(
            facturaProductoRangoPrecios,
            facturaProductosCantidad
          );
        }
      }
    }, 100);
    return () => clearTimeout(delayDebounceFn);
  }, [
    facturaProdutoDatosPreview.facturaProductosCantidad,
    mostrarPrecioSinIva,
  ]);

  const guardarEnListaProductosFactura = (e) => {
    e.preventDefault();
    const {
      facturaProductosId,
      facturaProductosCodigo,
      facturaProductosNombre,
      facturaProductosCantidad,
      facturaProductosPrecioVenta,
      facturaProductosMontoNoIva,
      facturaProductosMonto,
      facturaProductoRangoPrecios,
    } = facturaProdutoDatosPreview;
    if (
      parseInt(facturaProductosId) < 1 ||
      facturaProductosCodigo.trim().length < 3
    ) {
      Report.failure("Error", "Por favor agregue un producto válido", "Okay");
      return false;
    }
    if (Number(facturaProductosCantidad) <= 0) {
      Report.failure(
        "Error",
        "La cantidad del producto no puede ser igual a CERO",
        "Okay"
      );
      return false;
    }
    if (Number(facturaProductosPrecioVenta) <= 0) {
      Report.failure(
        "Error",
        "El precio de venta indivual del producto es inválido",
        "Okay"
      );
      return false;
    }
    if (Number(facturaProductosPrecioVenta) <= 0) {
      Report.failure(
        "Error",
        "El precio de venta del producto es inválido",
        "Okay"
      );
      return false;
    }
    //buscar si el producto ya ha sido agreggado a la lista
    const productoEnLista = listadoFacturaProductos.filter(
      (producto) =>
        parseInt(producto.facturaProductosId) === parseInt(facturaProductosId)
    );  
    if (productoEnLista.length > 0) {
      //si el producto ya existe y se desa agregar mas producto de este tipo se debe sumar la cantidad anterior con la nueva
      const nuevoListadoFacturaProductos = listadoFacturaProductos.map(
        (producto) => {
          if (producto.facturaProductosId === facturaProductosId) {
            const cantidadProducto =
              Number(producto.facturaProductosCantidad) +
              Number(facturaProductosCantidad);
            const rangoAplicar = facturaProductoRangoPrecios.filter((rango) => {
              let { productoCantidadRangoMinimo, productoCantidadRangoMaximo } =
                rango;
              productoCantidadRangoMinimo = Number(productoCantidadRangoMinimo);
              productoCantidadRangoMaximo = Number(productoCantidadRangoMaximo);
              return (
                cantidadProducto >= productoCantidadRangoMinimo &&
                cantidadProducto <= productoCantidadRangoMaximo
              );
            });
            let { precioVenta } = rangoAplicar[0];
            precioVenta = Number(precioVenta);
            if (precioVenta > 0) {
              let facturaProductosMontoNoIva = customDecimalRound(
                precioVenta * cantidadProducto
              );
              let facturaProductosMonto = 0;
              if (mostrarPrecioSinIva) {
                const totalMontoPreview = ((facturaProductosMontoNoIva * iva) + facturaProductosMontoNoIva);
                facturaProductosMonto = customDecimalRound(totalMontoPreview);
              } else {
                facturaProductosMonto = facturaProductosMontoNoIva;
              }
              producto.facturaProductosPrecioVenta = precioVenta;
              producto.facturaProductosCantidad = cantidadProducto;
              producto.facturaProductosMontoNoIva = customDecimalRound(
                facturaProductosMontoNoIva
              );
              producto.facturaProductosMonto = customDecimalRound(
                facturaProductosMonto
              );
            }
          }
          return producto;
        }
      );
      setListadoFacturaProductos(nuevoListadoFacturaProductos);
      //sumar todos los totales de nuevoListadoFacturaProductos
      let facturaProductosMontoTotal = 0;
      nuevoListadoFacturaProductos.forEach(
        (producto) =>
          (facturaProductosMontoTotal += Number(producto.facturaProductosMonto))
      );
      const totalFinal = facturaProductosMontoTotal;
      const nuevoFacturaDatos = {
        ...facturaDatos,
        facturaSubTotal: totalFinal,
      };
      setFacturaDatos(nuevoFacturaDatos);
      setFacturaProdutoDatosPreview(productosPreviewDefault);
    } else {
      setListadoFacturaProductos([
        ...listadoFacturaProductos,
        {
          facturaProductosId,
          facturaProductosCodigo,
          facturaProductosNombre,
          facturaProductosCantidad,
          facturaProductosPrecioVenta,
          facturaProductosMontoNoIva,
          facturaProductosMonto,
        },
      ]);
      const totalFinal =
        Number(facturaDatos.facturaTotal) + Number(facturaProductosMonto);
      const nuevoFacturaDatos = {
        ...facturaDatos,
        facturaSubTotal: totalFinal,
      };
      setFacturaDatos(nuevoFacturaDatos);
      setFacturaProdutoDatosPreview(productosPreviewDefault);
    }
  };
  /**
   *
   *
   * Render Listado de productos seleccionados
   *
   *
   *
   */
  const ListadoFacturaProductosRows = () => {
    return (
      <>
        {listadoFacturaProductos.map((producto) => (
          <tr key={producto.facturaProductosId}>
            <td className="text-center">
              <span>{producto.facturaProductosCodigo}</span>
            </td>
            <td className="text-center">
              <span>{producto.facturaProductosNombre}</span>
            </td>
            <td className="text-center">
              <span>{producto.facturaProductosCantidad}</span>
            </td>
            <td className="text-center">
              <span>${producto.facturaProductosPrecioVenta}</span>
            </td>
            {mostrarPrecioSinIva && (
              <td className="text-center">
                <span>${producto.facturaProductosMontoNoIva}</span>
              </td>
            )}
            <td className="text-center">
              <span className="h5">${producto.facturaProductosMonto}</span>
            </td>
          </tr>
        ))}
      </>
    );
  };
  const handleMostrarBusquedaCliente = () => {
    setMostrarBusquedaCliente(true);
  };
  const handleCancelarCreacionFactura = () => {
    history(Paths.ListaFacturas.path);
  };
  const handleGuardarFactura = async () => {
    try {
      let facturaFechaVencimientoFormateada = "";
      let facturaFechaFormateada = "";
      let {
        facturaFecha,
        facturaFechaVencimiento,
        facturaNumero,
        facturaTipo,
        facturaGiro,
        facturaCuentaDe,
        facturaComentarios,
        facturaSubTotal,
        facturaIvaIndividual,
        facturaDescuentoTipo,
        facturaDescuentoValor,
        facturaTotal,
      } = facturaDatos;
      facturaNumero = facturaNumero.toString().trim();
      facturaTipo = parseInt(facturaTipo);
      facturaGiro = facturaGiro.trim();
      facturaCuentaDe = facturaCuentaDe.trim();
      facturaComentarios = facturaComentarios.trim();
      facturaSubTotal = Number(facturaSubTotal);
      facturaIvaIndividual = Number(facturaIvaIndividual);
      facturaDescuentoTipo = parseInt(facturaDescuentoTipo);
      facturaDescuentoValor = Number(facturaDescuentoValor);
      facturaTotal = Number(facturaTotal);
      if (parseInt(facturaClienteId) < 1) {
        SweetAlert(
          "Error",
          `Es necesario la asignación de un cliente para guarda una factura`,
          "error",
          true
        );
        return false;
      }

      if (!facturaFecha || facturaFecha.toString().trim().length < 1) {
        SweetAlert("Error", `La fecha de factura es inválida`, "error", true);
        return false;
      }
      facturaFechaFormateada = format(facturaFecha, "yyyy/MM/dd");
      if (!facturaNumero || facturaNumero.trim().length < 5) {
        SweetAlert("Error", `El código de factura es inválido`, "error", true);
        return false;
      }
      if (!listadoFacturaProductos || listadoFacturaProductos.length < 1) {
        SweetAlert(
          "Error",
          `La factura debe tener al menos un producto`,
          "error",
          true
        );
        return false;
      }
      if (!facturaSubTotal || Number(facturaSubTotal) <= 0) {
        SweetAlert(
          "Error",
          `El total de la factura debe ser mayor a cero`,
          "error",
          true
        );
        return false;
      }
      if (!facturaTotal || Number(facturaTotal) <= 0) {
        SweetAlert(
          "Error",
          `El total de la factura debe ser mayor a cero`,
          "error",
          true
        );
        return false;
      }
      if (facturaFechaVencimiento.toString().trim().length > 0) {
        facturaFechaVencimientoFormateada = format(
          facturaFechaVencimiento,
          "yyyy/MM/dd"
        );
        if (
          new Date(facturaFechaVencimientoFormateada) <
          new Date(facturaFechaFormateada)
        ) {
          SweetAlert(
            "Error",
            `La fecha de vencimiento no puede ser menor a la fecha de factura.`,
            "error",
            true
          );
          return false;
        }
      }
      const datosFactura = {
        facturaFecha: facturaFechaFormateada,
        facturaFechaVencimiento: facturaFechaVencimientoFormateada,
        facturaTipo: facturaTipo,
        facturaGiro: facturaGiro,
        facturaCuentaDe: facturaCuentaDe,
        facturaNumero: facturaNumero,
        facturaComentarios: facturaComentarios,
        facturaListaProductos: listadoFacturaProductos,
        facturaSubTotal: facturaSubTotal,
        facturaIvaIndividual: facturaIvaIndividual,
        facturaDescuentoTipo: facturaDescuentoTipo,
        facturaDescuentoValor: facturaDescuentoValor,
        facturaTotal: facturaTotal,
        clientesId: facturaClienteId,
      };
      let guardarFacturaPeticion;
      if (type === "EDITAR" && facturaId > 0) {
        // Editar factura
      } else {
        // Crear factura
        guardarFacturaPeticion = await guardarNuevaFactura(datosFactura, Token);
      }
      const { status, ok } = guardarFacturaPeticion;
      if (status === 401) {
        logout();
        return false;
      }
      if (!ok) {
        throw new Error("Error de petición.");
      }
      const response = await guardarFacturaPeticion.json();
      const { success = "", message = "", data = [] } = response;
      if (!success) {
        SweetAlert("Error", message, "error", true);
        return false;
      }
      SweetAlert("Éxito", message, "success", true);
      handleCancelarCreacionFactura();
      return true;
    } catch (error) {
      console.error(error);
      SweetAlert(
        "Error",
        `Ocurrió un error inesperado. Por favor contacte con el adminitrador`,
        "error",
        true
      );
      return false;
    }
  };
  return (
    <div className="mt-4 mb-6 facturaContainer">
      <Card className="mb-4">
        <Card.Body>
          <Row>
            <Col className="d-flex justify-content-end">
              <Button
                type="button"
                onClick={handleCancelarCreacionFactura}
                className="mx-2"
                variant="danger"
              >
                <BsReply className="mx-2" /> Cancelar
              </Button>
              <Button
                type="button"
                onClick={handleGuardarFactura}
                variant="outline-primary"
              >
                <BsPlusLg className="mx-2" /> Guardar Factura
              </Button>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col
              xs="12"
              md={{ span: 5 }}
              className="factura-nuevo-cliente-container"
            >
              {clienteDatos.clientesId < 1 ? (
                <span
                  className="factura-nuevo-cliente-handler"
                  onClick={handleMostrarBusquedaCliente}
                >
                  <span>
                    <AiOutlineUsergroupAdd size="4em" />
                  </span>
                  <span>Nuevo Cliente</span>
                </span>
              ) : (
                <div className="w-80">
                  <div className="d-flex justify-content-between">
                    <div>
                      <span>
                        <GoPerson size="2em" className="mb-2" />
                      </span>
                      <span className="h3 mx-2">Cliente</span>
                    </div>
                    <div onClick={() => setClienteDatos(clientesDatosDefault)}>
                      <GoX
                        size="2em"
                        style={{ color: "rgb(152 32 68)", cursor: "pointer" }}
                        alt="Deseleccionar"
                      />
                    </div>
                  </div>
                  <hr />
                  <div className="mb-3 d-flex justify-content-between mt-4 text-left">
                    <div className="d-flex flex-column">
                      <span className="factura-cliente-propiedad-titulo">
                        Nombre
                      </span>
                      <span className="factura-cliente-propiedad-info">
                        {clienteDatos.clientesNombres}
                      </span>
                    </div>
                    <div className="d-flex flex-column">
                      <span className="factura-cliente-propiedad-titulo">
                        DUI
                      </span>
                      <span className="factura-cliente-propiedad-info">
                        {clienteDatos.clientesDui}
                      </span>
                    </div>
                  </div>
                  <div className="mb-2 d-flex justify-content-between text-left">
                    <div className="d-flex flex-column">
                      <span className="factura-cliente-propiedad-titulo">
                        Teléfono
                      </span>
                      <span className="factura-cliente-propiedad-info">
                        {clienteDatos.clientesTelefono.trim().length > 2
                          ? clienteDatos.clientesTelefono
                          : "N/A"}
                      </span>
                    </div>
                    <div className="d-flex flex-column">
                      <span className="factura-cliente-propiedad-titulo">
                        Nic
                      </span>
                      <span className="factura-cliente-propiedad-info">
                        {clienteDatos.clientesNRC}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </Col>
            <Col
              xs="12"
              md={{ span: 6, offset: 1 }}
              className="factura-informacion-container"
            >
              <Row>
                <Col xs="12" md="6">
                  <Form.Group>
                    <Form.Label>Fecha Factura*</Form.Label>
                    <DatePicker
                      onChange={(value) =>
                        handleChangeFacturaDatosFechas(value, "facturaFecha")
                      }
                      className="form-control"
                      value={facturaDatos.facturaFecha}
                      format="dd-MM-yyyy"
                      locale="es-ES"
                      name="facturaFecha"
                      id="facturaFecha"
                    />
                  </Form.Group>
                </Col>
                <Col xs="12" md="6">
                  <Form.Group>
                    <Form.Label>Fecha Vencimiento</Form.Label>
                    <DatePicker
                      onChange={(value) =>
                        handleChangeFacturaDatosFechas(
                          value,
                          "facturaFechaVencimiento"
                        )
                      }
                      className="form-control"
                      value={facturaDatos.facturaFechaVencimiento}
                      format="dd-MM-yyyy"
                      locale="es-ES"
                      name="facturaFechaVencimiento"
                      id="facturaFechaVencimiento"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col xs="12" md="6">
                  <Form.Group>
                    <Form.Label>Código de Factura</Form.Label>
                    <Form.Control
                      type="text"
                      name="facturaNumero"
                      id="facturaNumero"
                      value={facturaDatos.facturaNumero}
                      onChange={handleChangeFacturaDatos}
                    />
                  </Form.Group>
                </Col>
                <Col xs="12" md="6">
                  <Form.Group>
                    <Form.Label>Tipo de Factura</Form.Label>
                    <Form.Select
                      aria-label="Tipo de Factura"
                      name="facturaTipo"
                      value={facturaDatos.facturaTipo}
                      onChange={handleChangeFacturaDatos}
                    >
                      <option value="1">Consumidor Final</option>
                      <option value="2">Credito Fiscal</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-2">
                <Form.Group>
                  <Form.Label>Giro</Form.Label>
                  <Form.Control
                    type="text"
                    name="facturaGiro"
                    value={facturaDatos.facturaGiro}
                    onChange={handleChangeFacturaDatos}
                  />
                </Form.Group>
              </Row>
              <Row className="mt-2">
                <Form.Group>
                  <Form.Label>Venta a Cuenta De:</Form.Label>
                  <Form.Control
                    type="text"
                    name="facturaCuentaDe"
                    value={facturaDatos.facturaCuentaDe}
                    onChange={handleChangeFacturaDatos}
                  />
                </Form.Group>
              </Row>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <Form.Group>
                <Form.Label>No. de Nota de Remisión anterior:</Form.Label>
                <Form.Control
                  type="text"
                  name="facturaNumeroNotaRemisionAnterior"
                  value={facturaDatos.facturaNumeroNotaRemisionAnterior}
                  onChange={handleChangeFacturaDatos}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Fecha de Nota de Remisión anterior:</Form.Label>
                <DatePicker
                  onChange={(value) =>
                    handleChangeFacturaDatosFechas(
                      value,
                      "facturaFechaNotaRemisionAnterior"
                    )
                  }
                  value={facturaDatos.facturaFechaNotaRemisionAnterior}
                  className="form-control"
                  format="dd-MM-yyyy"
                  locale="es-ES"
                  name="facturaFechaNotaRemisionAnterior"
                  id="facturaFechaNotaRemisionAnterior"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Registro No.:</Form.Label>
                <Form.Control
                  type="text"
                  name="facturaRegistroNumero"
                  value={facturaDatos.facturaRegistroNumero}
                  onChange={handleChangeFacturaDatos}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col>
              <ProductosBuscarPorCodigo
                buscarPorCodigo={buscarPorCodigo}
                setBuscarPorCodigo={setBuscarPorCodigo}
                setFacturaDatosProductoPreview={setFacturaDatosProductoPreview}
              />
            </Col>
            <Col>
              <ProductosSearch
                setFacturaDatosProductoPreview={setFacturaDatosProductoPreview}
              />
            </Col>
          </Row>
          <Row className="mt-4 factura-productos-lista">
            <Col xs={12}>
              <Form onSubmit={guardarEnListaProductosFactura}>
                <Table>
                  <thead>
                    <tr>
                      <th style={{ width: "30%" }} className="text-center">
                        <span>Codigo Producto</span>
                      </th>
                      <th style={{ width: "30%" }} className="text-center">
                        <span>Nombre Producto</span>
                      </th>
                      <th style={{ width: "10%" }} className="text-center">
                        <span>Cantidad</span>
                      </th>
                      <th style={{ width: "10%" }} className="text-center">
                        <span>Precio Unidad</span>
                      </th>
                      {mostrarPrecioSinIva && (
                        <th style={{ width: "10%" }} className="text-center">
                          <span>Monto Sin Iva</span>
                        </th>
                      )}
                      <th style={{ width: "10%" }} className="text-center">
                        <span>Monto</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <ListadoFacturaProductosRows />
                    <tr>
                      <td>
                        <Form.Control
                          type="text"
                          name="facturaProductosCodigo"
                          placeholder="Código de producto"
                          value={
                            facturaProdutoDatosPreview.facturaProductosCodigo
                          }
                          onChange={handleChangeProductoPreview}
                          className="text-center"
                          ref={facturaProductosCodigoPreview}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          name="facturaProductosNombre"
                          placeholder="Nombre de Producto"
                          value={
                            facturaProdutoDatosPreview.facturaProductosNombre
                          }
                          onChange={handleChangeProductoPreview}
                          className="text-center"
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="number"
                          name="facturaProductosCantidad"
                          value={
                            facturaProdutoDatosPreview.facturaProductosCantidad
                          }
                          onChange={handleChangeProductoPreview}
                          ref={facturaProductosCantidadPreview}
                          className="text-center"
                          min="0"
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="number"
                          name="facturaProductosPrecioVenta"
                          value={
                            facturaProdutoDatosPreview.facturaProductosPrecioVenta
                          }
                          onChange={handleChangeProductoPreview}
                          className="text-center"
                          min="0"
                          readOnly
                        />
                      </td>

                      {mostrarPrecioSinIva && (
                        <td>
                          <Form.Control
                            type="number"
                            name="facturaProductosPrecioVenta"
                            value={
                              facturaProdutoDatosPreview.facturaProductosMontoNoIva
                            }
                            onChange={handleChangeProductoPreview}
                            className="text-center"
                            min="0"
                            readOnly
                          />
                        </td>
                      )}
                      <td>
                        <Form.Control
                          type="number"
                          name="facturaProductosMonto"
                          value={
                            facturaProdutoDatosPreview.facturaProductosMonto
                          }
                          onChange={handleChangeProductoPreview}
                          className="text-center h1"
                          readOnly
                        />
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={11}>
                        <div className="d-flex justify-content-center">
                          <Button type="submit" variant="info">
                            {" "}
                            <BsPlusLg className="mx-2" /> Agregar Producto
                          </Button>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </Table>
              </Form>
            </Col>
          </Row>
          <Row className="mb-6 mt-4">
            <Col md="6">
              <Form.Group>
                <Form.Label>Comentarios</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder=""
                  style={{ height: "100px" }}
                  value={facturaDatos.facturaComentarios}
                  onChange={handleChangeFacturaDatos}
                  name="facturaComentarios"
                  id="facturaComentarios"
                />
              </Form.Group>
            </Col>
            <Col
              md={{ span: 4, offset: 2 }}
              className="pt-4 factura-totales-container"
            >
              <div className="factura-totales mb-2">
                <span>Sub Total</span>
                <span>{facturaDatos.facturaSubTotal}</span>
              </div>
              {!mostrarPrecioSinIva && (
                <div className="factura-totales mb-2">
                  <span>Iva</span>
                  <span>{facturaDatos.facturaIvaIndividual}</span>
                </div>
              )}
              <div className="factura-totales mb-2">
                <span>Descuento</span>
                <span style={{ display: "flex", justifyContent: "center" }}>
                  <Form.Select
                    style={{ width: "40%" }}
                    className="mx-1"
                    onChange={handleChangeFacturaDatos}
                    value={facturaDatos.facturaDescuentoTipo}
                    id="facturaDescuentoTipo"
                    name="facturaDescuentoTipo"
                  >
                    <option value={0}>N/A</option>
                    <option value={1}>%</option>
                    <option value={2}>$</option>
                  </Form.Select>
                  {facturaDatos.facturaDescuentoTipo > 0 && (
                    <Form.Control
                      type="number"
                      placeholder="Valor"
                      style={{ width: "40%" }}
                      onChange={handleChangeFacturaDatos}
                      value={facturaDatos.facturaDescuentoValor}
                      id="facturaDescuentoValor"
                      name="facturaDescuentoValor"
                      min={0}
                      max={
                        parseInt(facturaDatos.facturaDescuentoTipo) === 2
                          ? facturaDatos.facturaSubTotal
                          : 100
                      }
                    />
                  )}
                </span>
              </div>
              <div className="factura-totales mb-2">
                <span className="h3">Total</span>
                <span className="h3">${facturaDatos.facturaTotal}</span>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {mostrarBusquedaCliente && (
        <BusquedaCliente
          mostrarBusquedaCliente={mostrarBusquedaCliente}
          setMostrarBusquedaCliente={setMostrarBusquedaCliente}
          setClienteDatos={setClienteDatos}
          setFacturaClienteId={setFacturaClienteId}
          facturaClienteId={facturaClienteId}
        />
      )}
    </div>
  );
};

export default Factura;
