import React from 'react';
import { FiUsers } from 'react-icons/fi';
import UsuarioForm from '../../components/tiendaBuenPrecioComponents/Usuarios/UsuarioForm';
const AgregarUsuarios = () => {
  return (
    <div className= "mt-4">
      <div className="px-0 mb-3" >
        <div className="d-flex justify-content-start">
          <div className="mx-3">
            <FiUsers size="2em" className="mx-4"/>
          </div>
          <h4>
            Agregar Usuario
          </h4>
        </div>
      </div>
      <UsuarioForm formType="NUEVO"/>
    </div>
  )
}

export default AgregarUsuarios;