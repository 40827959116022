import React, { useEffect, useState } from "react";
import { obtenerDepartamentos } from "../helper/clientesPeticiones";
import { useAuthContext } from "../../../../contexts/authContext";
import SweetAlert from "../../Utils/SweetAlert";
import { Form } from "@themesberg/react-bootstrap";

const ListaDepartamentos = ({
  onChange,
  value,
  consultarDepartamento,
  setConsultarDepartamento,
  paisId,
  name,
  id
}
) => {
  const { logout, token: Token } = useAuthContext();
  const [listaDepartamentos, setListaDepartamentos] = useState([]);
  useEffect(() => {
    (async () => {
      if (setConsultarDepartamento && paisId > 0) {
        const obtenerDepartamentosResultado = await obtenerDepartamentos(
          paisId,
          Token
        );
        const { status, ok } = obtenerDepartamentosResultado;
        if (status === 401) {
          logout();
          return false;
        }
        if (!ok) {
          throw new Error("Error de petición.");
        }
        const response = await obtenerDepartamentosResultado.json();
        const { success = false, message = "", data = {} } = response;
        
        if (!success) {
          SweetAlert(
            "Error",
            message || "Ocurrió un error al obtener los departamentos.",
            "error",
            true
          );
        }
        setListaDepartamentos(data);
      }
    })();
  }, [consultarDepartamento, paisId, Token, logout, setConsultarDepartamento]);
  return (
    <>
      <Form.Group style={{ marginRight: "10px",flex:1 }}>
        <Form.Label>Departamentos</Form.Label>
        <Form.Select 
          aria-label="Departamentos"
          onChange={onChange}
          value={value}
          name={name}
          id={id}
        >
          <option value="0">Seleccionar</option>
          {listaDepartamentos.map((depto) => (
            <option value={depto.deptoId} key={depto.deptoId}>{depto.deptoDescripcion}</option>
          ))}
        </Form.Select>
      </Form.Group>
    </>
  );
};

export default ListaDepartamentos;
