import React, { useState } from "react";
import BgImage from "../../assets/img/illustrations/signin.svg";
import { Col, Row, Container } from "@themesberg/react-bootstrap";
import { ErrorAlert } from "../../components/tiendaBuenPrecioComponents/Utils/ErrorAlert";
import SigningForm from "../../components/tiendaBuenPrecioComponents/Signing/SigningForm";
const Signin = () => {
  const alertTextInitial = {
    title: "",
    message: "",
  };
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertText, setAlertText] = useState(alertTextInitial);
  return (
    <main>
      <section className="d-flex align-items-center  mt-lg-8 mb-lg-5">
        <Container>
          <Row
            className="justify-content-center form-bg-image"
            style={{ backgroundImage: `url(${BgImage})` }}
          >
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Tienda Buen Precio</h3>
                </div>
                <SigningForm
                  setAlertVisible={setAlertVisible}
                  setAlertText={setAlertText}
                  alertTextInitial={alertTextInitial}
                />
                {/* <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">Bienvenido</span>
                </div> */}
                <div className="justify-content-md-center">
                  {alertVisible ? (
                    <div className="mt-4">
                      <ErrorAlert
                        message={alertText.message}
                        extraClassNames="text-center"
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
export default Signin;
