import React from "react";
import { BsReverseLayoutTextSidebarReverse } from "react-icons/bs";
import MarcasLista from "../components/MarcasLista";
//import { Link} from 'react-router-dom';
//import { Routes } from '../../../../routes';
const ListaMarcas = () => {
  return (
    <div className="mt-4">
      <div className="mb-4 mx-1 d-flex justify-content-between">
        <div className="d-flex justify-content-start">
          <BsReverseLayoutTextSidebarReverse size="1.8em" className="mx-2" />
          <h4>Lista Marcas</h4>
        </div>
      </div>
      <MarcasLista />
    </div>
  );
};

export default ListaMarcas;
