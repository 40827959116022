
import { makePetition } from "../../../../helpers/petition";

const fetchCategoria = async (requesUrl, requestMethod, requestData, token) => {
  try {
    const request = await makePetition(
      requesUrl,
      requestData,
      requestMethod,
      token
    );
    return request;
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message:
        "Ocurrió un error inexperado, por favor vuelva a intentar. Si este error persiste contacte al administrador del sistema",
    };
  }
};
const categoriasPeticiones = async (method,token,requestData = {}) => {
  switch (method) {
    case "obtenerListaCategorias":
      return await fetchCategoria(`categorias/lista`, "GET", requestData,token);
    case "obtenerCategoriaPorId":
      const {categoriaId = 0} = requestData;
      return await fetchCategoria(`categorias/categoria/${categoriaId}`, "GET", requestData,token);
    case "guardarDatosCategoriaEdicion":
      return await fetchCategoria(`categorias/editar`, "PUT", requestData,token);
    case "eliminarCategoria":
      return await fetchCategoria(`categorias/eliminar`, "DELETE", requestData,token);
    default:
      return {
        success: false,
        message:
          "Ocurrió un error inexperado, por favor vuelva a intentar. Si este error persiste contacte al administrador del sistema",
      };
  }
};

export { categoriasPeticiones };
