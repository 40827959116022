import { Form } from "@themesberg/react-bootstrap";
import React, { useEffect, useState } from "react";
import { makePetition } from "../../../helpers/petition";
import { Block } from "notiflix/build/notiflix-block-aio";
import { Notify } from "notiflix/build/notiflix-notify-aio";
import { useAuthContext } from "../../../contexts/authContext";
const ProductosBuscarPorCodigoYProveedorId = ({
  codigoProducto,
  setBuscarPorCodigo,
  setFacturaDatosProductoPreview,
  proveedorId
}) => {
  const { logout, token: Token } = useAuthContext();
  const [consultarCodigoProducto, setConsultarCodigoProducto] = useState(false);
  const handleChange = ({ target }) => {
    const { value } = target;
    setBuscarPorCodigo(value);
    if (codigoProducto.trim() !== value.trim()) {
      setConsultarCodigoProducto(true);
    }
  };
  useEffect(() => {
    const consultarProductoPorCodigoYProveedorId = async () => {
      try {
        Block.standard(".facturaContainer", {
          cssAnimationDuration: 1881,
          svgSize: "19px",
        });
        const urlTarget = `productos/productoPorCodigoYProveedor/${codigoProducto}/${proveedorId}`;
        const petitionMethod = "GET";
        const formData = {};
        const request = await makePetition(
          urlTarget,
          formData,
          petitionMethod,
          Token
        );
        const { status, ok } = request;
        if (status === 401) {
          Block.remove(".facturaContainer");
          logout();
          return true;
        }
        if (!ok) {
          throw new Error("Error de petición.");
        }
        const response = await request.json();
        const { success = false, message = "", data = [] } = response;
        Block.remove(".facturaContainer");
        if (success && Object.keys(data).length > 0) {
          setFacturaDatosProductoPreview(data);
          Notify.success("Producto Encontrado");
          return true;
        } else {
          Notify.failure(message || "Producto no encontrado");
          return false;
        }
      } catch (error) {
        Block.remove(".facturaContainer");
        Notify.failure(
          "Ocurrió un erro en la busqueda. Por favor contacte con el administrador."
        );
      }
    };
    const delayDebounceFn = setTimeout(() => {
      if (
        codigoProducto &&
        codigoProducto.trim().length > 3 &&
        consultarCodigoProducto &&
        proveedorId>0
      ) {
        setConsultarCodigoProducto(false);
        consultarProductoPorCodigoYProveedorId();
      }else{
        if(consultarCodigoProducto && proveedorId<1){
            Notify.failure(
                "Por favor seleccione un proveedor."
              );
        }
      }
    }, 2000);
    if (consultarCodigoProducto && proveedorId>0) {
      return () => clearTimeout(delayDebounceFn);
    }
  }, [
    codigoProducto,
    setFacturaDatosProductoPreview,
    consultarCodigoProducto,
    Token,
    proveedorId,
    logout,
  ]);

  return (
    <>
      <Form.Label>Buscar Producto Por Código</Form.Label>
      <Form.Control
        type="text"
        name="facturaProductosCodigo"
        placeholder="Búsqueda por código"
        onChange={handleChange}
        value={codigoProducto}
      />
    </>
  );
};

export default ProductosBuscarPorCodigoYProveedorId;
