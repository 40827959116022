import React from 'react';
import { Button, Modal } from "@themesberg/react-bootstrap";
import ProductoForm from "../ProductoForm";
const ModalAgregarProductos = ({
  showModalProductoForm,
  setShowModalProductoForm,
}) => {
  const cancelarFormProductos = () => {
    setShowModalProductoForm(false);
  };
  return (
    <div>
      <Modal
        as={Modal.Dialog}
        centered
        show={showModalProductoForm}
        onHide={() => setShowModalProductoForm(false)}
        backdrop="static"
        keyboard={false}
        size="xl"
        dialogClassName="modal-90w"
      >
        <Modal.Header>
          <Modal.Title className="h3 p-3"></Modal.Title>
          <Button
            variant="close"
            aria-label="Close"
            onClick={() => setShowModalProductoForm(false)}
          />
        </Modal.Header>
        <Modal.Body>
          <h3>Nuevo Producto</h3>
          <ProductoForm
            formType="NUEVO"
            cancelarFormProductos={cancelarFormProductos}
            fromModal={true}
          />
        </Modal.Body>
        {/* <Modal.Footer>
                    <div className="d-flex justify-content-center w-100">
                        <Button
                            size="md"
                            variant="success"
                            className="w-60 mt-4"
                            onClick={asignarProductoSeleccionadoEnListaProducto}
                        >
                            
                        </Button>
                    </div>
                </Modal.Footer> */}
      </Modal>
    </div>
  );
};
export default ModalAgregarProductos;
