import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { Form, Button, InputGroup } from "@themesberg/react-bootstrap";
import { makePetition } from "../../../helpers/petition.js";
import { Formik } from "formik";
import * as Yup from "yup";
import { useAuthContext } from "../../../contexts/authContext.js";
const SigningForm = ({ setAlertVisible, setAlertText, alertTextInitial }) => {
  const { login, logout } = useAuthContext();
  const defaultLoginStateForm = {
    usuario: "",
    password: "",
  };
  const formSubmitHandler = async (values) => {
    try {
      const { usuario, password } = values;
      setAlertVisible(false);
      setAlertText(alertTextInitial);
      const formData = {
        usuariosNombreUsuario: usuario,
        usuariosPassword: password,
      };
      const urlTarget = "auth";
      const petitionMethod = "POST";
      const request = await makePetition(urlTarget, formData, petitionMethod);
      const response = await request.json();
      const { success, message, token = "", data = {}} = response;
      if (!success) {
        setAlertText({ title: "Error", message: message });
        setAlertVisible(true);
        return false;
      }
      if (token.length < 10) {
        setAlertText({
          title: "Error",
          message: message || "Ocurrió un error inesperado",
        });
        setAlertVisible(true);
        return false;
      }
      login(token);
      return true;
    } catch (error) {
      logout();
      setAlertText({
        title: "Error",
        message: "Ocurrió un error inesperado",
      });
      setAlertVisible(true);
      console.error(error);
      return false;
    }
  };
  const SignupSchema = Yup.object().shape({
    usuario: Yup.string()
      .min(2, "El nombre de usuario es invalido.")
      .required("Este campo es requerido"),
    password: Yup.string()
      .min(2, "La contraseña es invalida")
      .required("Este campo es requerido"),
  });
  return (
    <Formik
      initialValues={defaultLoginStateForm}
      validationSchema={SignupSchema}
      onSubmit={(values) => {
        formSubmitHandler(values);
      }}
      render={({
        handleChange,
        handleSubmit,
        handleBlur,
        values: inputs,
        errors,
        touched,
      }) => {
        const { usuario, password } = inputs;
        return (
          <Form className="mt-4" onSubmit={handleSubmit}>
            <Form.Group id="email" className="mb-4">
              <Form.Label>Usuario</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faEnvelope} />
                </InputGroup.Text>
                <Form.Control
                  autoFocus
                  required
                  type="text"
                  placeholder="Ingrese su usuario"
                  name="usuario"
                  id="usuario"
                  value={usuario}
                  onChange={handleChange}
                  isInvalid={touched.usuario && errors.usuario}
                />
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <Form.Group id="password" className="mb-4">
                <Form.Label>Contraseña</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faUnlockAlt} />
                  </InputGroup.Text>
                  <Form.Control
                    required
                    type="password"
                    placeholder="Ingrese su contraseña"
                    name="password"
                    id="password"
                    value={password}
                    onChange={handleChange}
                    isInvalid={touched.password && errors.password}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Control.Feedback type="invalid">
                {errors.usuario}
              </Form.Control.Feedback>
            </Form.Group>
            <Button variant="primary" type="submit" className="w-100">
              Ingresar
            </Button>
          </Form>
        );
      }}
    />
  );
};

export default SigningForm;
