import React from 'react'
import { Button, Col, Container, Modal, Row } from '@themesberg/react-bootstrap'
import UsuarioForm from './UsuarioForm'

export const UsuariosModalFormView = ({type,showDefault,handleClose,functionCallBack = null}) => {
const  llamadaDesde = {
    desde:"MODAL",
    fnCierre:handleClose,
    fnCallBack:functionCallBack
}
  return (
    <>
    <Modal 
            show={showDefault} 
            onHide={handleClose}
            centered 
            as={Modal.Dialog} 
            backdrop="static"
            keyboard={false}
            size="xl"
            className='w-100'
        >
                <Modal.Header>
                    <Container>
                        <Row>
                            <Col><Modal.Title className="h2 mt-4 mb-2">{type === "NUEVO" ? "Nuevo Usuario" : "Editar Usuario" }</Modal.Title></Col>
                            <Col className="d-flex justify-left align-items-center"><Button variant="close" aria-label="Close" onClick={handleClose} /></Col>
                        </Row> 
                    </Container>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <UsuarioForm 
                            type={type}
                            llamadaDesde={llamadaDesde}
                        />
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
        </Modal>
    </>
  )
}
