import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
} from "@themesberg/react-bootstrap";
import { makePetition } from "../../../../helpers/petition";
import { ErrorAlert } from "../../Utils/ErrorAlert";
import { SuccessAlert } from "../../Utils/SuccessAlert";
//import onlyLetters from "../../Utils/onlyLetters";
import onlyLettersAndNumbers from "../../Utils/onlyLettersAndNumbers";
import { useAuthContext } from "../../../../contexts/authContext";
const AgregarProductoPresentacionModal = ({
  mostrarAgregarPresentacion,
  setMostrarAgregarPresentacion,
  setConsultarPresentaciones,
  setPresentacionIdModalState = () => {},
}) => {
  const { logout, token: Token } = useAuthContext();
  const alertaTextoInicial = {
    title: "",
    message: "",
  };
  const [Presentacion, setPresentacion] = useState("");
  const [alertaVisible, setAlertaVisible] = useState(false);
  const [desactivarBotonGuardar, setDesactivarBotonGuardar] = useState(false);
  const [alertText, setAlertText] = useState(alertaTextoInicial);
  const [alertSuccessVisible, setAlertSuccessVisible] = useState(false);
  const [alertSuccessText, setAlertSuccessText] = useState(alertaTextoInicial);
  const handlePresentacion = ({ target }) => {
    const { value } = target;
    setPresentacion(value);
  };
  useEffect(() => {
    if (!mostrarAgregarPresentacion) {
      setAlertSuccessVisible(false);
      setAlertaVisible(false);
      setAlertText({
        title: "",
        message: "",
      });
      setAlertSuccessText({
        title: "",
        message: "",
      });
      setPresentacion("");
    }
  }, [mostrarAgregarPresentacion]);
  const guardarPresentacion = async (e) => {
    try {
      e.preventDefault();
      setAlertSuccessVisible(false);
      setAlertaVisible(false);
      if (Presentacion.trim().length <= 1) {
        setAlertText({
          title: "Error",
          message:
            "La descripción de Presentación debe ser mayor a 1 caracter.",
        });
        setAlertaVisible(true);
        return false;
      }
      setDesactivarBotonGuardar(true);
      const formData = {
        presentacionDescripcion: Presentacion,
      };
      const urlTarget = "presentaciones/nueva";
      const petitionMethod = "POST";
      const request = await makePetition(
        urlTarget,
        formData,
        petitionMethod,
        Token
      );
      const { status, ok } = request;
      if (status === 401) {
        logout();
        return false;
      }
      if (!ok) {
        setAlertText({
          title: "Error",
          message:
            "Ocurrió un error inesperado en la peticion, por favor contacte con el administrador.",
        });
        setAlertaVisible(true);
        setDesactivarBotonGuardar(false);
        return false;
      }
      const response = await request.json();
      const { success, message = "", data = {} } = response;
      if (!success) {
        setAlertText({ title: "Error", message: message });
        setAlertaVisible(true);
        setDesactivarBotonGuardar(false);
        return false;
      }
      setPresentacion("");
      if (Object.keys(data).length > 0) {
        setPresentacionIdModalState(data.presentacionesId);
      }
      setAlertSuccessText({ title: "Exito", message: message });
      setAlertSuccessVisible(true);
      setDesactivarBotonGuardar(false);
      setTimeout(() => {
        setAlertSuccessVisible(false);
      }, 3000);
      setConsultarPresentaciones(true);
      return true;
    } catch (error) {
      console.error(error);
      setAlertText({
        title: "Error",
        message: "Ocurrió un error inexperado, por favor contacta con soporte",
      });
      setAlertaVisible(true);
      setDesactivarBotonGuardar(false);
    }
  };
  return (
    <>
      <Modal
        as={Modal.Dialog}
        centered
        show={mostrarAgregarPresentacion}
        onHide={() => setMostrarAgregarPresentacion(false)}
        backdrop="static"
        keyboard={false}
        size="md"
      >
        <Container>
          <Modal.Header className="text-center">
            <Button
              variant="close"
              aria-label="Close"
              onClick={() => setMostrarAgregarPresentacion(false)}
            />
          </Modal.Header>
          <Form onSubmit={guardarPresentacion}>
            <Modal.Body>
              <Row>
                <Col>
                  <h3>Nueva Presentacion</h3>
                </Col>
                <Col xs={12}>
                  <Form.Group className="mb-4">
                    <Form.Label></Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Ingrese el nombre de la Presentacion"
                      id="Presentacion"
                      name="Presentacion"
                      value={Presentacion}
                      onChange={handlePresentacion}
                      size="md"
                      onKeyPress={(event) =>
                        event.target.value.toString().length > 98
                          ? event.preventDefault()
                          : onlyLettersAndNumbers(event)
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Col xs={12}>
                {alertaVisible ? (
                  <ErrorAlert
                    title={alertText.title}
                    message={alertText.message}
                  />
                ) : null}
                {alertSuccessVisible ? (
                  <SuccessAlert
                    title={alertSuccessText.title}
                    message={alertSuccessText.message}
                  />
                ) : null}
              </Col>
              <Button
                size="md"
                variant="outline-primary"
                className="w-100 mt-2"
                onClick={guardarPresentacion}
                disabled={desactivarBotonGuardar}
              >
                Guardar
              </Button>
              <Button
                size="md"
                variant="danger"
                className="w-100 mt-1"
                onClick={() => setMostrarAgregarPresentacion(false)}
                disabled={desactivarBotonGuardar}
              >
                Cancelar
              </Button>
            </Modal.Footer>
          </Form>
        </Container>
      </Modal>
    </>
  );
};

export default AgregarProductoPresentacionModal;
