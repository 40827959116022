import React, { useState } from 'react';
import { faPen, faTrash, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Container, Form, Modal, Row, Table } from '@themesberg/react-bootstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import AgregarEditarRangoPrecio from "./AgregarEditarRangoPrecio";
import customDecimalRound from '../Utils/customDecimalRound';
import onlyNumbers from '../Utils/onlyNumbers';
const ProductoPrecios = ({ precioLista, setPrecioLista, precioCompra, setPrecioCompra }) => {
    const defaultPreciosRangoValores = {
        "rangoPrecioId": 0,
        "precioVenta": 0,
        "porcentajeVenta": 7,
        "productoCantidadRangoMinimo": 1,
        "productoCantidadRangoMaximo": 1000000
    };

    const [showDefault, setShowDefault] = useState(false);
    const [showDefaultPrecioCompraProducto, setShowDefaultPrecioCompraProducto] = useState(false);
    const [procesoModal, setProcesoModal] = useState("NUEVO");
    const [defaultRangoValor, setDefaultRangoValor] = useState(defaultPreciosRangoValores);
    const MySwal = withReactContent(Swal);
    const handleClose = () => setShowDefault(false);
    const handleShow = () => setShowDefault(true);
    const handleClosePrecioCompraProducto = () => setShowDefaultPrecioCompraProducto(false);;
    const handleShowPrecioCompraProducto = () => setShowDefaultPrecioCompraProducto(true);
    const reiniciarDefaultRangoValor = () => {
        setDefaultRangoValor(defaultPreciosRangoValores);
    }
    const nuevoRangoPrecio = () => {
        setProcesoModal("NUEVO");
        let nuevoRangoValores = {};
        nuevoRangoValores = defaultPreciosRangoValores;
        if (precioLista.length > 0) {

            const ultimoRangoMinimo = Math.min.apply(Math, precioLista.map(function (o) { return parseInt(o.productoCantidadRangoMinimo); }));
            const ultimoRangoMaximo = Math.max.apply(Math, precioLista.map(function (precio) { return parseInt(precio.productoCantidadRangoMaximo); }));
            const ultimoPorcentaje = Math.min.apply(Math, precioLista.map(function (precio) { return parseInt(precio.porcentajeVenta); }));
            let rangoAsignadosCorrectamente = false;
            let posicionRangoMalAsignado = -1;
            precioLista.map((precio, index) => {
                const rangoMinimoActual = precio.productoCantidadRangoMinimo;
                if (index > 0) {
                    if (rangoMinimoActual !== (precioLista[index - 1].productoCantidadRangoMaximo + 1)) {
                        rangoAsignadosCorrectamente = true;
                        posicionRangoMalAsignado = index;
                    }
                }
            });
            if (rangoAsignadosCorrectamente && rangoAsignadosCorrectamente > 0) {
                const {
                    productoCantidadRangoMaximo: productoCantidadRangoMaximoAnterior,
                    porcentajeVenta: porcentajeVentaAnterior
                } = precioLista[posicionRangoMalAsignado - 1];
                const {
                    productoCantidadRangoMinimo: productoCantidadRangoMinimoActual,
                } = precioLista[posicionRangoMalAsignado];
                nuevoRangoValores = {
                    ...nuevoRangoValores,
                    "productoCantidadRangoMinimo": (productoCantidadRangoMaximoAnterior + 1),
                    "productoCantidadRangoMaximo": (productoCantidadRangoMinimoActual - 1),
                    "porcentajeVenta": porcentajeVentaAnterior - 1
                };

            } else if (ultimoRangoMinimo === 1) {
                if (ultimoRangoMaximo >= 999999) {
                    MySwal.fire({
                        icon: "question",
                        html: (
                            <>
                                <h2>Atención!</h2>
                                <h4>Para agregar un nuevo rango de precio,
                                    debe de modificar el rango maximo mayor de los rangos ya agregados.
                                </h4>
                            </>
                        ),
                        showCancelButton: true,
                        showCloseButton: true
                    });
                    return false;
                }
                if (Number(ultimoPorcentaje) > 0) {
                    nuevoRangoValores = { ...nuevoRangoValores, "porcentajeVenta": (Number(ultimoPorcentaje) - 1) };
                }
                nuevoRangoValores = { ...nuevoRangoValores, "productoCantidadRangoMinimo": (parseInt(ultimoRangoMaximo) + 1) };
            } else {
                nuevoRangoValores = {
                    ...nuevoRangoValores,
                    "productoCantidadRangoMaximo": (ultimoRangoMinimo - 1),
                    "porcentajeVenta": ultimoPorcentaje + 1
                };
            }
        }
        setDefaultRangoValor(nuevoRangoValores);
        handleShow();
    }
    const editarRangoPrecio = (rangoId) => {
        setProcesoModal("EDITAR");
        const rangoEscogido = precioLista.filter((rango) => rango.rangoPrecioId === rangoId);
        if (rangoEscogido.length <= 0) {
            return false;
        }
        setDefaultRangoValor(rangoEscogido[0]);
        handleShow();
    }
    const eliminarRangoPrecio = (rangoId, showConfirmation = true) => {
        const eliminarProceso = () => {
            if (precioLista.length <= 0) {
                return false;
            }
            const nuevaListaPrecioRango = precioLista.filter((rango) => rango.rangoPrecioId !== rangoId);
            setPrecioLista(nuevaListaPrecioRango);
            return true;
        }
        if (showConfirmation) {
            MySwal.fire({
                icon: "question",
                html: (
                    <>
                        <h2>Atención!</h2>
                        <h4>Esta seguro que desea eliminar este rango de precio?</h4>
                    </>
                ),
                showCancelButton: true,
                showCloseButton: true
            }).then((event) => {
                const { isConfirmed = false } = event;
                if (isConfirmed) {
                    eliminarProceso();
                }
            });
        } else {
            eliminarProceso();
        }
    }
    const modificarPreciosListaRangos = () => {
        const nuevoPrecioLista = precioLista.map((rango) => {
            const {
                porcentajeVenta,
            } = rango;
            const nuevoPrecioVenta = Number(precioCompra) + Number(precioCompra * (porcentajeVenta / 100));
            rango = { ...rango, "precioVenta": Number(nuevoPrecioVenta), "precioCompra": Number(precioCompra) };
            return rango;
        });
        return nuevoPrecioLista;
    }
    const nuevoPrecioCompraPopUP = () => {
        handleShowPrecioCompraProducto();
    }
    const customHandleChangePrecioCompra = (e) => {
        const { target: input } = e;
        const value = customDecimalRound(input.value);
        setPrecioCompra(value);
    }
    const asignarNuevoPrecioCompra = () => {
        const nuevaListaPrecioRango = modificarPreciosListaRangos();
        setPrecioLista(nuevaListaPrecioRango);
        handleClosePrecioCompraProducto();
    }
    return (<>
        <Row>
            <Col xs={12}>
                <h5>Escala de precios</h5>
            </Col>
        </Row>

        <Row className="mt-4 mb-2">
            <Col sm={12}>
                <Button
                    size="sm"
                    variant="tertiary"
                    className="mt-4 mx-1"
                    onClick={nuevoRangoPrecio}
                >{<FontAwesomeIcon icon={faPlusCircle} />} Añadir Escala Precios</Button>
                <Button
                    size="sm"
                    variant="info"
                    className="mt-4 mx-1"
                    onClick={nuevoPrecioCompraPopUP}
                >{<FontAwesomeIcon icon={faPlusCircle} />} Asignar Precio Compra</Button>
            </Col>
        </Row>
        <Row className="mb-4">
            <Col sm={12}>
                <div className="table-responsive">
                    <Table responsive bordered={false}>
                        <thead className="table-custom-pink-bs-blue text-center">
                            <tr>
                                <th>Precio compra</th>
                                <th>Acciones</th>
                                <th>Porcentaje de Ganancia</th>
                                <th>Cantidad Rango Minimo</th>
                                <th>Cantidad Rango Maximo</th>
                                <th>Precio de Venta</th>
                            </tr>
                        </thead>
                        <tbody>

                            {precioLista.length > 0 ?
                                precioLista.map((precio) => {
                                    const {
                                        rangoPrecioId,
                                        precioCompra,
                                        precioVenta,
                                        porcentajeVenta,
                                        productoCantidadRangoMinimo,
                                        productoCantidadRangoMaximo,
                                    } = precio;
                                    return (<tr
                                        className="text-center"
                                        key={rangoPrecioId}
                                    >
                                        <td>${customDecimalRound(precioCompra)}</td>
                                        <td>
                                            <div className="d-flex justify-content-center">
                                                <span className="mx-2 pointer" onClick={() => editarRangoPrecio(rangoPrecioId)}><FontAwesomeIcon icon={faPen} /></span>
                                                <span className="pointer" onClick={() => eliminarRangoPrecio(rangoPrecioId)}><FontAwesomeIcon icon={faTrash} /></span>
                                            </div>
                                        </td>
                                        <td>{customDecimalRound(porcentajeVenta)}%</td>
                                        <td>{customDecimalRound(productoCantidadRangoMinimo)}</td>
                                        <td>{customDecimalRound(productoCantidadRangoMaximo)}</td>
                                        <td className="fw-bold">${customDecimalRound(precioVenta)}</td>
                                    </tr>);
                                })
                                : (<tr><td colSpan="5"><h6 className="text-center">No existen rangos de precios aún</h6></td></tr>)
                            }
                        </tbody>
                    </Table>
                </div>
            </Col>

        </Row>
        <Modal
            as={Modal.Dialog}
            centered
            show={showDefault}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size="lg"
        >
            <Modal.Header>
                <Button variant="close" aria-label="Close" onClick={handleClose} />
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col>
                            <h4>
                                {procesoModal === "NUEVO" ? "Nuevo" : "Editar"} Rango de Precio
                            </h4>
                        </Col>
                    </Row>
                    <div className='mt-2'>
                        <AgregarEditarRangoPrecio
                            precioCompra={precioCompra}
                            setPrecioCompra={setPrecioCompra}
                            setPrecioLista={setPrecioLista}
                            procesoModal={procesoModal}
                            showDefault={showDefault}
                            handleClose={handleClose}
                            defaultRangoValor={defaultRangoValor}
                            reiniciarDefaultRangoValor={reiniciarDefaultRangoValor}
                            modificarPreciosListaRangos={modificarPreciosListaRangos}
                        />
                    </div>
                </Container>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>
        <Modal
            as={Modal.Dialog}
            centered
            show={showDefaultPrecioCompraProducto}
            onHide={handleClosePrecioCompraProducto}
            backdrop="static"
            keyboard={false}
            size="md"
        >
            <Modal.Header>
                <Modal.Title className="h3 p-3">Modifique el precio de compra</Modal.Title>
                <Button variant="close" aria-label="Close" onClick={handleClosePrecioCompraProducto} />
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <Form.Group className="mb-4">
                                <Form.Label>Precio Compra</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Ingrese el precio de compra del producto"
                                    id="productosPrecioCompra"
                                    value={precioCompra}
                                    onChange={customHandleChangePrecioCompra}
                                    onKeyPress={onlyNumbers}
                                    step="0.01"
                                    min="0.00"
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    size="md"
                    variant="tertiary"
                    className="w-100 mt-4"
                    onClick={asignarNuevoPrecioCompra}
                >
                    Guardar
                </Button>
            </Modal.Footer>
        </Modal>
    </>)
}

export default ProductoPrecios;