import React from 'react';
import { BsPersonBadge } from 'react-icons/bs';
import EmpleadoForm from '../../components/tiendaBuenPrecioComponents/Empleados/EmpleadoForm';
const AgregarEmpleados = () => {
  return (
    <div className= "mt-4">
      <div className="px-0 mb-3" >
        <div className="d-flex justify-content-start">
          <div className="mx-3">
            <BsPersonBadge size="2em" />
          </div>
          <h4>
            Agregar Empleado
          </h4>
        </div>
      </div>
      <EmpleadoForm formType="NUEVO"/>
    </div>
  )
}

export default AgregarEmpleados;