import React from 'react';
import { BsPersonBadge } from 'react-icons/bs';
import { useParams } from 'react-router-dom';
import EmpleadoForm from '../../components/tiendaBuenPrecioComponents/Empleados/EmpleadoForm';
const EditarEmpleados = () => {
  const { id:empleadosId = 0 } = useParams();
  return (
    <div className= "mt-4">
      <div className="px-0 mb-3" >
        <div className="d-flex justify-content-start">
          <div className="mx-3">
            <BsPersonBadge size="2em" />
          </div>
          <h4>
            Editar Empleado
          </h4>
        </div>
      </div>
      <EmpleadoForm formType="EDITAR" empleadosId={empleadosId}/>
    </div>
  )
}

export default EditarEmpleados;