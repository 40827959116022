import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileCsv,
  faFilePdf,
  faPen,
  faTrash,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Button, Card, Col, Row } from "@themesberg/react-bootstrap";
import moment from "moment-timezone";
import jsPDF from "jspdf";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  CSVExport,
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import Cargando from "../Utils/Loading";
import SweetAlert from "../Utils/SweetAlert";
import SweetAlertQuestion from "../Utils/SweetAlertQuestion";
import { facturaCompraPeticiones } from "./helper/facturaCompraPeticiones";
import { Paths } from "../../../config/router/paths";
import { useAuthContext } from "../../../contexts/authContext";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
const FacturaCompraLista = () => {
  const { logout, token: Token } = useAuthContext();
  const [consulta, setConsulta] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  const [listaFacturas, setListaFacturas] = useState([]);
  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  useEffect(() => {
    const consultarListaFacturaCompra = async () => {
      try {
        setShowLoading(true);
        const request = await facturaCompraPeticiones(
          "getListaFacturaCompra",
          Token,
          {}
        );
        const { status, ok } = request;
        if (status === 401) {
          logout();
          return false;
        }
        if (!ok) {
          throw new Error("Error de petición.");
        }
        const response = await request.json();
        const { data = [] } = response;
        setShowLoading(false);
        setListaFacturas(data);
        return true;
      } catch (error) {
        setShowLoading(false);
        SweetAlert(
          "Error",
          `Por favor contacte al administrador del sistema`,
          "error",
          true
        );
        return false;
      }
    };
    if (consulta) {
      setConsulta(false);
      consultarListaFacturaCompra();
    }
  }, [consulta, Token, logout]);
  const botonesOpciones = (cell, row, rowIndex) => {
    const eliminaFacturaHandler = async (e, row) => {
      const { target: button = null } = e;
      if (button) {
        button.disabled = true;
      }
      const preguntaEliminacionParametros = {
        tipo: "pregunta",
        titulo: "Advertencia",
        mensaje: "¿Esta seguro que desea eliminar este factura?",
      };
      const preguntaEliminacion = await SweetAlertQuestion(
        preguntaEliminacionParametros
      );
      const { isConfirmed = false } = preguntaEliminacion;
      if (!isConfirmed) {
        return false;
      }
      SweetAlert("Exito", `Factura eliminada con éxito`, "success", true);
      return true;
    };
    const editarFacturaHandler = (row) => {
      let rutaEditarFacturaCompraPath = Paths.EditarFacturaCompra.path.replace(
        ":id",
        row.facturaCompraId
      );
      return rutaEditarFacturaCompraPath;
    };
    const urlEditarTo = editarFacturaHandler(row);
    return (
      <div className="d-flex">
          <Link
            type="button"
            className="mx-1 btn-icon-only btn btn-info btn-sm"
            to={urlEditarTo}
          >
            <AiOutlineEdit size="1.2em" />
          </Link>
          <Button
            size="sm"
            variant="danger"
            onClick={(e) => eliminaFacturaHandler(e,row)}
            className="btn-icon-only"
          >
            <AiOutlineDelete size="1.2em" />
          </Button>
      </div>
    );
  };
  const formatearTotal = (valueCell, row, rowIndex) => `$${valueCell}`;

  const columnas = [
    { dataField: "facturaCompraIndex", text: "#", sort: true },
    { dataField: "facturaCompraCodigo", text: "Codigo", sort: true },
    {
      dataField: "facturaCompraId",
      text: "Opciones",
      sort: false,
      formatter: botonesOpciones,
    },
    { dataField: "facturaCompraFechaVencimiento", text: "Fecha", sort: true },
    {
      dataField: "facturaCompraFechaCreacion",
      text: "Fecha Creacion",
      sort: true,
    },
    { dataField: "proveedoresDescripcion", text: "Tipo", sort: true },
    {
      dataField: "facturaCompraTotal",
      text: "Total",
      sort: true,
      formatter: formatearTotal,
    },
  ];

  const generarPdfTabla = () => {};
  return (
    <div className="px-1 mt-4">
      <Row>
        <Col xs={12}>
          <Card border="light" className="bg-white shadow-sm mb-4">
            <Card.Body className="mb-2">
              <Cargando loading={showLoading} />
              <ToolkitProvider
                keyField="facturaCompraId"
                data={listaFacturas}
                columns={columnas}
                search
                exportCSV
              >
                {(props) => {
                  return (
                    <>
                      <Row className="my-4">
                        <Col md={6} xs={12}>
                          <label>Buscar Factura</label>
                          <SearchBar
                            {...props.searchProps}
                            className="my-2 w-40"
                          />
                        </Col>
                        <Col md={6} xs={12}>
                          <div className="w-100 d-flex justify-content-end">
                            <ExportCSVButton
                              {...props.csvProps}
                              className="btn btn-tertiary btn-sm my-2"
                            >
                              <FontAwesomeIcon icon={faFileCsv} /> CSV
                            </ExportCSVButton>
                            <Button
                              className="mx-2 my-2"
                              size="sm"
                              variant="danger"
                              onClick={generarPdfTabla}
                            >
                              <FontAwesomeIcon icon={faFilePdf} /> PDF{" "}
                            </Button>
                          </div>
                        </Col>
                      </Row>
                      <BootstrapTable
                        tableHeaderClass="table-header"
                        wrapperClasses="table-responsive"
                        bordered={false}
                        hover
                        align="center"
                        tabIndexCell
                        paginationFactory={paginationFactory()}
                        noDataIndication="No existen facturas registradas."
                        {...props.baseProps}
                      />
                    </>
                  );
                }}
              </ToolkitProvider>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
export default FacturaCompraLista;
