import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
} from "@themesberg/react-bootstrap";
import { makePetition } from "../../../../helpers/petition";
import { ErrorAlert } from "../../Utils/ErrorAlert";
import { SuccessAlert } from "../../Utils/SuccessAlert";
import onlyLettersAndNumbers from "../../Utils/onlyLettersAndNumbers";
import { useAuthContext } from "../../../../contexts/authContext";

const NuevaCategoria = (props) => {
  const { logout, token: Token } = useAuthContext();
  const {
    mostrarAgregarCategoria,
    setMostrarAgregarCategoria,
    setConsultarCategorias,
  } = props;
  const alertTextInitial = {
    title: "",
    message: "",
  };
  const [categoria, setCategoria] = useState("");
  const [alertVisible, setAlertVisible] = useState(false);
  const [desactivarBotonGuardar, setDesactivarBotonGuardar] = useState(false);
  const [alertText, setAlertText] = useState(alertTextInitial);
  const [alertSuccessVisible, setAlertSuccessVisible] = useState(false);
  const [alertSuccessText, setAlertSuccessText] = useState(alertTextInitial);
  const handleCategoria = ({ target }) => {
    const { value } = target;
    setCategoria(value);
  };
  useEffect(() => {
    if (!mostrarAgregarCategoria) {
      setAlertSuccessVisible(false);
      setAlertVisible(false);
      setAlertText({
        title: "",
        message: "",
      });
      setAlertSuccessText({
        title: "",
        message: "",
      });
      setCategoria("");
    }
  }, [mostrarAgregarCategoria]);

  const guardarCategoria = async (e) => {
    try {
      e.preventDefault();
      setAlertSuccessVisible(false);
      setAlertVisible(false);
      if (categoria.trim().length <= 2) {
        setAlertText({
          title: "Error",
          message: "La descripción de categoria debe ser mayor a 2 caracteres.",
        });
        setAlertVisible(true);
        return false;
      }
      setDesactivarBotonGuardar(true);
      const formData = {
        categoriaDescripcion: categoria,
      };
      const urlTarget = "categorias/nueva";
      const petitionMethod = "POST";
      const request = await makePetition(
        urlTarget,
        formData,
        petitionMethod,
        Token
      );
      const { status, ok } = request;
      if (status === 401) {
        logout();
        return false;
      }
      if (!ok) {
        setAlertText({
          title: "Error",
          message:
            "Ocurrió un error inesperado en la peticion, por favor contacte con el administrador.",
        });
        setAlertVisible(true);
        setDesactivarBotonGuardar(false);
        return false;
      }
      const response = await request.json();
      let { success = false, message = "Ocurrio un error inesperado.", data = {} } = response;
      if (!success) {
        setAlertText({ title: "Error", message: message });
        setAlertVisible(true);
        setDesactivarBotonGuardar(false);
        return false;
      }
      setCategoria("");
      setAlertSuccessText({ title: "Exito", message: message });
      setAlertSuccessVisible(true);
      setDesactivarBotonGuardar(false);
      setTimeout(() => {
        setAlertSuccessVisible(false);
      }, 3000);
      setConsultarCategorias(true);
      return true;
    } catch (error) {
      console.error(error);
      setAlertText({
        title: "Error",
        message: "Ocurrió un error inexperado, por favor contacta con soporte",
      });
      setAlertVisible(true);
      setDesactivarBotonGuardar(false);
    }
  };
  return (
    <>
      <Modal
        as={Modal.Dialog}
        centered
        show={mostrarAgregarCategoria}
        onHide={() => setMostrarAgregarCategoria(false)}
        backdrop="static"
        keyboard={false}
        size="md"
      >
        <Container>
          <Modal.Header className="text-center">
            <Button
              variant="close"
              aria-label="Close"
              onClick={() => setMostrarAgregarCategoria(false)}
            />
          </Modal.Header>
          <Form onSubmit={guardarCategoria}>
            <Modal.Body>
              <Row>
                <Col xs={12}>
                  <h3>Nueva Categoria</h3>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Form.Group className="mb-4">
                    <Form.Label></Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Ingrese el nombre de la categoria"
                      id="categoria"
                      name="categoria"
                      value={categoria}
                      onChange={handleCategoria}
                      size="md"
                      onKeyPress={(event) =>
                        event.target.value.toString().length > 98
                          ? event.preventDefault()
                          : onlyLettersAndNumbers(event)
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Col xs={12}>
                {alertVisible ? (
                  <ErrorAlert
                    title={alertText.title}
                    message={alertText.message}
                  />
                ) : null}
                {alertSuccessVisible ? (
                  <SuccessAlert
                    title={alertSuccessText.title}
                    message={alertSuccessText.message}
                  />
                ) : null}
              </Col>
              <Button
                size="md"
                variant="outline-primary"
                className="w-100 mt-4"
                onClick={guardarCategoria}
                disabled={desactivarBotonGuardar}
              >
                Guardar
              </Button>
              <Button
                size="md"
                variant="danger"
                className="w-100 mt-1"
                onClick={() => setMostrarAgregarCategoria(false)}
                disabled={desactivarBotonGuardar}
              >
                Cancelar
              </Button>
            </Modal.Footer>
          </Form>
        </Container>
      </Modal>
    </>
  );
};

export default NuevaCategoria;
