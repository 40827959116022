import React, { useEffect, useState } from "react";
import FileBase64 from "react-file-base64";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card } from "@themesberg/react-bootstrap";
import { guardarUsuariosImagenesRequest } from "../helper/usuariosPeticiones";
import { useAuthContext } from "../../../../contexts/authContext";
import Cargando from "../../Utils/Loading";
import SweetAlert from "../../Utils/SweetAlert";
import { useNavigate } from "react-router-dom";
const CambiarFotoPerfil = ({ usuariosId }) => {
  const { token: Token, logout } = useAuthContext();
  const [imagenPerfil, setImagenPerfil] = useState({});
  const [showLoading, setShowLoading] = useState(false);
  const [cambiarPerfil, setCambiarPerfil] = useState(false);
  const navegacion = useNavigate();
  const tipoImagenesPermitidas = [
    "image/apng",
    "image/avif",
    "image/gif",
    "image/jpeg",
    "image/png",
    "image/svg+xml",
    "image/webp",
  ];
  const handleImageFrontal = (files) => {
    const { name, type, size, base64 } = files;
    if (!tipoImagenesPermitidas.includes(type)) {
      return false;
    }
    setImagenPerfil({ name, type, size, base64 });
    setCambiarPerfil(true);
    return;
  };
  useEffect(() => {
    const cambiarImagePerfilHandle = async () => {
      try {
        setShowLoading(true);
        const { name: imagenNameFrontal, base64: imagenBase64Frontal } =
          imagenPerfil;
        const request = await guardarUsuariosImagenesRequest(
          usuariosId,
          "usuariosImagenFrontal",
          imagenBase64Frontal,
          imagenNameFrontal,
          Token
        );
        const { status, ok } = request;
        if (status === 401) {
          setShowLoading(false);
          logout();
          return false;
        }
        if (!ok) {
          throw new Error("Error de petición.");
        }
        const response = await request.json();
        const { success, message, data = {} } = response;
        if (!success) {
          setShowLoading(false);
          SweetAlert("Error", `${message}`, "error", true);
          return false;
        }        
        SweetAlert("Exito", `${message}`, "success", true);
        setTimeout(() => {
          navegacion(0);
        }, 400);
      } catch (error) {
        console.error(error);
        setShowLoading(false);
        SweetAlert(
          "Error",
          `Ocurrió un error inesperado, por favor contacte con el administrador.`,
          "error",
          true
        );
      }
    };
    if (cambiarPerfil) {
      setCambiarPerfil(false);
      cambiarImagePerfilHandle();
    }
  }, [cambiarPerfil, Token, logout, usuariosId, imagenPerfil,navegacion]);

  return (
    <>
      <Card border="light" className="bg-white shadow-sm mb-4">
        <Card.Body>
          <h5 className="mb-4">Cambia tu foto de perfil</h5>
          <div className="d-xl-flex align-items-center">
            <div className="file-field">
              <div className="d-flex justify-content-xl-center ms-xl-3">
                <div className="d-flex">
                  <span className="icon icon-md">
                    <FontAwesomeIcon icon={faPaperclip} className="me-3" />
                  </span>
                  <FileBase64 onDone={handleImageFrontal} />
                  <div className="d-md-block text-start">
                    <div className="fw-normal text-dark mb-1">
                      Escoge una imagen
                    </div>
                    <div className="text-gray small">
                      JPG, GIF or PNG. Tamaño maximo de 1MB
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
      <Cargando loading={showLoading} />
    </>
  );
};

export default CambiarFotoPerfil;
