import React, { useEffect, useState } from "react";
import SweetAlert from "../../Utils/SweetAlert";
import SweetAlertQuestion from "../../Utils/SweetAlertQuestion";
import jsPDF from "jspdf";
import { Button, Card, Col, Row } from "@themesberg/react-bootstrap";
import Cargando from "../../Utils/Loading";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  CSVExport,
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileCsv,
  faFilePdf,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";

import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { categoriasPeticiones } from "../helper/peticionesCategorias";
import NuevaCategoria from "./NuevaCategoria";
import EditarCategoria from "./EditarCategoria";
import { useAuthContext } from "../../../../contexts/authContext";
import moment from "moment-timezone";

const CategoriasListas = () => {
  const { logout, token: Token } = useAuthContext();
  const [consultarCategorias, setConsultarCategorias] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  const [listaCategorias, setListaCategorias] = useState([]);
  //const [consultarCategorias, setConsultarCategorias] = useState(true);
  const [mostrarAgregarCategoria, setMostrarAgregarCategoria] = useState(false);
  const [categoriaIdSeleccionada, setCategoriaIdSeleccionada] = useState(0);
  const [mostrarEditarCategoria, setMostrarEditarCategoria] = useState(false);
  const [categoriaActualizadaHandle, setCategoriaActualizadaHandle] =
    useState(false);
  useEffect(() => {
    if (consultarCategorias) {
      setConsultarCategorias(false);
      (async () => {
        try {
          setShowLoading(true);
          const request = await categoriasPeticiones(
            "obtenerListaCategorias",
            Token
          );
          setShowLoading(false);
          const { status, ok } = request;
          if (status === 401) {
            logout();
            return false;
          }
          if (!ok) {
            throw new Error("Error de petición.");
          }
          const response = await request.json();
          const { success, message = "", data = [] } = response;
          if (!success || data.length <= 0) {
            setListaCategorias([]);
            return false;
          }
          setListaCategorias(data);
          return true;
        } catch (error) {
          setShowLoading(false);
          SweetAlert(
            "Error",
            `Por favor contacte al administrador del sistema`,
            "error",
            true
          );
          return false;
        }
      })();
    }
  }, [consultarCategorias, Token, logout]);
  useEffect(() => {
    if (categoriaActualizadaHandle) {
      setCategoriaIdSeleccionada(0);
      setCategoriaActualizadaHandle(false);
      setConsultarCategorias(true);
    }
  }, [categoriaActualizadaHandle, setCategoriaActualizadaHandle]);

  const handlerEditarCategoria = async (categoriasDatos) => {
    let { categoriaId } = categoriasDatos;
    categoriaId = parseInt(categoriaId);
    setCategoriaIdSeleccionada(categoriaId);
    setMostrarEditarCategoria(true);
  };
  const handlerEliminarCategoria = async (categoriasDatos) => {
    let { categoriaId = 0 } = categoriasDatos;
    categoriaId = parseInt(categoriaId);
    if (categoriaId < 1) {
      SweetAlert(
        "Error",
        `No se puede identificar la categoria que desea eliminar`,
        "error",
        true
      );
    }
    const preguntaEliminacionParametros = {
      tipo: "pregunta",
      titulo: "Advertencia",
      mensaje: "¿Esta seguro que desea eliminar este categoria?",
    };

    const preguntaEliminacion = await SweetAlertQuestion(
      preguntaEliminacionParametros
    );
    const { isConfirmed = false } = preguntaEliminacion;
    if (!isConfirmed) {
      return false;
    }
    setShowLoading(true);
    const request = await categoriasPeticiones("eliminarCategoria", Token, {
      categoriaId,
    });
    setShowLoading(false);
    const { status, ok } = request;
    if (status === 401) {
      logout();
      return false;
    }
    if (!ok) {
      throw new Error("Error de petición.");
    }
    const response = await request.json();
    const { success = false, message = "" } = response;
    if (!success) {
      SweetAlert("Error", `${message}`, "error", true);
      return false;
    }
    SweetAlert("Exito", `${message}`, "success", true);
    setConsultarCategorias(true);
    return true;
  };
  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const generarPdfTabla = () => {
    const fechaActual = () => {
      return moment().utcOffset("-06:00").format("DD-MM-YYYY");
    };
    const fechaActualCompleta = () => {
      return moment().utcOffset("-06:00").format("DD-MM-YYYY-HH-mm-ss");
    };
    const doc = new jsPDF({ orientation: "landscape" });
    doc.text(`Lista de Categorias ${fechaActual()}`, 16, 10);
    doc.autoTable({
      head: [
        [
          "#",
          "Categoria",
          "Usuario Creo",
          "Fecha Creo",
          "Usuario Actualizo",
          "Fecha Actualizo",
        ],
      ],
      body: listaCategorias.map((categoria, index) => [
        categoria.categoriaIndex,
        categoria.categoriaDescripcion,
        categoria.categoriaNombreUsuarioCreo,
        categoria.categoriaFechaCreacion,
        categoria.categoriaNombreUsuarioActualizo,
        categoria.categoriaFechaEdicion,
      ]),
    });
    doc.save(`Lista-Categorias-${fechaActualCompleta()}.pdf`);
  };

  const botonesOpciones = (cell, row, rowIndex) => {
    return (
      <div className="d-flex">
        <Button
          size="sm"
          variant="info"
          onClick={() => handlerEditarCategoria(row)}
          className="mx-1 btn-icon-only"
        >
          <AiOutlineEdit size="1.2em" />
        </Button>
        <Button
          size="sm"
          variant="danger"
          onClick={() => handlerEliminarCategoria(row)}
          className="btn-icon-only"
        >
          <AiOutlineDelete size="1.2em" />
        </Button>
      </div>
    );
  };
  const formatearFecha = (fecha) => {
    const nuevaFecha = new Date(fecha);
    if (isNaN(nuevaFecha.getTime()) || nuevaFecha.getTime() === 0) {
      return "";
    } else {
      return new Date(fecha).toLocaleDateString();
    }
  };
  const columns = [
    { dataField: "categoriaIndex", text: "#", sort: true },
    {
      dataField: "categoriaId",
      text: "Opciones",
      sort: false,
      formatter: botonesOpciones,
    },
    { dataField: "categoriaDescripcion", text: "Categoria", sort: true },
    {
      dataField: "categoriaNombreUsuarioCreo",
      text: "Usuario Creo",
      sort: true,
    },
    {
      dataField: "categoriaFechaCreacion",
      text: "Fecha Creo",
      sort: true,
      formatter: formatearFecha,
    },
    {
      dataField: "categoriaNombreUsuarioActualizo",
      text: "Usuario Actualizo",
      sort: true,
    },
    {
      dataField: "categoriaFechaEdicion",
      text: "Fecha Actualizo",
      sort: true,
      formatter: formatearFecha,
    },
  ];
  return (
    <div className="px-1">
      <Card>
        <div className="pagesCustomParent p-4">
          <Cargando loading={showLoading} />
          <div>
            <ToolkitProvider
              keyField="categoriaId"
              data={listaCategorias}
              columns={columns}
              search
              exportCSV
            >
              {(props) => (
                <>
                  <Row>
                    <Col md={6} sm={12}>
                      <label>Buscar Categoria:</label>
                      <SearchBar {...props.searchProps} className="my-2 w-40" />
                    </Col>
                    <Col
                      md={6}
                      sm={12}
                      className="d-flex align-items-end justify-content-end"
                    >
                      <Button
                        className="mx-2 my-2"
                        size="sm"
                        variant="info"
                        onClick={() => setMostrarAgregarCategoria(true)}
                      >
                        <FontAwesomeIcon icon={faPlus} /> Nueva Categoria
                      </Button>
                      <ExportCSVButton
                        {...props.csvProps}
                        className="btn btn-tertiary btn-sm my-2"
                      >
                        <FontAwesomeIcon icon={faFileCsv} /> CSV
                      </ExportCSVButton>
                      <Button
                        className="mx-2 my-2"
                        size="sm"
                        variant="danger"
                        onClick={generarPdfTabla}
                      >
                        <FontAwesomeIcon icon={faFilePdf} /> PDF
                      </Button>
                    </Col>
                  </Row>

                  <BootstrapTable
                    tableHeaderClass="table"
                    wrapperClasses="table-responsive"
                    headerClasses=""
                    hover
                    bordered={false}
                    search={{}}
                    align="center"
                    tabIndexCell
                    pagination={paginationFactory()}
                    noDataIndication="No existen categorias   registrados"
                    {...props.baseProps}
                  ></BootstrapTable>
                </>
              )}
            </ToolkitProvider>
          </div>
        </div>
      </Card>
      {mostrarAgregarCategoria && (
        <NuevaCategoria
          mostrarAgregarCategoria={mostrarAgregarCategoria}
          setMostrarAgregarCategoria={setMostrarAgregarCategoria}
          setConsultarCategorias={setConsultarCategorias}
        />
      )}
      {mostrarEditarCategoria && categoriaIdSeleccionada > 0 && (
        <EditarCategoria
          categoriaId={categoriaIdSeleccionada}
          mostrarEditarCategoria={mostrarEditarCategoria}
          setMostrarEditarCategoria={setMostrarEditarCategoria}
          categoriaActualizadaHandle={setCategoriaActualizadaHandle}
        />
      )}
    </div>
  );
};

export default CategoriasListas;
