import { useAuthContext } from "../../../contexts/authContext";
import {
  faFileCsv,
  faFilePdf,
  faPen,
  faTrash,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Col, Container, Modal, Row } from "@themesberg/react-bootstrap";
import jsPDF from "jspdf";
import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  CSVExport,
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import Cargando from "../Utils/Loading";
import moment from "moment-timezone";
import SweetAlert from "../Utils/SweetAlert";
import SweetAlertQuestion from "../Utils/SweetAlertQuestion";
import { getFacturaLista, anularFactura } from "./helper/facturaPeticiones";
import { useNavigate } from "react-router-dom";
import { petitionImageToBase64,makePetition } from "../../../helpers/petition";
const FacturaLista = () => {
  const { logout, token: Token } = useAuthContext();
  const [consulta, setConsulta] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  const [listaFacturas, setListaFacturas] = useState([]);
  const [mostrarModalPdf, setMostrarModalPdf] = useState(false);
  const [pdfUrlFactura, setPdfUrlFactura] = useState(false);
  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const history = useNavigate();
  useEffect(() => {
    if (consulta) {
      setConsulta(false);
      (async () => {
        try {
          setShowLoading(true);
          const request = await getFacturaLista(Token);
          setShowLoading(false);
          const { status, ok } = request;
          if (status === 400) {
            //logout();
            return;
          }
          if (!ok) {
            throw new Error("Error en peticion");
          }
          const response = await request.json();
          const { data = [] } = response;
          const nuevosDatos = data.map((factura) => {
            factura = {
              ...factura,
              facturaProductosIdParaPDF: factura.facturaProductosId,
            };
            return factura;
          });
          setListaFacturas(nuevosDatos);
          return true;
        } catch (error) {
          setShowLoading(false);
          SweetAlert(
            "Error",
            `Por favor contacte al administrador del sistema`,
            "error",
            true
          );
          return false;
        }
      })();
    }
  }, [consulta, Token, logout]);
  const fechaActual = () => {
    return moment().utcOffset("-06:00").format("DD-MM-YYYY");
  };
  const fechaActualCompleta = () => {
    return moment().utcOffset("-06:00").format("DD-MM-YYYY-HH-mm-ss");
  };
  const generarPdfTabla = () => {
    const doc = new jsPDF({ orientation: "landscape" });
    doc.text(`Lista de Factura Ventas ${fechaActual()}`, 16, 18);
    doc.autoTable({ html: "#facturaVentasLista" });
    doc.autoTable({
      head: [
        [
          "#",
          "Codigo",
          "Fecha Vencimiento",
          "Fecha",
          "Tipo",
          "Factura Total",
          "Cliente Nombre",
          "Cliente Email",
          "Cliente Tel.",
        ],
      ],
      body: listaFacturas.map((factura, index) => [
        factura.facturaIndex,
        factura.facturaProductosId,
        factura.facturaProductosFechaVencimiento,
        factura.facturaProductosFechaCreacion,
        factura.facturaTipo,
        factura.facturaProductosTotal,
        factura.clientesNombres,
        factura.clientesEmail,
        factura.clientesTelefono,
      ]),
      styles: { fontSize: 8 },
      margin: { top: 10 },
    });
    doc.save(`Factura-Venta-Lista-${fechaActualCompleta()}.pdf`);
  };
  const botonesDeAdministracionHtmlYHandles = (cell, row, rowIndex) => {
    let {
      facturaProductosId,
      facturaVentaAnulada = 0,
      facturaVentaRazonAnulacion = "",
    } = row;
    facturaProductosId = parseInt(facturaProductosId);
    facturaVentaAnulada = parseInt(facturaVentaAnulada);
    if (isNaN(facturaProductosId)) {
      SweetAlert(
        "Error",
        `No se puede identificar la factura seleccionada. Por favor contacte al administrador del sistema.`,
        "error",
        true
      );
      return false;
    }
    const anularFacturaHandler = async (facturaProductosId, event) => {
      try {
        const popupHtml = (
          <>
            <h3>Advertencia</h3>
            <h4>¿Esta seguro que desea eliminar este factura?</h4>
            <div className="form-group mt-2">
              <label htmlFor="razonAnulacion">Razón de Anulación</label>
              <textarea
                type="text"
                className="form-control mt-2"
                id="razonAnulacion"
                name="razonAnulacion"
                rows={3}
                cols={50}
                maxLength={190}
              ></textarea>
            </div>
          </>
        );
        let razonAnulacion = "";
        const preguntaEliminacionParametros = {
          tipo: "pregunta",
          textoBotonConfirmar: "Confirmar",
          textoBotonDenegar: "Cancelar",
          mostrarBotonConfirmar: true,
          mostrarBotonDenegar: true,
          htmlPersonalizado: popupHtml,
          functionCallback: () => {
            if (document.getElementById("razonAnulacion")) {
              document
                .getElementById("razonAnulacion")
                .addEventListener("input", (e) => {
                  const { target: input = null } = e;
                  razonAnulacion = input?.value || "";
                });
            }
          },
        };
        const preguntaEliminacion = await SweetAlertQuestion(
          preguntaEliminacionParametros
        );
        const { isConfirmed = false } = preguntaEliminacion;
        if (!isConfirmed) {
          return false;
        }
        const peticionAnularFactura = await anularFactura(
          facturaProductosId,
          Token,
          razonAnulacion
        );
        const { status, ok } = peticionAnularFactura;
        if (status === 401) {
          logout();
          return false;
        }
        if (!ok) {
          throw new Error("Error de petición.");
        }
        const response = await peticionAnularFactura.json();
        const { success = false, message = "" } = response;
        if (!success) {
          SweetAlert("Error", message, "error", true);
          return false;
        }
        setConsulta(true);
        SweetAlert("Exito", `Factura anulada con éxito.`, "success", true);
        return true;
      } catch (error) {}
    };
    const facturaAnuladaInfo = (razonAnulacion, event) => {
      const popupHtml = (
        <>
          <h3>Información</h3>
          <h4>Esta factura se encuentra anulada</h4>
          <div className="form-group mt-2">
            <label htmlFor="razonAnulacion">Razón de Anulación</label>
            <textarea
              type="text"
              className="form-control mt-2"
              id="razonAnulacion"
              name="razonAnulacion"
              rows={3}
              cols={50}
              maxLength={190}
              disabled
            >
              {razonAnulacion}
            </textarea>
          </div>
        </>
      );

      const preguntaEliminacionParametros = {
        tipo: "informacion",
        textoBotonConfirmar: "Ok",
        textoBotonDenegar: "Cancelar",
        mostrarBotonConfirmar: true,
        mostrarBotonDenegar: false,
        htmlPersonalizado: popupHtml,
      };
      SweetAlertQuestion(preguntaEliminacionParametros);
    };
    // const editarFacturaHandler = (row) => `/EditarFactura/${row.facturaProductosId}`;
    // const urlEditarTo = editarFacturaHandler(row);
    return (
      <div className="d-flex justify-content-center">
        {/* <Link type="button" className="me-2 btn btn-outline-primary btn-sm" to={urlEditarTo}>
          <FontAwesomeIcon icon={faPen} className="me-2" /> Editar
        </Link> */}
        {facturaVentaAnulada === 1 ? (
          <Button
            variant="info"
            size="sm"
            className="me-2"
            onClick={(e) => facturaAnuladaInfo(facturaVentaRazonAnulacion, e)}
          >
            <FontAwesomeIcon icon={faInfoCircle} className="me-2" /> Anulada
          </Button>
        ) : (
          <Button
            variant="danger"
            size="sm"
            className="me-2"
            onClick={(e) => anularFacturaHandler(facturaProductosId, e)}
          >
            <FontAwesomeIcon icon={faTrash} className="me-2" /> Anular
          </Button>
        )}
      </div>
    );
  };
  /**
   * 
   * @param {string} variableArchivo 
   * @returns 
   */
  // const esArchivoPDF = (variableArchivo)=> {
  //   // Verificar si la variable es de tipo File
  //   if (variableArchivo instanceof File) {
  //     // Obtener el tipo MIME del archivo
  //     const tipoMIME = variableArchivo.type;
  
  //     // Verificar si el tipo MIME coincide con el tipo de archivo PDF
  //     return tipoMIME === 'application/pdf';
  //   }
  
  //   return false; // La variable no es un archivo o no es un archivo PDF
  // }
  const botonAdministracionFacturaPdfInfo = (facturaId, row) => {
    // let host = window.location.hostname;
    // host = host === "localhost" ? "127.1.1" : host;
    // const payload = `//${host}/archivos/facturaVentas/13`;
    const payload = `archivos/facturaVentas/${facturaId}`;
    const obtenerPDF = async (url, token) => {
      const formData = {};
      const urlTarget = url;
      const petitionMethod = "GET";
      const petition = await petitionImageToBase64(
        urlTarget,
        formData,
        petitionMethod,
        token,
        "PDF"
      );
      if(typeof petition === 'object' && petition !== null && !Array.isArray(petition)){
        const {message = "Ocurrió un error inesperado al generar el pdf de la factura, por favor contacte con el administrador."} = petition;
        SweetAlert("Error", message, "error", true);
        return false;
      } 
      setMostrarModalPdf(true);
      setPdfUrlFactura(petition);
      // const a = document.createElement('a');
      // a.href = petition;
      // a.download = 'nombre_del_archivo.pdf'; // Cambia 'nombre_del_archivo.pdf' al nombre deseado del archivo para descargar
      // a.click();
      
    }
    // return (
    //   <>
    //     <a
    //       href={payload}
    //       target="_blank"
    //       rel="noreferrer"
    //       className="me-2 btn btn-info btn-sm"
    //     >
    //       <FontAwesomeIcon icon={faFilePdf} className="me-2" /> PDF
    //     </a>
    //   </>
    // );
    return (
      <>
        <Button
            variant="info"
            size="sm"
            className="me-2"
            onClick={()=>obtenerPDF(payload,Token)}
          >
            <FontAwesomeIcon icon={faFilePdf} className="me-2" /> PDF
          </Button>
      </>
    );
  };
  const imprimirTipoFactura = (tipoFactura) => {
    tipoFactura = parseInt(tipoFactura);
    switch (tipoFactura) {
      case 1:
        return "Credito Fiscal";
      case 2:
        return "Contado";
      default:
        return "Factura";
    }
  };
  const imprimirTotalFactura = (totalFactura) => {
    // if nan
    totalFactura = Number(totalFactura);
    if (isNaN(totalFactura)) {
      totalFactura = 0;
    }
    return `$${Number(totalFactura)}`;
  };
  const imprimirFechaFactura = (fechaFactura) => {
    const dateFormated = moment(fechaFactura).format("DD-MM-YYYY");
    return dateFormated === "Invalid date" ? "N/A" : dateFormated;
  };
  const columnas = [
    {
      dataField: "facturaIndex",
      text: "N°",
      sort: true,
    },
    {
      dataField: "facturaProductosCodigo",
      text: "Codigo",
      sort: true,
    },
    {
      dataField: "facturaProductosId",
      text: "Estado",
      sort: false,
      formatter: botonesDeAdministracionHtmlYHandles,
    },
    {
      dataField: "facturaProductosFechaVencimiento",
      text: "Fecha Vencimiento",
      sort: true,
      formatter: imprimirFechaFactura,
    },
    {
      dataField: "facturaProductosFechaCreacion",
      text: "Fecha Creacion",
      sort: true,
      formatter: imprimirFechaFactura,
    },
    {
      dataField: "facturaTipo",
      text: "Tipo Factura",
      sort: true,
      formatter: imprimirTipoFactura,
    },
    {
      dataField: "facturaProductosTotal",
      text: "Total",
      sort: true,
      formatter: imprimirTotalFactura,
    },
    {
      dataField: "clientesNombres",
      text: "Cliente",
      sort: true,
    },
    {
      dataField: "clientesEmail",
      text: "Cliente Email",
      sort: true,
    },
    {
      dataField: "clientesTelefono",
      text: "Cliente Teléfono",
      sort: true,
    },
    {
      dataField: "facturaProductosIdParaPDF",
      text: "Imprimir",
      formatter: botonAdministracionFacturaPdfInfo,
    },
  ];
  return (
    <div className="px-1 mt-4">
      <Row>
        <Col xs={12}>
          <Card border="light" className="bg-white shadow-sm mb-4">
            <Card.Body className="mb-2">
              <Cargando loading={showLoading} />
              <ToolkitProvider
                keyField="facturaProductosId"
                data={listaFacturas}
                columns={columnas}
                search
                exportCSV
              >
                {(props) => {
                  return (
                    <>
                      <Row className="my-4">
                        <Col md={6} xs={12}>
                          <label>Buscar Factura</label>
                          <SearchBar
                            {...props.searchProps}
                            className="my-2 w-40"
                          />
                        </Col>
                        <Col md={6} xs={12}>
                          <div className="w-100 d-flex justify-content-end">
                            <ExportCSVButton
                              {...props.csvProps}
                              className="btn btn-tertiary btn-sm my-2"
                            >
                              <FontAwesomeIcon icon={faFileCsv} /> CSV
                            </ExportCSVButton>
                            <Button
                              className="mx-2 my-2"
                              size="sm"
                              variant="danger"
                              onClick={generarPdfTabla}
                            >
                              <FontAwesomeIcon icon={faFilePdf} /> PDF{" "}
                            </Button>
                          </div>
                        </Col>
                      </Row>
                      <BootstrapTable
                        tableHeaderClass="table-header"
                        wrapperClasses="table-responsive"
                        //bordered={false}
                        hover
                        align="center"
                        tabIndexCell
                        paginationFactory={paginationFactory()}
                        noDataIndication="No existen facturas registradas."
                        {...props.baseProps}
                      />
                    </>
                  );
                }}
              </ToolkitProvider>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal
        as={Modal.Dialog}
        show={mostrarModalPdf}
        onHide={() => setMostrarModalPdf(false)}
        centered
        backdrop="static"
        keyboard={false}
        size="xl"
        dialogClassName="modal-90w"
      >
        <div>
          <Modal.Header className="text-center">
            <Button
              variant="close"
              aria-label="Close"
              onClick={() => setMostrarModalPdf(false)}
            />
          </Modal.Header>
          
            <Modal.Body style={{height: "100vh"}}> 
                <iframe 
                  title={'pdf'} 
                  src={pdfUrlFactura}
                  height="100%"
                  width="100%"
                />
            </Modal.Body>
            <Modal.Footer>
              
            </Modal.Footer>
        </div>
      </Modal>
    </div>
  );
};
export default FacturaLista;
