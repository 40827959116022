import React from 'react';
import { Alert } from '@themesberg/react-bootstrap';
export const SuccessAlert = ({ title, message, referencia }) => {
  return (
    <div ref={referencia}>
      <Alert variant="success">
        <Alert.Heading>{title}</Alert.Heading>
        <p>
          {message}
        </p>
      </Alert>
    </div>
  );
};
