import { Form } from '@themesberg/react-bootstrap';
import React, { useEffect, useState } from 'react'
import { makePetition } from '../../../../helpers/petition';
import { marcasPeticiones } from '../helper/peticionesMarcas';
import { useAuthContext } from '../../../../contexts/authContext';

export const MarcasSelect = ({
    marcasId:marcasIdValue,
    handleChange,
    touched,
    errors,
    setAlertText,
    setAlertVisible,
    consultarMarcas:getMarcas,
    setConsultarMarcas:setGetMarcas,
    setFieldValue=()=>{},
    marcaIdModalState = 0,
    setMarcaIdModalState = ()=>{},
}) => {
    //const [getMarcas, setGetMarcas] = useState(false);
    const [marcasData, setMarcasData] = useState([]);
    const { logout, token: Token } = useAuthContext();
    useEffect(() => {
        const obtenerMarcas = async () => {
            const request = await marcasPeticiones("obtenerListaMarcas",Token);
            const {status,ok} = request;
            if(status===401){
                logout();
                return false;
            }
            if(!ok){
                setGetMarcas(true);
                setAlertText({ title: "Error", message: "Ocurrió un error inesperado al consultar las marcas." })
                setAlertVisible(true);
                return false;
            }
            const response = await request.json();
            const { success, message = "", data = [] } = response;
            if (!success) {
                setGetMarcas(true);
                setAlertText({ title: "Error", message: message })
                setAlertVisible(true);
                return false;
            }
            setGetMarcas(false);
            setMarcasData(data);
            if(marcaIdModalState>0){
                setFieldValue("marcasId",marcaIdModalState,true);
                setMarcaIdModalState(0);
            }
            return true;

        }
        if (getMarcas) {
            obtenerMarcas();
        }
    }, [getMarcas, setAlertText, setAlertVisible, setGetMarcas, setMarcasData,marcaIdModalState,setFieldValue,setMarcaIdModalState,Token,logout]);
    
    return (
        <>
            <Form.Select
                aria-label="Selecction de marcas"
                id="marcasId"
                name="marcasId"
                value={marcasIdValue}
                onChange={handleChange}
                isValid={touched.marcasId && !errors.marcasId}
            >
                <option value="0">Seleccione una marca de producto</option>
                {marcasData.map((branch) => <option value={branch.marcasId} key={branch.marcasId}>{branch.marcasDescripcion}</option>)}
            </Form.Select>
        </>
    )
}

export const MarcaListaSelect = React.memo(MarcasSelect);