import { makePetition } from "../../../../helpers/petition";

const fetchProveedor = async (requesUrl, requestMethod, requestData, token) => {
  try {
    const request = await makePetition(
      requesUrl,
      requestData,
      requestMethod,
      token
    );
    return request;
  } catch (error) {
    return {
      success: false,
      message:
        "Ocurrió un error inexperado, por favor vuelva a intentar. Si este error persiste contacte al administrador del sistema",
    };
  }
};
const proveedorPeticiones = async (method, token, requestData = {}) => {
  switch (method) {
    case "getListaProveedor":
      return await fetchProveedor(`proveedores/lista`, "GET", requestData, token);
    default:
      return {
        success: false,
        message:
          "Ocurrió un error inexperado, por favor vuelva a intentar. Si este error persiste contacte al administrador del sistema",
      };
  }
};

export { proveedorPeticiones };
