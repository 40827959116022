import React, { useEffect, useState } from "react";
import "jspdf-autotable";
import "../../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "../../../../node_modules/react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "../../../../node_modules/react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import jsPDF from "jspdf";
import { Button, Col, Row, Card } from "@themesberg/react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import SweetAlert from "../Utils/SweetAlert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faFileCsv } from "@fortawesome/free-solid-svg-icons";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { Link } from "react-router-dom";
import Cargando from "../Utils/Loading";
import SweetAlertQuestion from "../Utils/SweetAlertQuestion";
import {
  eliminarProveedores,
  obtenerListaProveedores,
} from "./helper/proveedoresPeticiones";
import { Paths } from "../../../config/router/paths";
import { useAuthContext } from "../../../contexts/authContext";
import moment from "moment-timezone";

const ProveedoresLista = () => {
  const { logout, token: Token } = useAuthContext();
  const [consulta, setConsulta] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  const [listaProveedores, setListaProveedores] = useState([]);
  useEffect(() => {
    const obtenerProveedores = async () => {
      try {
        setShowLoading(true);
        const request = await obtenerListaProveedores(Token);
        const { status, ok } = request;
        if (status === 401) {
          logout();
          return false;
        }
        if (!ok) {
          throw new Error("Error de petición.");
        }
        const response = await request.json();
        const { success, message = "", data = [] } = response;
        setShowLoading(false);
        if (!success || data.length <= 0) {
          setListaProveedores([]);
          SweetAlert("Error", `${message}`, "error", true);
          return false;
        }
        setListaProveedores(data);

        return true;
      } catch (error) {
        setShowLoading(false);
        SweetAlert(
          "Error",
          `Ocurrió un error inesperado. Por favor contacte al administrador del sistema`,
          "error",
          true
        );
        return false;
      }
    };
    if (consulta) {
      setConsulta(false);
      obtenerProveedores();
    }
  }, [consulta, Token, logout]);
  const fechaActual = () => {
    return moment().utcOffset("-06:00").format("DD-MM-YYYY");
  };
  const fechaActualCompleta = () => {
    return moment().utcOffset("-06:00").format("DD-MM-YYYY-HH-mm-ss");
  };
  const handlerEliminarProveedor = async (proveedoresDatos) => {
    try {
      let { proveedoresId: proveedorId } = proveedoresDatos;
      proveedorId = parseInt(proveedorId);
      if (proveedorId < 1) {
        SweetAlert(
          "Error",
          `No se puede identificar el proveedor que desea eliminar`,
          "error",
          true
        );
      }
      const preguntaEliminacionParametros = {
        tipo: "pregunta",
        titulo: "Advertencia",
        mensaje: "¿Esta seguro que desea eliminar este proveedor?",
      };
      const preguntaEliminacion = await SweetAlertQuestion(
        preguntaEliminacionParametros
      );
      const { isConfirmed = false } = preguntaEliminacion;
      if (!isConfirmed) {
        return false;
      }
      setShowLoading(true);
      const request = await eliminarProveedores(Token, proveedorId);
      const { status, ok } = request;
      if (status === 401) {
        logout();
        return false;
      }
      if (!ok) {
        throw new Error("Error de petición.");
      }
      const response = await request.json();
      const { success = false, message = "" } = response;
      setShowLoading(false);
      setConsulta(true);
      if (!success) {
        SweetAlert("Error", `${message}`, "error", true);
        return false;
      }
      SweetAlert("Exito", `${message}`, "success", true);
      return true;
    } catch (error) {
      SweetAlert(
        "Error",
        `Ocurrió un error inesperado al soliticar la eliminacion del proveedor. Por favor contacte con el administrado.`,
        "error",
        true
      );
    }
  };
  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  /**
   *
   * Genera pdf de exportacion
   *
   */
  const generarPdfTabla = () => {
    const doc = new jsPDF({ orientation: "landscape" });
    doc.text(`Lista de Proveedores ${fechaActual()}`, 16, 18);
    doc.autoTable({ html: "#proveedoresTabla" });
    doc.autoTable({
      head: [
        ["#", "Descripcion", "Direccion", "Telefono", "Email", "NIT", "NIC"],
      ],
      // foot: [
      //   ["#", "Descripcion", "Direccion", "Telefono", "Email", "NIT", "NIC"],
      // ],
      body: listaProveedores.map((proveedor, index) => [
        index + 1,
        proveedor.proveedoresDescripcion,
        proveedor.proveedoresDireccion,
        proveedor.proveedoresTelefono,
        proveedor.proveedoresEmail,
        proveedor.proveedoresNIT,
        proveedor.proveedoresNRC,
      ]),
      styles: { fontSize:8 },
      margin: { top: 10 },
      
    });
    doc.save(`Proveedores-lista-${fechaActualCompleta()}.pdf`);
  };

  const index = (cell, row, rowIndex) => rowIndex + 1;

  /**
   *
   *  Imprimie los botones de edicion y eliminacion en la lista proveedores
   * @param {*} cell
   * @param {object} row
   * @param {int} rowIndex
   * @returns
   */
  const botonesOpciones = (cell, row, rowIndex) => {
    let urlEditar = Paths.EditarProveedores.path;
    urlEditar = urlEditar.replace(/:id/g, row.proveedoresId);
    return (
      <div className="d-flex">
        <Link
          to={urlEditar}
          className="mx-1 bnt btn-icon-only  btn-info btn-sm"
        >
          <AiOutlineEdit size="1.2em" />
        </Link>
        <Button
          size="sm"
          variant="danger"
          onClick={() => handlerEliminarProveedor(row)}
          className="btn-icon-only "
        >
          <AiOutlineDelete size="1.2em" />
        </Button>
      </div>
    );
  };
  const columns = [
    { dataField: "", text: "#", sort: true, formatter: index },
    {
      dataField: "proveedoresId",
      text: "Opciones",
      sort: true,
      formatter: botonesOpciones,
    },
    { dataField: "proveedoresDescripcion", text: "Descripcion", sort: true },
    { dataField: "proveedoresDireccion", text: "Direccion", sort: true },
    { dataField: "proveedoresTelefono", text: "Telefono", sort: true },
    { dataField: "proveedoresEmail", text: "Email", sort: true },
    { dataField: "proveedoresNIT", text: "NIT", sort: true },
    { dataField: "proveedoresNRC", text: "NIC", sort: true },
  ];

  return (
    <div className="px-1">
      <Card>
        <div className="pagesCustomParent p-4">
          <Cargando loading={showLoading} />

          <ToolkitProvider
            keyField="proveedoresId"
            data={listaProveedores}
            columns={columns}
            search
            exportCSV={{
              fileName: `Lista-Proveedores-${fechaActualCompleta()}.csv`
            }}
          >
            {(props) => (
              <>
                <Row>
                  <Col md={6} sm={12}>
                    <label>Buscar Proveedor:</label>
                    <SearchBar {...props.searchProps} className="my-2 w-40" />
                  </Col>
                  <Col
                    md={6}
                    sm={12}
                    className="d-flex align-items-end justify-content-end"
                  >
                    <ExportCSVButton
                      {...props.csvProps}
                      className="btn btn-tertiary btn-sm my-2"
                    >
                      <FontAwesomeIcon icon={faFileCsv} /> CSV
                    </ExportCSVButton>
                    <Button
                      className="mx-2 my-2"
                      size="sm"
                      variant="danger"
                      onClick={generarPdfTabla}
                    >
                      <FontAwesomeIcon icon={faFilePdf} /> PDF
                    </Button>
                  </Col>
                </Row>

                <BootstrapTable
                  tableHeaderClass="table"
                  wrapperClasses="table-responsive"
                  headerClasses=""
                  hover
                  bordered={false}
                  search={{}}
                  align="center"
                  tabIndexCell
                  pagination={paginationFactory()}
                  noDataIndication="No existen proveedores registrados"
                  {...props.baseProps}
                ></BootstrapTable>
              </>
            )}
          </ToolkitProvider>
        </div>
      </Card>
    </div>
  );
};

export default ProveedoresLista;
