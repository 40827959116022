import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, Modal, Row } from '@themesberg/react-bootstrap'
import { makePetition } from '../../../helpers/petition';
import { useAuthContext } from '../../../contexts/authContext';
const guardarContrasenia = async (
    contrasenaActual,
    contrasenaNueva,
    usuariosId,
    Token
) => {
    const formData =
    {
        contrasenaActual,
        contrasenaNueva,
        usuariosId
    };

    const urlTarget = "usuarios/actualizarContrasenia";
    const petitionMethod = "PUT";
    const petition = await makePetition(urlTarget, formData, petitionMethod, Token);
    return petition;
}

const UsuariosModalPassword = ({
    mostrarCambiarContrasenia,
    hanleCloseModalContrasenia,
    setCambioContraseniaExitoso,
    usuariosId,
    usuariosRole = "VENDEDOR",
    setShowLoading = ()=>{}
}) => {
    const { logout, token: Token } = useAuthContext();
    const [datosContraseña, setDatosContraseña] = useState({
        "contrasenaActual": "",
        "contrasenaNueva": "",
        "contrasenaNuevaRepetir": ""
    });
    const [mostrarMensajeVerificacionContraseniaNueva, setMostrarMensajeVerificacionContraseniaNueva] = useState(false);
    const [mostrarMensajeVerificacionContraseniaExistente, setmostrarMensajeVerificacionContraseniaExistente] = useState(false);
    const [mensajeVerificacionCambio, setMensajeVerificacionCambio] = useState("");
    const [mostrarMensajeVerificacionCambio, seMostrarMensajeVerificacionCambio] = useState(false);
    const { contrasenaActual, contrasenaNueva, contrasenaNuevaRepetir } = datosContraseña;

    const handleChange = ({ target }) => {
        const { name, value } = target;
        setDatosContraseña({ ...datosContraseña, [name]: value });
    }
    const guardarContrasena = async () => {
        try {
            setMensajeVerificacionCambio("");
            seMostrarMensajeVerificacionCambio(false);
            if ((usuariosRole !== "ADMIN" && usuariosRole !== "OWNER")) {
                if (contrasenaActual.trim().length < 6) {
                    setMensajeVerificacionCambio("La contraseña actual  debe ser mayor o igual a 8 caracteres");
                    seMostrarMensajeVerificacionCambio(true);
                    return false;
                }
            }
            if (contrasenaNueva.trim().length < 6) {
                setMensajeVerificacionCambio("La contraseña nueva  debe ser mayor o igual a 8 caracteres");
                seMostrarMensajeVerificacionCambio(true);
                return false;
            }
            if (contrasenaNueva.trim() !== contrasenaNuevaRepetir.trim()) {
                return false;
            }
            setShowLoading(true);
            const peticionGuardarContrasenia = await guardarContrasenia(contrasenaActual.trim(), contrasenaNueva.trim(), usuariosId,Token);
            setShowLoading(false);
            const { ok, status } = peticionGuardarContrasenia;
            if (status === 401) {
                logout();
                return false;
            }
            if (!ok) {
                throw new Error("Ocurrió un  error en la peticion.");
            }
            const response = await peticionGuardarContrasenia.json();
            const { success = false, message = "", data = [], esContrasenaCorrecta = true } = response;
            if (!success) {
                if (!esContrasenaCorrecta) {
                    setmostrarMensajeVerificacionContraseniaExistente(true);
                } else {
                    setMensajeVerificacionCambio(message);
                    seMostrarMensajeVerificacionCambio(true);
                }
                return false;
            }
            hanleCloseModalContrasenia();
            setTimeout(() => {
                setCambioContraseniaExitoso(true);
            }, 400);
        } catch (error) {
            setMensajeVerificacionCambio("Ocurrió un error inesperado.");
            seMostrarMensajeVerificacionCambio(true);
            return false;
        }

    }
    useEffect(() => {
        if (contrasenaNueva.trim().length > 2 || contrasenaNuevaRepetir.trim().length > 2) {
            if (contrasenaNueva !== contrasenaNuevaRepetir) {
                setMostrarMensajeVerificacionContraseniaNueva(true);
            } else {
                setMostrarMensajeVerificacionContraseniaNueva(false);
            }
        } else {
            setMostrarMensajeVerificacionContraseniaNueva(false);
        }
    }, [contrasenaNueva, contrasenaNuevaRepetir])

    return (
        <>
            <Modal
                as={Modal.Dialog}
                centered
                show={mostrarCambiarContrasenia}
                onHide={hanleCloseModalContrasenia}
                backdrop="static"
                keyboard={false}
                size="md"
                className='w-100'
            >
                <Modal.Header>
                    <Container>
                        <Row className='mt-2'>
                            <Col className="d-flex justify-left align-items-center"><Button variant="close" aria-label="Close" onClick={hanleCloseModalContrasenia} /></Col>
                        </Row>
                    </Container>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col><Modal.Title className="h2 mb-2 text-center">Cambiar Contraseña</Modal.Title></Col>
                        </Row>
                        {(usuariosRole !== "ADMIN" && usuariosRole !== "OWNER") ?
                            <Row>
                                <Col className='mt-4'>
                                    <Form.Group>
                                        <Form.Label>
                                            Contraseña Actual
                                        </Form.Label>
                                        <Form.Control
                                            autoFocus
                                            type="password"
                                            id="contrasenaActual"
                                            name="contrasenaActual"
                                            value={contrasenaActual}
                                            onChange={handleChange}
                                            placeholder="Ingrese la contraseña actual"
                                        >
                                        </Form.Control>
                                    </Form.Group>
                                    {
                                        mostrarMensajeVerificacionContraseniaExistente &&
                                        <div className="text-danger">Contraseña actual inválida</div>
                                    }
                                </Col>
                            </Row> : null
                        }

                        <Row>
                            <Col className='mt-4'>
                                <Form.Group>
                                    <Form.Label>
                                        Contraseña Nueva
                                    </Form.Label>
                                    <Form.Control
                                        autoFocus
                                        type="password"
                                        id="contrasenaNueva"
                                        name="contrasenaNueva"
                                        value={contrasenaNueva}
                                        onChange={handleChange}
                                        placeholder="Ingrese la contraseña nueva"
                                    >
                                    </Form.Control>
                                </Form.Group>
                                {
                                    mostrarMensajeVerificacionContraseniaNueva && <div className="text-danger">Verificacion contraseña inválida</div>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col className='mt-4'>
                                <Form.Group>
                                    <Form.Label>
                                        Verificar Contraseña
                                    </Form.Label>
                                    <Form.Control
                                        autoFocus
                                        type="password"
                                        id="contrasenaNuevaRepetir"
                                        name="contrasenaNuevaRepetir"
                                        value={contrasenaNuevaRepetir}
                                        onChange={handleChange}
                                        placeholder="Repetir contraseña nueva"
                                    >
                                    </Form.Control>
                                </Form.Group>
                                {
                                    mostrarMensajeVerificacionContraseniaNueva && <div className="text-danger">Verificacion contraseña inválida</div>
                                }
                            </Col>
                        </Row>
                        <Row className='mt-4'>
                            {
                                mostrarMensajeVerificacionCambio &&
                                <div className="alert alert-danger text-danger text-center">{mensajeVerificacionCambio}</div>
                            }
                        </Row>
                        <Row className='mt-4'>
                            <Col className='d-flex flex-column'>
                                <Button variant="outline-primary" className='mt-1' onClick={guardarContrasena}>Guardar Contraseña</Button>
                                <Button variant="danger" className='mt-2' onClick={hanleCloseModalContrasenia}>Cancelar </Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default UsuariosModalPassword;