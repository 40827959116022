import React from 'react';
import { BsBasket3, BsPlusLg } from 'react-icons/bs';
import { Link } from "react-router-dom";
import ProductosLista from '../ProductosLista';
import { Paths } from '../../../../config/router/paths';

const Productos = () => {
    return (
        <div className='mt-4'>
            <div className="px-0 mb-3 d-flex justify-content-between" >
                <div className="d-flex justify-content-start">
                    <div className="mx-3">
                        <BsBasket3 size="2em"/>
                    </div>
                    <h4>
                        Productos
                    </h4>
                </div>
                <div className=''>
                    <Link
                        className='mx-2 btn btn-lg btn-outline-primary'
                        to={Paths.AgregarProductos.path}
                    >
                        <BsPlusLg className='mx-2' />  Nuevo Producto
                    </Link>
                    
                </div>
            </div>
            <ProductosLista />
        </div>

    )
}

export default Productos;