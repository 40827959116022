import React from "react";
import { useEffect, useState } from "react";
import { Badge, Button, Card, Col, Row } from "@themesberg/react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "../../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "../../../../node_modules/react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "../../../../node_modules/react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import "jspdf-autotable";
import jsPDF from "jspdf";
import SweetAlert from "../Utils/SweetAlert";
import SweetAlertQuestion from "../Utils/SweetAlertQuestion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faTrash,
  faFilePdf,
  faFileCsv,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { makePetition } from "../../../helpers/petition";
import Cargando from "../Utils/Loading";
import FomularioCompraIndividual from "./FomularioCompraIndividual";
import {
  agregarCompraIndividual,
  consultarListaPrecioVentas,
} from "./helper/productosPeticiones";
import customDecimalRound from "../Utils/customDecimalRound";
import { useAuthContext } from "../../../contexts/authContext";
import { productoPeticiones } from "./helper/peticionesProductos";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import moment from "moment-timezone";
const ProductosLista = () => {
  const { logout, token: Token } = useAuthContext();
  const [listaProductos, setListaProductos] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [consultarListaProductos, setConsultarListaProductos] = useState(true);
  const [mostarAdministradorExistencia, setMostarAdministradorExistencia] =
    useState(false);
  const [estadoDeExistencia, setEstadoDeExistencia] = useState({});

  useEffect(() => {
    if (consultarListaProductos) {
      const obtenerListaProductos = async () => {
        try {
          setShowLoading(true);
          const request = await productoPeticiones(
            "obtenerListaProducto",
            Token
          );
          setShowLoading(false);
          const { ok, status } = request;
          if (status === 401) {
            logout();
            return false;
          }
          if (!ok) {
            setListaProductos([]);
            SweetAlert("Error", `Ocurrió un error inesperado`, "error", true);
            return false;
          }
          const response = await request.json();
          const { success, message = "", data = [] } = response;
          setShowLoading(false);
          if (!success) {
            setListaProductos([]);
            SweetAlert("Error", `${message}`, "error", true);
            return false;
          }
          setListaProductos(data);
          return true;
        } catch (error) {
          setShowLoading(false);
          SweetAlert(
            "Error",
            `Por favor contacte al administrador del sistema`,
            "error",
            true
          );
          return false;
        }
      };
      setConsultarListaProductos(false);
      obtenerListaProductos();
    }
  }, [consultarListaProductos, Token, logout]);
  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const fechaActual = () => {
    return moment().utcOffset("-06:00").format("DD-MM-YYYY");
  };
  const fechaActualCompleta = () => {
    return moment().utcOffset("-06:00").format("DD-MM-YYYY-HH-mm-ss");
  };
  const generarPdfTabla = () => {
    const doc = new jsPDF({ orientation: "landscape" });
    doc.text(`Lista de Productos ${fechaActual()}`, 16, 18);
    doc.autoTable({
      head: [
        [
          "#",
          "Codigo",
          "Producto",
          "Precio compra",
          "Existencia",
          "Activo",
          "Marca",
          "Proveedores",
        ],
      ],
      body: listaProductos.map((productos, index) => [
        index + 1,
        productos.productosCodigo,
        productos.productosDescripcion,
        `$${productos.productosPrecioCompra}`,
        `${productos.inventariosProductosExistencia} uds`,
        productos.productosActivo ? "SI" : "no",
        productos.marcasDescripcion,
        productos.proveedoresDescripcion,
      ]),
      styles: { fontSize:8 },
      margin: { top: 20 }
    });
    doc.save(`Lista-Productos-${fechaActualCompleta()}.pdf`);
  };

  const eliminarProductos = async (datosProductos) => {
    try {
      const eliminarProductosHandle = async (datosProductos) => {
        const { productosId } = datosProductos;
        setShowLoading(true);
        const request = await productoPeticiones("eliminarProducto", Token, {
          productosId,
        });
        setShowLoading(false);
        const { status, ok } = request;
        if (status === 401) {
          logout();
          return false;
        }
        if (!ok) {
          SweetAlert("Error", `Ocurrió un error inesperado`, "error", true);
          return false;
        }
        const response = await request.json();
        const { success, message = "" } = response;
        setConsultarListaProductos(true);
        if (!success) {
          SweetAlert("Error", `${message}`, "error", true);
          return false;
        }
        SweetAlert("Exito", `${message}`, "success", true);
        return true;
      };
      const preguntaEliminacionParametros = {
        tipo: "pregunta",
        titulo: "Advertencia",
        mensaje: "¿Esta seguro que desea eliminar este producto?",
      };
      const preguntaEliminacion = await SweetAlertQuestion(
        preguntaEliminacionParametros
      );
      const { isConfirmed = false } = preguntaEliminacion;
      if (!isConfirmed) return false;
      return await eliminarProductosHandle(datosProductos);
    } catch (error) {
      console.error(error);
      SweetAlert("Error", "Ocurrió un error inesperado", "error", true);
      return false;
    }
  };
  const consultarListaPrecioVentasHandle = async (productosId) => {
    try {
      if (
        productosId === undefined ||
        !productosId ||
        isNaN(productosId) ||
        parseInt(productosId) < 1
      ) {
        SweetAlert(
          "Error",
          "El producto no puede ser identificado",
          "error",
          true
        );
        return {
          success: false,
          message: "El producto no puede ser identificado",
        };
      }
      return await consultarListaPrecioVentas(productosId, Token);
    } catch (error) {
      console.error(error);
      SweetAlert("Error", "Ocurrió un error inesperado", "error", true);
      return {
        success: false,
        message: "El producto no puede ser identificado",
      };
    }
  };
  const botonesOpciones = (cell, row, rowIndex) => {
    const urlEditar = `/EditarProductos/${row.productosId}`;
    //const urlEliminar = `/eliminarProveedores?id=${row.proveedoresId}`;
    return (
      <div className="d-flex">
        <Link to={urlEditar} className="mx-1 btn-icon-only btn btn-info btn-sm">
          <AiOutlineEdit size="1.2em" />
        </Link>
        <Button
          size="sm"
          variant="danger"
          onClick={() => eliminarProductos(row)}
          className="btn-icon-only"
        >
          <AiOutlineDelete size="1.2em" />
        </Button>
      </div>
    );
  };
  const botonExistenciaHandle = (cell, row, rowIndex) => {
    const {
      productosId,
      productosActivo,
      productosCodigo,
      productosDescripcion,
      productosEliminado,
      productosPrecioCompra,
      productosPrecioVenta,
      inventariosProductosExistencia: existenciaActualProducto = 0,
      proveedoresDescripcion,
      proveedoresId,
      marcasDescripcion,
      marcasId,
    } = row;
    if (parseInt(productosId) < 1) {
      return (
          <span
            className="badge bg-danger text-wrap p-2"
            style={{ cursor: "pointer", padding: "0.2rem 0.4rem" }}
            disabled={true}
          >
            N/I{" uds."}
          </span>
      );
    }
    const mostrarAdministradorExistenciasHandle = async (e) => {
      const { target = null } = e;
      if (!target) {
        SweetAlert(
          "Error",
          "No se puede identificar el producto",
          "error",
          true
        );
        return false;
      }
      target.disabled = true;
      try {
        const consultarListaPrecioVentas =
          await consultarListaPrecioVentasHandle(productosId);
        const {
          success = false,
          data: precioVentaListaProductoData = [],
          message = "",
        } = consultarListaPrecioVentas;
        setEstadoDeExistencia({
          productosId: productosId,
          codigoProducto: productosCodigo,
          descripcionProducto: productosDescripcion,
          precioCompraIndividualProducto: productosPrecioCompra,
          precioVentaListaProducto: precioVentaListaProductoData,
          existenciaActualProducto,
          proveedoresId: proveedoresId,
          nuevaExistenciaProducto: 0,
          facturaCodigo: "",
          facturaFecha: "",
          facturaFechaVencimiento: "",
          facturaComentarios: "",
          facturaIvaUnoPorciento: 0,
          facturaIva: 0,
          facturaSubTotal: 0,
          facturaTotal: 0,
        });
        setMostarAdministradorExistencia(true);
        setTimeout(() => {
          if (target) target.disabled = false;
        }, 200);
      } catch (error) {
        setTimeout(() => {
          if (target) target.disabled = false;
        }, 200);
        SweetAlert(
          "Error",
          "Ocurrió un error inesperado. No se puede identificar el producto",
          "error",
          true
        );
        return false;
      }
    };
    return (
        <span
          className="badge bg-danger text-wrap p-2"
          onClick={mostrarAdministradorExistenciasHandle}
          disabled={parseInt(productosEliminado) === 1 ? true : false}
          style={{ cursor: "pointer"}}
        >
          {existenciaActualProducto} {" uds."}
        </span>
    );
  };
  const botonActivoHandle = (cell, row, rowIndex) => {
    const { productosActivo } = row;
    return (
      <div className="d-flex">
        <Badge
          bg={parseInt(productosActivo) === 1 ? "success" : "danger"}
          className="badge text-wrap p-2"
          // style={{ cursor: "pointer", padding: "0.2rem 0.4rem" }}
        >
          {parseInt(productosActivo) === 1 ? "Habilitado" : "Deshabilitado"}
        </Badge>
      </div>
    );
  };
  const botonMostrarPrecios = (cell, row, rowIndex)=>{
    const { productosPrecioCompra } = row;
    return (
      <div className="d-flex">
        <Badge
          bg="info"
          className="badge text-wrap p-2"
          // style={{ cursor: "pointer", padding: "0.2rem 0.4rem" }}
        >
          ${productosPrecioCompra}
        </Badge>
      </div>
    );
  }
  const guardarNuevaExistenciaHandler = async () => {
    try {
      const {
        productosId,
        codigoProducto,
        precioCompraIndividualProducto,
        precioVentaListaProducto,
        proveedoresId,
        nuevaExistenciaProducto,
        facturaCodigo,
        facturaComentarios,
        facturaFechaFormateada = "",
        facturaFechaVencimientoFormateada = "",
        facturaIva,
        facturaUnoPorcientoHabilitado = 0,
        facturaIvaUnoPorciento,
        facturaSubTotal,
        facturaTotal,
      } = estadoDeExistencia;
      setShowLoading(true);
      const requestData = {
        productosId: parseInt(productosId),
        codigoProducto: codigoProducto.toString().trim(),
        productosExistencia: customDecimalRound(nuevaExistenciaProducto),
        productosPrecioCompra: customDecimalRound(precioCompraIndividualProducto),
        productosPrecioVentaLista: precioVentaListaProducto,
        proveedoresId: parseInt(proveedoresId),
        facturaCodigo: facturaCodigo.toString().trim(),
        facturaFecha: facturaFechaFormateada.toString().trim(),
        facturaFechaVencimiento: facturaFechaVencimientoFormateada
          .toString()
          .trim(),
        facturaComentarios: facturaComentarios.toString().trim(),
        facturaIvaUnoPorciento: customDecimalRound(facturaIvaUnoPorciento),
        facturaIva: customDecimalRound(facturaIva),
        facturaUnoPorcientoHabilitado: parseInt(facturaUnoPorcientoHabilitado),
        facturaSubTotal: customDecimalRound(facturaSubTotal),
        facturaTotal: customDecimalRound(facturaTotal),
      };
      const request = await agregarCompraIndividual(requestData,Token);
      setShowLoading(false);
      const { status, ok } = request;
      if (status === 401) {
        logout();
        return false;
      }
      if (!ok) {
        throw new Error("Error en peticion");
      }
      const response = await request.json();
      const { success, message = "" } = response;
      if (!success) {
        return response;
      }
      setMostarAdministradorExistencia(false);
      setEstadoDeExistencia();
      setConsultarListaProductos(true);
      SweetAlert("Exito", `${message}`, "success", true);
      return response;
    } catch (error) {
      SweetAlert("Error", `Ocurrió un error inesperado al guardar la existencia.`, "error", true);
      setConsultarListaProductos(true);
    }
  };
  const columns = [
    { dataField: "productosIndex", text: "#", sort: true },
    {
      dataField: "productosId",
      text: "Opciones",
      sort: false,
      formatter: botonesOpciones,
    },
    { dataField: "productosCodigo", text: "Codigo", sort: true },
    { dataField: "productosDescripcion", text: "Producto", sort: true },
    { dataField: "productosPrecioCompra", text: "Precio compra", sort: true, formatter: botonMostrarPrecios },
    {
      dataField: "Existencia",
      text: "Existencia",
      sort: true,
      formatter: botonExistenciaHandle,
    },
    {
      dataField: "productosActivo",
      text: "Activo",
      sort: true,
      formatter: botonActivoHandle,
    },
    { dataField: "marcasDescripcion", text: "Marca", sort: true },
    { dataField: "proveedoresDescripcion", text: "Proveedor", sort: true },
  ];
  return (
    <div className="px-1">
      <Card>
        <div className="pagesCustomParent px-4">
          <Cargando loading={showLoading} />
          <div className="mt-5">
            <ToolkitProvider
              keyField="productosId"
              data={listaProductos}
              columns={columns}
              search
              exportCSV
            >
              {(props) => (
                <>
                  <Row>
                    <Col md={6} sm={12}>
                      <label>Buscar Productos:</label>
                      <SearchBar {...props.searchProps} className="my-2 w-40" />
                    </Col>
                    <Col
                      md={6}
                      sm={12}
                      className="d-flex align-items-end justify-content-end"
                    >
                      <ExportCSVButton
                        {...props.csvProps}
                        className="btn btn-tertiary btn-sm my-2"
                      >
                        <FontAwesomeIcon icon={faFileCsv} /> CSV
                      </ExportCSVButton>
                      <Button
                        className="mx-2 my-2"
                        size="sm"
                        variant="danger"
                        onClick={generarPdfTabla}
                      >
                        <FontAwesomeIcon icon={faFilePdf} /> PDF
                      </Button>
                    </Col>
                  </Row>
                  <BootstrapTable
                    tableHeaderClass="table-header"
                    wrapperClasses="table-responsive"
                    hover
                    bordered={false}
                    search={{}}
                    align="center"
                    tabIndexCell
                    pagination={paginationFactory()}
                    noDataIndication="No existen productos registrados"
                    {...props.baseProps}
                  ></BootstrapTable>
                </>
              )}
            </ToolkitProvider>
          </div>
        </div>
      </Card>
      {mostarAdministradorExistencia && (
        <FomularioCompraIndividual
          mostarAdministradorExistencia={mostarAdministradorExistencia}
          setMostarAdministradorExistencia={setMostarAdministradorExistencia}
          estadoDeExistencia={estadoDeExistencia}
          setEstadoDeExistencia={setEstadoDeExistencia}
          guardarNuevaExistenciaHandler={guardarNuevaExistenciaHandler}
        />
      )}
    </div>
  );
};

export default ProductosLista;
