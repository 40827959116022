import React, { useEffect, useState } from "react";
import { useAuthContext } from "../../../../contexts/authContext";
import SweetAlert from "../../Utils/SweetAlert";
//import SweetAlertQuestion from "../Utils/SweetAlertQuestion";
import { kardexPeticiones } from "../helper/peticionesKardex";
import ToolkitProvider, {
  CSVExport,
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import jsPDF from "jspdf";
import { AiOutlineDelete } from "react-icons/ai";
import { Button, Card, Col, Row } from "@themesberg/react-bootstrap";
import Cargando from "../../Utils/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import moment from "moment-timezone";
import customDecimalRound from "../../Utils/customDecimalRound";

const KardexLista = () => {
  const { logout, token: Token } = useAuthContext();
  const [consulta, setConsulta] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  const [listaDatosKardex, setListaDatosKardex] = useState([]);
  useEffect(() => {
    if (consulta) {
      setConsulta(false);
      (async () => {
        try {
          setShowLoading(true);
          const request = await kardexPeticiones("getListaKardex", Token);
          const { status, ok } = request;
          if (status === 401) {
            setShowLoading(false);
            logout();
            return false;
          }
          if (!ok) {
            throw new Error("Error de petición.");
          }
          const response = await request.json();
          const { success, message = "", data = [] } = response;
          if (!success || data.length <= 0) {
            setShowLoading(false);
            setListaDatosKardex([]);
            return false;
          }
          setShowLoading(false);
          setListaDatosKardex(data);
          return true;
        } catch (error) {
          setShowLoading(false);
          SweetAlert(
            "Error",
            `Por favor contacte al administrador del sistema`,
            "error",
            true
          );
          return false;
        }
      })();
    }
  }, [consulta, logout, Token]);
  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const fechaActual = () => {
    return moment().utcOffset("-06:00").format("DD-MM-YYYY");
  };
  const fechaActualCompleta = () => {
    return moment().utcOffset("-06:00").format("DD-MM-YYYY-HH-mm-ss");
  };
  const generarPdfTabla = () => {
    const doc = new jsPDF({ orientation: "landscape" });
    doc.text(`Lista Kardex ${fechaActual()}`, 18, 18);
    doc.autoTable({
      head: [[
        "#", 
        "Factura Tipo", 
        "Fecha Creacion", 
        "Usuario Creo", 
        "Producto Precio",
        "Producto Cantidad", 
        "Producto Creacion", 
        "Factura Fecha", 
        "Factura Fecha Vencimiento",
        "Factura Anulada",
        "Factura Iva",
        "Producto Nombre",
        "Proveedor",
        "Cliente"
      ]],
      body: listaDatosKardex.map((kardex, index) => [
        kardex.kardexFacturaIndex,
        kardex.kardexFacturaTipo,
        kardex.kardexFechaCreacion,
        kardex.kardexNombreUsuarioCreo,
        `$${customDecimalRound(Number(kardex.kardexFacturaProductoPrecio)).toFixed(2)}`,
        `${kardex.kardexFacturaProductoCantidad} Uds.`,
        kardex.kardexFacturaFechaCreacion,
        kardex.kardexFacturaFecha,
        kardex.kardexFacturaFechaVencimiento,
        (parseInt(kardex.kardexFacturaAnulada) === 0 ? "Activa" : "Anulada" ),
        kardex.kardexFacturaImpuestoIva,
        kardex.productosDescripcion,
        kardex.proveedoresDescripcion,
        kardex.clientesNombres
      ]),
      styles: { fontSize:6 },
      margin: { top: 10 }
    });
    doc.save(`Lista-Kardex-${fechaActualCompleta()}.pdf`);
  };
  const columns = [
    {
      dataField: "kardexFacturaIndex",
      text: "#",
      sort: false,
    },
    {
      dataField: "kardexFacturaTipo",
      text: "Factura Tipo",
      sort: false,
      formatter: (kardexFacturaTipo) => (
        <span className="badge bg-info text-wrap p-2 mx-2">
          {kardexFacturaTipo}
        </span>
      )
    },
    {
      dataField: "kardexFechaCreacion",
      text: "Fecha Creacion",
      sort: false,
      formatter:((fechaCreacion)=> moment(fechaCreacion).format("DD-MM-YYYY"))
    },
    {
      dataField: "kardexNombreUsuarioCreo",
      text: "Usuario Creo",
      sort: false,
    },
    {
      dataField: "kardexFacturaProductoPrecio",
      text: "Producto Precio",
      sort: true,
      formatter: (kardexFacturaProductoPrecio) => (
        <span className="badge bg-info text-wrap p-2 mx-2">
          {"$"}
          {customDecimalRound(Number(kardexFacturaProductoPrecio)).toFixed(2)}
        </span>
      ),
    },
    {
      dataField: "kardexFacturaProductoCantidad",
      text: "Producto Cantidad",
      sort: true,
      formatter: (kardexFacturaProductoCantidad) => (
        <span className="badge bg-danger text-wrap p-2 mx-2">
          {kardexFacturaProductoCantidad}
          {" uds."}
        </span>
      ),
    },
    {
      dataField: "kardexFacturaFechaCreacion",
      text: "Producto Creacion",
      sort: true,
      formatter:((fecha)=> moment(fecha).format("DD-MM-YYYY"))
    },
    {
      dataField: "kardexFacturaFecha",
      text: "Factura Fecha",
      sort: true,
      formatter:((fecha)=> moment(fecha).format("DD-MM-YYYY"))
    },
    {
      dataField: "kardexFacturaFechaVencimiento",
      text: "Factura Fecha Vencimiento",
      sort: true,
      formatter:((fecha)=> moment(fecha).format("DD-MM-YYYY"))
    },
    {
      dataField: "kardexFacturaAnulada",
      text: "Factura Anulada",
      sort: true,
      formatter: (kardexFacturaAnulada) => (
        <span className="badge bg-danger text-wrap p-2 mx-2">
          {(kardexFacturaAnulada === 0 ? "Activa" : "Anulada")}
        </span>
      ),
    },
    {
      dataField: "kardexFacturaImpuestoIva",
      text: "Factura Iva",
      sort: false,
    },
    {
      dataField: "productosDescripcion",
      text: "Producto Nombre",
      sort: false,
    },
    {
      dataField: "proveedoresDescripcion",
      text: "Proveedor",
      sort: false,
    },
    {
      dataField: "clientesNombres",
      text: "Cliente",
      sort: false,
    },
  ];
  return (
    <div className="px-1">
      <Card>
        <div className="pagesCustomParent p-4">
          <Cargando loading={showLoading} />
          <div>
            <ToolkitProvider
              keyField="kardexFacturaIndex"
              data={listaDatosKardex}
              columns={columns}
              search
              exportCSV
            >
              {(props) => (
                <>
                  <Row>
                    <Col md={6} sm={12}>
                      <label>Buscar:</label>
                      <SearchBar {...props.searchProps} className="my-2 w-40" />
                    </Col>
                    <Col
                      md={6}
                      sm={12}
                      className="d-flex align-items-end justify-content-end"
                    >
                      <ExportCSVButton
                        {...props.csvProps}
                        className="btn btn-tertiary btn-sm my-2"
                      >
                        <FontAwesomeIcon icon={faFileCsv} /> CSV
                      </ExportCSVButton>
                      <Button
                        className="mx-2 my-2"
                        size="sm"
                        variant="danger"
                        onClick={generarPdfTabla}
                      >
                        <FontAwesomeIcon icon={faFilePdf} /> PDF
                      </Button>
                    </Col>
                  </Row>

                  <BootstrapTable
                    tableHeaderClass="table"
                    wrapperClasses="table-responsive"
                    headerClasses=""
                    hover
                    bordered={false}
                    search={{}}
                    align="center"
                    tabIndexCell
                    pagination={paginationFactory()}
                    noDataIndication="No existen empleados   registrados"
                    {...props.baseProps}
                  ></BootstrapTable>
                </>
              )}
            </ToolkitProvider>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default KardexLista;
