import React, { useEffect, useState } from "react";
import { inventarioPeticiones } from "../helper/peticionesInventario";
import SweetAlert from "../../Utils/SweetAlert";
import Cargando from "../../Utils/Loading";
import ToolkitProvider, {
  CSVExport,
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import jsPDF from "jspdf";
import { Button, Card, Col, FormLabel, Row } from "@themesberg/react-bootstrap";
import {
  AiOutlineDelete,
  AiOutlineFundView,
  AiOutlineMenu,
} from "react-icons/ai";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import customDecimalRound from "../../Utils/customDecimalRound";
import ModalistaPrecioVentas from "../../Productos/ModalistaPrecioVentas";
import { useAuthContext } from "../../../../contexts/authContext";
import moment from "moment-timezone";

const InventarioLista = () => {
  const { logout, token: Token } = useAuthContext();
  const [consulta, setConsulta] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  const [listaDatosInventario, setlistaDatosInventario] = useState([]);
  const [mostrarModalListaPrecioVentas, setMostrarModalListaPrecioVentas] =
    useState(false);
  const [
    productoIdSeleccionadoPrecioVentas,
    setProductoIdSeleccionadoPrecioVentas,
  ] = useState(0);
  useEffect(() => {
    if (consulta) {
      setConsulta(false);
      (async () => {
        try {
          setShowLoading(true);
          const request = await inventarioPeticiones(
            "getListaInventario",
            Token
          );
          setShowLoading(false);
          const { status, ok } = request;
          if (status === 401) {
            logout();
            return false;
          }
          if (!ok) {
            throw new Error("Error de petición.");
          }
          const response = await request.json();
          const { success, message = "", data = [] } = response;
          if (!success || data.length <= 0) {
            setlistaDatosInventario([]);
            return false;
          }
          setlistaDatosInventario(data);
          return true;
        } catch (error) {
          setShowLoading(false);
          SweetAlert(
            "Error",
            `Por favor contacte al administrador del sistema`,
            "error",
            true
          );
          return false;
        }
      })();
    }
  }, [consulta, Token, logout]);
  const fechaActual = () => {
    return moment().utcOffset("-06:00").format("DD-MM-YYYY");
  };
  const fechaActualCompleta = () => {
    return moment().utcOffset("-06:00").format("DD-MM-YYYY-HH-mm-ss");
  };
  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const generarPdfTabla = () => {
    const doc = new jsPDF({ orientation: "landscape" });
    doc.text(`Inventarios ${fechaActual()}`, 16, 10);
    doc.autoTable({
      head: [
        [
          "#",
          "Producto",
          "Existencia",
          "Precio Compra",
          "Inventario Crecion",
          "Usuario Creacion",
        ],
      ],
      body: listaDatosInventario.map((inventario, index) => [
        inventario.inventariosIndex,
        inventario.productosDescripcion,
        `${inventario.inventariosExistencia} uds.`,
        `$${inventario.productosPrecioCompra}`,
        moment(inventario.inventariosFechaCreacion).format("DD-MM-YYYY h:mm:ss a"),
        inventario.inventariosUsuarioNombreCreo,
      ]),
      styles: { fontSize:8 },
      margin: { top: 12 }
    });
    doc.save(`Lista-Inventarios-${fechaActualCompleta()}.pdf`);
  };
  const verPrecioHandle = (datosFila) => {
    const { productoId } = datosFila;
    setProductoIdSeleccionadoPrecioVentas(productoId);
    setMostrarModalListaPrecioVentas(true);
  };
  const verPreciosVista = (productoId, datosFila, indexFila) => {
    try {
      productoId = parseInt(productoId);
      if (productoId < 1) {
        return (
          <Button size="sm" className="btn-icon-only" disabled={true}>
            Invalid
          </Button>
        );
      }
      return (
        <Button
          size="sm"
          variant="outline-primary"
          onClick={() => verPrecioHandle(datosFila)}
          className="btn-icon-only"
        >
          <AiOutlineFundView size="1.2em" />
        </Button>
      );
    } catch (error) {
      console.error(error);
    }
  };
  const columns = [
    {
      dataField: "inventariosIndex",
      text: "#",
      sort: true,
    },
    {
      dataField: "productosDescripcion",
      text: "Producto",
      sort: false,
    },
    {
      dataField: "inventariosExistencia",
      text: "Producto Existencia",
      sort: false,
      formatter: (inventariosExistencia) => (
        <span className="badge bg-danger text-wrap p-2 mx-2">
          {inventariosExistencia}
          {" uds."}
        </span>
      ),
    },
    {
      dataField: "productosPrecioCompra",
      text: "Precio Compra",
      sort: false,
      formatter: (productosPrecioCompra) => (
        <span className="badge bg-info text-wrap p-2 mx-2">
          {"$"}
          {customDecimalRound(Number(productosPrecioCompra)).toFixed(2)}
        </span>
      ),
    },
    {
      dataField: "inventariosFechaCreacion",
      text: "Inventario Creacion",
      sort: false,
      formatter:((fecha)=> moment(fecha).format("DD-MM-YYYY h:mm:ss a"))
    },
    {
      dataField: "inventariosUsuarioNombreCreo",
      text: "Creado Por",
      sort: false,
    },
    {
      dataField: "productoId",
      text: "Precios Ventas",
      sort: false,
      formatter: verPreciosVista,
    },
  ];
  return (
    <div className="px-1">
      <Card>
        <div className="pagesCustomParent p-4">
          <Cargando loading={showLoading} />
          <div>
            <ToolkitProvider
              keyField="inventariosId"
              data={listaDatosInventario}
              columns={columns}
              search
              exportCSV={{
                fileName: `Lista-Iventario-${fechaActualCompleta()}.csv`
              }}
            >
              {(props) => (
                <>
                  <Row>
                    <Col md={6} sm={12}>
                      <label>Buscar:</label>
                      <SearchBar {...props.searchProps} className="my-2 w-40" />
                    </Col>
                    <Col
                      md={6}
                      sm={12}
                      className="d-flex align-items-end justify-content-end"
                    >
                      <ExportCSVButton
                        {...props.csvProps}
                        className="btn btn-tertiary btn-sm my-2"
                      >
                        <FontAwesomeIcon icon={faFileCsv} /> CSV
                      </ExportCSVButton>
                      <Button
                        className="mx-2 my-2"
                        size="sm"
                        variant="danger"
                        onClick={generarPdfTabla}
                      >
                        <FontAwesomeIcon icon={faFilePdf} /> PDF
                      </Button>
                    </Col>
                  </Row>

                  <BootstrapTable
                    tableHeaderClass="table"
                    wrapperClasses="table-responsive"
                    headerClasses=""
                    hover
                    bordered={false}
                    search={{}}
                    align="center"
                    tabIndexCell
                    pagination={paginationFactory()}
                    noDataIndication="No existe inventario registrado"
                    {...props.baseProps}
                  ></BootstrapTable>
                </>
              )}
            </ToolkitProvider>
          </div>
        </div>
      </Card>
      {mostrarModalListaPrecioVentas && (
        <ModalistaPrecioVentas
          mostrarModalListaPrecioVentas={mostrarModalListaPrecioVentas}
          setMostrarModalListaPrecioVentas={setMostrarModalListaPrecioVentas}
          productosId={productoIdSeleccionadoPrecioVentas}
          containerParaLoading=".pagesCustomParent"
        />
      )}
    </div>
  );
};

export default InventarioLista;
