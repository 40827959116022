import Compressor from "compressorjs";
import React, { useEffect, useState } from "react";
import FileBase64 from "react-file-base64";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const EmpleadosImagenes = ({
  listaEmpleadosImagenes,
  setListaEmpleadosImagenes,
  imagenPreviewDui,
  imagenPreviewAdicional,
}) => {
  const [claseAsignada, setClaseAsignada] = useState(false);
  const MySwal = withReactContent(Swal);
  const tipoImagenesPermitidas = [
    "image/apng",
    "image/gif",
    "image/jpeg",
    "image/png",
  ];
  const mostrarMensajeFormatoInvalido = () => {
    MySwal.fire({
      icon: "error",
      html: (
        <>
          <h2>Atención!</h2>
          <h4>
            El formato de imagen es invalido, por favor suba una imagen valida.
          </h4>
        </>
      ),
      showCancelButton: true,
      showCloseButton: true,
    });
  };
  const handleImageDUI = (files) => {
    const { name, type, size, file } = files;
    if (!tipoImagenesPermitidas.includes(type)) {
      mostrarMensajeFormatoInvalido();
      return false;
    }
    new Compressor(file, {
      quality: 0.5,
      success(result) {
        const reader = new FileReader();
        reader.onloadend = function () {
          const base64data = reader.result;
          setListaEmpleadosImagenes({
            ...listaEmpleadosImagenes,
            imagenDUI: { name, type, size, base64: base64data },
          });
        };
        reader.readAsDataURL(result);
      },
      error(err) {
        console.log(err.message);
      },
    });
    return true;
  };
  const handleImageAdicional = (files) => {
    const { name, type, size, file } = files;
    if (!tipoImagenesPermitidas.includes(type)) {
      mostrarMensajeFormatoInvalido();
      return false;
    }
    new Compressor(file, {
      quality: 0.5,
      success(result) {
        const reader = new FileReader();
        reader.onloadend = function () {
          const base64data = reader.result;
          setListaEmpleadosImagenes({
            ...listaEmpleadosImagenes,
            imagenAdicional: { name, type, size, base64: base64data },
          });
        };
        reader.readAsDataURL(result);
      },
      error(err) {
        console.log(err.message);
      },
    });
    return true;
  };
  useEffect(() => {
    if (!claseAsignada) {
      [...document.querySelectorAll("input[type='file']")].map((input) => {
        const id = input.parentElement.classList[1];
        input.id = id;
        input.name = id;
        input.classList.add("form-control");
        return true;
      });
      setClaseAsignada(true);
    }
  }, [claseAsignada, setClaseAsignada]);
  const imagenDUIValue = listaEmpleadosImagenes.imagenDUI;
  const imagenAdicional = listaEmpleadosImagenes.imagenAdicional;
  const regexBlob = /blob/i;
  return (
    <div className="d-flex d-flex justify-content-around">
      <div className="mt-4">
        <label
          htmlFor="imagenDUI"
          className="d-flex flex-column text-center pointer"
        >
          <span>DUI</span>
          <img
            className={[
              Object.keys(imagenDUIValue).length > 1 ||
              regexBlob.test(imagenPreviewDui)
                ? "imageUploadedPreview"
                : "",
              "mt-2",
            ].join(" ")}
            src={
              Object.keys(imagenDUIValue).length > 0
                ? imagenDUIValue.base64
                : imagenPreviewDui
            }
            width={
              Object.keys(imagenDUIValue).length > 0 ||
              regexBlob.test(imagenPreviewDui)
                ? "100%"
                : 180
            }
            style={{ pointersEvent: "none" }}
            alt="Imagen DUI"
          />
        </label>
        <div
          className="imageDUIContainer imagenDUI"
          style={{ display: "none" }}
        >
          <FileBase64 onDone={handleImageDUI} />
        </div>
      </div>

      <div className="mt-4">
        <label
          htmlFor="imagenAdicional"
          className="d-flex flex-column text-center pointer"
        >
          <span>Imagen Adicional</span>
          <img
            className={[
              Object.keys(imagenAdicional).length > 1 ||
              regexBlob.test(imagenPreviewAdicional)
                ? "imageUploadedPreview"
                : "",
              "mt-2",
            ].join(" ")}
            src={
              Object.keys(imagenAdicional).length > 0
                ? imagenAdicional.base64
                : imagenPreviewAdicional
            }
            width={
              Object.keys(imagenAdicional).length > 0 ||
              regexBlob.test(imagenPreviewAdicional)
                ? "100%"
                : 180
            }
            style={{ pointersEvent: "none" }}
            alt="Imagen Adicional"
          />
        </label>
        <div
          className="imageDUINIT imagenAdicional"
          style={{ display: "none" }}
        >
          <FileBase64 onDone={handleImageAdicional} />
        </div>
      </div>
    </div>
  );
};

export default EmpleadosImagenes;
