import React from 'react'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const SweetAlert = async(titulo,mensaje,tipo,estado,textoBotonOk= "ok",textoBotonNo="Cancelar",mostrarBotonCancelar=false,) => {
    const MySwal = withReactContent(Swal);
    let icono = "success";
    if(tipo.toString().toLowerCase()==="informacion")icono = "info";
    else if(tipo.toString().toLowerCase()==="error")icono = "error";
    else if(tipo.toString().toLowerCase()==="advertencia")icono = "warning";
    else if(tipo.toString().toLowerCase()==="éxito") icono = "success"
    if(estado){
        const resultadoSwal = await MySwal.fire({
            icon:icono,
            html:(
                <>
                <h2>{titulo}</h2>
                <h4>
                    {mensaje}
                </h4>
                </>
            ),
            showDenyButton:mostrarBotonCancelar,
            denyButtonText:textoBotonNo,
            showCancelButton: false,
            showConfirmButton:true,
            confirmButtonText:textoBotonOk,
            showCloseButton: false
        });
        return resultadoSwal;
    }
    
}

export default SweetAlert