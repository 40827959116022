
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from "react-router-dom";
import '../node_modules/react-bootstrap-typeahead/css/Typeahead.css';
// core styles

import "./scss/volt.scss";
// import "./css/atlas.css";
// vendor styles
import "react-datetime/css/react-datetime.css";

import Home from './pages/Home';
// import ScrollToTop from "./components/ScrollToTop";

ReactDOM.render(
  <>
    {/* <ScrollToTop /> */}
    <Home />
  </>,
  document.getElementById("root")
);
