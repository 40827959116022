import { makePetition } from "../../../../helpers/petition";

const fetchMarca = async (requesUrl, requestMethod, token, requestData) => {
  try {
    const requestToken = token;
    const request = await makePetition(
      requesUrl,
      requestData,
      requestMethod,
      requestToken
    );
    return request;
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message:
        "Ocurrió un error inexperado, por favor vuelva a intentar. Si este error persiste contacte al administrador del sistema",
    };
  }
};
const marcasPeticiones = async (method, token, requestData = {}) => {
  switch (method) {
    case "nuevaMarca":
      return await fetchMarca("marcas/nueva","POST",token,requestData);
    case "obtenerListaMarcas":
      return await fetchMarca(`marcas/lista`, "GET", token, requestData);
    case "obtenerMarcaPorId":
      const { marcaId = 0 } = requestData;
      return await fetchMarca(`marcas/marca/${marcaId}`, "GET",token, {});
    case "guardarDatosMarcaEdicion":
      return await fetchMarca(`marcas/editar`, "PUT", token, requestData);
    case "eliminarMarca":
      return await fetchMarca(`marcas/eliminar`, "DELETE", token, requestData);
    default:
      return {
        success: false,
        message:
          "Ocurrió un error inexperado, por favor vuelva a intentar. Si este error persiste contacte al administrador del sistema",
      };
  }
};

export { marcasPeticiones };
