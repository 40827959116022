import React from "react";
import { BsReverseLayoutTextSidebarReverse } from "react-icons/bs";
import CategoriasListas  from "../components/CategoriasListas";
const Categorias = () => {
  return (
    <div className="mt-4">
      <div className="mb-4 mx-1 d-flex justify-content-between">
        <div className="d-flex justify-content-start">
          <BsReverseLayoutTextSidebarReverse size="1.8em" className="mx-2" />
          <h4>Lista Categorias</h4>
        </div>
      </div>
      <CategoriasListas />
    </div>
  );
};

export default Categorias;
