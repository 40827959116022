import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Form, Row } from '@themesberg/react-bootstrap';
import uniqid from 'uniqid';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import customDecimalRound from '../Utils/customDecimalRound';
import onlyNumbers from '../Utils/onlyNumbers';
const AgregarEditarRangoPrecio = ({
    precioCompra,
    setPrecioCompra,
    setPrecioLista,
    procesoModal,
    showDefault,
    handleClose,
    defaultRangoValor,
    reiniciarDefaultRangoValor,
    modificarPreciosListaRangos
}) => {

    const [precioVenta, setPrecioVenta] = useState(defaultRangoValor.precioVenta)
    const [porcentajeVenta, setPorcentajeVenta] = useState(defaultRangoValor.porcentajeVenta);
    const [productoCantidadRangoMinimo, setProductoCantidadRangoMinimo] = useState(defaultRangoValor.productoCantidadRangoMinimo);
    const [productoCantidadRangoMaximo, setProductoCantidadRangoMaximo] = useState(defaultRangoValor.productoCantidadRangoMaximo);
    const MySwal = withReactContent(Swal);
    useEffect(() => {
        if (showDefault) {
            const calculoHandle = setTimeout(() => {
                calcularFinalPrecioVenta();
            }, 700); 
            return () => clearTimeout(calculoHandle);
        }
    }, [showDefault]);
    const precioVentaReference = useRef();
    const porcentajeGananciaReference = useRef();
    const precioCompraReference = useRef();

    const calcularPorcentaGanacia = () => {
        const precioCompraCurrent = Number(precioCompraReference.current.value);
        const precioVentaCurrent = Number(precioVentaReference.current.value);
        const porcentajeGanancia = customDecimalRound((((precioVentaCurrent * 100) / precioCompraCurrent) - 100));
        if (!(isNaN(porcentajeGanancia))) {
            setPorcentajeVenta(porcentajeGanancia);
        }
    }
    const calcularFinalPrecioVenta = () => {
        const precioCompraCurrent = Number(precioCompraReference.current.value);
        const porcentajeGananciaCurrent = Number(porcentajeGananciaReference.current.value) / 100;
        const precioVentaFinal = customDecimalRound(precioCompraCurrent + (precioCompraCurrent * porcentajeGananciaCurrent));
        if (!(isNaN(precioVentaFinal))) {
            setPrecioVenta(precioVentaFinal);
        }
    }
    const guardarRangoDePrecio = () => {
        let error = false;
        let errorMensage = "";
        if (isNaN(Number(precioCompra)) || Number(precioCompra) <= 0) {
            error = true;
            errorMensage += "Precio de compra invalido.\n"
        }
        if (isNaN(Number(precioVenta)) || Number(precioVenta) <= 0) {
            error = true;
            errorMensage += "Precio de venta invalido.\n"
        }
        if (isNaN(Number(productoCantidadRangoMaximo)) || Number(productoCantidadRangoMaximo) <= 0) {
            error = true;
            errorMensage += "El rango maximo de la cantidad de producto is invalido.\n"
        }
        if (isNaN(Number(porcentajeVenta)) || Number(porcentajeVenta) < 0) {
            error = true;
            errorMensage += "Precio de venta invalido."
        }
        if (Number(productoCantidadRangoMinimo) >= Number(productoCantidadRangoMaximo)) {
            error = true;
            errorMensage += "El rango maximo debe de ser mayor que el minimo."
        }
        if (error) {
            MySwal.fire({
                icon: "error",
                position: 'top-end',
                html: (
                    <>
                        <h2>Atención!</h2>
                        <h4>{errorMensage}</h4>
                    </>
                ),
                showCancelButton: false,
                showConfirmButton: false,
                showCloseButton: true,
                timer: 3500
            });
            return false;
        }
        let nuevaListaPrecioRango = modificarPreciosListaRangos();
        if (procesoModal === "NUEVO") {
            const nuevoRango = {
                "rangoPrecioId": uniqid(),
                "precioCompra": Number(precioCompra),
                "precioVenta": Number(precioVenta),
                "porcentajeVenta": Number(porcentajeVenta),
                "productoCantidadRangoMinimo": Number(productoCantidadRangoMinimo),
                "productoCantidadRangoMaximo": Number(productoCantidadRangoMaximo)
            };
            
            nuevaListaPrecioRango = [...nuevaListaPrecioRango, nuevoRango];
            nuevaListaPrecioRango = nuevaListaPrecioRango.sort((a,b)=>{
                return parseFloat(a.productoCantidadRangoMinimo) - parseFloat(b.productoCantidadRangoMinimo);
            });
        } else {
            const { rangoPrecioId: rangoIdEdicion } = defaultRangoValor;
            const nuevoRango = {
                "rangoPrecioId": rangoIdEdicion,
                "precioCompra": Number(precioCompra),
                "precioVenta": Number(precioVenta),
                "porcentajeVenta": Number(porcentajeVenta),
                "productoCantidadRangoMinimo": Number(productoCantidadRangoMinimo),
                "productoCantidadRangoMaximo": Number(productoCantidadRangoMaximo)
            };
            let nuevaPrecioLista = nuevaListaPrecioRango.filter((precio) => precio.rangoPrecioId !== rangoIdEdicion);
            nuevaPrecioLista = nuevaPrecioLista.sort((a,b)=>{
                return parseFloat(a.productoCantidadRangoMinimo) - parseFloat(b.productoCantidadRangoMinimo);
            });
            nuevaListaPrecioRango = [...nuevaPrecioLista, nuevoRango];
            reiniciarDefaultRangoValor();
        }
        setPrecioLista(nuevaListaPrecioRango);
        handleClose();
    }
    const customHandleChangePorcentageGanancia = (e) => {
        const { target: input } = e;
        if(input){
            const value = customDecimalRound(input.value);
            setPorcentajeVenta(value);
            calcularFinalPrecioVenta();
        }
    }
    const customHandleChangePrecioVenta = (e) => {
        const { target: input = null } = e;
        if(input){
            const value = customDecimalRound(input.value);
            setPrecioVenta(value);
            calcularPorcentaGanacia();
        }
    }
    const customHandleChangePrecioCompra = (e) => {
        const { target: input = null } = e;
        if(input){
            const value = customDecimalRound(input.value);
            setPrecioCompra(value);
            calcularFinalPrecioVenta();
        }
    }
    const customHandleChangeProductoCantidadRangoMinimo = ({ target: input }) => {
        let { value } = input;
        value = customDecimalRound(input.value);
        setProductoCantidadRangoMinimo(value);
    }
    const customHandleChangeProductoCantidadRangoMaximo = ({ target: input }) => {
        let { value } = input;
        value = customDecimalRound(input.value);
        setProductoCantidadRangoMaximo(value);
    }
    return (
        <>
            <Row>
                <Col xs={12}>
                    <Form.Group className="mb-4">
                        <Form.Label>Precio Compra</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Ingrese el precio de compra del producto"
                            id="productosPrecioCompra"
                            value={precioCompra}
                            onChange={customHandleChangePrecioCompra}
                            onKeyPress={onlyNumbers}
                            step="0.01"
                            min="0.00"
                            ref={precioCompraReference}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col xs={6}>
                    <Form.Group className="mb-4">
                        <Form.Label>
                            Porcentage Ganancia
                        </Form.Label>
                        <Form.Control
                            id="porcetangeGanancia"
                            name="porcetangeGanancia"
                            type="number"
                            value={porcentajeVenta}
                            onKeyPress={onlyNumbers}
                            step="0.01"
                            min="0.00"
                            onChange={customHandleChangePorcentageGanancia}
                            ref={porcentajeGananciaReference}
                        />
                    </Form.Group>
                </Col>
                <Col xs={6}>
                    <Form.Group className="mb-4">
                        <Form.Label>
                            Rango Minimo
                        </Form.Label>
                        <Form.Control
                            type="number"
                            id="productoRangoMaximon"
                            name="productoRangoMaximo"
                            step="1"
                            min="1"
                            value={productoCantidadRangoMinimo}
                            onChange={customHandleChangeProductoCantidadRangoMinimo}
                            onKeyPress={onlyNumbers}
                            disabled
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col xs={6}>
                    <Form.Group className="mb-4">
                        <Form.Label>
                            Rango Maximo
                        </Form.Label>
                        <Form.Control
                            type="number"
                            id="productoRangoMinimo"
                            name="productoRangoMinimo"
                            step="1"
                            min={Number(productoCantidadRangoMinimo) + 1}
                            value={productoCantidadRangoMaximo}
                            max="1000000"
                            onChange={customHandleChangeProductoCantidadRangoMaximo}
                            onKeyPress={onlyNumbers}
                        />
                    </Form.Group>
                </Col>
                <Col xs={6}>
                    <Form.Group className="mb-4">
                        <Form.Label>Precio Venta</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Ingrese el precio de venta"
                            id="productosPrecioVenta"
                            value={precioVenta}
                            onChange={customHandleChangePrecioVenta}
                            ref={precioVentaReference}
                            onKeyPress={onlyNumbers}
                            step="0.01"
                            min="0.00"
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col xs={12} className="d-flex justify-center align-center">
                    <Button variant="outline-danger mx-1" onClick={handleClose} size="lg" className="w-50" type="button">Cancelar</Button>
                    <Button variant="outline-success mx-1" onClick={guardarRangoDePrecio} size="lg" className="w-50" type="button">Guardar</Button>
                </Col>
            </Row>
        </>
    );
}

export default AgregarEditarRangoPrecio;