import React from 'react';
import { BsCart3 } from 'react-icons/bs';
import Factura from '../../components/tiendaBuenPrecioComponents/Facturacion/Factura';
import { useNavigate } from 'react-router-dom';

const AgregarFactura = () => {
  const navigate = useNavigate();
  return (
    <div className= "mt-4">
        <div className='d-flex'>
          <BsCart3 className='mx-3' size="2em" style={{fontWeight:"800"}}/> 
          <h3>Facturación</h3>
        </div>
        <div >
         <Factura type="NUEVO"/>
        </div>
    </div>
  )
}


export default AgregarFactura;