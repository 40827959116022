import React from "react";
import { BsReverseLayoutTextSidebarReverse } from "react-icons/bs";
import KardexLista from "../components/KardexLista";
//import { Link} from 'react-router-dom';
//import { Routes } from '../../../../routes';
const ListaKardex = (props) => {
  return (
    <div className="mt-4">
      <div className="mb-4 mx-1 d-flex justify-content-between">
        <div className="d-flex justify-content-start">
          <BsReverseLayoutTextSidebarReverse size="1.8em" className="mx-2" />
          <h4>Lista Kardex</h4>
        </div>
        {/* <div className=''>
                    <Link
                        className='mx-2 btn btn-lg btn-outline-primary'
                        to={Paths.Kardex.path}
                    >
                        <BsPlusLg className='mx-2'/>  Nuevo Kardex
                    </Link>
                </div> */}
      </div>
      <KardexLista />
    </div>
  );
};

export default ListaKardex;
