const onlyNumbers = (event,guiones = false) => { 
    if(guiones){
        if (!/^[0-9-]+$/.test(event.key)) { 
            event.preventDefault(); 
        } 
    }else{
        if (!/^[0-9.]+$/.test(event.key)) { 
            event.preventDefault(); 
        } 
    }
}
export default onlyNumbers;