import React, { useState } from "react";
import { Button, Col, Modal, Row } from "@themesberg/react-bootstrap";
import ConsultarListaPrecioVentasTable from "./ConsultarListaPrecioVentasTable";

const ModalistaPrecioVentas = ({
  mostrarModalListaPrecioVentas,
  setMostrarModalListaPrecioVentas,
  productosId,
  containerParaLoading,
}) => {
  const handleClose = () => {
    setMostrarModalListaPrecioVentas(false);
  };
  return (
    <>
      <Modal
        show={mostrarModalListaPrecioVentas}
        onHide={handleClose}
        size="xl"
      >
        <Modal.Header closeButton className="mx-4 mt-2">
          <Modal.Title>Lista Precios Ventas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container" style={{ minHeight: "24em" }}>
            <ConsultarListaPrecioVentasTable
              productosId={productosId}
              container={containerParaLoading}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button onClick={handleClose}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalistaPrecioVentas;
