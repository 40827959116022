import { React,useState } from "react";
import { faPen, faTrash, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Table } from "@themesberg/react-bootstrap";
import ModalAgregarPreciosVenta from "../../Productos/helper/ModalAgregarPreciosVenta";

const FacturaCompraListaProductosSeleccionados = ({
    listaProductosSeleccionados,
    setListaProductosSeleccionados,
    eliminarProductoSeleccionadoEnListaProducto,
    editarProductoSeleccionadoEnListaProducto
}) => {


    const [precioLista, setPrecioLista] = useState([]);
    const [precioCompra, setPrecioCompra] = useState(0);
    const [precioListaProductoId, setPrecioListaProductoId] = useState(0);
    const [showModalProductPreciosVentaForm, setShowModalProductoPreciosVentaForm] = useState(false);
    const asignarPreciosListaVentaHandle = () => {
        let listaProductosSeleccionadosConSeleccion = listaProductosSeleccionados.filter((precio) => {
            return parseInt(precioListaProductoId) === precio.productosId;
        });

        const listaProductosSeleccionadosSinSeleccion = listaProductosSeleccionados.filter((precio) => {
            return parseInt(precioListaProductoId) !== precio.productosId;
        });

        listaProductosSeleccionadosConSeleccion = {
            ...listaProductosSeleccionadosConSeleccion[0],
            precioVentaListaProducto: precioLista,
            precioCompraIndividualProducto: Number(precioCompra)
        }
        setListaProductosSeleccionados([
            ...listaProductosSeleccionadosSinSeleccion,
            listaProductosSeleccionadosConSeleccion
        ]);
        abortarPreciosListaVentaHandle();
        setShowModalProductoPreciosVentaForm(false);
    }
    const abortarPreciosListaVentaHandle = () => {
        setPrecioLista([]);
        setPrecioCompra(0);
        setPrecioListaProductoId(0);
    }
    const showModalPreciosHandle = (productosId, precioVentaListaProducto, precioCompraIndividualProducto) => {
        setShowModalProductoPreciosVentaForm(true);
        setPrecioLista(precioVentaListaProducto);
        setPrecioCompra(precioCompraIndividualProducto);
        setPrecioListaProductoId(productosId);
    }
    const TableRow = (props) => {
        const {
            productosId,
            codigoProducto,
            descripcionProducto,
            precioVentaListaProducto,
            precioCompraIndividualProducto,
            nuevaExistenciaProducto,
            indexRow,
        } = props;

        return (
            <tr>
                <td className="border-0">
                    {indexRow + 1}
                </td>
                <td>
                    <div className="d-flex">
                        <Button variant="outline-primary mx-2" size="sm" onClick={() => editarProductoSeleccionadoEnListaProducto(productosId)}><FontAwesomeIcon icon={faPen} /></Button>
                        <Button variant="danger" size="sm" onClick={() => eliminarProductoSeleccionadoEnListaProducto(productosId)}><FontAwesomeIcon icon={faTrash} /></Button>
                    </div>
                </td>
                <td className="fw-bold border-0">
                    {codigoProducto.toString().trim()}
                </td>
                <td className="border-0">
                    {descripcionProducto.toString().trim()}
                </td>
                <td className="fw-bold border-0">
                    ${precioCompraIndividualProducto}
                </td>
                <td className="border-0">
                    {nuevaExistenciaProducto}
                </td>
                <td className="fw-bold border-0">
                    <Button
                        variant="outline-success"
                        size="sm"
                        onClick={() => showModalPreciosHandle(productosId, precioVentaListaProducto, precioCompraIndividualProducto)}
                    >
                        <FontAwesomeIcon icon={faEye} />
                    </Button>
                </td>
            </tr>
        );
    };

    return (
        <div>
            <Table responsive className="table-centered table-nowrap rounded mb-0">
                <thead className="thead-light">
                    <tr>
                        <th className="border-0">Indice</th>
                        <th className="border-0">Accion</th>
                        <th className="border-0">Codigo</th>
                        <th className="border-0">Descripción</th>
                        <th className="border-0">Precio Compra</th>
                        <th className="border-0">Nueva Existencia</th>
                        <th className="border-0">Precio Vent</th>
                    </tr>
                </thead>
                <tbody>
                    {listaProductosSeleccionados.map((r, i) => <TableRow key={`producto-${r.productosId}`} {...r} indexRow={i} />)}
                </tbody>
            </Table>
            {
                showModalProductPreciosVentaForm && (
                    <ModalAgregarPreciosVenta
                        precioLista={precioLista}
                        setPrecioLista={setPrecioLista}
                        precioCompra={precioCompra}
                        setPrecioCompra={setPrecioCompra}
                        showModalProductPreciosVentaForm={showModalProductPreciosVentaForm}
                        setShowModalProductoPreciosVentaForm={setShowModalProductoPreciosVentaForm}
                        asignarPreciosListaVentaHandle={asignarPreciosListaVentaHandle}
                        abortarPreciosListaVentaHandle={abortarPreciosListaVentaHandle}
                    />)
            }
        </div>
    )
}
export default FacturaCompraListaProductosSeleccionados;