import React, { useEffect, useState } from "react";
import { useAuthContext } from "../../../contexts/authContext.js";
import { parse, isDate, format } from "date-fns";
import { Form, Button, Col, Row, Card } from "@themesberg/react-bootstrap";
import { Formik } from "formik";
import DatePicker from "react-date-picker";
import InputMask from "react-input-mask";
import * as Yup from "yup";
import EmpleadosImagenes from "./EmpleadosImagenes.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { UsuariosModalFormView } from "../Usuarios/UsuariosModalFormView.jsx";
import {
  guardarEmpleado,
  guardarEmpleadosImagenesRequest,
  obtenerEmpleadoEditar,
  obtenerImagenes,
  obtenerImagenesEmpleados,
  obtenerUsuariosPorEmpresa,
} from "./helper/empleadosPeticiones.js";
import { useNavigate } from "react-router-dom";
import SweetAlert from "../Utils/SweetAlert.jsx";
import moment from "moment-timezone";
import Cargando from "../Utils/Loading.jsx";
import imgPreview from "../../../assets/img/tiendaBuenPrecio/util/image-svgrepo-com.svg";
import onlyNumbers from "../Utils/onlyNumbers.js";
import onlyLetters from "../Utils/onlyLetters.js";
import onlyLettersAndNumbers from "../Utils/onlyLettersAndNumbers.js";
import onlyAddress from "../Utils/onlyAddress.js";
import emailChecker from "../Utils/emailChecker.js";

const EmpleadoForm = ({ formType, empleadosId = 0 }) => {
  const { logout, token: Token } = useAuthContext();
  const history = useNavigate();
  const goListaEmpleados = () => {
    history("/ListaEmpleados");
  };
  const [showDefaultNuevoUsuario, setShowDefaultNuevoUsuario] = useState(false);
  const parseDateString = (value, originalValue) => {
    const parsedDate = isDate(originalValue)
      ? originalValue
      : parse(originalValue, "dd-MM-yyyy", new Date());

    return parsedDate;
  };
  const [listaEmpleadosImagenes, setListaEmpleadosImagenes] = useState({
    imagenDUI: {},
    imagenAdicional: {},
  });
  const [consultarEmpleado, setConsultarEmpleado] = useState(
    formType === "EDITAR" ? true : false
  );
  const [consultarUsuarios, setConsultarUsuarios] = useState(true);
  const [datosUsuarios, setDatosUsuarios] = useState([]);
  const [datosEmpleadosDefault, setDatosEmpleadosDefault] = useState({
    empleadosNombres: "",
    empleadosApellidos: "",
    empleadosDui: "",
    empleadosNit: "",
    empleadosTelefono: "",
    empleadosEmail: "",
    empleadosFechaNacimiento: "",
    empleadosDireccion: "",
    empleadosSalario: 0,
    empleadosNumeroDeCuenta: "",
    bancosId: 0,
    usuariosId: 0,
  });
  const [showLoading, setShowLoading] = useState(false);
  const [imagenPreviewDui, setImagenPreviewDui] = useState(imgPreview);
  const [imagenPreviewAdicional, setImagenPreviewAdicional] =
    useState(imgPreview);
  useEffect(() => {
    if (consultarEmpleado && empleadosId > 0) {
      setConsultarEmpleado(false);
      (async () => {
        try {
          setShowLoading(true);
          const obtenerDatosEmpleado = await obtenerEmpleadoEditar(
            empleadosId,
            Token
          );
          setShowLoading(false);
          const { status, ok } = obtenerDatosEmpleado;
          if (status === 401) {
            setShowLoading(false);
            logout();
            return false;
          }
          if (!ok) {
            throw new Error("Error de petición.");
          }
          const obtenerDatosEmpleadoResponse =
            await obtenerDatosEmpleado.json();
          const { success = false, data = [] } = obtenerDatosEmpleadoResponse;
          if (!success) {
            return false;
          }
          let empleadosFechaNacimientoFormateada = null;
          if (!isNaN(Date.parse(data.empleadosFechaNacimiento))) {
            empleadosFechaNacimientoFormateada = moment(
              data.empleadosFechaNacimiento
            ).toDate();
          }
          setDatosEmpleadosDefault({
            ...datosEmpleadosDefault,
            empleadosNombres: data.empleadosNombres,
            empleadosApellidos: data.empleadosApellidos,
            empleadosDui: data.empleadosDui,
            empleadosNit: data.empleadosNit ?? "",
            empleadosTelefono: data.empleadosTelefono,
            empleadosEmail: data.empleadosEmail,
            empleadosFechaNacimiento: empleadosFechaNacimientoFormateada,
            empleadosDireccion: data.empleadosDireccion,
            empleadosSalario: Number(data.empleadosSalario, 2),
            empleadosNumeroDeCuenta: data.empleadosNumeroDeCuenta,
            bancosId: parseInt(data.bancosId),
            usuariosId: parseInt(data.usuariosId),
          });
          const obtenerImagenesEmpleadosPeticion =
            await obtenerImagenesEmpleados(empleadosId, Token);
          const {
            status: obtenerImagenesEmpleadosStatus,
            ok: obtenerImagenesEmpleadosOk,
          } = obtenerImagenesEmpleadosPeticion;
          if (obtenerImagenesEmpleadosStatus === 401) {
            setShowLoading(false);
            logout();
            return false;
          }
          if (!obtenerImagenesEmpleadosOk) {
            throw new Error("Error de petición.");
          }
          const obtenerDatosEmpleadoResponseResponse =
            await obtenerImagenesEmpleadosPeticion.json();
          const {
            success: successObtenerImagenes,
            data: dataObtenerImagenes,
            message: messageObtenerImagenes,
          } = obtenerDatosEmpleadoResponseResponse;
          if (!successObtenerImagenes) {
            SweetAlert(
              "Error",
              messageObtenerImagenes ||
                "Ocurrió un error al obtener las imagenes del empleado.",
              "error",
              true
            );
            setShowLoading(false);
            return false;
          }
          if (dataObtenerImagenes.length < 1) {
            return true;
          }
          dataObtenerImagenes.map(async (imagen) => {
            const { empleadosImagenesArchivo, link, sufijoImagen } = imagen;
            if (sufijoImagen === "empleadosImagenDui") {
              const imagenBase64 = await obtenerImagenes(link, Token);
              setImagenPreviewDui(imagenBase64);
            } else if (sufijoImagen === "empleadosImagenAdicional") {
              const imagenBase64 = await obtenerImagenes(link, Token);
              setImagenPreviewAdicional(imagenBase64);
            }
          });
        } catch (error) {
          SweetAlert(
            "Error",
            "Ocurrió un error en la peticion de los datos de empleado. Por favor contacte con soporte",
            "error",
            true
          );
          setShowLoading(false);
          return false;
        }
      })();
    }
  }, [consultarEmpleado, Token, datosEmpleadosDefault, empleadosId, logout]);
  useEffect(() => {
    const consultaUsuariosPorEmpresa = async () => {
      try {
        setShowLoading(true);
        const obtenerUsuariosDisponibles = await obtenerUsuariosPorEmpresa(
          formType,
          Token
        );
        setShowLoading(false);
        const { status, ok } = obtenerUsuariosDisponibles;
        if (status === 401) {
          setShowLoading(false);
          logout();
          return false;
        }
        if (!ok) {
          throw new Error("Error de petición.");
        }
        const response = await obtenerUsuariosDisponibles.json();
        const { success = false, message = "", data = [] } = response;
        setDatosUsuarios(data);
        if (data.length <= 0) {
          SweetAlert(
            "ADVERTENCIA",
            "No existen usuarios disponibles, por favor agregue un nuevo en caso el empleado lo requiera.",
            "ADVERTENCIA",
            true
          );
        }
      } catch (error) {
        SweetAlert(
          "Error",
          "Ocurrió un error al consultar los usuarios",
          "error",
          true
        );
      }
    };

    if (consultarUsuarios) {
      setConsultarUsuarios(false);
      consultaUsuariosPorEmpresa();
    }
  }, [consultarUsuarios, Token, formType, logout]);

  const fechaNacimientoMinima = (aniosMinimos = 120) => {
    const dateNow = new Date();
    const year = dateNow.getFullYear();
    const month = dateNow.getMonth();
    const day = dateNow.getDate();
    return new Date(year - parseInt(aniosMinimos), month, day);
  };
  const handleShowNuevoUsuario = () => {
    setShowDefaultNuevoUsuario(true);
  };
  const handleHideNuevoUsuario = () => {
    setShowDefaultNuevoUsuario(false);
  };
  const EmpleadosSchema = Yup.object().shape({
    empleadosNombres: Yup.string()
      .min(4, "Los nombres del Empleado debe ser mayor o igual a 5 caracteres")
      .max(
        99,
        "Los nombres del Empleado debe ser menor o igual a 99 caracteres"
      )
      .required("Los nombres del empleado son obligatorios"),
    empleadosApellidos: Yup.string()
      .min(
        4,
        "Los apellidos del Empleado debe ser mayor o igual a 5 caracteres"
      )
      .max(
        99,
        "Los apellidos del Empleado debe ser menor o igual a 99 caracteres"
      )
      .required("Los apellidos del empleado son obligatorios"),
    empleadosDui: Yup.string()
      .min(10, 'El DUI del Empleado debe tener 10 caracteres incluyendo el "-"')
      .max(10, 'El DUI del Empleado debe tener 10 caracteres incluyendo el "-"')
      .required("El número de DUI del empleado es obligatorio"),
    empleadosNit: Yup.string().min(
      17,
      'El NIT del Empleado debe tener 17 caracteres incluyendo los "-"'
    ),
    empleadosTelefono: Yup.string()
      .min(
        8,
        "EL número de telefono del Empleado debe tener al menos 9 caracteres"
      )
      .required("El número de teléfono del empleado es obligatorio"),
    empleadosEmail: Yup.string()
      .email("Por favor ingrese un correo electronico válido")
      .min(8, "El correo del Empleado debe tener al menos 8 caracteres")
      .max(99, "El correo del Empleado debe ser menor o igual a 99 caracteres"),
    empleadosFechaNacimiento: Yup.date()
      .transform(parseDateString)
      .min(
        fechaNacimientoMinima(120),
        "Fecha de nacimiento invalida, la edad maxima de un Empleados es 120 años"
      )
      .max(
        fechaNacimientoMinima(10),
        "Fecha de nacimiento invalida, la edad minima de un Empleados es 10 años"
      )
      .required("La fecha de nacimiento del empleado es obligatoria"),
    empleadosDireccion: Yup.string()
      .min(8, "El correo del Empleado debe tener al menos 8 caracteres")
      .max(99, "El correo del Empleado debe ser menor o igual a 99 caracteres")
      .required("La dirección del empleado es obligatoria"),
    empleadosSalario: Yup.number("Formáto inválido de salario"),
    bancosId: Yup.number(
      "El identificador tiene un formato desconocido"
    ).integer(),
    usuariosId: Yup.number().integer(),
  });

  const formSubmitHandler = (valores, resetForm) => {
    (async () => {
      try {
        let errorResultanteEventos = false;
        let mensajeResultanteEventos = "";
        const {
          bancosId,
          empleadosApellidos,
          empleadosDireccion,
          empleadosDui,
          empleadosNit,
          empleadosEmail,
          empleadosFechaNacimiento,
          empleadosNombres,
          empleadosNumeroDeCuenta,
          empleadosSalario,
          empleadosTelefono,
          usuariosId
        } = valores;

        if (empleadosNombres.toString().trim().length < 2) {
          SweetAlert(
            "Error",
            "El nombre de empleado es requerido",
            "error",
            true
          );
          return false;
        }
        if (empleadosApellidos.toString().trim().length < 2) {
          SweetAlert(
            "Error",
            "El apellido de empleado es requerido",
            "error",
            true
          );
          return false;
        }
        if (empleadosDui.toString().trim().length < 2) {
          SweetAlert("Error", "El dui de empleado es requerido", "error", true);
          return false;
        }
        if (empleadosFechaNacimiento.toString().trim().length < 2) {
          SweetAlert(
            "Error",
            "La fecha del nacimiento del empleado es requerido",
            "error",
            true
          );
          return false;
        }

        const empleadosFechaNacimientoBackendFormato = format(
          empleadosFechaNacimiento,
          "yyyy/MM/dd"
        );
        setShowLoading(true);
        const guardarPeticion = await guardarEmpleado(
          Token,
          formType === "EDITAR" ? empleadosId : 0,
          empleadosNombres,
          empleadosApellidos,
          empleadosFechaNacimientoBackendFormato,
          empleadosDireccion,
          empleadosTelefono,
          empleadosEmail,
          empleadosNumeroDeCuenta,
          empleadosSalario,
          empleadosDui,
          empleadosNit,
          bancosId,
          parseInt(usuariosId)
        );
        const { status, ok } = guardarPeticion;
        if (status === 401) {
          setShowLoading(false);
          logout();
          return false;
        }
        if (!ok) {
          throw new Error("Error de petición.");
        }
        const response = await guardarPeticion.json();
        const {
          success = false,
          message = "",
          error = false,
          data = [],
        } = response;
        if (!success) {
          setShowLoading(false);
          SweetAlert("Error", message, "error", true);
          return false;
        }
        const empleadoAlmacenadoId =
          formType === "EDITAR" ? empleadosId : parseInt(data.empleadosId || 0);
        if (empleadoAlmacenadoId < 1) {
          SweetAlert("Error", message, "error", true);
          showLoading(false);
          return false;
        }
        const { imagenDUI, imagenAdicional } = listaEmpleadosImagenes;
        if (Object.keys(imagenDUI).length > 1) {
          const { name: imagenNameDui, base64: imagenBase64Dui } = imagenDUI;
          const guardarImagenesDuiEmpleados =
            await guardarEmpleadosImagenesRequest(
              Token,
              empleadoAlmacenadoId,
              "empleadosImagenDui",
              imagenBase64Dui,
              imagenNameDui
            );
          const { status, ok } = guardarImagenesDuiEmpleados;
          if (status === 401) {
            setShowLoading(false);
            logout();
            return false;
          }
          if (!ok) {
            throw new Error("Error de petición.");
          }
          const response = await guardarImagenesDuiEmpleados.json();
          const {success} = response;
          if (!success) {
            errorResultanteEventos = true;
            mensajeResultanteEventos += `\n Ocurrió un error al subir la imagen de DUI del empleado`;
          } else {
            mensajeResultanteEventos += `\n Imagen de Dui subida con éxito.`;
          }
        }
        if (Object.keys(imagenAdicional).length > 1) {
          const {
            name: imagenNameImagenAdicional,
            base64: imagenBase64ImagenAdicional,
          } = imagenAdicional;
          const guardarImagenesAdicionalEmpleados =
            await guardarEmpleadosImagenesRequest(
              Token,
              empleadoAlmacenadoId,
              "empleadosImagenAdicional",
              imagenBase64ImagenAdicional,
              imagenNameImagenAdicional
            );
          const { status, ok } = guardarImagenesAdicionalEmpleados;
          if (status === 401) {
            setShowLoading(false);
            logout();
            return false;
          }
          if (!ok) {
            throw new Error("Error de petición.");
          }
          const response = await guardarImagenesAdicionalEmpleados.json();
          const {success} = response;
          if (!success) {
            errorResultanteEventos = true;
            mensajeResultanteEventos += `\n Ocurrió un error al subir la imagen adicional del empleado.`;
          } else {
            mensajeResultanteEventos += `\n Imagen adicional subida con éxito.`;
          }
        }
        if (errorResultanteEventos) {
          mensajeResultanteEventos +=
            "\n Si ocurrieron errores en la subida de imágenes, puede volver a intentarlo en la edición del empleado.";
          SweetAlert(
            "Advertencia",
            message + mensajeResultanteEventos,
            "warning",
            true
          );
        } else {
          SweetAlert(
            "Exito",
            message + mensajeResultanteEventos,
            "success",
            true
          );
        }
        setShowLoading(false);
        goListaEmpleados();
      } catch (error) {
        setShowLoading(false);
        SweetAlert(
          "Error",
          "Ocurrió un error inesperado, por favor contacte al administrador del sistema",
          "error",
          true
        );
        console.error(error);
      }
    })();
  };

  return (
    <>
      <Cargando loading={showLoading} />
      {formType === "EDITAR" && empleadosId < 1 ? (
        <div className="container d-flex justify-center mt-4">
          <h2>
            No se puede identificar el empleado seleccionado, por favor contacte
            al administrador del sistema
          </h2>
        </div>
      ) : (
        <Formik
          initialValues={datosEmpleadosDefault}
          validationSchema={EmpleadosSchema}
          enableReinitialize={formType === "EDITAR" ? true : false}
          onSubmit={(values, { resetForm }) =>
            formSubmitHandler(values, resetForm)
          }
        >
          {({
            handleChange,
            handleSubmit,
            handleBlur,
            values: inputs,
            errors,
            touched,
            setFieldValue,
          }) => {
            const {
              empleadosNombres,
              empleadosApellidos,
              empleadosDui,
              empleadosNit,
              empleadosTelefono,
              empleadosEmail,
              empleadosFechaNacimiento,
              empleadosDireccion,
              empleadosSalario,
              empleadosNumeroDeCuenta,
              bancosId,
              usuariosId,
            } = inputs;
            return (
              <Card className="p-4">
                <Form onSubmit={handleSubmit} className="mb-4">
                  <Row>
                    <Col md={4} sm={12} xs={12} className="mt-5">
                      <Form.Group>
                        <Form.Label>Nombres</Form.Label>
                        <Form.Control
                          autoFocus
                          type="text"
                          id="empleadosNombres"
                          name="empleadosNombres"
                          value={empleadosNombres}
                          onChange={handleChange}
                          placeholder="Ingrese los nombres del Empleado"
                          isInvalid={
                            touched.empleadosNombres && errors.empleadosNombres
                          }
                          onKeyPress={onlyLetters}
                        ></Form.Control>
                      </Form.Group>
                      {touched.empleadosNombres && errors.empleadosNombres && (
                        <div className="text-danger">
                          {errors.empleadosNombres}{" "}
                        </div>
                      )}
                    </Col>
                    <Col md={4} sm={12} xs={12} className="mt-5">
                      <Form.Group>
                        <Form.Label>Apellidos</Form.Label>
                        <Form.Control
                          autoFocus
                          type="text"
                          id="empleadosApellidos"
                          name="empleadosApellidos"
                          value={empleadosApellidos}
                          onChange={handleChange}
                          placeholder="Ingrese el nombre completo del Empleado"
                          isInvalid={
                            touched.empleadosApellidos &&
                            errors.empleadosApellidos
                          }
                          onKeyPress={onlyLetters}
                        ></Form.Control>
                      </Form.Group>
                      {touched.empleadosApellidos &&
                        errors.empleadosApellidos && (
                          <div className="text-danger">
                            {errors.empleadosApellidos}{" "}
                          </div>
                        )}
                    </Col>
                    <Col md={4} sm={12} xs={12} className="mt-5">
                      <Form.Group>
                        <Form.Label>DUI</Form.Label>
                        <InputMask
                          id="empleadosDui"
                          name="empleadosDui"
                          mask="99999999-9"
                          className="form-control"
                          value={empleadosDui}
                          onChange={handleChange}
                          placeholder="Ingrese el DUI del Empleado"
                          isValid={touched.empleadosDui && !errors.empleadosDui}
                        />
                        {touched.empleadosDui && errors.empleadosDui && (
                          <div className="text-danger">
                            {errors.empleadosDui}
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4} sm={12} xs={12} className="mt-5">
                      <Form.Group>
                        <Form.Label>NIT</Form.Label>
                        <InputMask
                          type="text"
                          id="empleadosNit"
                          name="empleadosNit"
                          value={empleadosNit}
                          onChange={handleChange}
                          placeholder="Ingrese el NIT del Empleado"
                          isInvalid={
                            touched.empleadosNit && errors.empleadosNit
                          }
                          mask="9999-999999-999-9"
                          className="form-control"
                        />
                        {touched.clientesNIT && errors.clientesNIT && (
                          <div className="text-danger">
                            {errors.clientesNIT}
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={4} sm={12} xs={12} className="mt-5">
                      <Form.Group>
                        <Form.Label>Correo Electrónico</Form.Label>
                        <Form.Control
                          type="email"
                          id="empleadosEmail"
                          name="empleadosEmail"
                          value={empleadosEmail}
                          onChange={handleChange}
                          placeholder="Ingrese el correo electrónico del Empleado"
                          isValid={
                            touched.empleadosEmail &&
                            !errors.empleadosEmail &&
                            empleadosEmail.trim().length > 1
                          }
                          onKeyPress={emailChecker}
                        ></Form.Control>
                        {touched.empleadosEmail && errors.empleadosEmail && (
                          <div className="text-danger">
                            {errors.empleadosEmail}
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={4} sm={12} xs={12} className="mt-5">
                      <Form.Group>
                        <Form.Label>Teléfono</Form.Label>
                        <InputMask
                          type="text"
                          id="empleadosTelefono"
                          name="empleadosTelefono"
                          mask="9999-9999"
                          value={empleadosTelefono}
                          onChange={handleChange}
                          placeholder="Ingrese el número telefónico del Empleado"
                          isInvalid={
                            touched.empleadosTelefono &&
                            errors.empleadosTelefono
                          }
                          className="form-control"
                          onKeyPress={(event) =>
                            event.target.value.length > 9
                              ? event.preventDefault()
                              : onlyNumbers(event)
                          }
                        />
                        {errors.empleadosTelefono && (
                          <div className="text-danger">
                            {errors.empleadosTelefono}
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4} sm={12} xs={12} className="mt-5">
                      <Form.Group>
                        <Form.Label>Fecha de Nacimiento</Form.Label>
                        <DatePicker
                          onChange={(value) =>
                            setFieldValue(
                              "empleadosFechaNacimiento",
                              value,
                              true
                            )
                          }
                          className="form-control"
                          value={empleadosFechaNacimiento}
                          format="dd/MM/yyyy"
                          locale="es-ES"
                          name="empleadosFechaNacimiento"
                          id="empleadosFechaNacimiento"
                        />
                        {touched.empleadosFechaNacimiento &&
                          errors.empleadosFechaNacimiento && (
                            <div className="text-danger">
                              {errors.empleadosFechaNacimiento}
                            </div>
                          )}
                      </Form.Group>
                    </Col>
                    <Col md={4} sm={12} xs={12} className="mt-5">
                      <Form.Group>
                        <Form.Label>Salario</Form.Label>
                        <Form.Control
                          type="number"
                          id="empleadosSalario"
                          name="empleadosSalario"
                          step="0.10"
                          value={empleadosSalario}
                          onChange={handleChange}
                          placeholder="Ingrese el salario del empleado"
                          isInvalid={
                            touched.empleadosSalario && errors.empleadosSalario
                          }
                          mask="999.99"
                          className="form-control"
                          onKeyPress={onlyNumbers}
                        />
                        {touched.empleadosSalario &&
                          errors.empleadosSalario && (
                            <div className="text-danger">
                              {errors.empleadosSalario}
                            </div>
                          )}
                      </Form.Group>
                    </Col>
                    <Col md={4} sm={12} xs={12} className="mt-5">
                      <Form.Group>
                        <Form.Label>Bancos</Form.Label>
                        <Form.Select
                          type="text"
                          id="bancosId"
                          name="bancosId"
                          value={bancosId}
                          onChange={handleChange}
                          isInvalid={touched.bancosId && errors.bancosId}
                        >
                          <option value="0">Seleccione</option>
                          <option value="1">Fedecredito</option>
                          <option value="2">Hipotecario</option>
                          <option value="3">Credomatic</option>
                          <option value="4">America Central</option>
                          <option value="5">Davivienda</option>
                        </Form.Select>
                        {touched.bancosId && errors.bancosId && (
                          <div className="text-danger">{errors.bancosId}</div>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4} sm={12} xs={12} className="mt-5">
                      <Form.Group>
                        <Form.Label>Usuarios</Form.Label>
                        <Form.Select
                          type="text"
                          id="usuariosId"
                          name="usuariosId"
                          value={usuariosId}
                          onChange={handleChange}
                          isInvalid={touched.usuariosId && errors.usuariosId}
                        >
                          <option value="0">Seleccione un usuario</option>
                          {datosUsuarios.length > 0 &&
                            datosUsuarios.map((usuario) => (
                              <option
                                value={usuario.usuariosId}
                                key={usuario.usuariosId}
                              >
                                {usuario.usuariosNombreUsuario}
                              </option>
                            ))}
                        </Form.Select>
                        <Form.Text className="text-muted">
                          Puede agregar un nuevo usuario aquí.{" "}
                          <Button
                            size="sm"
                            variant="info"
                            onClick={handleShowNuevoUsuario}
                          >
                            <FontAwesomeIcon icon={faPlusCircle} /> Aquí
                          </Button>
                        </Form.Text>
                        {touched.usuariosId && errors.usuariosId && (
                          <div className="text-danger">{errors.usuariosId}</div>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={4} sm={12} xs={12} className="mt-5">
                      <Form.Group>
                        <Form.Label>Numero de Cuenta</Form.Label>
                        <Form.Control
                          autoFocus
                          type="text"
                          id="empleadosNumeroDeCuenta"
                          name="empleadosNumeroDeCuenta"
                          value={empleadosNumeroDeCuenta}
                          onChange={handleChange}
                          placeholder="Ingrese el numero de cuenta del Empleado"
                          isInvalid={
                            touched.empleadosNumeroDeCuenta &&
                            errors.empleadosNumeroDeCuenta
                          }
                          onKeyPress={onlyLettersAndNumbers}
                        ></Form.Control>
                      </Form.Group>
                      {touched.empleadosNumeroDeCuenta &&
                        errors.empleadosNumeroDeCuenta && (
                          <div className="text-danger">
                            {errors.empleadosNumeroDeCuenta}{" "}
                          </div>
                        )}
                    </Col>
                    <Col md={4} sm={12} xs={12} className="mt-5">
                      <Form.Group>
                        <Form.Label>Dirección</Form.Label>
                        <Form.Control
                          as="textarea"
                          id="empleadosDireccion"
                          name="empleadosDireccion"
                          value={empleadosDireccion}
                          onChange={handleChange}
                          isInvalid={
                            touched.empleadosDireccion &&
                            errors.empleadosDireccion
                          }
                          onKeyPress={onlyAddress}
                        ></Form.Control>
                        {touched.empleadosDireccion &&
                          errors.empleadosDireccion && (
                            <div className="text-danger">
                              {errors.empleadosDireccion}
                            </div>
                          )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={9} xs={12} className="mt-5">
                      <h5>Imágenes de Empleado</h5>
                      <EmpleadosImagenes
                        listaEmpleadosImagenes={listaEmpleadosImagenes}
                        setListaEmpleadosImagenes={setListaEmpleadosImagenes}
                        imagenPreviewDui={imagenPreviewDui}
                        imagenPreviewAdicional={imagenPreviewAdicional}
                      />
                    </Col>
                    <Col md={3} xs={12} className="mt-7 justify-content-center">
                      <Button
                        variant="outline-primary"
                        type="submit"
                        className="w-100"
                      >
                        Guardar
                      </Button>
                      <Button
                        variant="danger"
                        type="button"
                        className="w-100 mt-2"
                        onClick={goListaEmpleados}
                      >
                        Cancelar
                      </Button>
                    </Col>
                  </Row>
                </Form>
                {showDefaultNuevoUsuario && (
                  <UsuariosModalFormView
                    type={"NUEVO"}
                    showDefault={showDefaultNuevoUsuario}
                    handleClose={handleHideNuevoUsuario}
                    functionCallBack={() => setConsultarUsuarios(true)}
                  />
                )}
              </Card>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default EmpleadoForm;
