import { makePetition } from "../../../../helpers/petition";

const fetchProducto = async (requesUrl, requestMethod, requestData, token) => {
  try {
    const request = await makePetition(
      requesUrl,
      requestData,
      requestMethod,
      token
    );
    return request;
  } catch (error) {
    return {
      success: false,
      message:
        "Ocurrió un error inexperado, por favor vuelva a intentar. Si este error persiste contacte al administrador del sistema",
    };
  }
};
const productoPeticiones = async (method, token, requestData = {}) => {
  switch (method) {
    case "obtenerListaProducto":
      return await fetchProducto(`productos/lista`, "GET", requestData, token);
    case "obtenerProductoPorId":
      return await fetchProducto(
        `productos/producto/${requestData.productosId}`,
        "GET",
        requestData,
        token
      );
    case "obtenerProductoPorCodigo":
      return await fetchProducto(
        `productos/productoPorCodigo/${encodeURIComponent(btoa(requestData.productosCodigo))}`,
        "GET",
        requestData,
        token
      );
    case "obtenerProductoImagenes":
      return await fetchProducto(
        `productosImagenes/obtenerImagenesProducto/${requestData.productosId}`,
        "GET",
        requestData,
        token
      );
    case "eliminarProducto":
      return await fetchProducto(
        `productos/eliminar`,
        "DELETE",
        requestData,
        token
      );
    default:
      return {
        success: false,
        message:
          "Ocurrió un error inexperado, por favor vuelva a intentar. Si este error persiste contacte al administrador del sistema",
      };
  }
};

export { productoPeticiones };
