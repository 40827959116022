import { useAuthContext } from "../../../contexts/authContext";
import { faFileCsv, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Col, Modal, Row } from "@themesberg/react-bootstrap";
import jsPDF from "jspdf";
import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  CSVExport,
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import {
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineFilePdf,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import Cargando from "../Utils/Loading";
import SweetAlert from "../Utils/SweetAlert";
import SweetAlertQuestion from "../Utils/SweetAlertQuestion";
import {
  eliminarEmpleado,
  obtenerEmpleados,
} from "./helper/empleadosPeticiones";
import moment from "moment-timezone";
import EmpleadosPdfReciboModalGenerador from "./EmpleadosPdfReciboModalGenerador";
const EmpleadosLista = () => {
  const { logout, token: Token } = useAuthContext();
  const [consulta, setConsulta] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  const [listaEmpleados, setListaEmpleados] = useState([]);
  const [mostrarModalPdf, setMostrarModalPdf] = useState(false);
  const [reciboPdfEmpleadoDatos, setReciboPdfEmpleadoDatos] = useState(0);
  useEffect(() => {
    if (consulta) {
      setConsulta(false);
      (async () => {
        try {
          setShowLoading(true);
          const request = await obtenerEmpleados(Token);
          const { status, ok } = request;
          if (status === 401) {
            setShowLoading(false);
            logout();
            return false;
          }
          if (!ok) {
            throw new Error("Error de petición.");
          }
          const response = await request.json();
          const { success, message = "", data = [] } = response;
          setShowLoading(false);
          if (!success || data.length <= 0) {
            setListaEmpleados([]);
            return false;
          }
          setListaEmpleados(data);
          return true;
        } catch (error) {
          setShowLoading(false);
          SweetAlert(
            "Error",
            `Por favor contacte al administrador del sistema`,
            "error",
            true
          );
          return false;
        }
      })();
    }
  }, [consulta, logout, Token]);
  const handlerEliminarEmpleado = async (empleadosDatos) => {
    try {
      let { empleadosId = 0 } = empleadosDatos;
      empleadosId = parseInt(empleadosId);
      if (empleadosId < 1) {
        SweetAlert(
          "Error",
          `No se puede identificar el empleado que desea eliminar`,
          "error",
          true
        );
      }
      const preguntaEliminacionParametros = {
        tipo: "pregunta",
        titulo: "Advertencia",
        mensaje: "¿Esta seguro que desea eliminar este empleado?",
      };
  
      const preguntaEliminacion = await SweetAlertQuestion(
        preguntaEliminacionParametros
      );
      const { isConfirmed = false } = preguntaEliminacion;
      if (!isConfirmed) {
        return false;
      }
      setShowLoading(true);
      const request = await eliminarEmpleado(
        empleadosId,
        Token
      );
      const { status, ok } = request;
      if (status === 401) {
        setShowLoading(false);
        logout();
        return false;
      }
      if (!ok) {
        SweetAlert("Error", `Ocurrió un error inesperado en la petición. Por favor contacte con el administrador.`, "error", true);
        return false;
      }
      const response = await request.json();
      const { success = false, message = "", data = [] } = response;
      setShowLoading(false);
      if (!success) {
        SweetAlert("Error", `${message || "Ocurrió un error inesperado, por favor contacte con el administrador."}`, "error", true);
        return false;
      }
      SweetAlert("Exito", `${message}`, "success", true);
      setConsulta(true);
      return true;
    } catch (error) {
      console.error(error);
      SweetAlert("Error", `Ocurrió un error interno. Por favor contacte con el administrador.`, "error", true);
      return false;
    }
  };
  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const generarPdfTabla = () => {
    const doc = new jsPDF({ orientation: "landscape" });
    const fechaActual = () => {
      return moment().utcOffset("-06:00").format("DD-MM-YYYY");
    };
    const fechaActualCompleta = () => {
      return moment().utcOffset("-06:00").format("DD-MM-YYYY-HH-mm-ss");
    };
    doc.text(`Lista de Empleados  ${fechaActual()}`, 16, 10);
    doc.autoTable({
      head: [["#", "Nombre", "Apellidos", "Email", "Usuario"]],
      body: listaEmpleados.map((empleado, index) => [
        index + 1,
        empleado.empleadosNombres,
        empleado.empleadosApellidos,
        empleado.empleadosEmail,
        empleado.usuariosNombreUsuario,
      ]),
    });
    doc.save(`Lista-empleados-${fechaActualCompleta()}.pdf`);
  };
  const botonAdministracionReciboSueldoEmpleadoPdf = async (row) => {
    setReciboPdfEmpleadoDatos(row);
    setMostrarModalPdf(true);
  };
  const botonesOpciones = (cell, row, rowIndex) => {
    const urlEditar = `/EditarEmpleados/${row.empleadosId}`;
    //const urlEliminar = `/eliminarEmpleados?id=${row.empleadosId}`;
    return (
      <div className="d-flex">
        <Link
          to={urlEditar}
          className="mx-1 bnt btn-icon-only  btn-info btn-sm"
        >
          <AiOutlineEdit size="1.2em" />
        </Link>
        <Button
          size="sm"
          variant="danger"
          onClick={() => handlerEliminarEmpleado(row)}
          className="mx-1 btn-icon-only"
        >
          <AiOutlineDelete size="1.2em" />
        </Button>
        <Button
          size="sm"
          variant="success"
          onClick={() =>
            botonAdministracionReciboSueldoEmpleadoPdf(row)
          }
          className="mx-1 btn-icon-only"
        >
          <AiOutlineFilePdf size="1.2em" />
        </Button>
      </div>
    );
  };
  const columns = [
    { dataField: "indice", text: "#", sort: true },
    {
      dataField: "empleadosId",
      text: "Opciones",
      sort: false,
      formatter: botonesOpciones,
    },
    { dataField: "empleadosNombres", text: "Nombre", sort: true },
    { dataField: "empleadosApellidos", text: "Apellidos", sort: true },
    { dataField: "empleadosDui", text: "DUI", sort:false},
    { dataField: "empleadosEmail", text: "Email", sort: true },
    { dataField: "usuariosNombreUsuario", text: "Usuario", sort: true },
  ];
  return (
    <div className="px-1">
      <Card>
        <div className="pagesCustomParent p-4">
          <Cargando loading={showLoading} />
          <div>
            <ToolkitProvider
              keyField="empleadosId"
              data={listaEmpleados}
              columns={columns}
              search
              exportCSV
            >
              {(props) => (
                <>
                  <Row>
                    <Col md={6} sm={12}>
                      <label>Buscar Empleado:</label>
                      <SearchBar {...props.searchProps} className="my-2 w-40" />
                    </Col>
                    <Col
                      md={6}
                      sm={12}
                      className="d-flex align-items-end justify-content-end"
                    >
                      <ExportCSVButton
                        {...props.csvProps}
                        className="btn btn-tertiary btn-sm my-2"
                      >
                        <FontAwesomeIcon icon={faFileCsv} /> CSV
                      </ExportCSVButton>
                      <Button
                        className="mx-2 my-2"
                        size="sm"
                        variant="danger"
                        onClick={generarPdfTabla}
                      >
                        <FontAwesomeIcon icon={faFilePdf} /> PDF
                      </Button>
                    </Col>
                  </Row>

                  <BootstrapTable
                    tableHeaderClass="table"
                    wrapperClasses="table-responsive"
                    headerClasses=""
                    hover
                    bordered={false}
                    search={{}}
                    align="center"
                    tabIndexCell
                    pagination={paginationFactory()}
                    noDataIndication="No existen empleados   registrados"
                    {...props.baseProps}
                  ></BootstrapTable>
                </>
              )}
            </ToolkitProvider>
          </div>
        </div>
      </Card>
      {
        mostrarModalPdf && 
        (
          <EmpleadosPdfReciboModalGenerador
            mostrarModalPdf={mostrarModalPdf}
            setMostrarModalPdf={setMostrarModalPdf}
            reciboPdfEmpleadoDatos={reciboPdfEmpleadoDatos}
          />
        )
      }
    </div>
  );
};

export default EmpleadosLista;
