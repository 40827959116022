import React, { useEffect, useState } from "react";
import { Form } from "@themesberg/react-bootstrap";
import { useAuthContext } from "../../../contexts/authContext.js";
import { proveedorPeticiones } from "./helper/peticionesProveedores.js";
const ProveedoresListaSelect = ({
  proveedoresId,
  consultarProveedores,
  setConsultarProveedores,
  handleChange,
  touched,
  errors,
  setAlertText,
  setAlertVisible,
  setFieldValue,
  proveedorIdModalState,
  setProveedorIdModalState,
}) => {
  const { logout, token: Token } = useAuthContext();
  const [proveedoresData, setProveedoresData] = useState([]);

  useEffect(() => {
    const obtenerProveedoresRequest = async () => {
      const request = await proveedorPeticiones("getListaProveedor", Token);
      const { status, ok } = request;
      if (status === 401) {
        logout();
        return false;
      }
      if (!ok) {
        setConsultarProveedores(false);
        setAlertText({
          title: "Error",
          message: "Ocurrió un error en la peticion de proveedores",
        });
        setAlertVisible(true);
        return false;
      }
      const response = await request.json();
      const { success, message = "", data = [] } = response;
      if (!success) {
        setConsultarProveedores(false);
        setAlertText({ title: "Error", message: message });
        setAlertVisible(true);
        return false;
      }
      setConsultarProveedores(false);
      setProveedoresData(data);
      if (proveedorIdModalState > 0) {
        setFieldValue("proveedoresId", proveedorIdModalState, true);
        setProveedorIdModalState(0);
      }
      return true;
    };
    if (consultarProveedores) {
      obtenerProveedoresRequest();
    }
  }, [
    consultarProveedores,
    setAlertText,
    setAlertVisible,
    setConsultarProveedores,
    setProveedoresData,
    setProveedorIdModalState,
    proveedorIdModalState,
    setFieldValue,
    Token,
    logout,
  ]);
  return (
    <>
      <Form.Select
        aria-label="Seleccion de proveedores"
        id="proveedoresId"
        name="proveedoresId"
        value={proveedoresId}
        onChange={handleChange}
        isValid={touched.proveedoresId && !errors.proveedoresId}
      >
        <option value="0">Seleccione un Proveedor</option>
        {proveedoresData.map((proveedor) => (
          <option value={proveedor.proveedoresId} key={proveedor.proveedoresId}>
            {proveedor.proveedoresDescripcion}
          </option>
        ))}
      </Form.Select>
    </>
  );
};

export const ProveedoresSelect = React.memo(ProveedoresListaSelect);
