import React from 'react';
import ProveedorForm from '../../components/tiendaBuenPrecioComponents/Proveedores/ProveedorForm';
//import proveedores01 from "../../assets/img/tiendaBuenPrecio/util/proveedores01.svg";
import { BsTruck } from 'react-icons/bs';
const AgregarProveedores = () => {
  return (
    <div className= "mt-4">
      <div className="px-0 mb-3" >
        <div className="d-flex justify-content-start">
          <div className="mx-3">
            {/* <img src={proveedores01} width="40" height="40" className="svg-icon" alt="Agregar"/> */}
            <BsTruck size="2.4em"/>
          </div>
          <h4>
            Agregar Proveedor
          </h4>
        </div>
      </div>
      <ProveedorForm formType="NUEVO"/>
    </div>
  )
}

export default AgregarProveedores;