import React from 'react';
import { BsPeople } from 'react-icons/bs';
import { useParams } from 'react-router-dom';
import ClienteForm from '../../components/tiendaBuenPrecioComponents/Clientes/ClientesForm';
const EditarClientes = () => {
  const { id:clientesId = 0 } = useParams();
  return (
    <div className= "mt-4">
      <div className="px-0 mb-3" >
        <div className="d-flex justify-content-start">
          <div className="mx-3">
            <BsPeople size="2em" />
          </div>
          <h4>
            Editar Cliente
          </h4>
        </div>
      </div>
      <ClienteForm type="EDITAR" clientesId={clientesId}/>
    </div>
  )
}

export default EditarClientes;