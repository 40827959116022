import { React} from "react";
import { Button, Modal } from "@themesberg/react-bootstrap";
import ProductoPrecios from "../ProductoPrecios";

const ModalAgregarPreciosVenta = ({
    precioLista,
    setPrecioLista,
    precioCompra,
    setPrecioCompra,
    showModalProductPreciosVentaForm,
    setShowModalProductoPreciosVentaForm,
    asignarPreciosListaVentaHandle,
    abortarPreciosListaVentaHandle
}) => {
    const hideModalHandle = ()=>{
        abortarPreciosListaVentaHandle();
        setShowModalProductoPreciosVentaForm(false)
    }
    return (
        <div>
            <Modal
                as={Modal.Dialog}
                centered
                show={showModalProductPreciosVentaForm}
                onHide={hideModalHandle}
                backdrop="static"
                keyboard={false}
                size="xl"
                dialogClassName="modal-90w"
            >
                <Modal.Header>
                    <Modal.Title className="h3 p-3"></Modal.Title>
                    <Button variant="close" aria-label="Close" onClick={hideModalHandle} />
                </Modal.Header>
                <Modal.Body>
                    <ProductoPrecios
                        precioLista={precioLista}
                        setPrecioLista={setPrecioLista}
                        precioCompra={precioCompra}
                        setPrecioCompra={setPrecioCompra}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={hideModalHandle}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={asignarPreciosListaVentaHandle}>
                        Guardar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
export default ModalAgregarPreciosVenta;