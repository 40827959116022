import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import SweetAlert from "../../Utils/SweetAlert";
import SweetAlertQuestion from "../../Utils/SweetAlertQuestion";
import jsPDF from "jspdf";
import { Button, Card, Col, Row } from "@themesberg/react-bootstrap";
import Cargando from "../../Utils/Loading";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  CSVExport,
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileCsv,
  faFilePdf,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";

import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { marcasPeticiones } from "../helper/peticionesMarcas";
import NuevaMarca from "./NuevaMarca";
import EditarMarca from "./EditarMarca";
import { useAuthContext } from "../../../../contexts/authContext";

const MarcasLista = () => {
  const { logout, token: Token } = useAuthContext();
  const [consulta, setConsulta] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  const [listaMarcas, setListaMarcas] = useState([]);
  const [consultarMarcas, setConsultarMarcas] = useState(true);
  const [mostrarAgregarMarca, setMostrarAgregarMarca] = useState(false);
  const [marcaIdSeleccionada, setMarcaIdSeleccionada] = useState(0);
  const [mostrarEditarMarca, setMostrarEditarMarca] = useState(false);
  const [marcaActualizadaHandle, setMarcaActualizadaHandle] = useState(false);
  useEffect(() => {
    if (consulta) {
      setConsulta(false);
      (async () => {
        try {
          setShowLoading(true);
          const request = await marcasPeticiones("obtenerListaMarcas",Token);
          setShowLoading(false);
          const { status, ok } = request;
          if (status === 401) {
            logout();
            return false;
          }
          if (!ok) {
            throw new Error("Error de petición.");
          }
          const response = await request.json();
          const { success, message = "", data = [] } = response;
          if (!success || data.length <= 0) {
            setListaMarcas([]);
            return false;
          }
          setListaMarcas(data);
          return true;
        } catch (error) {
          setShowLoading(false);
          SweetAlert(
            "Error",
            `Por favor contacte al administrador del sistema`,
            "error",
            true
          );
          return false;
        }
      })();
    }
  }, [consulta,Token,logout]);
  useEffect(() => {
    if (marcaActualizadaHandle) {
      setMarcaIdSeleccionada(0);
      setMarcaActualizadaHandle(false);
      setConsulta(true);
    }
  }, [marcaActualizadaHandle, setMarcaActualizadaHandle]);

  const handlerEditarMarca = async (marcasDatos) => {
    let { marcasId } = marcasDatos;
    marcasId = parseInt(marcasId);
    setMarcaIdSeleccionada(marcasId);
    setMostrarEditarMarca(true);
  };
  const handlerEliminarMarca = async (marcasDatos) => {
    let { marcasId:marcaId } = marcasDatos;
    marcaId = parseInt(marcaId);
    if (marcaId < 1) {
      SweetAlert(
        "Error",
        `No se puede identificar la marca que desea eliminar`,
        "error",
        true
      );
    }
    const preguntaEliminacionParametros = {
      tipo: "pregunta",
      titulo: "Advertencia",
      mensaje: "¿Esta seguro que desea eliminar este marca?",
    };

    const preguntaEliminacion = await SweetAlertQuestion(
      preguntaEliminacionParametros
    );
    const { isConfirmed = false } = preguntaEliminacion;
    if (!isConfirmed) {
      return false;
    }
    setShowLoading(true);
    const request = await marcasPeticiones(
      "eliminarMarca",
      Token,
      {marcaId}
    );
    setShowLoading(false);
    const { status, ok } = request;
    if (status === 401) {
      logout();
      return false;
    }
    if (!ok) {
      throw new Error("Error de petición.");
    }
    const response = await request.json();
    const { success = false, message = "" } = response;
    if (!success) {
      SweetAlert("Error", `${message}`, "error", true);
      return false;
    }
    SweetAlert("Exito", `${message}`, "success", true);
    setConsulta(true);
    return true;
  };
  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const generarPdfTabla = () => {
    const doc = new jsPDF({ orientation: "landscape" });
    const fechaActual = ()=>{
      return moment().utcOffset('-06:00').format('DD-MM-YYYY');
    }
    const fechaActualCompleta = ()=>{
      return moment().utcOffset('-06:00').format('DD-MM-YYYY-HH-mm-ss');
    }
    doc.text(`Lista de Marcas ${fechaActual()}`, 16, 10);
    doc.autoTable({
      head: [["#", "MARCA", "USUARIO CREO", "USUARIO ACTUALIZO"]],
      body: listaMarcas.map((marca, index) => [
        index + 1,
        marca.marcasDescripcion,
        marca.nombreUsuarioCreo,
        marca.nombreUsuarioActualizo,
      ]),
    });
    doc.save(`Lista-Marcas-${fechaActualCompleta()}.pdf`);
  };

  const botonesOpciones = (cell, row, rowIndex) => {
    return (
      <div className="d-flex">
        <Button
          size="sm"
          variant="info"
          onClick={() => handlerEditarMarca(row)}
          className="mx-1 btn-icon-only"
        >
          <AiOutlineEdit size="1.2em" />
        </Button>
        <Button
          size="sm"
          variant="danger"
          onClick={() => handlerEliminarMarca(row)}
          className="btn-icon-only"
        >
          <AiOutlineDelete size="1.2em" />
        </Button>
      </div>
    );
  };
  const columns = [
    { dataField: "marcasIndex", text: "#", sort: true },
    {
      dataField: "marcasId",
      text: "Opciones",
      sort: false,
      formatter: botonesOpciones,
    },
    { dataField: "marcasDescripcion", text: "Marca", sort: true },
    { dataField: "nombreUsuarioCreo", text: "Usuario Creo", sort: true },
    {
      dataField: "nombreUsuarioActualizo",
      text: "Usuario Actualizo",
      sort: true,
    },
  ];
  return (
    <div className="px-1">
      <Card>
        <div className="pagesCustomParent p-4">
          <Cargando loading={showLoading} />
          <div>
            <ToolkitProvider
              keyField="marcasId"
              data={listaMarcas}
              columns={columns}
              search
              exportCSV
            >
              {(props) => (
                <>
                  <Row>
                    <Col md={6} sm={12}>
                      <label>Buscar Marca:</label>
                      <SearchBar {...props.searchProps} className="my-2 w-40" />
                    </Col>
                    <Col
                      md={6}
                      sm={12}
                      className="d-flex align-items-end justify-content-end"
                    >
                      <Button
                        className="mx-2 my-2"
                        size="sm"
                        variant="info"
                        onClick={() => setMostrarAgregarMarca(true)}
                      >
                        <FontAwesomeIcon icon={faPlus} /> Nueva Marca
                      </Button>
                      <ExportCSVButton
                        {...props.csvProps}
                        className="btn btn-tertiary btn-sm my-2"
                      >
                        <FontAwesomeIcon icon={faFileCsv} /> CSV
                      </ExportCSVButton>
                      <Button
                        className="mx-2 my-2"
                        size="sm"
                        variant="danger"
                        onClick={generarPdfTabla}
                      >
                        <FontAwesomeIcon icon={faFilePdf} /> PDF
                      </Button>
                    </Col>
                  </Row>

                  <BootstrapTable
                    tableHeaderClass="table"
                    wrapperClasses="table-responsive"
                    headerClasses=""
                    hover
                    bordered={false}
                    search={{}}
                    align="center"
                    tabIndexCell
                    pagination={paginationFactory()}
                    noDataIndication="No existen marcas   registrados"
                    {...props.baseProps}
                  ></BootstrapTable>
                </>
              )}
            </ToolkitProvider>
          </div>
        </div>
      </Card>
      {mostrarAgregarMarca && (
        <NuevaMarca
          mostrarAgregarMarca={mostrarAgregarMarca}
          setMostrarAgregarMarca={setMostrarAgregarMarca}
          setConsultarMarcas={setConsultarMarcas}
        />
      )}
      {mostrarEditarMarca && marcaIdSeleccionada > 0 && (
        <EditarMarca
          marcaId={marcaIdSeleccionada}
          mostrarEditarMarca={mostrarEditarMarca}
          setMostrarEditarMarca={setMostrarEditarMarca}
          marcaActualizadaHandle={setMarcaActualizadaHandle}
        />
      )}
    </div>
  );
};

export default MarcasLista;
