import React, { useEffect, useState } from "react";
import { Col, Row  } from "@themesberg/react-bootstrap";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faBoxOpen,
//   faCartArrowDown,
//   faChartPie,
//   faChevronDown,
//   faClipboard,
//   faCommentDots,
//   faFileAlt,
//   faPlus,
//   faRocket,
//   faStore,
// } from "@fortawesome/free-solid-svg-icons";
// import {
//   ChoosePhotoWidget,
//   ProfileCardWidget,
// } from "../../../../components/Widgets";
//import Profile3 from "../../../../assets/img/team/profile-picture-3.jpg";
import customers from "../../../../assets/img/tiendaBuenPrecio/util/customers.svg";
import UsuarioPerfilForm from "../UsuarioPerfilForm";
import { useAuthContext } from "../../../../contexts/authContext";
import TarjetaPresentacionUsuarioPerfil from "../components/TarjetaPresentacionUsuarioPerfil";
import { petitionImageToBase64 } from "../../../../helpers/petition";
import CambiarFotoPerfil from "../components/CambiarFotoPerfil";

export const UsuarioPerfil = () => {
  const { obtenerDatosUsuarios, token:Token } = useAuthContext();
  const [consultarImagenPerfilHandle, setConsultarImagenPerfilHandle] = useState(true);
  let {userId = 0,name:nombres = "", lastName:apellidos = "" , userRole:rol = "", userImages = []} = obtenerDatosUsuarios();
  const [imagenUsuario, setImagenUsuario] = useState(customers);
  userId = parseInt(userId);
  useEffect(() => {
    const obtenerImagenes = async (url, token) => {
      const formData = {};
      const urlTarget = url;
      const petitionMethod = "GET";
      const petition = await petitionImageToBase64(
        urlTarget,
        formData,
        petitionMethod,
        token
      );
      return petition;
    }
    if(consultarImagenPerfilHandle){
      setConsultarImagenPerfilHandle(false);
      (async()=>{
        const {link:payload = ""} = userImages[0] ?? [];
        if(payload.trim().length > 2){
          const imagenBase64 = await obtenerImagenes(payload,Token);
          setImagenUsuario(imagenBase64);
        }
      })();
    }
}, [consultarImagenPerfilHandle,Token,userImages]);
  return (
    <>
      <Row className="mt-4">
        <Col xs={12} xl={8}>
          <UsuarioPerfilForm 
            usuariosId={userId}
          />
        </Col>

        <Col xs={12} xl={4}>
          <Row>
            <Col xs={12}>
              <TarjetaPresentacionUsuarioPerfil
                usuariosId = {userId}
                nombres = {nombres}
                apellidos  = {apellidos}
                rol = {rol}
                usuarioImagen= {imagenUsuario}
              />
            </Col>
            <Col xs={12}>
              <CambiarFotoPerfil
               usuariosId = {userId}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
