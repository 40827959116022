import { makePetition } from "../../../../helpers/petition";

const obtenerListaProveedores = async (Token) => {
  try {
    const formData = {};
    const urlTarget = "proveedores/lista";
    const petitionMethod = "GET";
    const petition = await makePetition(
      urlTarget,
      formData,
      petitionMethod,
      Token
    );
    return petition;
  } catch (error) {
    throw error;
  }
};
const obtenerProveedorEditar = async (Token, proveedorId) => {
  try {
    const formData = {
      proveedoresId: proveedorId,
    };
    const urlTarget = `proveedores/proveedor/${proveedorId}`;
    const petitionMethod = "GET";
    const petition = await makePetition(
      urlTarget,
      formData,
      petitionMethod,
      Token
    );
    return petition;
  } catch (error) {
    throw error;
  }
};
const guardarProveedor = async (
  Token,
  proveedorId,
  proveedoresDescripcion,
  proveedoresDireccion,
  proveedoresNIT,
  proveedoresNRC,
  proveedoresTelefono,
  proveedoresEmail,
  proveedoresGiro
) => {
  try {
    const formData = {
      proveedoresDescripcion,
      proveedoresDireccion,
      proveedoresNIT,
      proveedoresNRC,
      proveedoresTelefono,
      proveedoresEmail,
      proveedoresGiro,
    };
    if (proveedorId > 0) formData.proveedoresId = proveedorId;
    const urlTarget =
      proveedorId > 0 ? "proveedores/editar" : "proveedores/nuevo";
    const petitionMethod = proveedorId > 0 ? "PUT" : "POST";
    const petition = await makePetition(
      urlTarget,
      formData,
      petitionMethod,
      Token
    );
    return petition;
  } catch (error) {
    throw error;
  }
};
const eliminarProveedores = async (Token, proveedorId) => {
  try {
    proveedorId = parseInt(proveedorId);
    const formData = { proveedoresId: proveedorId };
    const urlTarget = "proveedores/eliminar";
    const petitionMethod = "DELETE";
    const petition = await makePetition(
      urlTarget,
      formData,
      petitionMethod,
      Token
    );
    return petition;
  } catch (error) {
    throw error;
  }
};

export {
  obtenerListaProveedores,
  eliminarProveedores,
  obtenerProveedorEditar,
  guardarProveedor,
};
