const makePetition = async (url, params, petitionMethod, token = "") => {
  try {
    let host = "https://api.tiendabuenprecio.com";
    //host = host === "localhost" ? "127.1.1" : host;
    const payload = `${host}/${url}`;
    let headersData = {
      "Content-Type": "application/json",
      Authorization:
        "Basic OWZmVG1oOTJSUkJqQ2RrVnY2SjhmTjlLc0xXd1p3ZXVuNFJGZzNINGRHdU1UUlZkd1o6Y2p4N2dnNXliYTdFazNEU0xYZVhCMlVEN0xQVHJrRm5jNE5Fd0JDaEJxenRuclo5UXI=",
    };
    if (token.length > 1) {
      headersData = { ...headersData, token: token };
    }
    const dataToken = {
      method: petitionMethod,
      headers: headersData,
      redirect: "follow",
    };
    if (petitionMethod !== "GET") {
      dataToken.body = JSON.stringify(params);
    }
    const response = await fetch(payload, dataToken);
    return response;
  } catch (error) {
    return {
      success: false,
      message: "Ocurrió un error en la(s) peticion(es)",
    };
  }
};
const petitionImageToBase64 = async (
  url,
  params,
  petitionMethod,
  token = "",
  typeFile = "IMAGE"
) => {
 try {
  const host = "https://api.tiendabuenprecio.com";
  //host = host === "localhost" ? "127.1.1" : host;
  const payload = `${host}/${url}`;
  const dataToken = {
    method: petitionMethod,
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Basic OWZmVG1oOTJSUkJqQ2RrVnY2SjhmTjlLc0xXd1p3ZXVuNFJGZzNINGRHdU1UUlZkd1o6Y2p4N2dnNXliYTdFazNEU0xYZVhCMlVEN0xQVHJrRm5jNE5Fd0JDaEJxenRuclo5UXI=",
      token: token,
    },
    redirect: "follow",
  };
  if (petitionMethod !== "GET") {
    dataToken.body = JSON.stringify(params);
  }
  const response = await fetch(payload, dataToken);
  const { ok } = response;
  if (!ok) {
    return null;
  }
  if(typeFile === "PDF"){
    const tipoMime = response.headers.get('Content-Type');
    if(tipoMime.includes('application/json')){
      return await response.json();
    }
  }
  const blob = await response.blob();
  const imageObjectURL = URL.createObjectURL(blob);
  return imageObjectURL;
 } catch (error) {
  console.error(error);
 }
};

export { makePetition, petitionImageToBase64 };
