import React, { useEffect, useState } from "react";
import FileBase64 from "react-file-base64";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import imgPreview from "../../../assets/img/tiendaBuenPrecio/util/image-svgrepo-com.svg";
import Compressor from "compressorjs";
const UsuariosImagenes = ({
  listaUsuariosImagenes,
  setListaUsuariosImagenes,
  imagenPreviewFrontal,
  imagenPreviewAdicional,
}) => {
  const [claseAsignada, setClaseAsignada] = useState(false);
  const MySwal = withReactContent(Swal);
  const tipoImagenesPermitidas = [
    "image/apng",
    "image/avif",
    "image/gif",
    "image/jpeg",
    "image/png",
    "image/svg+xml",
    "image/webp",
  ];
  const mostrarMensajeFormatoInvalido = () => {
    MySwal.fire({
      icon: "error",
      html: (
        <>
          <h2>Atención!</h2>
          <h4>
            El formato de imagen es invalido, por favor suba una imagen valida.
          </h4>
        </>
      ),
      showCancelButton: true,
      showCloseButton: true,
    });
  };
  const handleImageFrontal = (files) => {
    const { name, type, size, file } = files;
    if (!tipoImagenesPermitidas.includes(type)) {
      mostrarMensajeFormatoInvalido();
      return false;
    }
    new Compressor(file, {
      quality: 0.5,
      success(result) {
        const reader = new FileReader();
        reader.onloadend = function () {
          const base64data = reader.result;
          setListaUsuariosImagenes({
            ...listaUsuariosImagenes,
            imagenFrontal: { name, type, size, base64: base64data },
          });
        };
        reader.readAsDataURL(result);
      },
      error(err) {
        console.log(err.message);
      },
    });
    return true;
  };
  const handleImageAdicional = (files) => {
    const { name, type, size, file } = files;
    if (!tipoImagenesPermitidas.includes(type)) {
      mostrarMensajeFormatoInvalido();
      return false;
    }
    new Compressor(file, {
      quality: 0.5,
      success(result) {
        const reader = new FileReader();
        reader.onloadend = function () {
          const base64data = reader.result;
          setListaUsuariosImagenes({
            ...listaUsuariosImagenes,
            imagenAdicional: { name, type, size, base64: base64data },
          });
        };
        reader.readAsDataURL(result);
      },
      error(err) {
        console.log(err.message);
      },
    });
    return true;
  };
  useEffect(() => {
    if (!claseAsignada) {
      [...document.querySelectorAll("input[type='file']")].map((input) => {
        const id = input.parentElement.classList[1];
        input.id = id;
        input.name = id;
        input.classList.add("form-control");
        return true;
      });
      setClaseAsignada(true);
    }
  }, [claseAsignada, setClaseAsignada]);
  const imagenFrontalValue = listaUsuariosImagenes.imagenFrontal;
  const imagenAdicionalValue = listaUsuariosImagenes.imagenAdicional;
  const regexBlob = /blob/i;
  //Object.keys(imagenAdicional).length > 0 || regexBlob.test(imagenPreviewAdicional)
  console.log(
    "Object.keys(imagenAdicional).length: ",
    Object.keys(imagenAdicionalValue).length
  );
  console.log(
    "regexBlob.test(imagenPreviewAdicional): ",
    regexBlob.test(imagenPreviewAdicional)
  );
  return (
    <div className="d-flex d-flex justify-content-around">
      <div className="mt-4">
        <label
          htmlFor="imagenFrontal"
          className="d-flex flex-column text-center pointer"
        >
          <span>Foto Perfil</span>
          <img
            className={[
              Object.keys(imagenFrontalValue).length > 0 ||
              regexBlob.test(imagenFrontalValue)
                ? "imageUploadedPreview"
                : "",
              "mt-2",
            ].join(" ")}
            src={
              Object.keys(imagenFrontalValue).length > 0
                ? imagenFrontalValue.base64
                : imagenPreviewFrontal
            }
            width={
              Object.keys(imagenFrontalValue).length > 0 ||
              regexBlob.test(imagenFrontalValue)
                ? "100%"
                : 220
            }
            style={{ pointersEvent: "none" }}
            alt="Imagen Frontal"
          />
        </label>
        <div
          className="imageFrontalContainer imagenFrontal"
          style={{ display: "none" }}
        >
          <FileBase64 onDone={handleImageFrontal} />
        </div>
      </div>

      <div className="mt-4">
        <label
          htmlFor="imagenAdicional"
          className="d-flex flex-column text-center pointer"
        >
          <span>Adicional</span>
          <img
            className={[
              Object.keys(imagenAdicionalValue).length > 0 ||
              regexBlob.test(imagenAdicionalValue)
                ? "imageUploadedPreview"
                : "",
              "mt-2",
            ].join(" ")}
            src={
              Object.keys(imagenAdicionalValue).length > 0
                ? imagenAdicionalValue.base64
                : imagenPreviewAdicional
            }
            width={
              Object.keys(imagenAdicionalValue).length > 0 ||
              regexBlob.test(imagenAdicionalValue)
                ? "100%"
                : 220
            }
            style={{ pointersEvent: "none" }}
            alt="Imagen Adicional"
          />
        </label>
        <div
          className="imageFrontalAdicional imagenAdicional"
          style={{ display: "none" }}
        >
          <FileBase64 onDone={handleImageAdicional} />
        </div>
      </div>
    </div>
  );
};

export default UsuariosImagenes;
