import React from 'react';
import { Navigate, Outlet } from "react-router-dom";
import { useAuthContext } from "../../contexts/authContext";
import { Paths } from "../../config/router/paths";

const PrivateRouter = () => {
  const { estaAutenticado,token,logout } = useAuthContext();
  if (
    !estaAutenticado ||
    !token ||
    String(token).length < 100
  ) {
    logout();
    return <Navigate to={Paths.Signin.path} />;
  }
  return (
    <div>
      <Outlet />
    </div>
  );
};

export default PrivateRouter;
