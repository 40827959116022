import React from "react";
import { Form } from "@themesberg/react-bootstrap";
import { useEffect, useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { makePetition } from "../../../helpers/petition";
import SweetAlert from "../Utils/SweetAlert";
import { BsBoxSeam } from "react-icons/bs";
import { useAuthContext } from "../../../contexts/authContext";
import { Notify } from "notiflix";
const ProductosBuscarPorNombreYProveedorId = ({
  setFacturaDatosProductoPreview,
  proveedoresId
}) => {
  const { logout, token: Token } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const handleSearch = (query) => {
    setIsLoading(true);
    setSearchValue(query);
  };
  useEffect(() => {
    const searchValuelength = searchValue.toString().trim().length;
    if (searchValuelength > 1) {
      const busquedaProductoHandle = setTimeout(async () => {
        try {
          if(proveedoresId<1){
            Notify.failure(
              "Por favor seleccione un proveedor."
            );
            return false;
          }
          const formData = {};
          const urlTarget = `productos/productoPorNombreYProveedor/${searchValue}/${proveedoresId}`;
          const petitionMethod = "GET";
          const request = await makePetition(
            urlTarget,
            formData,
            petitionMethod,
            Token
          );
          const { status, ok } = request;
          if (status === 401) {
            logout();
            return false;
          }
          if (!ok) {
            throw new Error("Error de petición.");
          }
          const response = await request.json();
          const { success, message = "", data = [] } = response;
          setIsLoading(false);
          if (!success) {
            SweetAlert("Error", `${message}`, "error", true);
            return false;
          }
          if (data.lenght < 1) {
            SweetAlert(
              "Error",
              `No existen datos de este proveedor`,
              "error",
              true
            );
            return false;
          }
          setOptions(data);
        } catch (error) {
          SweetAlert("Error", `Ocurrió un error inesperado. Por favor contacte con el administrador.`, "error", true);
          return false;
        }
      }, 1000);
      return () => clearTimeout(busquedaProductoHandle);
    }
  }, [searchValue,Token,logout,proveedoresId]);

  const handleSeleccionarProducto = async (producto) => {
    const { productosId } = producto;
    const obtenerInformacionExtra = async () => {
      const formData = {};
      const urlTarget = "productos/producto/" + productosId;
      const petitionMethod = "GET";
      const request = await makePetition(
        urlTarget,
        formData,
        petitionMethod,
        Token
      );
      const { status, ok } = request;
      if (status === 401) {
        logout();
        return false;
      }
      if (!ok) {
        throw new Error("Error de petición.");
      }
      const response = await request.json();
      const { success, message = "", data = [] } = response;
      return response;
    };
    const obtenerProductoInformacionExtra = await obtenerInformacionExtra();
    const { success = false, data: productoInformacionExtra = [] } =
      obtenerProductoInformacionExtra;
    if (!success || productoInformacionExtra.lenght < 1) {
      SweetAlert(
        "Error",
        `Ocurrió un error al obtener la información del producto. Por favor contacte al administrador del sistema para recibir asistencia. `,
        "error",
        true
      );
      return false;
    }
    if (document.querySelector(".rbt-close")) {
      document.querySelector(".rbt-close").click();
    }
    setFacturaDatosProductoPreview(productoInformacionExtra);
  };
  const renderItem = (productos) => (
    <div
      className="d-flex"
      onClick={() => handleSeleccionarProducto(productos)}
    >
      <span className="mx-3">
        <BsBoxSeam size="2em" />
      </span>
      <span className="mx-3">{productos.productosDescripcion}</span>
    </div>
  );
  const filterBy = () => true;
  return (
    <>
      <Form.Label>Buscar Producto Por Nombre</Form.Label>
      <AsyncTypeahead
        useCache={false}
        filterBy={filterBy}
        id="buscardorProductos"
        name="buscardorProductos"
        isLoading={isLoading}
        labelKey="productosDescripcion"
        minLength={2}
        onSearch={handleSearch}
        options={options}
        placeholder="Búsqueda de productos por nombre"
        renderMenuItemChildren={renderItem}
        clearButton={true}
      />
    </>
  );
};
export default ProductosBuscarPorNombreYProveedorId;
