import React from 'react'
import { Loading } from 'notiflix/build/notiflix-loading-aio';
const Cargando = ({loading}) => {
    //let [color, setColor] = useState("#1C4E80");
    if(loading){
        Loading.circle('Cargando...', {
            backgroundColor: 'rgb(4 16 57 / 80%)',
            });
    }else{
        Loading.remove();
    }
    return (
        ""
    );
}

export default Cargando;