import { React, useState, useEffect, useCallback } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Row,
} from "@themesberg/react-bootstrap";
import DatePicker from "react-date-picker";
import { BsPlusLg, BsReply } from "react-icons/bs";
import { facturaCompraPeticiones } from "../../../components/tiendaBuenPrecioComponents/FacturacionCompras/helper/facturaCompraPeticiones";
import ProductosBuscarPorCodigoYProveedorId from "../../../components/tiendaBuenPrecioComponents/Facturacion/ProductosBuscarPorCodigoYProveedorId";
import { AiOutlineAlert } from "react-icons/ai";
import FacturaCompraListaProductosSeleccionados from "./helper/FacturaCompraListaProductosSeleccionados";
import ModalProductosCrud from "../Productos/helper/ModalProductosCrud.jsx";
import customDecimalRound from "../Utils/customDecimalRound";
import ModalAgregarProductos from "../Productos/helper/ModalAgregarProductos";
import ProveedorModalForm from "../Proveedores/ProveedorModalForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { format } from "date-fns";
import Cargando from "../Utils/Loading";
import SweetAlert from "../Utils/SweetAlert";
import { useAuthContext } from "../../../contexts/authContext";
import ProductosBuscarPorNombreYProveedorId from "../Facturacion/ProductosBuscarPorNombreYProveedorId";
const FacturaCompra = ({
  type,
  facturaId,
  datosFacturaDefault,
  listaProductosDefaultState,
  accionCancelar,
}) => {
  const { logout, token: Token } = useAuthContext();
  const [consultarProveedores, setConsultarProveedores] = useState(true);
  const [listaProveedores, setListaProveedores] = useState([]);
  const [showModalProductosCrud, setShowModalProductosCrud] = useState(false);
  const [productoSeleccionadoDatos, setProductoSeleccionadoDatos] = useState(
    {}
  );
  const [mostrarAlerta, setMostrarAlerta] = useState(false);
  const [mensajeAlerta, setMensajeAlerta] = useState("");
  const [showLoading, setShowLoading] = useState(false);

  const [proveedoresId, setProveedoresId] = useState(
    datosFacturaDefault.proveedoresId
  );
  const [facturaCodigo, setFacturaCodigo] = useState(
    datosFacturaDefault.facturaCodigo
  );
  const [facturaCodigoCompra, setFacturaCodigoCompra] = useState(
    datosFacturaDefault.facturaCodigoCompra
  );
  const [facturaCodigoPedido, setFacturaCodigoPedido] = useState(
    datosFacturaDefault.facturaCodigoPedido
  );
  const [facturaFecha, setFacturaFecha] = useState(
    datosFacturaDefault.facturaFecha
  );
  const [facturaFechaVencimiento, setFacturaFechaVencimiento] = useState(
    datosFacturaDefault.facturaFechaVencimiento
  );
  const [unoPorcientoHabilitado, setUnoPorcientoHabilitado] = useState(
    datosFacturaDefault.facturaUnoPorcientoHabilitado
  );
  const [facturaTotalIvaUno, setfacturaTotalIvaUno] = useState(
    datosFacturaDefault.facturaTotalIvaUno
  );
  const [facturaComentarios, setFacturaComentarios] = useState(
    datosFacturaDefault.facturaComentarios
  );
  const [impuestoIva, setImpuestoIva] = useState(
    datosFacturaDefault.facturaIva
  );
  const [facturaTotalIva, setfacturaTotalIva] = useState(
    datosFacturaDefault.facturaTotalIva
  );
  const [facturaSubTotal, setfacturaSubTotal] = useState(
    datosFacturaDefault.facturaSubTotal
  );
  const [facturaTotal, setfacturaTotal] = useState(
    datosFacturaDefault.facturaTotal
  );
  const [listaProductosSeleccionados, setListaProductosSeleccionados] =
    useState(listaProductosDefaultState);
  // State para formulario - end

  const [buscarPorCodigo, setBuscarPorCodigo] = useState("");
  const [showModalProductoForm, setShowModalProductoForm] = useState(false);
  const [mostrarModalProveedor, setMostrarModalProveedor] = useState(false);
  // const [consultarProveedoresModal, setConsultarProveedoresModal] = useState(true);
  const [proveedorIdModalState, setProveedorIdModalState] = useState(0);
  const [deshabilitarBotonForm, setDeshabilitarBotonForm] = useState(false);
  const setDatosProductoSeleccionadoDesdeBusqueda = useCallback(
    (data) => {
      setBuscarPorCodigo("");
      const {
        productosCodigo,
        productosId,
        productosDescripcion,
        productosPrecioCompra: productosPrecioCompraAnterior,
        productosPrecioVentaLista,
      } = data;
      setProductoSeleccionadoDatos({
        productosId: productosId,
        codigoProducto: productosCodigo,
        descripcionProducto: productosDescripcion,
        precioVentaListaProducto: productosPrecioVentaLista,
        precioCompraIndividualAnteriorProducto: Number(
          productosPrecioCompraAnterior
        ),
        precioCompraIndividualProducto: 0,
        nuevaExistenciaProducto: 0,
      });

      setShowModalProductosCrud(true);
      // setListaProductosSeleccionados([
      //   ...listaProductosSeleccionados,

      // ]);
    },
    [listaProductosSeleccionados]
  );

  const productoSeleccionadoHandleChange = (target) => {
    const valoresNumericosId = [
      "precioCompraIndividualProducto",
      "nuevaExistenciaProducto",
    ];
    const valoresArray = ["precioVentaListaProducto"];
    if (target) {
      let { id = "", value = "" } = target;
      if (id.toString().trim().length > 1) {
        if (valoresNumericosId.includes(id)) {
          value = customDecimalRound(value);
          if (!isNaN(value) && value < 0) {
            return false;
          }
        }
        setProductoSeleccionadoDatos({
          ...productoSeleccionadoDatos,
          [id]:
            !valoresArray.includes(id) && !valoresNumericosId.includes(id)
              ? value.toString().trim()
              : value,
        });
      }
    }
  };
  const asignarProductoSeleccionadoAListaProducto = () => {
    try {
      const {
        productosId = 0,
        precioCompraIndividualAnteriorProducto = 0,
        precioCompraIndividualProducto = 0,
        nuevaExistenciaProducto = 0,
      } = productoSeleccionadoDatos;
      if (
        Number(precioCompraIndividualAnteriorProducto) > 0 &&
        Number(precioCompraIndividualAnteriorProducto) !==
          Number(precioCompraIndividualProducto)
      ) {
        SweetAlert(
          "Advertencia",
          `El precio de compra cambio, por favor estableza nuevamente los precios de venta`,
          "advertencia",
          true
        );
      }

      let nuevoArrayConProductoSeleccion;
      const productoExiste = listaProductosSeleccionados.find((p) => {
        const { productosId: productosIdExistente = 0 } = p;
        return parseInt(productosId) === parseInt(productosIdExistente);
      });
      if (!productoExiste) {
        nuevoArrayConProductoSeleccion = [
          ...listaProductosSeleccionados,
          productoSeleccionadoDatos,
        ];
      } else {
        nuevoArrayConProductoSeleccion = listaProductosSeleccionados.map(
          (p) => {
            if (parseInt(productosId) === parseInt(p.productosId)) {
              p["precioCompraIndividualProducto"] = Number(
                precioCompraIndividualProducto
              );
              p["nuevaExistenciaProducto"] = Number(nuevaExistenciaProducto);
              return p;
            } else {
              return p;
            }
          }
        );
      }
      setListaProductosSeleccionados(nuevoArrayConProductoSeleccion);
      setShowModalProductosCrud(false);
    } catch (error) {
      console.error(error);
    }
  };
  const eliminarProductoSeleccionadoEnListaProducto = (productosId) => {
    const nuevoListaProductosSeleccionados = listaProductosSeleccionados.filter(
      (p) => parseInt(productosId) !== parseInt(p.productosId)
    );
    setListaProductosSeleccionados(nuevoListaProductosSeleccionados);
  };
  const editarProductoSeleccionadoEnListaProducto = (productosId) => {
    const dataListaProductosSeleccionado = listaProductosSeleccionados.filter(
      (p) => parseInt(productosId) === parseInt(p.productosId)
    );
    if (dataListaProductosSeleccionado.length > 0) {
      setProductoSeleccionadoDatos(dataListaProductosSeleccionado[0]);
      setShowModalProductosCrud(true);
    }
  };

  const handleCancelarCreacionFactura = () => {
    accionCancelar();
  };
  const handleGuardarFactura = async () => {
    try {
      let error = false;
      let errorMessage = "Ocurrió un error inesperado. \n";
      setDeshabilitarBotonForm(true);
      setMensajeAlerta("");
      setMostrarAlerta(false);
      if (!facturaCodigo || facturaCodigo.toString().length < 1) {
        error = true;
        errorMessage += "El código de factura es requerido. \n";
      }
      if (!(facturaFecha instanceof Date)) {
        error = true;
        errorMessage += "Factura de fecha es invalida. \n";
      }
      if (parseInt(proveedoresId) < 1) {
        error = true;
        errorMessage += "Debe Seleccione un proveedor. \n";
      }
      if (listaProductosSeleccionados.length < 1) {
        error = true;
        errorMessage += "La factura debe tener al menos un producto. \n";
      }
      if (facturaTotal < 0.01) {
        error = true;
        errorMessage += "El total de factura es inválido. \n";
      }
      if (error) {
        setMensajeAlerta(errorMessage);
        setMostrarAlerta(true);
        setDeshabilitarBotonForm(false);
        return false;
      }
      let facturaFechaFormateada = format(facturaFecha, "yyyy/MM/dd");
      let facturaFechaVencimientoFormateada = "";
      if (facturaFecha instanceof Date) {
        facturaFechaVencimientoFormateada = format(facturaFecha, "yyyy/MM/dd");
      }
      const listaProductos = listaProductosSeleccionados.map((producto) => {
        const {
          productosId,
          codigoProducto,
          descripcionProducto,
          precioVentaListaProducto,
          precioCompraIndividualAnteriorProducto,
          precioCompraIndividualProducto,
          nuevaExistenciaProducto,
        } = producto;
        return {
          productoId: parseInt(productosId),
          productoCodigo: codigoProducto.toString().trim(),
          productoDescripcion: descripcionProducto.toString().trim(),
          productoPrecioVenta: 0,
          productoPrecioCompraAnterior: customDecimalRound(
            Number(precioCompraIndividualAnteriorProducto)
          ),
          productoPrecioCompra: customDecimalRound(
            Number(precioCompraIndividualProducto)
          ),
          productoCantidad: customDecimalRound(Number(nuevaExistenciaProducto)),
          productoPrecioMontoSinIva: customDecimalRound(
            Number(precioCompraIndividualProducto)
          ),
          productoPrecioMonto: customDecimalRound(
            Number(precioCompraIndividualProducto)
          ),
          productoPrecioVentaLista: precioVentaListaProducto,
        };
      });
      let requestData = {
        proveedoresId: parseInt(proveedoresId),
        facturaCodigo: facturaCodigo.toString().trim(),
        facturaCodigoCompra: facturaCodigoCompra.toString().trim(),
        facturaCodigoPedido: facturaCodigoPedido.toString().trim(),
        facturaFecha: facturaFechaFormateada.toString().trim(),
        facturaFechaVencimiento: facturaFechaVencimientoFormateada
          .toString()
          .trim(),
        facturaComentarios: facturaComentarios.toString().trim(),
        facturaUnoPorcientoHabilitado: unoPorcientoHabilitado ? 1 : 0,
        facturaIva: customDecimalRound(impuestoIva),
        facturaSubTotal: customDecimalRound(facturaSubTotal),
        facturaTotalIva: customDecimalRound(facturaTotalIva),
        facturaTotalIvaUno: customDecimalRound(facturaTotalIvaUno),
        facturaTotal: customDecimalRound(facturaTotal),
        facturaListaProductos: listaProductos,
      };
      if (type === "EDITAR") {
        requestData = { ...requestData, facturaId: facturaId };
      }
      const request = await facturaCompraPeticiones(
        type === "EDITAR" ? "actualizarDatosFactura" : "guardarDatosFactura",
        Token,
        requestData
      );
      setShowLoading(false);
      const { status, ok } = request;
      if (status === 401) {
        logout();
        return false;
      }
      if (!ok) {
        throw new Error("Error de petición.");
      }
      const response = await request.json();
      const { success = false, message = "" } = response;
      if (!success) {
        setDeshabilitarBotonForm(false);
        setMensajeAlerta(
          `${
            message.length > 3
              ? message
              : "Ocurrió un inesperado al guardar la factura."
          }`
        );
        setMostrarAlerta(true);
        return response;
      }
      SweetAlert("Exito", `${message}`, "success", true);
      handleCancelarCreacionFactura();
    } catch (error) {
      setDeshabilitarBotonForm(false);
      setMensajeAlerta("Ocurrió un error al guarda la factura");
      setMostrarAlerta(true);
    }
  };
  useEffect(() => {
    async function obtenerListaProveedoresHandler() {
      try {
        const requestData = await facturaCompraPeticiones(
          "getListaProveedores",
          Token
        );
        const { status, ok } = requestData;
        if (status === 401) {
          logout();
          return false;
        }
        if (!ok) {
          throw new Error("Error de petición.");
        }
        const response = await requestData.json();
        const { success, message = "", data = [] } = response;
        if (!success) {
          const errorMessage = message.toString().trim();
          setMensajeAlerta(
            errorMessage.trim().length > 0
              ? errorMessage
              : "Ocurrió un error al consultar los proveedores"
          );
          setMostrarAlerta(true);
        }
        setListaProveedores(data);
        if (proveedorIdModalState > 0) {
          setProveedoresId(proveedorIdModalState);
        }
      } catch (error) {
        setMensajeAlerta("Ocurrió un error al consultar los proveedores");
        setMostrarAlerta(true);
      }
    }
    if (consultarProveedores) {
      setConsultarProveedores(false);
      obtenerListaProveedoresHandler();
    }
  }, [consultarProveedores, Token, logout, proveedorIdModalState]);
  useEffect(() => {
    const setTotalesFactura = setTimeout(() => {
      let subTotalFactura = 0;
      let totalIva = 0;
      let totalIvaUno = 0;
      let totalFinal = 0;
      listaProductosSeleccionados.map((producto) => {
        const { nuevaExistenciaProducto, precioCompraIndividualProducto } =
          producto;
        const total =
          Number(nuevaExistenciaProducto) *
          Number(precioCompraIndividualProducto);
        subTotalFactura += Number(isNaN(total) ? 0 : total);
      });
      totalIva = customDecimalRound(Number(subTotalFactura * impuestoIva));
      totalIvaUno = unoPorcientoHabilitado
        ? customDecimalRound(Number(subTotalFactura * 0.01))
        : 0.0;
      totalFinal = customDecimalRound(
        Number(subTotalFactura) + Number(totalIva) + Number(totalIvaUno)
      );
      setfacturaSubTotal(subTotalFactura);
      setfacturaTotalIva(totalIva);
      setfacturaTotalIvaUno(totalIvaUno);
      setfacturaTotal(totalFinal);
    }, 1000);
    return () => clearTimeout(setTotalesFactura);
  }, [listaProductosSeleccionados, unoPorcientoHabilitado, impuestoIva]);
  return (
    <div className="mt-4 facturaContainer">
      <Cargando loading={showLoading} />
      <Card className="mb-4">
        <Card.Body>
          <Row>
            <Col className="d-flex justify-content-end">
              <Button
                type="button"
                onClick={handleCancelarCreacionFactura}
                className="mx-2"
                variant="danger"
              >
                <BsReply className="mx-2" /> Cancelar
              </Button>
              <Button
                type="button"
                onClick={handleGuardarFactura}
                variant="outline-primary"
                disabled={deshabilitarBotonForm}
              >
                <BsPlusLg className="mx-2" /> Guardar Factura
              </Button>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <Alert
                variant="danger"
                show={mostrarAlerta}
                onClose={() => setMostrarAlerta(false)}
                dismissible
              >
                <Alert.Heading>
                  <AiOutlineAlert size={25} />
                  <span className="mx-2">¡Atención!</span>
                </Alert.Heading>
                <p>{mensajeAlerta}</p>
              </Alert>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col xs="12" md="12" lg="4">
              <Form.Group>
                <Form.Label>Código de Factura</Form.Label>
                <Form.Control
                  type="text"
                  name="facturaCodigo"
                  id="facturaCodigo"
                  value={facturaCodigo}
                  onChange={(e) => setFacturaCodigo(e.target.value.toString())}
                />
              </Form.Group>
            </Col>
            <Col xs="12" md="12" lg="4">
              <Form.Group>
                <Form.Label>Fecha Factura*</Form.Label>
                <DatePicker
                  className="form-control"
                  format="dd-MM-yyyy"
                  locale="es-ES"
                  name="facturaFecha"
                  id="facturaFecha"
                  value={facturaFecha}
                  onChange={(value) => setFacturaFecha(value)}
                />
              </Form.Group>
            </Col>
            <Col xs="12" md="12" lg="4">
              <Form.Group>
                <Form.Label>Fecha Vencimiento</Form.Label>
                <DatePicker
                  className="form-control"
                  format="dd-MM-yyyy"
                  locale="es-ES"
                  name="facturaFechaVencimiento"
                  id="facturaFechaVencimiento"
                  value={facturaFechaVencimiento}
                  onChange={(value) => setFacturaFechaVencimiento(value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col xs="12" md="12" lg="4">
              <Form.Group>
                <Form.Label>Código de Compra</Form.Label>
                <Form.Control
                  type="text"
                  name="facturaCodigoCompra"
                  id="facturaCodigoCompra"
                  value={facturaCodigoCompra}
                  onChange={(e) =>
                    setFacturaCodigoCompra(e.target.value.toString())
                  }
                />
              </Form.Group>
            </Col>
            <Col xs="12" md="12" lg="4">
              <Form.Group>
                <Form.Label>Código de Pedido</Form.Label>
                <Form.Control
                  type="text"
                  name="facturaCodigoCompra"
                  id="facturaCodigoCompra"
                  value={facturaCodigoPedido}
                  onChange={(e) =>
                    setFacturaCodigoPedido(e.target.value.toString())
                  }
                />
              </Form.Group>
            </Col>
            <Col xs="12" md="12" lg="4">
              <Form.Group>
                <Form.Label>Preveedor Compra</Form.Label>
                <Form.Select
                  name="proveedoresId"
                  id="proveedoresId"
                  value={proveedoresId}
                  onChange={(e) => setProveedoresId(parseInt(e.target.value))}
                  required
                >
                  <option value={0}>Seleccione un proveedor</option>
                  {listaProveedores.map((proveedor) => {
                    const { index, proveedoresId, proveedoresDescripcion } =
                      proveedor;
                    return (
                      <option key={index} value={proveedoresId}>
                        {proveedoresDescripcion}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
              <p className="parrafo-aviso">
                Puede agregar un nuevo <strong>Proveedor</strong>
                <Button
                  className="parrafo-aviso"
                  size="sm"
                  variant="info"
                  onClick={() => setMostrarModalProveedor(true)}
                >
                  <FontAwesomeIcon icon={faPlusCircle} /> Aqui
                </Button>
              </p>
            </Col>
          </Row>
          <Row>
            <Row className="mt-6">
              <Col md={12} lg={6}>
                <ProductosBuscarPorCodigoYProveedorId
                  codigoProducto={buscarPorCodigo}
                  setBuscarPorCodigo={setBuscarPorCodigo}
                  setFacturaDatosProductoPreview={
                    setDatosProductoSeleccionadoDesdeBusqueda
                  }
                  proveedorId={proveedoresId}
                />
              </Col>
              <Col md={12} lg={6}>
                <ProductosBuscarPorNombreYProveedorId
                  setFacturaDatosProductoPreview={
                    setDatosProductoSeleccionadoDesdeBusqueda
                  }
                  proveedoresId={proveedoresId}
                />
                <p className="parrafo-aviso">
                  Puede agregar un nuevo <strong>Producto</strong>
                  <Button
                    className="parrafo-aviso"
                    size="sm"
                    variant="info"
                    onClick={() => setShowModalProductoForm(true)}
                  >
                    <FontAwesomeIcon icon={faPlusCircle} /> Aqui
                  </Button>
                </p>
              </Col>
            </Row>
          </Row>
          <Row className="mt-4 mb-8">
            <Col>
              <FacturaCompraListaProductosSeleccionados
                listaProductosSeleccionados={listaProductosSeleccionados}
                setListaProductosSeleccionados={setListaProductosSeleccionados}
                eliminarProductoSeleccionadoEnListaProducto={
                  eliminarProductoSeleccionadoEnListaProducto
                }
                editarProductoSeleccionadoEnListaProducto={
                  editarProductoSeleccionadoEnListaProducto
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Comentarios</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  name="facturaComentarios"
                  id="facturaComentarios"
                  value={facturaComentarios}
                  onChange={(e) =>
                    setFacturaComentarios(e.target.value.toString())
                  }
                />
              </Form.Group>
            </Col>
            <Col className="pt-4 factura-totales-container">
              <div className="factura-totales mb-2">
                <span>Sub Total</span>
                <span>${facturaSubTotal}</span>
              </div>
              <div className="factura-totales mb-2">
                <span className="h5">Iva 13%</span>
                <span className="h5">${facturaTotalIva}</span>
              </div>
              <div className="factura-totales mb-2">
                <span className="h5">
                  <input
                    type="checkbox"
                    className=""
                    onChange={({ target }) =>
                      setUnoPorcientoHabilitado(target.checked)
                    }
                    checked={unoPorcientoHabilitado}
                  />
                  1% Iva Percibido
                </span>
                <span className="h5">${facturaTotalIvaUno}</span>
              </div>
              <div className="factura-totales mb-2">
                <span className="h5">Total</span>
                <span className="h5">${facturaTotal}</span>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {showModalProductosCrud && (
        <ModalProductosCrud
          showModalProductosCrud={showModalProductosCrud}
          setShowModalProductosCrud={setShowModalProductosCrud}
          productoSeleccionadoDatos={productoSeleccionadoDatos}
          productoSeleccionadoHandleChange={productoSeleccionadoHandleChange}
          asignarProductoSeleccionadoAListaProducto={
            asignarProductoSeleccionadoAListaProducto
          }
        />
      )}
      {showModalProductoForm && (
        <ModalAgregarProductos
          showModalProductoForm={showModalProductoForm}
          setShowModalProductoForm={setShowModalProductoForm}
        />
      )}
      {mostrarModalProveedor && (
        <ProveedorModalForm
          mostrarModalProveedor={mostrarModalProveedor}
          setMostarModalProveedor={setMostrarModalProveedor}
          setProveedorIdModalState={setProveedorIdModalState}
          setConsultarProveedores={setConsultarProveedores}
        />
      )}
    </div>
  );
};
export default FacturaCompra;
