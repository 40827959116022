import { useAuthContext } from "../../../contexts/authContext";
import React, { useRef } from "react";
import { Button, Col, Modal, Row } from "@themesberg/react-bootstrap";
import { Form } from "@themesberg/react-bootstrap";
import { useEffect, useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { makePetition } from "../../../helpers/petition";
import SweetAlert from "../Utils/SweetAlert";
import { GoPlus, GoCheck, GoPerson } from "react-icons/go";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { Block, Notify } from "notiflix";
const BusquedaCliente = ({
  mostrarBusquedaCliente,
  setMostrarBusquedaCliente,
  setClienteDatos,
  setFacturaClienteId,
  facturaClienteId,
}) => {
  const { logout, token: Token } = useAuthContext();
  const clientesDatosPreview = {
    clientesId: 0,
    clientesDireccion: "",
    clientesDui: "",
    clientesEmail: "",
    clientesFechaNacimiento: "",
    clientesNRC: "",
    clientesNombres: "",
    clientesTelefono: "",
  };
  const [isLoading, setIsLoading] = useState(false);
  const [datosClientes, setdatosClientes] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [consultarBusqueda, setConsultarBusqueda] = useState(false);
  const [clientePreview, setClientePreview] = useState(clientesDatosPreview);
  const handleChangeClienteDataPreview = ({ target }) => {
    const { name, value } = target;
    setClientePreview({
      ...clientePreview,
      [name]: value,
    });
  };
  let timeDelay;
  const handleSearch = (query) => {
    setIsLoading(true);
    setSearchValue(query);
    setConsultarBusqueda(false);
    clearTimeout(timeDelay);
    timeDelay = setTimeout(() => {
      setConsultarBusqueda(true);
    }, 600);
  };
  useEffect(() => {
    const busquedaClienteConsulta = async () => {
      const formData = {};
      const urlTarget =
        "clientes/busquedaClientes?inicio=0&limite=50&busquedaCliente=" +
        searchValue;
      const petitionMethod = "GET";
      const request = await makePetition(
        urlTarget,
        formData,
        petitionMethod,
        Token
      );
      const { status, ok } = request;
      if (status === 401) {
        logout();
        return false;
      }
      if (!ok) {
        throw new Error("Error de petición.");
      }
      const response = await request.json();
      const { success, message = "", data = [] } = response;
      setIsLoading(false);
      if (!success) {
        SweetAlert("Error", `${message}`, "error", true);
        return false;
      }
      if (data.lenght < 1) {
        SweetAlert(
          "Error",
          `No existen datos de este proveedor`,
          "error",
          true
        );
        return false;
      }
      setdatosClientes(data);
      return true;
    };
    if (consultarBusqueda) {
      setConsultarBusqueda(false);
      busquedaClienteConsulta();
    }
  }, [consultarBusqueda, searchValue, Token, logout]);
  const selectItem = (params) => setClientePreview(params);
  const renderItem = (cliente, props) => (
    <div className="d-flex" onClick={() => selectItem(cliente)}>
      <span className="mx-3">
        <GoPerson size="2em" />
      </span>
      <span className="mx-3">{cliente.clientesNombres}</span>
    </div>
  );
  const handleClose = () => {
    setMostrarBusquedaCliente(false);
  };
  const asignarCliente = () => {
    if (parseInt(clientePreview.clientesId) < 1) {
      Notify.failure(
        "Por favor asigne un cliente desde el buscador o asigne uno genérico",
        {
          timeout: 3333,
          backOverlayColor: "#fff",
        }
      );
      return false;
    }
    setClienteDatos(clientePreview);
    const clienteId = parseInt(clientePreview.clientesId);
    setFacturaClienteId(clienteId);
    setMostrarBusquedaCliente(false);
  };
  // generarCliente
  const generarCliente = async () => {
    try {
      Block.pulse(".facturaContainer");
      const formData = {};
      const urlTarget = "clientes/generarCliente";
      const petitionMethod = "POST";
      const request = await makePetition(
        urlTarget,
        formData,
        petitionMethod,
        Token
      );
      const { status, ok } = request;
      if (status === 401) {
        logout();
        return false;
      }
      if (!ok) {
        throw new Error("Error de petición.");
      }
      const response = await request.json();
      const { success, message = "", data = [] } = response;
      Block.remove(".facturaContainer");
      if (!success) {
        SweetAlert("Error", `${message}`, "error", true);
        return false;
      }
      if (data.lenght <= 0) {
        SweetAlert(
          "Error",
          `No existen datos de este proveedor`,
          "error",
          true
        );
        return false;
      }
      let {
        clientesId = 0,
        clientesNombres = "",
        clientesDui = "",
        clientesNRC = "",
        clientesDireccion = "",
        clientesTelefono = "",
        clientesEmail = "",
        clientesFechaNacimiento = "",
      } = data;
      clientesId = parseInt(clientesId);
      if (clientesId < 1) {
        SweetAlert(
          "Error",
          `Ocurrió un error al generar el cliente, por favor contacte al administrador del sistema.`,
          "error",
          true
        );
        return false;
      }
      const dataCliente = {
        clientesId,
        clientesDireccion,
        clientesDui,
        clientesEmail,
        clientesFechaNacimiento,
        clientesNRC,
        clientesNombres,
        clientesTelefono,
      };
      setClienteDatos(dataCliente);
      setFacturaClienteId(clientesId);
      setMostrarBusquedaCliente(false);
      return true;
    } catch (error) {
      SweetAlert(
        "Error",
        `Ocurrió un error inesperado, por favor contacte al administrador del sistema.`,
        "error",
        true
      );
      return false;
    }
  };
  return (
    <>
      <Modal show={mostrarBusquedaCliente} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Clientes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container" style={{ minHeight: "24em" }}>
            <Row>
              <Col className="d-flex justify-content-center">
                <AiOutlineUsergroupAdd size="4em" />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col>
                <Form.Group>
                  <Form.Label>Busqueda de cliente:</Form.Label>
                  <AsyncTypeahead
                    useCache={false}
                    filterBy={() => true}
                    id="buscardorClientes"
                    name="buscardorClientes"
                    isLoading={isLoading}
                    labelKey="clientesNombres"
                    minLength={3}
                    onSearch={handleSearch}
                    options={datosClientes}
                    placeholder="Búsqueda por nombre, apellidos, dui, nic"
                    renderMenuItemChildren={renderItem}
                  />
                </Form.Group>
              </Col>
            </Row>
            {parseInt(clientePreview.clientesId) > 0 && (
              <Row className="mt-4">
                <p>Por favor revise los datos del cliente antes de asignar</p>
                <Col>
                  <Form.Group className="mb-2">
                    <Form.Label>Cliente Nombre:</Form.Label>
                    <Form.Control
                      type="text"
                      name="clientesNombres"
                      value={clientePreview.clientesNombres}
                      onChange={handleChangeClienteDataPreview}
                      disabled
                    />
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label>Cliente Dui:</Form.Label>
                    <Form.Control
                      type="text"
                      name="clientesNombres"
                      value={clientePreview.clientesDireccion}
                      onChange={handleChangeClienteDataPreview}
                      disabled
                    />
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label>Cliente Nic:</Form.Label>
                    <Form.Control
                      type="text"
                      name="clientesNombres"
                      value={clientePreview.clientesNRC}
                      onChange={handleChangeClienteDataPreview}
                      disabled
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-2">
                    <Form.Label>Cliente Teléfono:</Form.Label>
                    <Form.Control
                      type="text"
                      name="clientesEmail"
                      value={clientePreview.clientesTelefono}
                      onChange={handleChangeClienteDataPreview}
                      disabled
                    />
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label>Cliente Email:</Form.Label>
                    <Form.Control
                      type="text"
                      name="clientesEmail"
                      value={clientePreview.clientesEmail}
                      onChange={handleChangeClienteDataPreview}
                      disabled
                    />
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label>Cliente Dirección:</Form.Label>
                    <Form.Control
                      type="text"
                      name="clientesNombres"
                      value={clientePreview.clientesDireccion}
                      onChange={handleChangeClienteDataPreview}
                      disabled
                    />
                  </Form.Group>
                </Col>
              </Row>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          {parseInt(clientePreview.clientesId) > 0 && (
            <Button
              variant="outline-info buttonWithIconsBlack"
              onClick={asignarCliente}
            >
              <GoCheck className="mx-2" /> Asignar Cliente
            </Button>
          )}
          <Button
            variant="outline-info buttonWitthIconsBlack"
            onClick={generarCliente}
          >
            <GoPlus className="mx-2" /> Cliente Genérico
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BusquedaCliente;
