import { Button } from '@themesberg/react-bootstrap';
import React from 'react';
import { BsPersonBadge, BsPlusLg } from 'react-icons/bs';
import { Link} from 'react-router-dom';
import EmpleadosLista from '../../components/tiendaBuenPrecioComponents/Empleados/EmpleadosLista';
import { Routes } from '../../routes';
import { Paths } from '../../config/router/paths';
const ListaClientes = (props) => {
    
    return (
        <div className='mt-4'>
            <div className="mb-4 mx-1 d-flex justify-content-between" >
                <div className="d-flex justify-content-start">
                    <BsPersonBadge size="2em" className='mx-2' />
                    <h4>
                        Lista Empleados
                    </h4>
                </div>
                <div className=''>
                    <Link
                        className='mx-2 btn btn-lg btn-outline-primary'
                        to={Paths.AgregarEmpleados.path}
                    >
                        <BsPlusLg className='mx-2'/>  Nuevo Empleado
                    </Link>
                </div>
            </div>
            <EmpleadosLista/>
        </div>
    )
}

export default ListaClientes;