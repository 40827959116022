import React, { useEffect, useState, useRef, useCallback } from "react";
import * as Yup from "yup";
import { Form, Button, Col, Row, Card } from "@themesberg/react-bootstrap";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import {
  editarProducto,
  guardarProducto,
  guardarProductosImagenesRequest,
  obtenerImagenes,
  // obtenerImagenesProductos,
  // obtenerProductos,
} from "./helper/productosPeticiones";
import { ErrorAlert } from "../Utils/ErrorAlert.jsx";
import { SuccessAlert } from "../Utils/SuccessAlert.jsx";
import { ProveedoresSelect } from "../Proveedores/ProveedoresSelect.jsx";
import { MarcaListaSelect } from "../Marcas/components/MarcasSelect.jsx";
import ProductoPrecios from "./ProductoPrecios.jsx";
import NuevaCategoria from "../Categorias/components/NuevaCategoria.jsx";
import { ListadoCategoriasFormProductos } from "../Categorias/components/ListadoCategoriasFormProductos.jsx";
import ProductoImagenes from "./ProductoImagenes.jsx";
import { ProductosPresentacion } from "../ProductosPresentacion/components/ProductosPresentacion.jsx";
import NuevaMarca from "../Marcas/components/NuevaMarca.jsx";
import ProveedorModalForm from "../Proveedores/ProveedorModalForm.jsx";
import AgregarProductoPresentacionModal from "../ProductosPresentacion/components/AgregarProductoPresentacionModal.jsx";
import SweetAlert from "../Utils/SweetAlert.jsx";
import Cargando from "../Utils/Loading.jsx";
import imgPreview from "../../../assets/img/tiendaBuenPrecio/util/image-svgrepo-com.svg";
import onlyLettersAndNumbers from "../Utils/onlyLettersAndNumbers";
import { productoPeticiones } from "./helper/peticionesProductos";
import { useAuthContext } from "../../../contexts/authContext";
import { BarcodeScanner } from "./helper/CodigoEscanear";
const ProductoForm = ({
  formType,
  productoId = 0,
  cancelarFormProductos,
  fromModal = false,
}) => {
  const { logout, token: Token } = useAuthContext();
  const refSuccess = useRef(null);
  const refError = useRef(null);
  const formikReferencia = useRef(null);
  const [consultarProducto, setConsultarProducto] = useState(true);
  const [listaCategoriasSelected, setListaCategoriasSelected] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [imagenPreviewFrontal, setImagenPreviewFrontal] = useState(imgPreview);
  const [imagenPreviewTrasera, setImagenPreviewTrasera] = useState(imgPreview);
  const [imagenPreviewAdicional, setImagenPreviewAdicional] =
    useState(imgPreview);
  // TODO: Agregar el estado del nombre de las imagenes
  const [imageRealNameFrontal, setImageRealNameFrontal] = useState("");
  const [imageRealNameTrasera, setImageRealNameTrasera] = useState("");
  const [imageRealNameAdicional, setImageRealNameAdicional] = useState("");
  const [mostrarCodigoEscaner, setMostrarCodigoEscaner] = useState(false);
  const [resultadoCodigoEscaner, setResultadoCodigoEscaner] = useState(null);
  const [consultarProductoPorCodigo, setConsultarProductoPorCodigo] =
    useState(false);
  // const [asignarCodigoDesdeEscaner, setAsignarCodigoDesdeEscaner] = useState(false);

  const [estadoProductos, setEstadoProductos] = useState({
    productosId: 0,
    productosCodigo: "",
    productosDescripcion: "",
    productosPrecioVentaLista: [],
    productosCategoriasLista: [],
    productosImagenesLista: {
      imagenFrontal: {},
      imagenTrasera: {},
      imagenAdicional: {},
    },
    marcasId: 0,
    proveedoresId: 0,
    presentacionesId: 0,
  });
  const {
    productosId,
    productosCodigo,
    productosPrecioVentaLista,
    productosCategoriasLista,
    productosImagenesLista,
    productosDescripcion,
    marcasId,
    proveedoresId,
    presentacionesId,
  } = estadoProductos;
  const alertTextInitial = {
    title: "",
    message: "",
  };
  const navigate = useNavigate();
  const [precioLista, setPrecioLista] = useState(productosPrecioVentaLista);
  const [precioCompra, setPrecioCompra] = useState(0);
  const [listadoCategorias, setListadoCategorias] = useState(
    productosCategoriasLista
  );
  const [listaProductoImagenes, setListaProductoImagenes] = useState(
    productosImagenesLista
  );
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertText, setAlertText] = useState(alertTextInitial);
  const [alertSuccessVisible, setAlertSuccessVisible] = useState(false);
  const [alertSuccessText, setAlertSuccessText] = useState(alertTextInitial);
  const [consultarListaCategoria, setConsultarListaCategoria] = useState(true);
  const [mostrarAgregarCategoria, setMostrarAgregarCategoria] = useState(false);
  const [mostrarAgregarMarca, setMostrarAgregarMarca] = useState(false);
  const [consultarMarcas, setConsultarMarcas] = useState(true);
  const [marcaIdModalState, setMarcaIdModalState] = useState(0);
  const [proveedorIdModalState, setProveedorIdModalState] = useState(0);
  const [mostrarModalProveedor, setMostrarModalProveedor] = useState(false);
  const [consultarProveedores, setConsultarProveedores] = useState(true);
  const [presentacionIdModalState, setPresentacionIdModalState] = useState(0);
  const [mostrarAgregarPresentacion, setMostrarAgregarPresentacion] =
    useState(false);
  const [consultarPresentaciones, setConsultarPresentaciones] = useState(true);

  const scrollToLastAlert = useCallback((referencia) => {
    const lastChildElement = referencia.current?.lastElementChild;
    lastChildElement?.scrollIntoView({ behavior: "smooth" });
  }, []);

  useEffect(() => {
    if (
      mostrarCodigoEscaner &&
      resultadoCodigoEscaner &&
      resultadoCodigoEscaner.toString().length > 4
    ) {
      alert(`Resultado de escaner: ${resultadoCodigoEscaner}`);
      setMostrarCodigoEscaner(false);
      setResultadoCodigoEscaner(false);
      if (formikReferencia.current) {
        formikReferencia.current.setFieldValue(
          "productosCodigo",
          resultadoCodigoEscaner
        );
      }
    }
  }, [resultadoCodigoEscaner, mostrarCodigoEscaner, estadoProductos]);
  /**
   *
   * Llamadas para obtener los datos de productos para la edicion
   *
   *
   */
  useEffect(() => {
    const obtenerDatosProducto = async (productosId) => {
      productosId = parseInt(productosId);
      setShowLoading(true);
      const request = await productoPeticiones("obtenerProductoPorId", Token, {
        productosId,
      });
      const { status, ok } = request;
      if (status === 401) {
        setShowLoading(false);
        logout();
        return false;
      }
      if (!ok) {
        setShowLoading(false);
        setAlertText({
          title: "Error",
          message:
            "Ocurrió un error inesperado, por favor contacte con el administrador.",
        });
        setAlertVisible(true);
        scrollToLastAlert(refError);
        return false;
      }
      const response = await request.json();
      setShowLoading(false);
      const { success, message = "", data } = response;
      setConsultarProducto(false);
      if (!success) {
        setAlertText({ title: "Error", message: message });
        setAlertVisible(true);
        scrollToLastAlert(refError);
        return false;
      }
      setEstadoProductos({
        productosId: parseInt(data.productosId),
        productosCodigo: data.productosCodigo,
        productosDescripcion: data.productosDescripcion,
        productosPrecioVentaLista: data.productosPrecioVentaLista,
        productosCategoriasLista: data.productosCategoriasLista,
        productosImagenesLista: {
          imagenFrontal: {},
          imagenTrasera: {},
          imagenAdicional: {},
        },
        marcasId: parseInt(data.marcasId),
        proveedoresId: parseInt(data.proveedoresId),
        presentacionesId: parseInt(data.presentacionesId),
      });
      setPrecioCompra(Number(data.productosPrecioCompra));
      if (data.productosCategoriasLista.length > 0) {
        setListadoCategorias(data.productosCategoriasLista);
        let listaCategoriasAlredySelected = [];
        listaCategoriasAlredySelected = data.productosCategoriasLista.map(
          (categoria) => parseInt(categoria.categoriaId)
        );
        setListaCategoriasSelected(listaCategoriasAlredySelected);
      }
      if (data.productosPrecioVentaLista.length > 0) {
        setPrecioLista(data.productosPrecioVentaLista);
      }
      const obtenerImagenesProductosPeticionRequest = await productoPeticiones(
        "obtenerProductoImagenes",
        Token,
        { productosId }
      );
      if (
        obtenerImagenesProductosPeticionRequest.status === 400 ||
        !obtenerImagenesProductosPeticionRequest.ok
      ) {
        logout();
        return false;
      }
      const obtenerImagenesProductosPeticion =
        await obtenerImagenesProductosPeticionRequest.json();
      const {
        success: successObtenerImagenes,
        data: dataObtenerImagenes,
        message: messageObtenerImagenes,
      } = obtenerImagenesProductosPeticion;
      if (!successObtenerImagenes) {
        SweetAlert(
          "Error",
          messageObtenerImagenes ||
            "Ocurrió un error al obtener las imagenes del producto.",
          "error",
          true
        );
        setShowLoading(false);
        return false;
      }
      if (dataObtenerImagenes.length < 1) {
        return true;
      }
      dataObtenerImagenes.map(async (imagen) => {
        const { productosImagenesArchivo, link, sufijoImagen } = imagen;
        if (sufijoImagen === "productosImagenFrontal") {
          const imagenBase64 = await obtenerImagenes(link);
          setImagenPreviewFrontal(imagenBase64);
        } else if (sufijoImagen === "productosImagenAdicional") {
          const imagenBase64 = await obtenerImagenes(link);
          setImagenPreviewAdicional(imagenBase64);
        } else if (sufijoImagen === "productosImagenTrasera") {
          const imagenBase64 = await obtenerImagenes(link);
          setImagenPreviewTrasera(imagenBase64);
        }
      });

      return true;
    };
    if (formType === "EDITAR" && consultarProducto && productoId > 0) {
      setConsultarProducto(false);
      if (productoId > 0) {
        obtenerDatosProducto(productoId);
      } else {
        setAlertText({
          title: "Error",
          message:
            "Producto no encontrado, por favor contacte al administrador del sistema",
        });
        setAlertVisible(true);
        scrollToLastAlert(refError);
        return false;
      }
    }
  }, [
    formType,
    consultarProducto,
    setConsultarProducto,
    productoId,
    Token,
    logout,
    scrollToLastAlert,
  ]);

  /**
   *
   * Consultar producto por codigo, para comprobar si el producto ya esta ingresado, en el formaulario de nuevo y editar
   *
   */
  const consultarProductoPorCodigoHandle = useCallback(
    async (codigoProductoBuscar) => {
      try {
        const request = await productoPeticiones(
          "obtenerProductoPorCodigo",
          Token,
          { productosCodigo: codigoProductoBuscar }
        );
        const { status, ok } = request;

        if (status === 401) {
          logout();
          return false;
        }

        if (!ok) {
          setAlertText({
            title: "Error",
            message:
              "Ocurrió un error inesperado, por favor contacte con el administrador.",
          });
          setAlertVisible(true);
          scrollToLastAlert(refError);
          return false;
        }

        const response = await request.json();
        const { success, message = "", data = {} } = response;

        if (!success || Object.keys(data).length === 0) {
          return false;
        }
        return true;
      } catch (error) {
        console.error("Error en la consulta:", error);
        return false;
      }
    },
    [Token, logout, setAlertText, setAlertVisible, scrollToLastAlert]
  );
  const delayTiempo = 2000; // 2000 ms (2 segundo) de retraso
  useEffect(() => {
    if (consultarProductoPorCodigo) {
      const timeoutId = setTimeout(async () => {
        setConsultarProductoPorCodigo(false);
        const codigoProductoBuscar =
          formikReferencia.current.values.productosCodigo;
        if (codigoProductoBuscar.toString().trim().length > 0) {
          const consultarExistenciaProductoPorCodigo =
            await consultarProductoPorCodigoHandle(codigoProductoBuscar);
          if (consultarExistenciaProductoPorCodigo) {
            SweetAlert(
              "Advertencia",
              "Ya existe un producto con este código, por favor verificar los datos ingresados.",
              "advertencia",
              true,
              "OK",
              "Cancelar",
              false
            );
          }
        }
      }, delayTiempo);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [
    consultarProductoPorCodigo,
    Token,
    logout,
    consultarProductoPorCodigoHandle,
  ]);

  const requiredMessage = "Este campo es requerido";

  const defaultFormikValues = {
    productosCodigo: productosCodigo,
    productosDescripcion: productosDescripcion,
    marcasId: marcasId,
    proveedoresId: proveedoresId,
    presentacionesId: presentacionesId,
  };

  const ProductSchema = Yup.object().shape({
    productosCodigo: Yup.string()
      .min(5, "El codigo de producto debe ser mayor que 5 caracteres")
      .required(requiredMessage),
    productosDescripcion: Yup.string()
      .min(2, "El codigo de producto debe ser mayor que 2 caracteres")
      .required(requiredMessage),
    marcasId: Yup.number()
      .integer("Marca invalida")
      .positive("Marca invalida")
      .required(requiredMessage),
    proveedoresId: Yup.number()
      .integer("Proveedor invalido")
      .positive("Proveedor invalido")
      .required(requiredMessage),
    presentacionesId: Yup.number()
      .integer("Presentacion invalida")
      .positive("Presentacion invalida")
      .required(requiredMessage),
  });
  // //generate numeric id
  // const generateId = () => {
  // return Math.floor(Math.random() * 100000000000);
  // };
  const formSubmitHandler = async (values, resetForm) => {
    try {
      const productosIdGuardar = parseInt(productosId);
      const productosCodigo = values.productosCodigo.toString().trim();
      const productosDescripcion = values.productosDescripcion
        .toString()
        .trim();
      const productosPrecioCompra = Number(precioCompra);
      const productosPrecioVentaLista = precioLista; // array
      const productosCategoriasLista = listadoCategorias; // array
      const productosImagenesLista = listaProductoImagenes; // array
      const marcasId = parseInt(values.marcasId);
      const proveedoresId = parseInt(values.proveedoresId);
      const presentacionesId = parseInt(values.presentacionesId);
      if (productosCodigo.toString().trim().length < 1) {
        SweetAlert(
          "Advertencia",
          "El código de producto es requerido.",
          "advertencia",
          true,
          "OK",
          "Cancelar",
          false
        );
        return false;
      }
      console.log("formType: ", formType);
      let consultarExistenciaPorCodigoProducto = false;
      if (formType !== "EDITAR") {
        consultarExistenciaPorCodigoProducto =
          await consultarProductoPorCodigoHandle(productosCodigo);
      }
      if (consultarExistenciaPorCodigoProducto) {
        // Producto encontrado..
        SweetAlert(
          "Advertencia",
          "Ya existe un producto con este código, por favor verificar los datos ingresados.",
          "advertencia",
          true,
          "OK",
          "Cancelar",
          false
        );
        return false;
      }
      if (productosDescripcion.length < 1) {
        SweetAlert(
          "Advertencia",
          "El nombre de producto es requerido.",
          "advertencia",
          true,
          "OK",
          "Cancelar",
          false
        );
        return false;
      }
      if (precioLista.length < 1) {
        const mostrarAlertaPrecios = await SweetAlert(
          "Advertencia",
          "Si el producto no posee un precio de venta, este no podra ser listado para su facturacion",
          "advertencia",
          true,
          "Guardar",
          "Cancelar",
          true
        );
        const { isConfirmed } = mostrarAlertaPrecios;
        if (!isConfirmed) {
          return false;
        }
      }
      let precioListaInfinitoAsignado = false;
      let precioListaInicioAsignado = false;
      let precioListaRangosEstablecidos = [];
      precioLista.forEach((precio) => {
        let { productoCantidadRangoMinimo, productoCantidadRangoMaximo } =
          precio;
        productoCantidadRangoMinimo = parseInt(productoCantidadRangoMinimo);
        productoCantidadRangoMaximo = parseInt(productoCantidadRangoMaximo);
        precioListaRangosEstablecidos = [
          ...precioListaRangosEstablecidos,
          {
            productoCantidadRangoMinimo,
            productoCantidadRangoMaximo,
          },
        ];
        if (productoCantidadRangoMinimo === 1) {
          precioListaInicioAsignado = true;
        }
        if (parseFloat(productoCantidadRangoMaximo) === 1000000.0) {
          precioListaInfinitoAsignado = true;
        }
      });

      if (!precioListaInicioAsignado) {
        await SweetAlert(
          "Advertencia",
          "El producto debe tener asignado la cantidad minimas de 1",
          "advertencia",
          true,
          "OK",
          "Cancelar",
          false
        );
        return false;
      }
      if (!precioListaInfinitoAsignado) {
        await SweetAlert(
          "Advertencia",
          "El producto debe tener asignado la cantidad maxima de 1000000(Simboliza infinito)",
          "advertencia",
          true,
          "OK",
          "Cancelar",
          false
        );
        return false;
      }
      const mensajeErrorGuardarImagenProducto = async (
        productoId,
        listaProductoImagenes,
        resultSubirProductosImagenes
      ) => {
        const mensajeExito = await SweetAlert(
          "Advertencia",
          `El producto se guardo correctamente, pero Ocurrió un error al subir la(s) imagen(es). Por favor intente nuevamente, si el problema persiste por favor contacte al administrador del sistema.`,
          "advertencia",
          true,
          "Intentar Nuevamente",
          "Lista de Productos",
          true
        );
        const { isConfirmed } = mensajeExito;
        if (!isConfirmed) {
          cancelarFormProductos();
        } else {
          const resultSubirProductosImagenesReintento =
            await guardarProductosImagenes(
              productoId,
              listaProductoImagenes,
              resultSubirProductosImagenes
            );
          const { imagenFrontal, imagenTrasera, imagenAdicional } =
            resultSubirProductosImagenesReintento;
          if (imagenFrontal.existe && !imagenFrontal.subida) {
            await mensajeErrorGuardarImagenProducto(
              resultSubirProductosImagenes,
              listaProductoImagenes
            );
            return false;
          } else if (imagenTrasera.existe && !imagenTrasera.subida) {
            await mensajeErrorGuardarImagenProducto(
              resultSubirProductosImagenes,
              listaProductoImagenes
            );
            return false;
          } else if (imagenAdicional.existe && !imagenAdicional.subida) {
            await mensajeErrorGuardarImagenProducto(
              resultSubirProductosImagenes,
              listaProductoImagenes
            );
            return false;
          } else {
            await mensajeExitoGuardarProducto();
            return true;
          }
        }
        return true;
      };
      const mensajeErrorGuardarProducto = async (mensajeError) => {
        const mensajeExito = await SweetAlert(
          "Error",
          mensajeError,
          "error",
          true,
          "Ok",
          "Lista de Productos",
          false
        );
        const { isConfirmed } = mensajeExito;
        if (!isConfirmed) {
          return false;
        } else {
          cancelarFormProductos();
        }
        return true;
      };
      const mensajeExitoGuardarProducto = async () => {
        if (fromModal) {
          cancelarFormProductos();
          return true;
        }
        const mensajeExito = await SweetAlert(
          "Exito",
          "El producto ha sido ingresado con éxito, desea agregar otro producto?",
          "éxito",
          true,
          "Nuevo Producto",
          "Lista de Productos",
          true
        );
        const { isConfirmed } = mensajeExito;
        if (!isConfirmed) {
          cancelarFormProductos();
        } else {
          navigate.go(0);
        }
        return true;
      };
      const guardarProductosImagenes = async (
        Token,
        productoId,
        listaProductoImagenes,
        comprobacionImagenesSubidas = {}
      ) => {
        const { imagenFrontal, imagenTrasera, imagenAdicional } =
          listaProductoImagenes;
        if (Object.keys(comprobacionImagenesSubidas).length < 1) {
          comprobacionImagenesSubidas = {
            imagenFrontal: {
              existe: false,
              subida: false,
            },
            imagenTrasera: {
              existe: false,
              subida: false,
            },
            imagenAdicional: {
              existe: false,
              subida: false,
            },
          };
        }
        if (
          Object.keys(imagenFrontal).length > 0 &&
          !comprobacionImagenesSubidas.imagenFrontal.subida
        ) {
          comprobacionImagenesSubidas.imagenFrontal = {
            ...comprobacionImagenesSubidas.imagenFrontal,
            existe: true,
          };
          const { base64, name } = imagenFrontal;
          const subirImagen = await guardarProductosImagenesRequest(
            productoId,
            "productosImagenFrontal",
            base64,
            name,
            Token
          );
          const { success } = subirImagen;
          comprobacionImagenesSubidas.imagenFrontal = {
            ...comprobacionImagenesSubidas.imagenFrontal,
            subida: success,
          };
        }
        if (
          Object.keys(imagenTrasera).length > 0 &&
          !comprobacionImagenesSubidas.imagenTrasera.subida
        ) {
          comprobacionImagenesSubidas.imagenFrontal = {
            ...comprobacionImagenesSubidas.imagenTrasera,
            existe: true,
          };
          const { base64, name } = imagenTrasera;
          const subirImagen = await guardarProductosImagenesRequest(
            productoId,
            "productosImagenTrasera",
            base64,
            name
          );
          const { success } = subirImagen;
          comprobacionImagenesSubidas.imagenFrontal = {
            ...comprobacionImagenesSubidas.imagenTrasera,
            subida: success,
          };
        }
        if (
          Object.keys(imagenAdicional).length > 0 &&
          !comprobacionImagenesSubidas.imagenAdicional.subida
        ) {
          comprobacionImagenesSubidas.imagenFrontal = {
            ...comprobacionImagenesSubidas.imagenAdicional,
            existe: true,
          };
          const { base64, name } = imagenAdicional;
          const subirImagen = guardarProductosImagenesRequest(
            productoId,
            "productosImagenAdicional",
            base64,
            name
          );
          const { success } = subirImagen;
          comprobacionImagenesSubidas.imagenFrontal = {
            ...comprobacionImagenesSubidas.imagenAdicional,
            subida: success,
          };
        }
        return comprobacionImagenesSubidas;
      };
      setShowLoading(true);
      const datosPeticion = {
        productosId: productosIdGuardar,
        productosCodigo: productosCodigo,
        productosDescripcion: productosDescripcion,
        productosPrecioCompra: productosPrecioCompra,
        productosPrecioVentaLista: productosPrecioVentaLista,
        productosCategoriasLista: productosCategoriasLista,
        productosImagenesLista: productosImagenesLista,
        marcasId: marcasId,
        proveedoresId: proveedoresId,
        presentacionesId: presentacionesId,
      };
      let peticionGuardar = {};
      let request;
      if (formType === "NUEVO")
        request = await guardarProducto(datosPeticion, Token);
      else request = await editarProducto(datosPeticion, Token);

      const { status, ok } = request;
      if (status === 401) {
        setShowLoading(false);
        logout();
        return false;
      }
      if (!ok) {
        throw new Error("Error de petición.");
      }
      peticionGuardar = await request.json();
      const { success, message = "", data = {} } = peticionGuardar;
      setShowLoading(false);
      if (formType === "NUEVO") {
        if (!success) {
          mensajeErrorGuardarProducto(message);
          return false;
        }
        const { productoId } = data;

        /**
         *
         *
         * Peticion para guardar imagenes
         *
         */
        const resultSubirProductosImagenes = await guardarProductosImagenes(
          Token,
          productoId,
          listaProductoImagenes
        );
        const { imagenFrontal, imagenTrasera, imagenAdicional } =
          resultSubirProductosImagenes;
        if (imagenFrontal.existe && !imagenFrontal.subida) {
          await mensajeErrorGuardarImagenProducto(
            productoId,
            listaProductoImagenes,
            resultSubirProductosImagenes
          );
          return false;
        } else if (imagenTrasera.existe && !imagenTrasera.subida) {
          await mensajeErrorGuardarImagenProducto(
            productoId,
            listaProductoImagenes,
            resultSubirProductosImagenes
          );
          return false;
        } else if (imagenAdicional.existe && !imagenAdicional.subida) {
          await mensajeErrorGuardarImagenProducto(
            productoId,
            listaProductoImagenes,
            resultSubirProductosImagenes
          );
          return false;
        } else {
          await mensajeExitoGuardarProducto();
          return true;
        }
      } else {
        if (!success) {
          setAlertText({ title: "Error", message: message });
          setAlertVisible(true);
          scrollToLastAlert(refError);
          return false;
        }
        setAlertSuccessText({ title: "Exito", message: message });
        setAlertSuccessVisible(true);
        scrollToLastAlert(refSuccess);
        setTimeout(() => {
          resetForm();
          cancelarFormProductos();
        }, 2800);
        return true;
      }
    } catch (error) {
      const mostrarAlertaError = await SweetAlert(
        "Advertencia",
        "Ocurrió un error al guardar el producto. Por favor contacte con el administrador.",
        "advertencia",
        true,
        "Guardar",
        "Cancelar",
        true
      );
      setShowLoading(false);
      mostrarAlertaError();
    }
  };

  return (
    <div>
      <Cargando loading={showLoading} />
      <Formik
        innerRef={formikReferencia}
        initialValues={defaultFormikValues}
        validationSchema={ProductSchema}
        enableReinitialize={formType === "EDITAR" ? true : false}
        onSubmit={(values, { resetForm }) =>
          formSubmitHandler(values, resetForm)
        }
      >
        {({
          handleChange,
          handleSubmit,
          values: inputs,
          errors,
          touched,
          setFieldValue,
        }) => {
          const {
            productosCodigo,
            productosDescripcion,
            marcasId,
            proveedoresId,
            presentacionesId,
          } = inputs;
          return (
            <Card>
              <Card.Body>
                <Form className="mb-3" onSubmit={handleSubmit}>
                  <Row>
                    <Col xs={12}>
                      {alertVisible ? (
                        <ErrorAlert
                          referencia={refError}
                          title={alertText.title}
                          message={alertText.message}
                        />
                      ) : null}
                      {alertSuccessVisible ? (
                        <SuccessAlert
                          referencia={refSuccess}
                          title={alertSuccessText.title}
                          message={alertSuccessText.message}
                        />
                      ) : null}
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col md={6} sm={12} xs={12}>
                      <Form.Group className="mb-2">
                        <Form.Label>Código Producto</Form.Label>
                        <Form.Control
                          autoFocus
                          type="text"
                          placeholder="Ingrese el codigo del producto"
                          id="productosCodigo"
                          onChange={({ target }) => {
                            setFieldValue(
                              "productosCodigo",
                              target.value,
                              true
                            );
                            setConsultarProductoPorCodigo(true);
                          }}
                          value={productosCodigo}
                          onKeyPress={(event) =>
                            event.target.value.toString().length > 98
                              ? event.preventDefault()
                              : onlyLettersAndNumbers(event)
                          }
                          isValid={
                            touched.productosCodigo && !errors.productosCodigo
                          }
                        />
                        {touched.productosCodigo && errors.productosCodigo && (
                          <div className="text-danger">
                            {errors.productosCodigo}
                          </div>
                        )}
                      </Form.Group>
                      <p className="parrafo-aviso d-lg-flex justify-content-end">
                        <Button
                          className="parrafo-aviso mx-1"
                          size="xs"
                          variant="info"
                          onClick={() => setMostrarCodigoEscaner(true)}
                        >
                          <FontAwesomeIcon icon={faPlusCircle} />
                          Escanear
                        </Button>
                      </p>
                    </Col>
                    <Col md={6} sm={12} xs={12}>
                      <Form.Group className="mb-4">
                        <Form.Label>Descripción</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Ingrese el nombre del producto"
                          id="productosDescripcion"
                          value={productosDescripcion}
                          onChange={handleChange}
                          onKeyPress={(event) =>
                            event.target.value.toString().length > 98
                              ? event.preventDefault()
                              : onlyLettersAndNumbers(event)
                          }
                          isValid={
                            touched.productosDescripcion &&
                            !errors.productosDescripcion
                          }
                        />
                        {touched.productosDescripcion &&
                          errors.productosDescripcion && (
                            <div className="text-danger">
                              {errors.productosDescripcion}
                            </div>
                          )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col md={4} sm={12} xs={12}>
                      <Form.Group className="mb-4">
                        <Form.Label>Marca</Form.Label>
                        <MarcaListaSelect
                          marcasId={marcasId}
                          handleChange={handleChange}
                          touched={touched}
                          errors={errors}
                          setAlertText={setAlertText}
                          setAlertVisible={setAlertVisible}
                          consultarMarcas={consultarMarcas}
                          setConsultarMarcas={setConsultarMarcas}
                          setFieldValue={setFieldValue}
                          marcaIdModalState={marcaIdModalState}
                          setMarcaIdModalState={setMarcaIdModalState}
                        />
                        {touched.marcasId && errors.marcasId && (
                          <div className="text-danger">{errors.marcasId}</div>
                        )}
                      </Form.Group>
                      <p className="parrafo-aviso">
                        Puede agregar una nueva Marca
                        <Button
                          className="parrafo-aviso mx-1"
                          size="xs"
                          variant="info"
                          onClick={() => setMostrarAgregarMarca(true)}
                        >
                          <FontAwesomeIcon icon={faPlusCircle} />
                          Aqui
                        </Button>
                      </p>
                    </Col>
                    <Col md={4} sm={12} xs={12}>
                      <Form.Group className="mb-4">
                        <Form.Label>Proveedores</Form.Label>
                        <ProveedoresSelect
                          proveedoresId={proveedoresId}
                          handleChange={handleChange}
                          touched={touched}
                          errors={errors}
                          setAlertText={setAlertText}
                          setAlertVisible={setAlertVisible}
                          consultarProveedores={consultarProveedores}
                          setConsultarProveedores={setConsultarProveedores}
                          setFieldValue={setFieldValue}
                          proveedorIdModalState={proveedorIdModalState}
                          setProveedorIdModalState={setProveedorIdModalState}
                        />
                        {touched.proveedoresId && errors.proveedoresId && (
                          <div className="text-danger">
                            {errors.proveedoresId}
                          </div>
                        )}
                      </Form.Group>
                      <p className="parrafo-aviso">
                        Puede agregar un nuevo
                        <strong>Proveedor</strong>
                        <Button
                          className="parrafo-aviso mx-1"
                          size="xs"
                          variant="info"
                          onClick={() => setMostrarModalProveedor(true)}
                        >
                          <FontAwesomeIcon icon={faPlusCircle} />
                          Aqui
                        </Button>
                      </p>
                    </Col>
                    <Col md={4} sm={12} xs={12}>
                      <Form.Group className="mb-4">
                        <Form.Label>Presentación</Form.Label>
                        <ProductosPresentacion
                          presentacionesId={presentacionesId}
                          presentacionIdModalState={presentacionIdModalState}
                          setPresentacionIdModalState={
                            setPresentacionIdModalState
                          }
                          consultarPresentaciones={consultarPresentaciones}
                          setConsultarPresentaciones={
                            setConsultarPresentaciones
                          }
                          setFieldValue={setFieldValue}
                          setAlertText={setAlertText}
                          setAlertVisible={setAlertVisible}
                          establecerValorDePresentacion={formType === "EDITAR" ? true : false}
                        />
                        {touched.presentacionesId &&
                          errors.presentacionesId && (
                            <div className="text-danger">
                              {errors.presentacionesId}
                            </div>
                          )}
                      </Form.Group>
                      <p className="parrafo-aviso">
                        Puede agregar una nueva Presentacion
                        <Button
                          className="parrafo-aviso mx-1"
                          size="xs"
                          variant="info"
                          onClick={() => setMostrarAgregarPresentacion(true)}
                        >
                          <FontAwesomeIcon icon={faPlusCircle} />
                          Aqui
                        </Button>
                      </p>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col md={6} sm={12} xs={12}>
                      <span className="h5">Imágenes del Producto</span>
                      <ProductoImagenes
                        listaProductoImagenes={listaProductoImagenes}
                        setListaProductoImagenes={setListaProductoImagenes}
                        imagenPreviewFrontal={imagenPreviewFrontal}
                        imagenPreviewTrasera={imagenPreviewTrasera}
                        imagenPreviewAdicional={imagenPreviewAdicional}
                      />
                    </Col>
                    <Col md={6} sm={12} xs={12}>
                      <span className="h5">Seleccion una o mas categorias</span>
                      <div className="px-2">
                        <ListadoCategoriasFormProductos
                          listadoCategorias={listadoCategorias}
                          setListadoCategorias={setListadoCategorias}
                          consultarListaCategoria={consultarListaCategoria}
                          setConsultarListaCategoria={
                            setConsultarListaCategoria
                          }
                          listaCategoriasSelected={listaCategoriasSelected}
                          setListaCategoriasSelected={
                            setListaCategoriasSelected
                          }
                        />
                        <p className="parrafo-aviso">
                          Puede agregar una nueva categoria
                          <Button
                            className="parrafo-aviso"
                            size="sm"
                            variant="info"
                            onClick={() => setMostrarAgregarCategoria(true)}
                          >
                            <FontAwesomeIcon icon={faPlusCircle} />
                            Aqui
                          </Button>
                        </p>
                      </div>
                    </Col>
                  </Row>

                  <div className="mt-4">
                    <ProductoPrecios
                      precioLista={precioLista}
                      setPrecioLista={setPrecioLista}
                      precioCompra={precioCompra}
                      setPrecioCompra={setPrecioCompra}
                    />
                  </div>
                  <Row className="justify-content-end mt-4">
                    <Col md={3} xs={12}>
                      <Button
                        variant="outline-primary"
                        type="submit"
                        className="w-100"
                        size="lg"
                      >
                        Guardar
                      </Button>
                      <Button
                        variant="danger"
                        type="button"
                        className="w-100 mt-2"
                        onClick={cancelarFormProductos}
                        size="lg"
                      >
                        Cancelar
                      </Button>
                    </Col>
                  </Row>
                </Form>
                {mostrarAgregarCategoria && (
                  <NuevaCategoria
                    mostrarAgregarCategoria={mostrarAgregarCategoria}
                    setMostrarAgregarCategoria={setMostrarAgregarCategoria}
                    setConsultarListaCategoria={setConsultarListaCategoria}
                  />
                )}
                {mostrarAgregarMarca && (
                  <NuevaMarca
                    mostrarAgregarMarca={mostrarAgregarMarca}
                    setMostrarAgregarMarca={setMostrarAgregarMarca}
                    setConsultarMarcas={setConsultarMarcas}
                    setMarcaIdModalState={setMarcaIdModalState}
                  />
                )}
                {mostrarModalProveedor && (
                  <ProveedorModalForm
                    mostrarModalProveedor={mostrarModalProveedor}
                    setMostarModalProveedor={setMostrarModalProveedor}
                    setProveedorIdModalState={setProveedorIdModalState}
                    setConsultarProveedores={setConsultarProveedores}
                  />
                )}
                {mostrarAgregarPresentacion && (
                  <AgregarProductoPresentacionModal
                    mostrarAgregarPresentacion={mostrarAgregarPresentacion}
                    setMostrarAgregarPresentacion={
                      setMostrarAgregarPresentacion
                    }
                    setPresentacionIdModalState={setPresentacionIdModalState}
                    setConsultarPresentaciones={setConsultarPresentaciones}
                  />
                )}
                {mostrarCodigoEscaner && (
                  <BarcodeScanner
                    setMostrarCodigoEscaner={setMostrarCodigoEscaner}
                    mostrarCodigoEscaner={mostrarCodigoEscaner}
                    resultadoCodigoEscaner={resultadoCodigoEscaner}
                    setResultadoCodigoEscaner={setResultadoCodigoEscaner}
                  />
                )}
              </Card.Body>
            </Card>
          );
        }}
      </Formik>
    </div>
  );
};

export default ProductoForm;
