import React, { useEffect, useState } from "react";
import { Paths } from "../../../config/router/paths";
import { useAuthContext } from "../../../contexts/authContext";
import { parse, isDate, format } from "date-fns";
import { Form, Button, Col, Row, Card } from "@themesberg/react-bootstrap";
import { Formik } from "formik";
import moment from "moment-timezone";
import DatePicker from "react-date-picker";
import * as Yup from "yup";
import Cargando from "../Utils/Loading";
import ClientesImagenes from "./ClientesImagenes";
import { useNavigate } from "react-router-dom";
import SweetAlert from "../Utils/SweetAlert";
import InputMask from "react-input-mask";
import {
  guardarCliente,
  guardarClientesImagenesRequest,
  obtenerClienteEditar,
  obtenerImagenes,
  obtenerImagenesClientes,
} from "./helper/clientesPeticiones";
import imgPreview from "../../../assets/img/tiendaBuenPrecio/util/image-svgrepo-com.svg";
import onlyLetters from "../Utils/onlyLetters";
import onlyNumbers from "../Utils/onlyNumbers";
import emailChecker from "../Utils/emailChecker";
import onlyAddress from "../Utils/onlyAddress";
import ListaPaises from "./components/ListaPaises";
import ListaDepartamentos from "./components/ListaDepartamentos";
const ClienteForm = ({ type, clientesId = 0 }) => {
  const { logout, token: Token } = useAuthContext();
  const history = useNavigate();
  const goListaClientes = () => {
    history(Paths.ListaClientes.path);
  };
  const fechaNacimientoMinima = (aniosMinimos = 100) => {
    const dateNow = new Date();
    const year = dateNow.getFullYear();
    const month = dateNow.getMonth();
    const day = dateNow.getDate();
    return new Date(year - parseInt(aniosMinimos), month, day);
  };

  const parseDateString = (value, originalValue) => {
    const parsedDate = isDate(originalValue)
      ? originalValue
      : originalValue
      ? parse(originalValue, "dd-MM-yyyy", new Date())
      : null;

    return parsedDate;
  };
  const defaultFormikValues = {
    clientesNombres: "",
    clientesDui: "",
    clientesNRC: "",
    clientesNIT: "",
    clientesTelefono: "",
    clientesEmail: "",
    clientesDireccion: "",
    clientesGiro: "",
    clientesPais: 0,
    clientesDepartamento: 0,
    clientesFechaNacimiento: null,
  };
  const [consultarClienteDatos, setConsultarClienteDatos] = useState(
    type === "EDITAR" ? true : false
  );
  const [listaClientesImagenes, setListaClientesImagenes] = useState({
    imagenDUI: {},
    imagenAdicional: {},
  });
  const [clienteDatosDefault, setClienteDatosDefault] =
    useState(defaultFormikValues);
  const [showLoading, setShowLoading] = useState(false);
  const [imagenPreviewDui, setImagenPreviewDui] = useState(imgPreview);
  const [imagenPreviewAdicional, setImagenPreviewAdicional] =
    useState(imgPreview);
  const [consultarPaises,setConsultarPaises] = useState(true);
  const [consultarDepartamento, setConsultarDepartamento] = useState(false);
  useEffect(() => {
    if (consultarClienteDatos) {
      setConsultarClienteDatos(false);
      (async () => {
        try {
          if (clientesId < 1) {
            SweetAlert(
              "Error",
              `El identificador del cliente es inválido, por favor contacte al administrador del sistema.`,
              "error",
              true
            );
          }
          setShowLoading(true);
          const obtenerDatos = await obtenerClienteEditar(clientesId, Token);
          const { status, ok } = obtenerDatos;
          if (status === 401) {
            setShowLoading(false);
            logout();
            return false;
          }
          if (!ok) {
            throw new Error("Error de petición.");
          }
          const response = await obtenerDatos.json();
          let { success = false, message = "", data = {} } = response;
          if (!success || Object.keys(data).length < 1) {
            setShowLoading(false);
            SweetAlert(
              "Error",
              message || "Ocurrió un error al obtener los datos del cliente.",
              "error",
              true
            );
            return false;
          }
          const {clientesFechaNacimiento} = data;
          let clientesFechaNacimientoFormateada = null;
          if (!isNaN(Date.parse(clientesFechaNacimiento))) {
            clientesFechaNacimientoFormateada = moment(
              clientesFechaNacimiento
            ).toDate();
          }
          data = {...data,clientesFechaNacimiento:clientesFechaNacimientoFormateada};
          setClienteDatosDefault(data);
          const obtenerImagenesClientesPeticion = await obtenerImagenesClientes(
            clientesId,
            Token
          );
          const { status: statusOIC, ok: okOIC } =obtenerImagenesClientesPeticion;
          if (statusOIC === 401) {
            setShowLoading(false);
            logout();
            return false;
          }
          if (!okOIC) {
            throw new Error("Error de petición.");
          }
          const responseObtenerImagenesClientesPeticion =
            await obtenerImagenesClientesPeticion.json();
          const {
            success: successObtenerImagenes,
            data: dataObtenerImagenes,
            message: messageObtenerImagenes,
          } = responseObtenerImagenesClientesPeticion;
          if (!successObtenerImagenes) {
            SweetAlert(
              "Error",
              messageObtenerImagenes ||
                "Ocurrió un error al obtener las imagenes del cliente.",
              "error",
              true
            );
            setShowLoading(false);
            return false;
          }
          if (dataObtenerImagenes.length < 1) {
            setShowLoading(false);
            return true;
          }
          dataObtenerImagenes.map(async (imagen) => {
            const { clientesImagenesArchivo, link, sufijoImagen } = imagen;
            if (sufijoImagen === "clientesImagenDui") {
              const imagenBase64 = await obtenerImagenes(link);
              setImagenPreviewDui(imagenBase64);
            } else if (sufijoImagen === "clientesImagenAdicional") {
              const imagenBase64 = await obtenerImagenes(link);
              setImagenPreviewAdicional(imagenBase64);
            }
          });
          setShowLoading(false);
        } catch (error) {
          console.error(error);
          SweetAlert(
            "Error",
            `Ocurrió un error al obtener los datos del cliente, por favor contacte al administrador del sistema.`,
            "error",
            true
          );
        }
      })();
    }
  }, [consultarClienteDatos, clientesId, logout, Token]);

  const ClientesSchema = Yup.object().shape({
    clientesNombres: Yup.string()
      .min(4, "El nombre del cliente debe ser mayor o igual a 5 caracteres")
      .required("El nombre del cliente es obligatorio"),
    clientesDui: Yup.string()
      .min(0, 'El DUI del cliente debe tener 10 caracteres incluyendo el "-"')
      .max(10, 'El DUI del cliente debe tener 10 caracteres incluyendo el "-"'),
    clientesNRC: Yup.string()
      .min(0, "El número minimo de caracteres es 8")
      .max(8, "El número minimo de caracteres es 8"),
    clientesNIT: Yup.string()
      .min(0, 'El NIC del cliente debe tener 16 caracteres incluyendo los "-"')
      .max(
        18,
        'El NIC del cliente debe tener 16 caracteres incluyendo los "-"'
      ),
    clientesTelefono: Yup.string().min(
      8,
      "EL número de teléfono del cliente debe tener al menos 9 caracteres"
    ),
    clientesEmail: Yup.string()
      .email("Por favor ingrese un correo electronico valido")
      .min(8, "El correo del cliente debe tener al menos 8 caracteres"),
    clientesDireccion: Yup.string(),
    clientesGiro: Yup.string(),
    clientesPais: Yup.number().integer(),
    clientesDepartamento:  Yup.number().integer(),
    clientesFechaNacimiento: Yup.date("Fecha ingresada inválida")
      .nullable()
      .transform(parseDateString)
      .min(
        fechaNacimientoMinima(),
        "Fecha de nacimiento invalida, la edad maxima de un clientes es 120 años"
      )
      .max(
        fechaNacimientoMinima(10),
        "Fecha de nacimiento invalida, la edad minima de un clientes es 10 años"
      )
      .required("La fecha de nacimiento es obligatoria."),
  });
  const formSubmitHandler = (values, resetForm) => {
    (async () => {
      try {
        clientesId = parseInt(clientesId);
        let errorResultanteEventos = false;
        let mensajeResultanteEventos = "";
        values = {...values,clientesFechaNacimiento:format(values.clientesFechaNacimiento,"yyyy/MM/dd")};
        /**
         *
         *
         * Guarda o actualizar imagenes del cliente
         *
         *
         */
        setShowLoading(true);
        const saveCustomer = await guardarCliente(
          values,
          Token
        );
        const { status, ok } = saveCustomer;
        if (status === 401) {
          setShowLoading(false);
          logout();
          return false;
        }
        if (!ok) {
          throw new Error("Error de petición.");
        }
        const responseGuardarCliente = await saveCustomer.json();
        const {
          success = false,
          message = "",
          data = [],
        } = responseGuardarCliente;
        setShowLoading(false);
        if (!success) {
          SweetAlert("Error", message, "error", true);
          return false;
        }
        if (clientesId < 1) {
          const { clientesId: clientesIdNuevo = 0 } = data;
          clientesId = parseInt(clientesIdNuevo);
        }
        /**
         *
         * Revisar si las imagenes existe
         *
         * si existen subir imagenes
         *
         *
         */
        const { imagenDUI, imagenAdicional } = listaClientesImagenes;
        if (Object.keys(imagenDUI).length > 1) {
          const { name: imagenNameDui, base64: imagenBase64Dui } = imagenDUI;
          const guardarImageneDuiClientes =
            await guardarClientesImagenesRequest(
              clientesId,
              "clientesImagenDui",
              imagenBase64Dui,
              imagenNameDui,
              Token
            );
          const { status, ok } = guardarImageneDuiClientes;
          if (status === 401) {
            setShowLoading(false);
            logout();
            return false;
          }
          if (!ok) {
            throw new Error("Error de petición.");
          }
          const responseGuardarImageneDuiClientes = await saveCustomer.json();
          const { success } = responseGuardarImageneDuiClientes;
          if (!success) {
            errorResultanteEventos = true;
            mensajeResultanteEventos += `\n Ocurrió un error al subir la imagen de DUI del cliente`;
          } else {
            mensajeResultanteEventos += `\n Imagen de Dui subida con éxito.`;
          }
        }
        if (Object.keys(imagenAdicional).length > 1) {
          const {
            name: imagenNameImagenAdicional,
            base64: imagenBase64ImagenAdicional,
          } = imagenAdicional;
          const guardarImageneAdicionalClientes =
            await guardarClientesImagenesRequest(
              clientesId,
              "clientesImagenAdicional",
              imagenBase64ImagenAdicional,
              imagenNameImagenAdicional,
              Token
            );
          const { status, ok } = guardarImageneAdicionalClientes;
          if (status === 401) {
            setShowLoading(false);
            logout();
            return false;
          }
          if (!ok) {
            throw new Error("Error de petición.");
          }
          const responseGuardarImageneAdicionalClientes =
            await guardarImageneAdicionalClientes.json();
          const { success } = responseGuardarImageneAdicionalClientes;
          if (!success) {
            errorResultanteEventos = true;
            mensajeResultanteEventos += `\n Ocurrió un error al subir la imagen adicional del cliente.`;
          } else {
            mensajeResultanteEventos += `\n Imagen adicional subida con éxito.`;
          }
        }
        if (errorResultanteEventos) {
          mensajeResultanteEventos +=
            "\n Si ocurrieron errores en la subida de imágenes, puede volver a intentarlo en la edición del cliente.";
          SweetAlert(
            "Advertencia",
            message + mensajeResultanteEventos,
            "warning",
            true
          );
        } else {
          SweetAlert(
            "Exito",
            message + mensajeResultanteEventos,
            "success",
            true
          );
        }
        setShowLoading(false);
        goListaClientes();
        return true;
      } catch (error) {
        setShowLoading(false);
        SweetAlert("Error", `Ocurrió un error inesperado`, "error", true);
        return false;
      }
    })();
  };
  if (type === "EDITAR") {
    clientesId = parseInt(clientesId);
    if (
      !clientesId ||
      isNaN(parseInt(clientesId)) ||
      parseInt(clientesId) <= 0
    ) {
      SweetAlert(
        "Error",
        `El identificador del cliente es inválido, por favor contacte al administrador del sistema`,
        "error",
        true
      );
      goListaClientes();
      return <></>;
    }
    
  } else {
    clientesId = 0;
  }

  return (
    <Card className="p-4">
      <Formik
        initialValues={clienteDatosDefault}
        validationSchema={ClientesSchema}
        enableReinitialize={type === "EDITAR" ? true : false}
        onSubmit={(values, { resetForm }) =>
          formSubmitHandler(values, resetForm)
        }
      >
        {({
          handleChange,
          handleSubmit,
          handleBlur,
          values: inputs,
          errors,
          touched,
          setFieldValue,
        }) => {
          const {
            clientesNombres,
            clientesDui,
            clientesNRC,
            clientesNIT,
            clientesTelefono,
            clientesEmail,
            clientesDireccion,
            clientesGiro,
            clientesPais,
            clientesDepartamento,
            clientesFechaNacimiento,
          } = inputs;
          return (
            <>
              <Cargando loading={showLoading} />
              <Form onSubmit={handleSubmit} className="mb-4">
                <Row>
                  <Col md={4} sm={12} xs={12} className="mt-4">
                    <Form.Group>
                      <Form.Label>Nombres</Form.Label>
                      <Form.Control
                        autoFocus
                        type="text"
                        id="clientesNombres"
                        name="clientesNombres"
                        value={clientesNombres}
                        onChange={handleChange}
                        placeholder="Ingrese el nombre completo del cliente"
                        isValid={
                          touched.clientesNombres && !errors.clientesNombres
                        }
                        onKeyPress={(event) =>
                          event.target.value.length > 350
                            ? event.preventDefault()
                            : onlyLetters(event)
                        }
                        required
                      ></Form.Control>
                    </Form.Group>
                    {touched.clientesNombres && errors.clientesNombres && (
                      <div className="text-danger">
                        {errors.clientesNombres}{" "}
                      </div>
                    )}
                  </Col>
                  <Col md={4} sm={12} xs={12} className="mt-4">
                    <Form.Group>
                      <Form.Label>Fecha de Nacimiento</Form.Label>
                      <DatePicker
                        onChange={(value) =>{
                          setFieldValue("clientesFechaNacimiento", value, true)}
                        }
                        className="form-control"
                        value={clientesFechaNacimiento}
                        format="dd/MM/yyyy"
                        locale="es-ES"
                        name="clientesFechaNacimiento"
                        id="clientesFechaNacimiento"
                      />
                      {touched.clientesFechaNacimiento &&
                        errors.clientesFechaNacimiento && (
                          <div className="text-danger">
                            {errors.clientesFechaNacimiento}
                          </div>
                        )}
                    </Form.Group>
                  </Col>
                  <Col md={4} sm={12} xs={12} className="mt-4">
                    <Form.Group>
                      <Form.Label>DUI</Form.Label>
                      {/* <Form.Control
                        type="text"
                        id="clientesDui"
                        name="clientesDui"
                        value={clientesDui}
                        onChange={handleChange}
                        placeholder="Ingrese el DUI del cliente"
                        isValid={touched.clientesDui && !errors.clientesDui}
                        onKeyPress={(event) =>
                          event.target.value.length > 9
                            ? event.preventDefault()
                            : onlyNumbers(event, true)
                        }
                      ></Form.Control> */}
                      <InputMask
                        id="clientesDui"
                        name="clientesDui"
                        mask="99999999-9"
                        value={clientesDui}
                        onChange={handleChange}
                        placeholder="Ingrese el DUI del cliente"
                        isValid={touched.clientesDui && !errors.clientesDui}
                        className="form-control"
                      />
                      {errors.clientesDui && (
                        <div className="text-danger">{errors.clientesDui}</div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={4} sm={12} xs={12} className="mt-4">
                    <Form.Group>
                      <Form.Label>NRC</Form.Label>
                      {/* <Form.Control
                        type="text"
                        id="clientesNRC"
                        name="clientesNRC"
                        value={clientesNRC}
                        onChange={handleChange}
                        placeholder="Ingrese el NIC del cliente"
                        isValid={touched.clientesNRC && !errors.clientesNRC}
                        onKeyPress={(event) =>
                          event.target.value.length > 26
                            ? event.preventDefault()
                            : onlyNumbers(event, true)
                        }
                      ></Form.Control> */}
                      <InputMask
                        mask="999999-9"
                        type="text"
                        id="clientesNRC"
                        name="clientesNRC"
                        value={clientesNRC}
                        onChange={handleChange}
                        placeholder="Ingrese el NIC del cliente"
                        isValid={touched.clientesNRC && !errors.clientesNRC}
                        onKeyPress={(event) =>
                          event.target.value.length > 26
                            ? event.preventDefault()
                            : onlyNumbers(event, true)
                        }
                        className="form-control"
                      />
                      {touched.clientesNRC && errors.clientesNRC && (
                        <div className="text-danger">{errors.clientesNRC}</div>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={4} sm={12} xs={12} className="mt-4">
                    <Form.Group>
                      <Form.Label>NIT</Form.Label>
                      <InputMask
                        mask="9999-999999-999-9"
                        type="text"
                        id="clientesNIT"
                        name="clientesNIT"
                        value={clientesNIT}
                        onChange={handleChange}
                        placeholder="Ingrese el NIC del cliente"
                        isValid={touched.clientesNIT && !errors.clientesNIT}
                        onKeyPress={(event) =>
                          event.target.value.length > 26
                            ? event.preventDefault()
                            : onlyNumbers(event, true)
                        }
                        className="form-control"
                      />
                      {touched.clientesNIT && errors.clientesNIT && (
                        <div className="text-danger">{errors.clientesNIT}</div>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={4} sm={12} xs={12} className="mt-4">
                    <Form.Group>
                      <Form.Label>Correo Electrónico</Form.Label>
                      <Form.Control
                        type="email"
                        id="clientesEmail"
                        name="clientesEmail"
                        value={clientesEmail}
                        onChange={handleChange}
                        placeholder="Ingrese el correo electrónico del cliente"
                        isValid={
                          touched.clientesEmail &&
                          !errors.clientesEmail &&
                          clientesEmail.trim().length > 1
                        }
                        onKeyPress={emailChecker}
                      ></Form.Control>
                      {touched.clientesEmail && errors.clientesEmail && (
                        <div className="text-danger">
                          {errors.clientesEmail}
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={4} sm={12} xs={12} className="mt-4 d-flex">
                    <ListaPaises
                      onChange={(e)=>{
                        if(e){
                          handleChange(e);
                          setConsultarDepartamento(true);
                        }
                      }}
                      value={clientesPais}
                      consultarPaises={consultarPaises}
                      setConsultarPaises={setConsultarPaises}
                      name="clientesPais"
                      id="clientesPais"
                    />
                    <ListaDepartamentos
                        onChange={handleChange}
                        value={clientesDepartamento}
                        consultarDepartamento={consultarDepartamento}
                        setConsultarDepartamento={setConsultarDepartamento}
                        paisId={clientesPais}
                        name="clientesDepartamento"
                        id="clientesDepartamento"
                    />
                  </Col>
                  <Col md={4} sm={12} xs={12} className="mt-4">
                    <Form.Group>
                      <Form.Label>Dirección</Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Ingrese la dirección del cliente"
                        style={{ height: "100px" }}
                        value={clientesDireccion}
                        onChange={handleChange}
                        name="clientesDireccion"
                        id="clientesDireccion"
                        maxLength={200}
                        onKeyPress={onlyAddress}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4} sm={12} xs={12} className="mt-4">
                    <Form.Group>
                      <Form.Label>Giro</Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Ingrese el giro del cliente, en caso que aplique."
                        style={{ height: "100px" }}
                        value={clientesGiro}
                        onChange={handleChange}
                        name="clientesGiro"
                        id="clientesGiro"
                        maxLength={200}
                        onKeyPress={onlyLetters}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={4} sm={12} xs={12} className="mt-1 d-flex">
                    <Form.Group
                      className="flex-1"
                      style={{ marginRight: "10px" }}
                    >
                      <Form.Label>Area</Form.Label>
                      <Form.Select aria-label="Número de Area" disabled>
                        <option value="1">+503</option>
                        <option value="2">+502</option>
                        <option value="3">+504</option>
                        <option value="4">+505</option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Group className="flex-2 ">
                      <Form.Label>Teléfono</Form.Label>
                      <InputMask
                        id="clientesTelefono"
                        name="clientesTelefono"
                        mask="9999-9999"
                        value={clientesTelefono}
                        onChange={handleChange}
                        placeholder="Ingrese el teléfono del cliente"
                        isValid={
                          touched.clientesTelefono && !errors.clientesTelefono
                        }
                        className="form-control"
                        onKeyPress={(event) =>
                          event.target.value.length > 9
                            ? event.preventDefault()
                            : onlyNumbers(event)
                        }
                      />
                      {errors.clientesTelefono && (
                        <div className="text-danger">
                          {errors.clientesTelefono}
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={9} xs={12} className="mt-5">
                    <h5>Imágenes de Cliente</h5>
                    <ClientesImagenes
                      listaClientesImagenes={listaClientesImagenes}
                      setListaClientesImagenes={setListaClientesImagenes}
                      imagenPreviewDui={imagenPreviewDui}
                      imagenPreviewAdicional={imagenPreviewAdicional}
                    />
                  </Col>
                  <Col md={3} xs={12} className="mt-7 justify-content-center">
                    <Button
                      variant="outline-primary"
                      type="submit"
                      className="w-100"
                      size="lg"
                    >
                      Guardar
                    </Button>
                    <Button
                      variant="danger"
                      type="button"
                      className="w-100 mt-2"
                      onClick={goListaClientes}
                      size="lg"
                    >
                      Cancelar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </>
          );
        }}
      </Formik>
    </Card>
  );
};

export default ClienteForm;
