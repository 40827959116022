import { makePetition } from "../../../../helpers/petition";

const fetchFactura = async (
  requesUrl,
  requestMethod,
  requestToken,
  requestData
) => {
  try {
    const request = await makePetition(
      requesUrl,
      requestData,
      requestMethod,
      requestToken
    );
    return request;
  } catch (error) {
    return {
      success: false,
      message:
        "Ocurrió un error inexperado, por favor vuelva a intentar. Si este error persiste contacte al administrador del sistema",
    };
  }
};
const facturaCompraPeticiones = async (method, token, requestData) => {
  switch (method) {
    case "getListaFacturaCompra":
      return await fetchFactura(
        `compras/obtenerListaFacturaCompra`,
        "GET",
        token,
        requestData
      );
    case "getListaProveedores":
      return await fetchFactura(`proveedores/lista`, "GET", token, {});
    case "guardarDatosFactura":
      return await fetchFactura(`compras/nueva`, "POST", token, requestData);
    case "actualizarDatosFactura":
      return await fetchFactura(
        `compras/actualizar`,
        "PUT",
        token,
        requestData
      );
    case "getDatosFacturaCompra":
      return await fetchFactura(
        `compras/obtenerFacturaPorId/${requestData}`,
        "GET",
        token,
        {}
      );
    default:
      return {
        success: false,
        message:
          "Ocurrió un error inexperado, por favor vuelva a intentar. Si este error persiste contacte al administrador del sistema",
      };
  }
};

export { facturaCompraPeticiones };
