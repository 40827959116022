import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button, Col, Row, Card } from "@themesberg/react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import SweetAlert from "../Utils/SweetAlert";
import UsuariosModalPassword from "./UsuariosModalPassword";
import Cargando from "../Utils/Loading";
import { guardarPerfilUsuario, obtenerUsuarioPerfil } from "./helper/usuariosPeticiones";
import { useAuthContext } from "../../../contexts/authContext";
import onlyLetters from "../Utils/onlyLetters";
import onlyAddress from "../Utils/onlyAddress";
import onlyNumbers from "../Utils/onlyNumbers.js";
import { Paths } from "../../../config/router/paths";
const UsuarioPerfilForm = ({ usuariosId = 0 }) => {
  const { logout, token: Token } = useAuthContext();
  const navegacion = useNavigate();
  const usuarioPerfilDefaultEsquema = {
    empleadosId: 0,
    usuariosEmail: "",
    usuario: "",
    empleadosNombres: "",
    empleadosApellidos: "",
    empleadosDireccion: "",
    empleadosTelefono: "",
    empleadosDui: "",
  };
  const [
    consultarInformacionPerfilHandle,
    setConsultarInformacionPerfilHandle,
  ] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  const [usuarioPerfilDefault, setUsuarioPerfilDefault] = useState(
    usuarioPerfilDefaultEsquema
  );
  const [mostrarCambiarContrasenia, setMostrarCambiarContrasenia] =
    useState(false);
  const [cambioContraseniaExitoso, setCambioContraseniaExitoso] =
    useState(false);
  useEffect(() => {
    if (cambioContraseniaExitoso) {
      setCambioContraseniaExitoso(false);
      SweetAlert("Exito", `Contraseña cambiada con éxito`, "success", true);
    }
  }, [cambioContraseniaExitoso]);
  useEffect(() => {
    const consultarDatosPerfil = async () => {
      try {
        const obtenerUsuariosDatos = await obtenerUsuarioPerfil(
          usuariosId,
          Token
        );
        const {
          status: obtenerUsuariosDatosStatus,
          ok: obtenerUsuariosDatosOk,
        } = obtenerUsuariosDatos;
        if (obtenerUsuariosDatosStatus === 401) {
          setShowLoading(false);
          logout();
          return false;
        }
        if (!obtenerUsuariosDatosOk) {
          throw new Error("Error de petición.");
        }
        const obtenerUsuariosDatosResponse = await obtenerUsuariosDatos.json();
        const { success, message, data } = obtenerUsuariosDatosResponse;
        if (!success || Object.keys(data).length < 1) {
          setShowLoading(false);
          SweetAlert("Error", `${message}`, "error", true);
          return false;
        }

        const {
          empleadosId,
          usuario,
          usuariosEmail,
          usuariosFechaRegistro,
          empleadosNombres,
          empleadosApellidos,
          empleadosDireccion,
          empleadosTelefono,
          empleadosDui,
          empleadosFechaCreacion,
        } = data;
        setUsuarioPerfilDefault({
          empleadosId,
          usuariosEmail,
          usuario,
          empleadosNombres,
          empleadosApellidos,
          empleadosDireccion,
          empleadosTelefono,
          empleadosDui,
        });
        setShowLoading(false);
      } catch (error) {
        setShowLoading(false);
        SweetAlert(
          "Error",
          `Ocurrió un error inesperado en la peticion de datos de usuario`,
          "error",
          true
        );
        return false;
      }
    };
    if (consultarInformacionPerfilHandle) {
      setConsultarInformacionPerfilHandle(false);
      consultarDatosPerfil();
    }
  }, [Token, logout, usuariosId, consultarInformacionPerfilHandle]);
  const datosEsquemaValidacionPerfil = {
    usuariosEmail: Yup.string()
      .email("Por favor ingrese un correo valido ej: usuario@email.com")
      .min(5, "El correo electrónico debe ser mayor o igual 5 caracteres")
      .max(98, "El correo electrónico debe ser menor o igual a 98 caracteres")
      .required("El correo electrónico es obligatorio"),
    empleadosNombres: Yup.string()
      .min(4, "Los nombres del Empleado debe ser mayor o igual a 5 caracteres")
      .max(
        99,
        "Los nombres del Empleado debe ser menor o igual a 99 caracteres"
      )
      .required("Los nombres del empleado son obligatorios"),
    usuario: Yup.string()
      .min(7, "El nombre de usuario debe ser mayor o igual a 4 caracteres")
      .max(26, "El nombre de usuario debe ser menor o igual a 26 caracteres")
      .required("El nombre de usuario es obligatorio"),
    empleadosApellidos: Yup.string()
      .min(
        4,
        "Los apellidos del Empleado debe ser mayor o igual a 5 caracteres"
      )
      .max(
        99,
        "Los apellidos del Empleado debe ser menor o igual a 99 caracteres"
      )
      .required("Los apellidos del empleado son obligatorios"),
    empleadosDireccion: Yup.string()
      .min(8, "El correo del Empleado debe tener al menos 8 caracteres")
      .max(99, "El correo del Empleado debe ser menor o igual a 99 caracteres")
      .required("La dirección del empleado es obligatoria"),
    empleadosTelefono: Yup.string()
      .min(
        8,
        "EL número de telefono del Empleado debe tener al menos 9 caracteres"
      )
      .required("El número de teléfono del empleado es obligatorio"),
    empleadosDui: Yup.string()
      .min(10, 'El DUI del Empleado debe tener 10 caracteres incluyendo el "-"')
      .max(10, 'El DUI del Empleado debe tener 10 caracteres incluyendo el "-"')
      .required("El número de DUI del empleado es obligatorio"),
  };
  const esquemaValidacionPerfil = Yup.object().shape(datosEsquemaValidacionPerfil);
  const hanleCloseModalContrasenia = () => {
    setMostrarCambiarContrasenia(false);
  };
  const handleShowModalContrasenia = () => {
    setMostrarCambiarContrasenia(true);
  };
  const formSubmitHandler = async(values,parametros) => {
    setShowLoading(true);
    try {
      values = {...values,usuariosId};
      const request = await guardarPerfilUsuario(values,Token);
      const {
        status: obtenerUsuariosDatosStatus,
        ok: obtenerUsuariosDatosOk,
      } = request;
      if (obtenerUsuariosDatosStatus === 401) {
        setShowLoading(false);
        logout();
        return false;
      }
      if (!obtenerUsuariosDatosOk) {
        throw new Error("Error de petición.");
      }
      const response = await request.json();
      const { success, message, data = {} } = response;
      if (!success) {
        setShowLoading(false);
        SweetAlert("Error", `${message}`, "error", true);
        return false;
      }
      setTimeout(() => {
        SweetAlert(
          "Exito",
          message,
          "success",
          true
        );
        setShowLoading(false);
        setTimeout(() => {
          navegacion(0);
        }, 1000);
      }, 1000);
      return true;
    } catch (error) {
      setShowLoading(false);
      SweetAlert("Error", `Ocurrió un error inesperado.`, "error", true);
      return false;
    }
  };
  return (
    <>
      <Cargando loading={showLoading} />
      <Formik
        initialValues={usuarioPerfilDefault}
        validationSchema={esquemaValidacionPerfil}
        enableReinitialize={true}
        onSubmit={(values,parametros) =>
          formSubmitHandler(values, parametros)
        }
      >
        {({
          handleChange,
          handleSubmit,
          handleBlur,
          values: inputs,
          errors,
          touched,
        }) => {
          const {
            usuariosEmail,
            usuario,
            empleadosNombres,
            empleadosApellidos,
            empleadosDireccion,
            empleadosTelefono,
            empleadosDui,
          } = inputs;
          return (
            <Card className="p-4">
              <h4>Edita tus datos</h4>
              <hr />
              <Form onSubmit={handleSubmit} className="mb-4">
                <Row>
                  <Col md={4} sm={12} xs={12} className="mt-4">
                    <Form.Group>
                      <Form.Label>Nombre de Usuario</Form.Label>
                      <Form.Control
                        type="text"
                        id="usuario"
                        name="usuario"
                        value={usuario}
                        onChange={handleChange}
                        placeholder="Ingrese el nombre del usuario"
                        isInvalid={errors.usuario}
                      ></Form.Control>
                      {touched.usuario &&
                        errors.usuario && (
                          <div className="text-danger">
                            {errors.usuario}
                          </div>
                        )}
                    </Form.Group>
                  </Col>
                  <Col md={4} sm={12} xs={12} className="mt-4">
                    <Form.Group>
                      <Form.Label>Nombres</Form.Label>
                      <Form.Control
                        autoFocus
                        type="text"
                        id="empleadosNombres"
                        name="empleadosNombres"
                        value={empleadosNombres}
                        onChange={handleChange}
                        placeholder="Ingrese los nombres del Empleado"
                        isInvalid={errors.empleadosNombres}
                        onKeyPress={onlyLetters}
                      ></Form.Control>
                    </Form.Group>
                    {touched.empleadosNombres && errors.empleadosNombres && (
                      <div className="text-danger">
                        {errors.empleadosNombres}{" "}
                      </div>
                    )}
                  </Col>
                  <Col md={4} sm={12} xs={12} className="mt-4">
                    <Form.Group>
                      <Form.Label>Apellidos</Form.Label>
                      <Form.Control
                        autoFocus
                        type="text"
                        id="empleadosApellidos"
                        name="empleadosApellidos"
                        value={empleadosApellidos}
                        onChange={handleChange}
                        placeholder="Ingrese el nombre completo del Empleado"
                        isInvalid={errors.empleadosApellidos}
                        onKeyPress={onlyLetters}
                      ></Form.Control>
                    </Form.Group>
                    {touched.empleadosApellidos &&
                      errors.empleadosApellidos && (
                        <div className="text-danger">
                          {errors.empleadosApellidos}{" "}
                        </div>
                      )}
                  </Col>
                </Row>
                <Row>
                  <Col md={4} sm={12} xs={12} className="mt-4">
                    <Form.Group>
                      <Form.Label>DUI</Form.Label>
                      <Form.Control
                        type="text"
                        id="empleadosDui"
                        name="empleadosDui"
                        value={empleadosDui}
                        onChange={handleChange}
                        placeholder="Ingrese el DUI del Empleado"
                        isInvalid={touched.empleadosDui && errors.empleadosDui}
                        onKeyPress={(event) =>
                          event.target.value.length > 9
                            ? event.preventDefault()
                            : onlyNumbers(event, true)
                        }
                      ></Form.Control>
                      {touched.empleadosDui && errors.empleadosDui && (
                        <div className="text-danger">{errors.empleadosDui}</div>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={4} sm={12} xs={12} className="mt-4">
                    <Form.Group>
                      <Form.Label>Correo Electronico</Form.Label>
                      <Form.Control
                        autoFocus
                        type="text"
                        id="usuarioEmail"
                        name="usuarioEmail"
                        value={usuariosEmail}
                        onChange={handleChange}
                        placeholder="Ingrese el correo electronico del usuario"
                        isValid={touched.usuarioEmail && !errors.usuarioEmail}
                        isInvalid={errors.usuarioEmail}
                      ></Form.Control>
                    </Form.Group>
                    {touched.usuarioEmail && errors.usuarioEmail && (
                      <div className="text-danger">{errors.usuarioEmail} </div>
                    )}
                  </Col>
                  <Col md={4} sm={12} xs={12} className="mt-4">
                    <Form.Group>
                      <Form.Label>Teléfono</Form.Label>
                      <Form.Control
                        type="text"
                        id="empleadosTelefono"
                        name="empleadosTelefono"
                        value={empleadosTelefono}
                        onChange={handleChange}
                        placeholder="Ingrese el número telefonico del Empleado"
                        isInvalid={
                          touched.empleadosTelefono && errors.empleadosTelefono
                        }
                        onKeyPress={onlyNumbers}
                      ></Form.Control>
                      {touched.empleadosTelefono &&
                        errors.empleadosTelefono && (
                          <div className="text-danger">
                            {errors.empleadosTelefono}
                          </div>
                        )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={4} sm={12} xs={12} className="mt-4">
                    <Form.Group>
                      <Form.Label>Direccion</Form.Label>
                      <Form.Control
                        as="textarea"
                        id="empleadosDireccion"
                        name="empleadosDireccion"
                        value={empleadosDireccion}
                        onChange={handleChange}
                        isInvalid={
                          errors.empleadosDireccion
                        }
                        onKeyPress={onlyAddress}
                      ></Form.Control>
                      {touched.empleadosDireccion &&
                        errors.empleadosDireccion && (
                          <div className="text-danger">
                            {errors.empleadosDireccion}
                          </div>
                        )}
                    </Form.Group>
                  </Col>
                  <Col md={4} sm={12} xs={12} className="mt-5">
                    <div className="mt-4 mx-2">
                      <p>
                        Cambia tu contraseña{"  "}
                        <button
                          className="btn btn-primary btn-sm"
                          type="button"
                          onClick={handleShowModalContrasenia}
                        >
                          Aqui
                        </button>
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row className="justify-content-end">
                  <Col className="mt-2" md={4}>
                        <div className="mt-2 d-flex justify-content-end">
                          <Button  
                            size="md" 
                            variant="outline-info"
                            type="submit"
                          >
                            Guardar Datos
                          </Button>
                        </div>
                  </Col>
                </Row>
              </Form>
              <Row>
                <Col>
                </Col>
              </Row>
              {mostrarCambiarContrasenia && (
                <UsuariosModalPassword
                  mostrarCambiarContrasenia={mostrarCambiarContrasenia}
                  hanleCloseModalContrasenia={hanleCloseModalContrasenia}
                  setCambioContraseniaExitoso={setCambioContraseniaExitoso}
                  setShowLoading={setShowLoading}
                  usuariosId={usuariosId}
                />
              )}
            </Card>
          );
        }}
      </Formik>
    </>
  );
};

export default UsuarioPerfilForm;
