import React,{useState,useEffect} from "react";
import { useAuthContext } from "../contexts/authContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faCog,
  faEnvelopeOpen,
  faSearch,
  faSignOutAlt,
  faUserShield,
} from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import {
  Row,
  Col,
  Nav,
  Form,
  Image,
  Navbar,
  Dropdown,
  Container,
  ListGroup,
  InputGroup,
} from "@themesberg/react-bootstrap";
import customers from "../assets/img/tiendaBuenPrecio/util/customers.svg";
import { petitionImageToBase64 } from "../helpers/petition";
import { useNavigate } from "react-router-dom";
import { Paths } from "../config/router/paths";

const NavbarMenu = (props) => {
  const USER_IMAGE = "TBP_USER_IMG";
  const navigate = useNavigate();
  const { logout, token: Token, obtenerDatosUsuarios } = useAuthContext();
  const { userId, user, userRole, userImages = [] } = obtenerDatosUsuarios();
  //atob(window.localStorage.getItem(USER_IMAGE)) ??
  const [imagenUsuario, setImagenUsuario] = useState(customers);
  const [consultarImagenUsuario, setConsultarImagenUsuario] = useState(true)
  useEffect(() => {
    const obtenerImagenes = async (url, token) => {
      const formData = {};
      const urlTarget = url;
      const petitionMethod = "GET";
      const petition = await petitionImageToBase64(
        urlTarget,
        formData,
        petitionMethod,
        token
      );
      return petition;
    }
    (async ()=>{
      if(consultarImagenUsuario){
        setConsultarImagenUsuario(false );
        const {link:payload = ""} = userImages[0] ?? [];
        if(payload.trim().length > 2){
          const imagenBase64 = await obtenerImagenes(payload,Token);
          setImagenUsuario(imagenBase64);
          //window.localStorage.setItem(USER_IMAGE,btoa(imagenBase64));
        }
      }
    })()
  }, [consultarImagenUsuario,imagenUsuario,Token,userImages]);
  return (
    <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0">
      <Container fluid className="px-0">
        <div className="d-flex justify-content-end w-100">
          {/* <div className="d-flex align-items-center">
            <Form className="navbar-search">
              <Form.Group id="topbarSearch">
                <InputGroup className="input-group-merge search-bar">
                  <InputGroup.Text><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
                  <Form.Control type="text" placeholder="Search" />
                </InputGroup>
              </Form.Group>
            </Form>
          </div> */}
          <Nav className="align-items-left">
            {/* <Dropdown as={Nav.Item} onToggle={markNotificationsAsRead} >
              <Dropdown.Toggle as={Nav.Link} className="text-dark icon-notifications me-lg-3">
                <span className="icon icon-sm">
                  <FontAwesomeIcon icon={faBell} className="bell-shake" />
                  {areNotificationsRead ? null : <span className="icon-badge rounded-circle unread-notifications" />}
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-center mt-2 py-0">
                <ListGroup className="list-group-flush">
                  <Nav.Link href="#" className="text-center text-primary fw-bold border-bottom border-light py-3">
                    Notificaciones
                  </Nav.Link>

                  {notifications.map(n => <Notification key={`notification-${n.id}`} {...n} />)}

                  <Dropdown.Item className="text-center text-primary fw-bold py-3">
                    View all
                  </Dropdown.Item>
                </ListGroup>
              </Dropdown.Menu>
            </Dropdown> */}

            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                <div className="media d-flex align-items-center">
                  <Image
                    src={imagenUsuario}
                    className="user-avatar md-avatar rounded-circle"
                  />
                  <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                    <span className="mb-0 font-small fw-bold">{user}</span>
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                <Dropdown.Item className="fw-bold" onClick={()=>navigate(Paths.UsuarioPerfil.path)}>
                  <FontAwesomeIcon icon={faUserCircle} className="me-2" /> Mi Perfil
                </Dropdown.Item>
                {/* <Dropdown.Item className="fw-bold">
                  // <FontAwesomeIcon icon={faUserShield} className="me-2" />{" "}
                  Support
                </Dropdown.Item> */}
                <Dropdown.Divider />
                <Dropdown.Item className="fw-bold" onClick={() => logout()}>
                  <FontAwesomeIcon
                    icon={faSignOutAlt}
                    className="text-danger me-2"
                  />{" "}
                  Salir
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
      </Container>
    </Navbar>
  );
};
export default NavbarMenu;
