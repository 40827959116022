import React from 'react';
import { useParams } from 'react-router-dom';
import add from "../../assets/img/tiendaBuenPrecio/util/plus02.svg";
import ProductoForm from '../../components/tiendaBuenPrecioComponents/Productos/ProductoForm';
import { useNavigate } from 'react-router-dom';
const EditarProductos = () => {
  const { id: productoId } = useParams();
  const navigate = useNavigate();
  const cancelarFormProductos = () => {
    navigate("/ListaProductos");
  }
  return (
    <div className="mt-4">
      <div className="px-0 mb-3" >
        <div className="d-flex justify-content-start">
          <div className="mx-3">
            <img src={add} width="30" height="30" className="svg-icon" alt="Agregar" />
          </div>
          <h4>
            Editar Producto
          </h4>
        </div>
      </div>
      <ProductoForm 
        formType="EDITAR" 
        productoId={productoId} 
        cancelarFormProductos={cancelarFormProductos}
      />
    </div>
  )
}

export default EditarProductos;