import { Form } from "@themesberg/react-bootstrap";
import React, { useEffect, useState } from "react";
import { obtenerPaises } from "../helper/clientesPeticiones";
import { useAuthContext } from "../../../../contexts/authContext";
import SweetAlert from "../../Utils/SweetAlert";

const ListaPaises = ({
  onChange,
  value,
  consultarPaises,
  setConsultarPaises,
  name,
  id,
}) => {
  const { logout, token: Token } = useAuthContext();
  const [listaPaises, setListaPaises] = useState([]);
  useEffect(() => {
    (async () => {
      if (consultarPaises) {
        setConsultarPaises(false);
        const obtenerPaisesResultado = await obtenerPaises(Token);
        const { status, ok } = obtenerPaisesResultado;
        if (status === 401) {
          logout();
          return false;
        }
        if (!ok) {
          throw new Error("Error de petición.");
        }
        const response = await obtenerPaisesResultado.json();
        const { success = false, message = "", data = {} } = response;
        if (!success) {
          SweetAlert(
            "Error",
            message || "Ocurrió un error al obtener los paises.",
            "error",
            true
          );
        }
        setListaPaises(data);
      }
    })();
  }, [consultarPaises, Token, logout, setConsultarPaises]);
  return (
    <>
      <Form.Group 
        style={{ marginRight: "10px",flex:1 }}
      >
        <Form.Label>Pais</Form.Label>
        <Form.Select
          aria-label="Paises"
          onChange={onChange}
          value={value}
          name={name}
          id={id}
        >
          <option value="0">Seleccionar</option>
          {listaPaises.map((pais) => (
            <option value={pais.paisId} key={pais.paisId}>{pais.paisNombre}</option>
          ))}
        </Form.Select>
      </Form.Group>
    </>
  );
};

export default ListaPaises;
