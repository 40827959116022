import { React,useCallback, useEffect, useState } from "react";
import { BsCart3 } from "react-icons/bs";
import { useParams } from "react-router-dom";
import { Routes } from "../../routes";
import { useNavigate } from "react-router-dom";
import { facturaCompraPeticiones } from "../../components/tiendaBuenPrecioComponents/FacturacionCompras/helper/facturaCompraPeticiones";
import SweetAlertQuestion from "../../components/tiendaBuenPrecioComponents/Utils/SweetAlertQuestion";
import FacturaCompra from "../../components/tiendaBuenPrecioComponents/FacturacionCompras/FacturaCompra";
import { Paths } from "../../config/router/paths";
import { useAuthContext } from "../../contexts/authContext";

const EditarFacturaCompra = () => {
  const { logout, token: Token } = useAuthContext();
  const [consultarDatosFactura, setConsultarDatosFactura] = useState(true);
  const [listaProductosDefaultState, setListaProductosDefaultState] = useState(
    []
  );
  const [datosFacturaDefault, setDatosFacturaDefault] = useState({});
  const navigate = useNavigate();
  let { id: facturaId = 0 } = useParams();
  facturaId = parseInt(facturaId);
  const accionCancelar = useCallback(() => {
    const pathListaFactura = Paths.ListaFacturaCompra.path;
    navigate(pathListaFactura);
  }, [navigate]);

  useEffect(() => {
    const consultaDatosFactura = async () => {
      const errorAlertParams = {
        tipo: "error",
        titulo: "Error",
        mensaje:
          "No se pudieron obtener los datos de factura. Por favor contacte al administrador.",
        textoBotonConfirmar: "OK",
        mostrarBotonDenegar: false,
      };
      const request = await facturaCompraPeticiones(
        "getDatosFacturaCompra",
        Token,
        facturaId
      );
      const { status, ok } = request;
      if (status === 401) {
        logout();
        return false;
      }
      if (!ok) {
        throw new Error("Error de petición.");
      }
      const response = await request.json();
      const { success, message, data } = response;
      if (!success) {
        await SweetAlertQuestion(errorAlertParams);
        accionCancelar();
      }
      if (data.length < 1) {
        await SweetAlertQuestion(errorAlertParams);
        accionCancelar();
        return false;
      }

      const {
        facturaCompraCodigo: facturaCodigo,
        facturaCompraCodigoCompra: facturaCodigoCompra,
        facturaCompraCodigoPedido: facturaCodigoPedido,
        facturaCompraFecha: facturaFecha,
        facturaCompraFechaVencimiento: facturaFechaVencimiento,
        facturaCompraComentarios: facturaComentarios,
        facturaCompraIva: facturaIva,
        facturaCompraSubTotal: facturaSubTotal,
        facturaCompraTotal: facturaTotal,
        facturaCompraTotalIva: facturaTotalIva,
        facturaCompraTotalUno: facturaTotalIvaUno,
        facturaCompraUnoPorcientoHabilitado = 0,
        proveedoresId,
        listaProductosFacturaCompra,
      } = data;
      let facturaUnoPorcientoHabilitado =
        parseInt(facturaCompraUnoPorcientoHabilitado) === 1 ? true : false;
      let facturaFechaFormateada = null;
      if (facturaFecha.toString().length > 1) {
        facturaFechaFormateada = new Date(facturaFecha);
      }
      let facturaFechaVencimientoFormateada = null;
      if (facturaFechaVencimiento.toString().length > 1) {
        facturaFechaVencimientoFormateada = new Date(facturaFechaVencimiento);
      }
      const listaProductosFacturaCompraFormateada =
        listaProductosFacturaCompra.map((producto) => {
          const {
            productoId,
            productoCodigo,
            productoDescripcion,
            productoPrecioCompra,
            productoCantidad,
            // productoPrecioVenta,
            // productoMontoSinIva,
            // productoMonto,
            // productoActivo,
            // productoEliminado,
            // productoFechaCreacion,
            // productoFechaEdicion,
            // productoFechaEliminacion,
            // productoIdUsuariosCreo,
            // productoIdUsuariosActualizo,
            // productoIdUsuariosElimino,
            productoPrecioLista,
          } = producto;
          return {
            productosId: productoId,
            codigoProducto: productoCodigo,
            descripcionProducto: productoDescripcion,
            precioVentaListaProducto: productoPrecioLista,
            precioCompraIndividualProducto: productoPrecioCompra,
            nuevaExistenciaProducto: productoCantidad,
          };
        });
      setListaProductosDefaultState(listaProductosFacturaCompraFormateada);
      setDatosFacturaDefault({
        proveedoresId,
        facturaCodigo,
        facturaCodigoCompra,
        facturaCodigoPedido,
        facturaFecha: facturaFechaFormateada,
        facturaFechaVencimiento: facturaFechaVencimientoFormateada,
        facturaUnoPorcientoHabilitado,
        facturaTotalIvaUno,
        facturaComentarios,
        facturaTotalIva,
        facturaTotal,
        facturaSubTotal,
        facturaIva,
      });
    };
    if (consultarDatosFactura) {
      setConsultarDatosFactura(false);
      consultaDatosFactura();
    }
  }, [consultarDatosFactura, facturaId, Token, logout, accionCancelar]);
  return (
    <div className="mt-4">
      <div className="d-flex">
        <BsCart3 className="mx-3" size="2em" style={{ fontWeight: "800" }} />
        <h3>Facturación Compras</h3>
      </div>
      <div>
        {Object.keys(datosFacturaDefault).length > 0 && (
          <FacturaCompra
            type="EDITAR"
            facturaId={facturaId}
            datosFacturaDefault={datosFacturaDefault}
            listaProductosDefaultState={listaProductosDefaultState}
            accionCancelar={accionCancelar}
          />
        )}
      </div>
    </div>
  );
};
export default EditarFacturaCompra;
