import React, { useState,useEffect } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
} from "@themesberg/react-bootstrap";
import { petitionImageToBase64 } from "../../../helpers/petition";
import SweetAlert from "../Utils/SweetAlert";
import { useAuthContext } from "../../../contexts/authContext";
import onlyLettersAndNumbers from "../Utils/onlyLettersAndNumbers";
import onlyNumbers from "../Utils/onlyNumbers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-date-picker";
import moment from "moment-timezone";

const EmpleadosPdfReciboModalGenerador = ({
  mostrarModalPdf,
  setMostrarModalPdf,
  reciboPdfEmpleadoDatos,
}) => {
  const {
    empleadosNombres,
    empleadosApellidos,
    empleadosId,
    empleadosSalario,
  } = reciboPdfEmpleadoDatos;
  const obtenerConceptoQuincena = () => {
    const fechaActual = new Date();
    const mes = fechaActual.getMonth() + 1;
    const año = fechaActual.getFullYear();
    const quincena = fechaActual.getDate() <= 15 ? "primer" : "segunda";
    const nombreMeses = [
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "diciembre",
    ];
    const nombreMes = nombreMeses[mes - 1];
    const cadena = `Pago de ${quincena} quincena de ${nombreMes} del ${año}`;
    return cadena;
  };
  const { logout, token: Token } = useAuthContext();
  const [pdfUrlRecibo, setPdfUrlRecibo] = useState(false);
  const [mostrarIframePDF, setMostrarIframePDF] = useState(false);
  const [datosReciboSueldo, setDatosReciboSueldo] = useState({
    conceptoPDFRecibo: obtenerConceptoQuincena(),
    montoTotalPagoPDFRecibo: parseFloat(empleadosSalario / 2).toFixed(2),
    fechaPagoPDFRecibo: new Date(),
    deduccionPagoPDFRecibo: 0.0,
    conceptoDeduccionPDFRecibo: "",
  });
  const {
    conceptoPDFRecibo,
    montoTotalPagoPDFRecibo,
    fechaPagoPDFRecibo,
    deduccionPagoPDFRecibo,
    conceptoDeduccionPDFRecibo,
  } = datosReciboSueldo;
  const handleChange = (event) => {
    const { target: input } = event;
    const { name, value } = input;
    setDatosReciboSueldo({ ...datosReciboSueldo, [name]: value });
    // if(name==="deduccionPagoPDFRecibo" || name==="deduccionPagoPDFRecibo"){
    //   let total = montoTotalPagoPDFRecibo - conceptoDeduccionPDFRecibo;
    //   if(total<0.01){
    //     total = 0.00;
    //   }
    //   total = parseFloat(total);
    //   setDatosReciboSueldo({ ...datosReciboSueldo, "montoTotalPagoPDFRecibo": total });
    // }
  };
  useEffect(() => {
    let total = Number(empleadosSalario/2) - Number(deduccionPagoPDFRecibo);
    if(total<0.01){
      total = 0.00;
    }
    setDatosReciboSueldo({ ...datosReciboSueldo, "montoTotalPagoPDFRecibo": total });
  }, [empleadosSalario,deduccionPagoPDFRecibo])
  
  const generarReciboPDF = () => {
    let fechaPagoPDFReciboFormateada = fechaPagoPDFRecibo;
    if (!isNaN(Date.parse(fechaPagoPDFReciboFormateada))) {
      fechaPagoPDFReciboFormateada = moment(fechaPagoPDFReciboFormateada)
        .utcOffset("-06:00")
        .format("DD/MM/YYYY");
    }
    const payload = `archivos/reciboSueldoEmpleado/${empleadosId}/${encodeURIComponent(btoa(conceptoPDFRecibo))}/${encodeURIComponent(btoa(montoTotalPagoPDFRecibo))}/${encodeURIComponent(btoa(fechaPagoPDFReciboFormateada))}/${encodeURIComponent(btoa(deduccionPagoPDFRecibo))}/${encodeURIComponent(btoa(conceptoDeduccionPDFRecibo))}
    `;
    const obtenerPDF = async (url, token) => {
      const formData = {};
      const urlTarget = url;
      const petitionMethod = "GET";
      const petition = await petitionImageToBase64(
        urlTarget,
        formData,
        petitionMethod,
        token,
        "PDF"
      );
      if (
        typeof petition === "object" &&
        petition !== null &&
        !Array.isArray(petition)
      ) {
        const {
          message = "Ocurrió un error inesperado al generar el pdf de la factura, por favor contacte con el administrador.",
        } = petition;
        SweetAlert("Error", message, "error", true);
        return false;
      }
      setMostrarIframePDF(true);
      setMostrarModalPdf(true);
      setPdfUrlRecibo(petition);
      // const a = document.createElement('a');
      // a.href = petition;
      // a.download = 'nombre_del_archivo.pdf'; // Cambia 'nombre_del_archivo.pdf' al nombre deseado del archivo para descargar
      // a.click();
    };
    obtenerPDF(payload, Token);
  };
  return (
    <Modal
      as={Modal.Dialog}
      show={mostrarModalPdf}
      onHide={() => setMostrarModalPdf(false)}
      centered
      backdrop="static"
      keyboard={false}
      //   size="xl"
      dialogClassName={mostrarIframePDF ? "modal-90w" : ""}
    >
      <div>
        <Modal.Header className="text-center">
          <p className="mt-2 mx-2 h3">Generador de boleta de pago</p>
          <Button
            variant="close"
            aria-label="Close"
            onClick={() => setMostrarModalPdf(false)}
          />
        </Modal.Header>

        <Modal.Body style={mostrarIframePDF ? { height: "100vh" } : {}}>
          {!mostrarIframePDF ? (
            <Container>
              <Row className="mb-4 mx-1">
                <Col xs={12}>
                  <p className="h5">
                    <strong>
                      {empleadosNombres} {empleadosApellidos}
                    </strong>
                  </p>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col xs={12} md={12}>
                  <Form.Group>
                    <Form.Label>Concepto Pago</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      id="conceptoPDFRecibo"
                      name="conceptoPDFRecibo"
                      value={conceptoPDFRecibo}
                      onChange={handleChange}
                      placeholder="Ingrese el concepto de pago."
                      onKeyPress={(event) =>
                        event.target.value.length > 100
                          ? event.preventDefault()
                          : onlyLettersAndNumbers(event, true)
                      }
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col xs={12} md={12}>
                  <Form.Group>
                    <Form.Label>Concepto Deducción</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      id="conceptoDeduccionPDFRecibo"
                      name="conceptoDeduccionPDFRecibo"
                      value={conceptoDeduccionPDFRecibo}
                      onChange={handleChange}
                      placeholder="Ingrese el concepto de la deducción."
                      onKeyPress={(event) =>
                        event.target.value.length > 100
                          ? event.preventDefault()
                          : onlyLettersAndNumbers(event, true)
                      }
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col xs={12} md={6}>
                  <Form.Group>
                    <Form.Label>Deducción</Form.Label>
                    <Form.Control
                      type="number"
                      min={0.00}
                      id="deduccionPagoPDFRecibo"
                      name="deduccionPagoPDFRecibo"
                      value={deduccionPagoPDFRecibo}
                      onChange={handleChange}
                      placeholder="0.00"
                      onKeyPress={(event) =>
                        event.target.value.length > 9
                          ? event.preventDefault()
                          : onlyNumbers(event, false)
                      }
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group>
                    <Form.Label>Monto Total</Form.Label>
                    <Form.Control
                      type="number"
                      id="montoTotalPagoPDFRecibo"
                      name="montoTotalPagoPDFRecibo"
                      value={montoTotalPagoPDFRecibo}
                      onChange={handleChange}
                      placeholder="0.00"
                      min={0.00}
                      onKeyPress={(event) =>
                        event.target.value.length > 9
                          ? event.preventDefault()
                          : onlyNumbers(event, false)
                      }
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col xs={12} md={12}>
                  <Form.Group>
                    <Form.Label>Fecha de Pago</Form.Label>
                    <DatePicker
                      onChange={(value) =>
                        handleChange({
                          target: {
                            name: "fechaPagoPDFRecibo",
                            value: value,
                          },
                        })
                      }
                      className="form-control"
                      value={fechaPagoPDFRecibo}
                      format="dd/MM/yyyy"
                      locale="es-ES"
                      name="fechaPagoPDFRecibo"
                      id="fechaPagoPDFRecibo"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Button
                  size="md"
                  variant="tertiary"
                  className="mt-4 mx-1"
                  onClick={generarReciboPDF}
                >
                  {<FontAwesomeIcon icon={faFilePdf} />} Generar PDF
                </Button>
              </Row>
            </Container>
          ) : (
            ""
          )}
          {mostrarIframePDF && (
            <iframe
              title={"pdf"}
              src={`/pdfjs/web/viewer.html?file=${pdfUrlRecibo}`}
              height="100%"
              width="100%"
            />
          )}
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </div>
    </Modal>
  );
};

export default EmpleadosPdfReciboModalGenerador;
