import React from 'react';
import { Alert } from '@themesberg/react-bootstrap';
export const ErrorAlert = ({ title, message,referencia, extraClassNames = "" }) => {
  return (
    <div ref={referencia}>
      <Alert variant="danger" className={extraClassNames ? extraClassNames : ""}>
        <Alert.Heading>{title}</Alert.Heading>
        <p>
          {message}
        </p>
      </Alert>
    </div>
  );
};
