import { Form } from '@themesberg/react-bootstrap';
import React, { useEffect,useState } from 'react';
import { makePetition } from '../../../helpers/petition';
import { Block } from 'notiflix/build/notiflix-block-aio';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { useAuthContext } from '../../../contexts/authContext';
const ProductosBuscarPorCodigo = ({
    buscarPorCodigo,
    setBuscarPorCodigo,
    setFacturaDatosProductoPreview
}) => {
    const { logout, token: Token } = useAuthContext();
    const [consultarCodigoProducto, setConsultarCodigoProducto] = useState(false)
    const handleChange = ({ target }) => {
        const { value } = target;
        setBuscarPorCodigo(value);
        if (buscarPorCodigo.trim() !== value.trim()) {
            setConsultarCodigoProducto(true);
        }
    };
    useEffect(() => {
        const consultarProductoPorCodigo = async () => {
            Block.standard('.facturaContainer', {
                cssAnimationDuration: 1881,
                svgSize: '19px',
            });
            const urlTarget = `productos/productoPorCodigo/${encodeURIComponent(btoa(buscarPorCodigo))}`;
            const petitionMethod = "GET";
            const formData = {};
            const request = await makePetition(urlTarget, formData, petitionMethod, Token);
            const { status, ok } = request;
            if (status === 401) {
                setFacturaDatosProductoPreview([]);
                Notify.failure('Ocurrió un error inesperado');
                return true;
            }
            if (!ok) {
              throw new Error("Error de petición.");
            }
            const response = await request.json();
            const { success = false, message = "", data = [] } = response;
            Block.remove('.facturaContainer');
            if (success && Object.keys(data).length > 0) {
                setFacturaDatosProductoPreview(data);
                Notify.success('Producto Encontrado');
                return true;
            } else {
                Notify.failure(message || 'Producto no encontrado');
                return false;
            }
        };
        const delayDebounceFn = setTimeout(() => {
            if (buscarPorCodigo && buscarPorCodigo.trim().length > 3 && consultarCodigoProducto) {
                setConsultarCodigoProducto(false);
                consultarProductoPorCodigo();
            }
        }, 2000);
        if(consultarCodigoProducto){
            return () => clearTimeout(delayDebounceFn);
        }
    }, [buscarPorCodigo, setFacturaDatosProductoPreview, consultarCodigoProducto, Token]);

    return (
        <>
            <Form.Label>Buscar Producto Por Código</Form.Label>
            <Form.Control
                type="text"
                name="facturaProductosCodigo"
                placeholder="Búsqueda por código"
                onChange={handleChange}
                value={buscarPorCodigo}
            />
        </>
    )
}

export default ProductosBuscarPorCodigo;