import React from "react";
import { BsBasket3, BsPlusLg } from "react-icons/bs";
import { Link } from "react-router-dom";
import FacturaLista from "../../components/tiendaBuenPrecioComponents/Facturacion/FacturaLista";
import { Paths } from "../../config/router/paths";

const ListaFactura = () => {
  return (
    <div className='mt-4'>
      <div className="px-0 mb-3 d-flex justify-content-between" >
        <div className="d-flex justify-content-start">
          <div className="mx-3">
            <BsBasket3 size="2em" />
          </div>
          <h4>
            Lista Facturas
          </h4>
        </div>
        <div className=''>
          <Link
            className='mx-2 btn btn-lg btn-outline-primary'
            to={Paths.AgregarFactura.path}
          >
            <BsPlusLg className='mx-2' />  Nueva Factura
          </Link>

        </div>
      </div>
      <FacturaLista />
    </div>
  )
}
export default ListaFactura;