
import { makePetition } from "../../../../helpers/petition";

const fetchIventario = async (
  requesUrl,
  requestMethod,
  requestToken,
  requestData
) => {
  try {
    const request = await makePetition(
      requesUrl,
      requestData,
      requestMethod,
      requestToken
    );
    return request;
  } catch (error) {
    return {
      success: false,
      message:
        "Ocurrió un error inexperado, por favor vuelva a intentar. Si este error persiste contacte al administrador del sistema",
    };
  }
};
const inventarioPeticiones = async (method, token, requestData = {}) => {
  switch (method) {
    case "getListaInventario":
      return await fetchIventario(
        `inventario/lista`,
        "GET",
        token,
        requestData
      );
    default:
      return {
        success: false,
        message:
          "Ocurrió un error inexperado, por favor vuelva a intentar. Si este error persiste contacte al administrador del sistema",
      };
  }
};

export { inventarioPeticiones };
