import { consultarListaPrecioVentas } from "./productosPeticiones";

const consultarListaPrecioVentasHandle = async (productosId,token) => {
    try {
        if (productosId === undefined || !productosId || isNaN(productosId)  ||parseInt(productosId) < 1) {
            return {
                success: false,
                message: "El producto no puede ser identificado"
            };
        }
        return await consultarListaPrecioVentas(productosId,token);
    } catch (error) {
        return {
            success: false,
            message: "Ocurrió un error inesperado, por favor contacte el Administrador."
        };
    }
}

export default consultarListaPrecioVentasHandle;