import React from 'react'
import { BsPlusLg, BsTruck } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import ProveedoresLista from '../../components/tiendaBuenPrecioComponents/Proveedores/ProveedoresLista';
import { Routes } from '../../routes';
import { Paths } from '../../config/router/paths';
const ListaProveedores = () => {
    return (
        <div className='mt-4'>
            <div className="px-0 mb-3 d-flex justify-content-between" >
                <div className="d-flex justify-content-start">
                    <BsTruck size="2em" className="mx-4"/>
                    <h4>
                        Lista Proveedores
                    </h4>
                </div>
                <div className=''>
                    <Link
                        className='mx-2 btn btn-lg btn-outline-primary'
                        to={Paths.AgregarProveedores.path}
                    >
                        <BsPlusLg className='mx-2'/>  Nuevo Proveedor
                    </Link>
                </div>
            </div>
            <ProveedoresLista />
        </div>
    )
}

export default ListaProveedores;