import React from 'react';
import { Alert } from '@themesberg/react-bootstrap';
import { BsCart3 } from 'react-icons/bs';
import { useParams } from 'react-router-dom';
import Factura from '../../components/tiendaBuenPrecioComponents/Facturacion/Factura';
const EditarFactura = () => {
    let { id: facturaId = 0 } = useParams();
    facturaId = parseInt(facturaId);
    return (
        <div className="mt-4">
            <div className='d-flex'>
                <BsCart3 className='mx-3' size="2em" style={{ fontWeight: "800" }} />
                <h3>Facturación</h3>
            </div>
            <div >
                {
                    facturaId > 0
                        ?
                        (<Factura type="EDITAR" facturaId={facturaId} />)
                        :
                        (
                            <div className='container mt-4 mb-4'>
                                {/*mostrar mensaje de error */}
                                <Alert variant="danger" >
                                    <Alert.Heading>Error</Alert.Heading>
                                    <p>
                                        No se ha encontrado la factura que desea editar. Si cree que esto es un error por favor contacte con el administrador
                                    </p>
                                </Alert>
                            </div>
                        )

                }
            </div>
        </div>
    );
}


export default EditarFactura;